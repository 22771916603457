import { getApolloClient } from '../clients/apollo';
import { GRAPH_EVENTS } from './graphEvents.graphql';

async function fetchGraphEvents(siteId, blockId, startDate, endDate) {
  const { loading, error, data } = await getApolloClient().query({
    query: GRAPH_EVENTS,
    variables: {
      siteId,
      blockId,
      startDate,
      endDate,
    },
  });

  let graphEvents;
  if (data) {
    graphEvents = data.viewer.site.block.events;
  }

  return {
    loading,
    error,
    graphEvents,
  };
}

export { fetchGraphEvents };
