// services
import { SORT_ORDER } from '../../utils/sorting';

const SORT_COLUMNS = {
  COMPONENT: 'component',
  STATUS: 'status',
  RPM: 'rpm',
};

const compareComponent = (a, b, sortOrder) => {
  const alias1 = a.alias ? a.alias.toLowerCase() : '';
  const alias2 = b.alias ? b.alias.toLowerCase() : '';
  let compareValue = alias1.localeCompare(alias2);
  if (compareValue === 0) {
    const name1 = a.name ? a.name.toLowerCase() : '';
    const name2 = b.name ? b.name.toLowerCase() : '';
    compareValue = name1.localeCompare(name2);
  }
  return sortOrder === SORT_ORDER.ASC ? compareValue : -compareValue;
};

const sortAutomationComponents = (components, column, order) => {
  const compareByComponent = (a, b) => compareComponent(a, b, order);

  const compareByStatus = (a, b) => {
    const statusIndexA = a.statusOrder();
    const statusIndexB = b.statusOrder();

    if (statusIndexA > statusIndexB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (statusIndexA < statusIndexB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else {
      return compareComponent(a, b, order);
    }
  };

  const compareByRPM = (a, b) => {
    const rpmAValues = a.getRPMValues();
    const rpmBValues = b.getRPMValues();
    const rpmA = rpmAValues ? rpmAValues[0] : null;
    const rpmB = rpmBValues ? rpmBValues[0] : null;

    if (rpmA > rpmB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (rpmA < rpmB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else {
      return compareComponent(a, b, order);
    }
  };

  switch (column) {
    case SORT_COLUMNS.COMPONENT: {
      components.sort(compareByComponent);
      break;
    }
    case SORT_COLUMNS.STATUS: {
      components.sort(compareByStatus);
      break;
    }
    case SORT_COLUMNS.RPM: {
      components.sort(compareByRPM);
      break;
    }
  }
};

export { sortAutomationComponents, SORT_COLUMNS };
