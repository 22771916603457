export const EMPTY_NOTE_ERROR_CODE = 1;
export const NO_BLOCK_ERROR_CODE = 2;

export const getNoteErrorTranslationKey = (errorCode) => {
  switch (errorCode) {
    case EMPTY_NOTE_ERROR_CODE:
      return 'note_edit_error_empty_note';
    case NO_BLOCK_ERROR_CODE:
      return 'note_edit_error_no_block_selected';
    case 3011:
      return 'note_edit_error_maximum_number_of_notes_reached';
    default:
      return 'generic_error_server_error_message';
  }
};
