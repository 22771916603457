import moment from 'moment-timezone';
import { compareBlock, SORT_ORDER } from '../../utils/sorting';

const SORT_COLUMNS = {
  STATUS: 'status',
  SERIAL: 'serial',
  BLOCK: 'block',
  BATTERY: 'battery',
  SIGNAL: 'signal',
};

const sortDevicesHealth = (devices, column, order) => {
  const compareByBlock = (a, b) => compareBlock(a.block, b.block, order);

  const compareByStatus = (a, b) => {
    const lastUpdatedA = a.lastUpdated;
    const lastUpdatedB = b.lastUpdated;
    const value1 = lastUpdatedA ? moment(lastUpdatedA) : null;
    const value2 = lastUpdatedB ? moment(lastUpdatedB) : null;

    if (value1 === null && value2 === null) {
      return 0;
    }

    if (value1 === null) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    }

    if (value2 === null) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }

    if (value1.isAfter(value2)) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else if (value1.isBefore(value2)) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    }
    return 0;
  };

  const compareBySerial = (a, b) => {
    const serialA = a.serialNumber ? a.serialNumber : -1;
    const serialB = b.serialNumber ? b.serialNumber : -1;

    return compareNumber(serialA, serialB);
  };

  const compareByBattery = (a, b) => {
    return compareNumber(a.batteryValue(), b.batteryValue());
  };

  const compareBySignal = (a, b) => {
    return compareNumber(a.signalValue(), b.signalValue());
  };

  const compareNumber = (a, b) => {
    if (a > b) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (a < b) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }

    return 0;
  };

  switch (column) {
    case SORT_COLUMNS.BLOCK: {
      devices.sort(compareByBlock);
      break;
    }
    case SORT_COLUMNS.STATUS: {
      devices.sort(compareByStatus);
      break;
    }
    case SORT_COLUMNS.SERIAL: {
      devices.sort(compareBySerial);
      break;
    }
    case SORT_COLUMNS.BATTERY: {
      devices.sort(compareByBattery);
      break;
    }
    case SORT_COLUMNS.SIGNAL: {
      devices.sort(compareBySignal);
      break;
    }
  }
};

export { sortDevicesHealth, SORT_COLUMNS };
