class CancellableManager {
  constructor() {
    this.registeredTimers = [];
    this.registeredTasks = [];
  }

  registerTimer(timer) {
    this.registeredTimers.push(timer);
  }

  registerTask(task) {
    this.registeredTasks.push(task);
  }

  cancelAll() {
    this.registeredTimers.forEach((timer) => clearInterval(timer));
    this.registeredTasks.forEach((task) => task.cancel());
    this.registeredTimers = [];
    this.registeredTasks = [];
  }
}

export default new CancellableManager();
