export class FlowStationBlockPresenter {
  constructor(block) {
    this.block = block;
  }

  getFirstFlowmeterUnit() {
    const flowmeters = this.block.flowStationCapability().flowmeters();
    return flowmeters[0] && flowmeters[0].outflow && flowmeters[0].outflow.dataStream
      ? flowmeters[0].outflow.dataStream.unit
      : undefined;
  }

  getFlowmetersFlowDataStreamsIds() {
    return this.block
      .flowStationCapability()
      .flowmeters()
      .reduce((ids, flowmeter) => {
        if (flowmeter.outflow && flowmeter.outflow.id) {
          ids.push(flowmeter.outflow.id);
        }
        return ids;
      }, []);
  }

  getFlowmeters() {
    return this.block.flowStationCapability().flowmeters();
  }
}
