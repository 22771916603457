import moment from 'moment-timezone';

// services
import { formattedTimeDifferenceForSchedule } from '../utils/dateUtils';
import * as TensionStatus from '../models/status/tensionStatus';
import IrrigationEvent from '../models/entities/irrigationEvent';

//constant
import { AUTOMATED_PLANNED, INFERRED, PLANNED } from '../models/calendarType';

export default class SchedulePresenter {
  constructor(schedule) {
    this.blocks = this.initBlocks(schedule);
    this.blockGroups = this.initGroups(schedule);
  }

  initBlocks(schedule) {
    const blocks = new Map([]);

    schedule.blocks.forEach((block) => {
      const inferredEvents = this._initInferredEvents(block);
      const manualPlannedEvents = this._initManualPlannedEvents(block);
      const automatedPlannedEvents = this._initAutomatedPlannedEvents(block);

      blocks.set(block.id, {
        alias: block.alias,
        capabilities: block.capabilities,
        id: block.id,
        groupId: block.groupId,
        name: block.name,
        notes: block.notes || [],
        isVirtual: block?.isVirtual ? block.isVirtual : false,
        inferredEvents,
        manualPlannedEvents,
        automatedPlannedEvents,
      });
    });

    return blocks;
  }

  initGroups(schedule) {
    const blockGroups = new Map([]);

    schedule.blockGroups.forEach((group) => {
      if (group.elementIds?.length > 0 && this.getBlocksWithinGroup(group.groupId).length > 0) {
        blockGroups.set(group.groupId, {
          id: group.groupId,
          name: group.name,
          elementIds: group.elementIds,
        });
      }
    });
    return blockGroups;
  }

  _initInferredEvents(block) {
    if (!block.events || block.events.length === 0) {
      return [];
    }

    return block.events.map((event) => {
      return this._createScheduleEvent(event, INFERRED);
    });
  }

  _initManualPlannedEvents(block) {
    return (
      block?.calendars
        ?.find((calendar) => calendar.type === PLANNED)
        ?.events.map((event) => {
          return this._createScheduleEvent(event, PLANNED);
        }) ?? []
    );
  }

  _initAutomatedPlannedEvents(block) {
    return (
      block?.schedule?.scheduleEvents?.map((event) => {
        return this._createScheduleEvent(event, AUTOMATED_PLANNED);
      }) ?? []
    );
  }

  _createScheduleEvent(event, calendarType) {
    return new IrrigationEvent({ ...event, calendarType });
  }

  getEventsDuration(eventType, block, dayOfYear, year) {
    return this.getDailyEvents(eventType, block, dayOfYear, year).reduce((total, event) => {
      const duration = moment.duration(event.duration);
      const durationAsMinutes = Math.ceil(duration.asMinutes());
      return total.add(durationAsMinutes, 'm');
    }, moment.duration(0));
  }

  getEventDurationFormat(eventDuration) {
    return formattedTimeDifferenceForSchedule(moment.duration(eventDuration));
  }

  getDailyEvents(eventType, block, dayOfYear, year) {
    if (!this.blocks.has(block.id)) {
      return [];
    }

    const events = this._mapConstantsToFields(eventType);

    return this.blocks.get(block.id)[events].filter((event) => event.dayOfYear === dayOfYear && event.year === year);
  }

  /* istanbul ignore next */
  getAllDailyEvents(block, dayOfYear, year) {
    if (!this.blocks.has(block.id)) {
      return [];
    }

    return this.getDailyEvents(INFERRED, block, dayOfYear, year)
      .concat(this.getDailyEvents(PLANNED, block, dayOfYear, year))
      .concat(this.getDailyEvents(AUTOMATED_PLANNED, block, dayOfYear, year))
      .sort((a, b) => {
        return a.anytimeDayEvent === true ? -1 : b.anytimeDayEvent === true ? 1 : b.startDate.diff(a.startDate);
      });
  }

  getBlocks() {
    return Array.from(this.blocks.values());
  }

  getBlocksWithoutOutOfFieldStatus() {
    return this.getBlocks().filter((block) => block.capabilities.tension.status !== TensionStatus.OUT_OF_FIELD);
  }

  getBlocksWithinGroup(groupId) {
    return this.getBlocks().filter((block) => block.groupId === groupId);
  }

  getBlocksWithoutGroup() {
    return this.getBlocks().filter((block) => block.groupId === null && !block.isVirtual);
  }

  getVirtualBlocksWithoutGroup() {
    return this.getBlocks().filter((block) => block.groupId === null && block.isVirtual);
  }

  getBlockGroups() {
    return Array.from(this.blockGroups.values());
  }

  getDayLabels(startDate, endDate) {
    const labels = [];
    let currentDate = startDate.clone();

    while (currentDate.isBefore(endDate)) {
      labels.push(currentDate.valueOf());
      currentDate = currentDate.add({ days: 1 });
    }

    return labels;
  }

  _mapConstantsToFields(eventType) {
    switch (eventType) {
      case INFERRED:
        return 'inferredEvents';
      case PLANNED:
        return 'manualPlannedEvents';
      case AUTOMATED_PLANNED:
        return 'automatedPlannedEvents';
    }
  }
}
