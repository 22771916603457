import PropTypes from 'prop-types';

export const dataStreamShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataStreamType: PropTypes.string.isRequired,
  isVirtual: PropTypes.bool.isRequired,
});

export const tensionReadingShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  colorCode: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string.isRequired,
  depth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  depthUnit: PropTypes.string,
});
