/* istanbul ignore file */
import React from 'react';
import { Text, TextInput, Pressable } from 'react-native';
import Proptypes from 'prop-types';
import moment from 'moment-timezone';

// services
import { useTranslation } from '../../../../hooks/useTranslation';

// components
import ShadowedDivider from '../../../ShadowedDivider';

// styles
import { globalStyles } from '../../../../styles';
import styles from './SelectStartDate.style';

// helpers
import { applyDateTimezone, removeDateTimezone } from './SelectStartDateHelper';

const SelectStartDate = ({ eventDate, isAnytimeEvent = true, isDatePickerVisible, onSelectDate, setDatePickerVisibility }) => {
  const { t } = useTranslation();
  const eventDateWithTimezone = applyDateTimezone(eventDate);

  const startDateLegend = t(isAnytimeEvent ? 'edit_event_start_time_only_date_title' : 'edit_event_start_time_title');
  const startDateFormat = eventDateWithTimezone.format(t(isAnytimeEvent ? 'date_format_long' : 'date_format_long_time'));

  const onChangeDate = (event, selectedDate) => {
    const currentDate = selectedDate || eventDateWithTimezone;
    const momentWithoutTimezone = removeDateTimezone(currentDate);

    return onSelectDate(momentWithoutTimezone);
  };

  return (
    <>
      <Pressable
        style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.container]}
        onPress={setDatePickerVisibility}
        testID={'schedule__datetime-picker-button'}>
        <Text style={styles.legendText}>{startDateLegend}</Text>
        <Text style={styles.valueText}>{startDateFormat}</Text>
      </Pressable>
      {isDatePickerVisible ? (
        <TextInput
          testID={'schedule__datetime-picker'}
          value={new Date(eventDateWithTimezone.format())}
          onChange={onChangeDate}
        />
      ) : null}
      <ShadowedDivider style={styles.divider} />
    </>
  );
};

SelectStartDate.propTypes = {
  eventDate: Proptypes.instanceOf(moment).isRequired,
  isAnytimeEvent: Proptypes.bool,
  isDatePickerVisible: Proptypes.bool,
  onSelectDate: Proptypes.func.isRequired,
  setDatePickerVisibility: Proptypes.func.isRequired,
};

export default SelectStartDate;
