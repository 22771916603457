import { SORT_ORDER, compareBlock } from '../../utils/sorting';

const SORT_COLUMNS = {
  BLOCK: 'block',
  DRY_BLUE_BAND_INDEX: 'dry',
  GOOD_BLUE_BAND_INDEX: 'good',
  WET_BLUE_BAND_INDEX: 'wet',
};

const sortTensionBlocks = (tensionBlocks, column, order) => {
  const compareByBlock = (a, b) => compareBlock(a, b, order);

  const compareByDryBluebandIndex = (a, b) => {
    return compareBluebandIndex(a.tensionCapability().blueBandIndex().dry, b.tensionCapability().blueBandIndex().dry);
  };

  const compareByGoodBluebandIndex = (a, b) => {
    return compareBluebandIndex(a.tensionCapability().blueBandIndex().good, b.tensionCapability().blueBandIndex().good);
  };

  const compareByWetBluebandIndex = (a, b) => {
    return compareBluebandIndex(a.tensionCapability().blueBandIndex().wet, b.tensionCapability().blueBandIndex().wet);
  };

  const compareBluebandIndex = (val1, val2) => {
    const value1 = val1 !== null ? val1 : -1;
    const value2 = val2 !== null ? val2 : -1;
    if (value1 > value2) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (value1 < value2) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return 0;
  };

  switch (column) {
    case SORT_COLUMNS.BLOCK: {
      tensionBlocks.sort(compareByBlock);
      break;
    }
    case SORT_COLUMNS.DRY_BLUE_BAND_INDEX: {
      tensionBlocks.sort(compareByDryBluebandIndex);
      break;
    }
    case SORT_COLUMNS.GOOD_BLUE_BAND_INDEX: {
      tensionBlocks.sort(compareByGoodBluebandIndex);
      break;
    }
    case SORT_COLUMNS.WET_BLUE_BAND_INDEX: {
      tensionBlocks.sort(compareByWetBluebandIndex);
      break;
    }
  }
};

export { sortTensionBlocks, SORT_COLUMNS };
