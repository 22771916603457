import moment from 'moment-timezone';

//Those functions resolve some issue from @react-native-community/datetimepicker using Date object.
//Date object may have a different timezone than Moment default and can override Moment timezone.
//Those functions help to apply Date object timezone or remove it.

const DATE_TIMEZONE_REGEX = '(\\+|\\-)\\d{4}';
const DATE_FORMAT_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss';

const extractOffsetFromDate = (momentDate) => {
  const dateString = moment.isMoment(momentDate) ? momentDate.toDate().toString() : moment(momentDate).toDate().toString();
  return dateString.match(DATE_TIMEZONE_REGEX)[0];
};

export const applyDateTimezone = (momentDate) => {
  const dateUtcOffset = extractOffsetFromDate(momentDate);
  return moment(momentDate).utcOffset(dateUtcOffset, true);
};

export const removeDateTimezone = (momentDate) => {
  const dateUtcOffset = extractOffsetFromDate(momentDate);
  const momentWithOffset = moment(momentDate).utcOffset(dateUtcOffset);
  const dateWithoutTimezone = momentWithOffset.format(DATE_FORMAT_WITHOUT_TIMEZONE);
  return moment(dateWithoutTimezone);
};
