import { bytesToString, stringToBytes } from '../../utils/stringUtil';

// services
import { BLE_ON, getBluetoothService } from '../../services/BluetoothService';
import { DeviceAskingForPassword, DeviceConfirmingConnection } from '../../services/BluetoothDataParsingService';

// utils
import { consoleDebug } from '../../utils/loggerUtil';
import terminalParser from '../../utils/terminalParser';

// constants
const MAX_TRACES_HISTORY_LENGTH = 100;

export default class BluetoothDeviceEntity {
  constructor(peripheral, dispatch) {
    this.peripherical = peripheral;
    this.dispatch = dispatch;

    this.bluetoothConnecting = false;
    this.bluetoothConnected = false;
    this.abortConnection = false;
    this.connected = false;
    this.connectionError = false;
    this.connectionLost = false;
    this.connectionHandle = null;

    this.traces = [];
    this.terminalParser = new terminalParser();
    this.showData = false;
  }

  getId() {
    return this.peripherical.id;
  }

  getSerialNumber() {
    return parseInt(this.peripherical.name, 10);
  }

  getKey() {
    return this.peripherical.name;
  }

  getName() {
    return this.peripherical.name;
  }

  setConnectionError() {
    this.abortConnection = false;
    this.connected = false;
    this.connectionError = true;
    this.connectionLost = false;
  }

  setConnectionLost() {
    this.abortConnection = false;
    this.connected = false;
    this.connectionError = false;
    this.connectionLost = true;
  }

  setDisconnected() {
    this.connected = false;
    this.connectionError = false;
    this.connectionLost = false;
  }

  setConnected() {
    this.abortConnection = false;
    this.connected = true;
    this.connectionError = false;
    this.connectionLost = false;
  }

  setBluetoothConnecting() {
    this.bluetoothConnecting = true;
    this.bluetoothConnected = false;
  }

  setBluetoothConnected() {
    this.bluetoothConnecting = false;
    this.bluetoothConnected = true;
  }

  setBluetoothDisconnected() {
    this.bluetoothConnecting = false;
    this.bluetoothConnected = false;
  }

  resetConnectionFlags() {
    this.connected = false;
    this.connectionError = false;
    this.connectionLost = false;
  }

  setShowData(shouldShow) {
    this.showData = shouldShow;
  }

  /*
   * Connection is in 2 steps:
   *  1) Perform the bluetooth connection
   *  2) Perform the "application" connection
   *
   * This function performs the bluetooth connection.
   */
  async connect() {
    if (!this.bluetoothConnecting && !this.bluetoothConnected) {
      consoleDebug('BluetoothDevice - Connecting ...');
      return new Promise(async (resolve, reject) => {
        try {
          this.resetConnectionFlags();
          this.setBluetoothConnecting();
          this.connectionHandle = await getBluetoothService().connect(
            this.getId(),
            (data) => this.onData(data, resolve, reject),
            () => this.onConnexionLost(reject),
          );
          this.setBluetoothConnected();

          if (this.abortConnection) {
            // Disconnection request arise while connecting to bluetooth
            await this.disconnect();
          }
          // On success, it WON'T resolve as there is still the "application" connection to be done.
        } catch (e) {
          this.setBluetoothDisconnected();
          this.setConnectionError();
          reject(`BluetoothDevice - Connection Error - ${e}`);
        }
      });
    }
    return Promise.resolve();
  }

  /*
   * Called by the native driver when connection is lost with device.
   */
  onConnexionLost(reject) {
    consoleDebug('BluetoothDevice - Connection Lost');
    this.disconnect();
    this.bluetoothConnected = false;

    if (!this.connected) {
      // If we are in the process of connecting, we must reject the 'connect()' promise.
      this.setConnectionError();
      reject('BluetoothDevice - Connection lost when connecting');
      return;
    }

    // If we are connected, no promise to reject, just update the store
    this.setConnectionLost();
    this.dispatch.console.updateSelectedDevice(this);
  }

  async onData(data, resolve, reject) {
    const payload = bytesToString(data);
    if (!this.connected) {
      // If we are in the process of connecting, we must get our way through "application" connection.
      await this.completeConnexion(payload, resolve, reject);
      return;
    }

    // If we are connected, process the data!
    this.processData(payload);
    this.dispatch.console.updateSelectedDevice(this);
  }

  processData(data) {
    const lines = this.terminalParser.parse(data, !this.showData);
    this.traces.push(...lines);
    if (this.traces.length > MAX_TRACES_HISTORY_LENGTH) {
      this.traces = this.traces.slice(-MAX_TRACES_HISTORY_LENGTH);
    }
  }

  /*
   * This function performs the bluetooth connection.
   */
  async completeConnexion(data, resolve, reject) {
    if (this.abortConnection) {
      await this.disconnect();
      reject('BluetoothDevice - Connection aborted when connecting');
      return;
    }

    if (DeviceAskingForPassword(data)) {
      // (1) wait for the device to send a key
      // (2) based on the key sent by the device, answer with a password
      await this.sendConnectionPassword(data, reject);
      return;
    }

    if (DeviceConfirmingConnection(data)) {
      // (3) wait for the device confirmation and complete (resolve) connection.
      consoleDebug('BluetoothDevice - Connected');
      this.setConnected();
      resolve();
      return;
    }

    this.disconnect();
    this.setConnectionError();
    reject(`BluetoothDevice : Error completing connexion : ${data}`);
  }

  async sendConnectionPassword(data, reject) {
    try {
      let search = /\(([^)]+)\)/;
      let matches = search.exec(data);
      /* eslint no-bitwise: ["error", { "allow": ["^"] }] */
      let key = this.getSerialNumber() ^ matches[1];
      await this.send(key.toString());
    } catch (e) {
      reject(e);
    }
  }

  async send(data) {
    const bleState = await getBluetoothService().checkState();
    if (bleState !== BLE_ON) {
      this.setConnectionLost();
      this.dispatch.console.updateSelectedDevice(this);
      return;
    }
    const payload = stringToBytes(data);
    await getBluetoothService().send(this.peripherical.id, payload);
  }

  async sendCommand(command) {
    this.send(command);
  }

  async disconnect() {
    if (this.bluetoothConnecting) {
      this.abortConnection = true;
      consoleDebug('BluetoothDevice - Disconnecting while bluetooth not connected');
    } else if (this.bluetoothConnected) {
      consoleDebug('BluetoothDevice - Disconnecting ...');
      if (!this.connected) {
        this.abortConnection = true;
        consoleDebug('BluetoothDevice - Disconnecting while app not connected');
      }
      await getBluetoothService().disconnect(this.connectionHandle);
      this.setDisconnected();
      consoleDebug('BluetoothDevice - Disconnected');
    }
  }
}
