import React from 'react';
import { createRoot } from 'react-dom/client';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';

import './app.css';
import './_headers';
import './public/robots.txt';
import App from '../src/App';

const IoniconsStyles = `@font-face {
  src: url(${Ionicons});
  font-family: Ionicons;
}`;

const style = document.createElement('style');

if (style.styleSheet) {
  style.styleSheet.cssText = IoniconsStyles;
} else {
  style.appendChild(document.createTextNode(IoniconsStyles));
}

document.head.appendChild(style);

const root = createRoot(document.getElementById('root'));
root.render(<App />);
