const BASE_NUMBER = 10;

export const modulo = (n, mod) => ((n % mod) + mod) % mod;

export const round = (value, precision) => {
  const multiplier = Math.pow(BASE_NUMBER, precision || 0);

  return Math.round(Number(value) * multiplier) / multiplier;
};

export const celsiusToFahrenheit = (celsius) => {
  const fahrenheit = (celsius * 9) / 5 + 32;
  return parseFloat(Math.round(fahrenheit));
};

export const kpaToPsi = (kpa) => {
  const psi = kpa * 0.1450377;
  return parseFloat(Math.round(psi));
};
