import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

//proptype
import { dataStreamShape } from '../../shapes/dataStreams';

//hooks
import { useTranslation } from '../../hooks/useTranslation';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

//presenter
import { FlowStationSensorPresenter } from '../../presenters/FlowStationSensorPresenter';

//components
import RoundedUnderline from '../RoundedUnderline';

const FlowStationLegendElement = ({ readings }) => {
  const { t } = useTranslation();
  const flowStationSensorPresenter = new FlowStationSensorPresenter(readings, t);
  const value = flowStationSensorPresenter.value();
  const color = flowStationSensorPresenter.color();
  const unit = flowStationSensorPresenter.unit();

  return (
    <View style={styles.container}>
      <View style={styles.flowStationButtonContainer}>
        <RoundedUnderline color={color} />

        <View style={styles.readingSection}>
          <Text style={styles.value}>{value}</Text>
          <Text style={styles.unit}>{unit}</Text>
        </View>
      </View>
    </View>
  );
};

FlowStationLegendElement.propTypes = {
  readings: dataStreamShape.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '33.33%',
  },
  flowStationButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 85,
    height: 15,
  },
  readingSection: {
    flexDirection: 'row',
  },
  value: {
    marginRight: 5,
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  unit: {
    alignSelf: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
});

export default FlowStationLegendElement;
