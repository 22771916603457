import PIN_ICONS from '../../assets/icons/mapPins/pinIcons';

export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const DISABLED = 'DISABLED';
export const ERROR = 'ERROR';
export const ERROR_WHILE_IRRIGATING = 'ERROR_WHILE_IRRIGATING';
export const OFF = 'OFF';
export const ONGOING = 'ONGOING';
export const SYNCHRONIZING = 'SYNCHRONIZING';
export const UNKNOWN = 'UNKNOWN';

const mapPumpIconByStatus = {
  COMMUNICATION_ERROR: PIN_ICONS.pumpDisabled,
  DISABLED: PIN_ICONS.pumpDisabled,
  ERROR: PIN_ICONS.pumpError,
  ERROR_WHILE_IRRIGATING: PIN_ICONS.pumpErrorIrrigating,
  OFF: PIN_ICONS.pumpGood,
  ONGOING: PIN_ICONS.pumpIrrigating,
  SYNCHRONIZING: PIN_ICONS.pumpSync,
  UNKNOWN: PIN_ICONS.pumpUnknown,
};

export const isPumpIcon = (icon) => {
  const icons = Object.values(mapPumpIconByStatus);

  for (let i = 0; i < icons.length; i++) {
    if (icons[i].normal.name === icon || icons[i].active.name === icon) {
      return true;
    }
  }

  return false;
};

export const iconForMapStatus = (status) => mapPumpIconByStatus[status] || mapPumpIconByStatus.UNKNOWN;
