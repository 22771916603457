import { getApolloClient } from '../clients/apollo';
import { WEATHER_DATA } from '../models/dataStreamTypes';
import { SITE_WEATHER_DATA_STREAMS } from './siteWeatherDataStreams.graphql';

const fetchSiteWeatherDataStreams = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: SITE_WEATHER_DATA_STREAMS,
    variables: { siteId, dataStreamTypes: WEATHER_DATA },
  });

  let siteWeatherDataStreams;
  if (data) {
    siteWeatherDataStreams = [];

    data.viewer.site.blocks.forEach((block) => {
      if (block.sensors) {
        block.sensors.forEach((sensor) => {
          sensor.dataStreams.forEach((dataStream) => {
            siteWeatherDataStreams.push(dataStream);
          });
        });
      }
    });
  }

  return {
    loading,
    error,
    siteWeatherDataStreams,
  };
};

export { fetchSiteWeatherDataStreams };
