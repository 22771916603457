import React, { useEffect, useState } from 'react';
import { Keyboard, KeyboardAvoidingView, StyleSheet, Text, View, Image, TextInput, ScrollView, Pressable } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// icons
import icnNavRight from '../../assets/icons/icnNavRight.png';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';
import { navigationShape } from '../../shapes/navigation';
import { AlertTypes } from '../main/AlertConstants';

// services
import { createNote, deleteNote, updateNote } from '../../mutations/manageNote';
import { isIos } from '../../services/PlatformService';
import { getNoteErrorTranslationKey, EMPTY_NOTE_ERROR_CODE, NO_BLOCK_ERROR_CODE } from '../../models/errors/NoteErrorMapper';
import { formatMoment } from '../../utils/dateUtils';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ANALYTICS from '../../services/AnalyticsEvents';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandler } from '../../hooks/useBackHandler';

// model
import BlockEntity from '../../models/entities/blockEntity';
import NoteEntity from '../../models/entities/noteEntity';

// components
import Alert from '../main/Alert';
import Divider from '../../components/Divider';
import HeaderEdition from '../../components/header/HeaderEdition';
import ToggleSwitch from '../../components/button/ToggleSwitch';

// styles
import { globalStyles } from '../../styles';

const GENERAL_NOTE_LENGTH = 280;
const BLOCK_NOTE_LENGTH = 160;

const NoteEditScreen = ({ navigation, route }) => {
  const { t } = useTranslation();

  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  const { dateRange, headerLabel, note, block } = route.params || {};

  const isNoteOpened = note !== undefined;

  const currentSite = useSelector((state) => state.site.currentSite);

  const [isGeneralNote, setIsGeneralNote] = useState(false);
  const [noteLength, setNoteLength] = useState(BLOCK_NOTE_LENGTH);
  const [noteText, setNoteText] = useState('');
  const [interactionEnabled, setInteractionEnabled] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState();

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [deleteWarningModalVisible, setDeleteWarningModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    setInteractionEnabled(true);
    setIsGeneralNote(false);
  }, [navigation]);

  useEffect(() => {
    setSelectedBlock(block);
  }, [block]);

  useEffect(() => {
    if (note) {
      setNoteLength(note.isGeneralNote() ? GENERAL_NOTE_LENGTH : BLOCK_NOTE_LENGTH);
      setNoteText(note.content);
    } else {
      setNoteText('');
    }
  }, [note]);

  useEffect(() => {
    if (isGeneralNote) {
      setSelectedBlock(undefined);
    }
  }, [isGeneralNote]);

  const goBack = (reloadData) => {
    Keyboard.dismiss();
    setNoteText('');
    setSelectedBlock(undefined);
    setInteractionEnabled(true);
    if (reloadData) {
      dispatch.notes.loadNotes({
        startDate: dateRange.from,
        endDate: dateRange.to,
      });
    }
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewNotes);
    navigation.navigate(ROUTES.NOTES, { reloadData: true, dateRange });
    return true; // prevent event bubble up (Android will close the app)
  };

  useBackHandler(goBack);

  const toggleSwitch = () => {
    const enabled = !isGeneralNote;
    const newNoteLength = enabled ? GENERAL_NOTE_LENGTH : BLOCK_NOTE_LENGTH;

    setIsGeneralNote(enabled);
    setNoteLength(newNoteLength);
    setNoteText(noteText.substring(0, newNoteLength));
  };

  const onNoteChangeText = (text) => setNoteText(text);

  const setErrorModalVisibility = () => {
    setErrorModalVisible(!errorModalVisible);
    setInteractionEnabled(true);
  };

  const setDeleteWarningModalVisibility = () => {
    setDeleteWarningModalVisible(!deleteWarningModalVisible);
    setInteractionEnabled(true);
  };

  const blockValid = () => {
    return isGeneralNote || selectedBlock !== undefined;
  };

  const goToBlockList = () => {
    Keyboard.dismiss();
    navigation.navigate(ROUTES.NOTE_BLOCK_LIST, { selectedBlock, setSelectedBlock, dateRange, headerLabel, note });
  };

  const onSavePress = async () => {
    setInteractionEnabled(false);
    if (noteText.trim() === '') {
      setErrorMessage(t(getNoteErrorTranslationKey(EMPTY_NOTE_ERROR_CODE)));
      setErrorModalVisible(true);
      setInteractionEnabled(true);
    } else if (note) {
      await onUpdateNote();
    } else {
      onCreateNote();
    }
  };

  const onCreateNote = async () => {
    if (!blockValid()) {
      setErrorMessage(t(getNoteErrorTranslationKey(NO_BLOCK_ERROR_CODE)));
      setErrorModalVisible(true);
      setInteractionEnabled(true);
    } else {
      setErrorMessage(undefined);
      setErrorModalVisible(false);
      await saveNote();
    }
  };

  const showDeleteNoteWarning = () => {
    setInteractionEnabled(false);
    setDeleteWarningModalVisible(true);
  };

  const onDeleteNote = async () => {
    setDeleteWarningModalVisible(false);
    setInteractionEnabled(true);
    executeNoteOperation(deleteNote(note?.id));
  };

  const onUpdateNote = async () => {
    executeNoteOperation(updateNote(note?.id, noteText));
  };

  const saveNote = async () => {
    const operation = createNote(
      selectedBlock ? selectedBlock.id : null,
      noteText,
      formatMoment(dateRange?.from.clone().add(1, 'second')),
      formatMoment(dateRange?.to.clone().subtract(1, 'second')),
      currentSite.id,
    );
    executeNoteOperation(operation);
  };

  const executeNoteOperation = async (action) => {
    let response = await action;
    if (response?.errors) {
      const error0 = response.errors[0];
      const errorCode = error0.extensions?.code;
      setErrorMessage(t(getNoteErrorTranslationKey(errorCode)));
      setErrorModalVisible(true);
      setInteractionEnabled(true);
    } else {
      goBack(true);
    }
  };

  const generalNoteColor = isGeneralNote ? colorState[0] : colorState[1];
  const blockButtonColor = isGeneralNote ? colorState[1] : colorState[0];

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header} pointerEvents={interactionEnabled ? 'auto' : 'none'} testID="notes__subscreen-container">
        <HeaderEdition
          title={t('note_title')}
          subtitle={headerLabel}
          onCancel={() => goBack(false)}
          onDelete={showDeleteNoteWarning}
          onSave={onSavePress}
          deleteEnabled={isNoteOpened}
        />
      </View>

      <Divider />

      <KeyboardAvoidingView behavior={isIos() ? 'padding' : 'height'} style={styles.keyboardView} keyboardVerticalOffset={10}>
        {!note && (
          <>
            <View style={styles.toggleContainer}>
              <Text style={[styles.toggleText, generalNoteColor]}>{t('note_edit_general_note_label')}</Text>
              <ToggleSwitch onValueChange={toggleSwitch} value={isGeneralNote} testID={'note-edit__toggle-switch'} />
            </View>

            <Divider />

            <View style={styles.blockContainer}>
              <View>
                {!isGeneralNote && selectedBlock && (
                  <Text style={styles.blockAlias} testID={'note-edit__selected-block'}>
                    {selectedBlock.alias}
                  </Text>
                )}
              </View>
              <Pressable
                style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.centerRow]}
                disabled={isGeneralNote}
                onPress={goToBlockList}
                testID={'note-edit__select-block-text-button'}>
                <Text style={[styles.details, blockButtonColor]} testID={'note-edit__select-block-text'}>
                  {t('note_edit_select_block_title')}
                </Text>
                <Image source={icnNavRight} style={[styles.arrowSpacing, { tintColor: blockButtonColor.color }]} />
              </Pressable>
            </View>
            <Divider />
          </>
        )}

        <ScrollView keyboardShouldPersistTaps="handled" style={styles.noteInputContainer} showsVerticalScrollIndicator={false}>
          <TextInput
            style={styles.noteInput}
            autoCorrect={true}
            autoCapitalize={'sentences'}
            maxLength={noteLength}
            placeholder={t('note_edit_placeholder')}
            multiline={true}
            value={noteText}
            onChangeText={onNoteChangeText}
            testID={'note-edit__note-input'}
          />
        </ScrollView>
      </KeyboardAvoidingView>

      {errorModalVisible && (
        <Alert
          onPress={setErrorModalVisibility}
          onCancel={setErrorModalVisibility}
          show={errorModalVisible}
          title={t('note_error_title')}
          message={errorMessage}
          alertType={AlertTypes.ERROR}
          testID={'note-edit__error-modal'}
        />
      )}

      {deleteWarningModalVisible && (
        <Alert
          cancelButtonKey={'note_edit_cancel_button_title'}
          okButtonKey={'note_edit_delete_button_title'}
          onPress={onDeleteNote}
          onCancel={setDeleteWarningModalVisibility}
          show={deleteWarningModalVisible}
          title={t('note_edit_delete_note_confirmation_dialog_title')}
          message={t('note_edit_delete_note_confirmation_dialog_message')}
          alertType={AlertTypes.WARNING}
          testID={'note-edit__delete-warning-modal'}
        />
      )}
    </SafeAreaView>
  );
};

NoteEditScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      dateRange: PropTypes.object.isRequired,
      headerLabel: PropTypes.string,
      note: PropTypes.instanceOf(NoteEntity),
      block: PropTypes.instanceOf(BlockEntity),
    }),
  }).isRequired,
};

const colorState = [{ color: COLORS.greyishBrown }, { color: COLORS.warmGrey }];

const styles = StyleSheet.create({
  arrowSpacing: {
    marginLeft: 10,
  },
  blockAlias: {
    padding: 3,
    borderColor: COLORS.warmGrey,
    borderWidth: 2,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
    letterSpacing: 0.66,
    color: COLORS.warmGrey,
    textAlignVertical: 'center',
  },
  blockContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    paddingRight: 5,
    paddingLeft: 15,
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  details: {
    fontFamily: FONTS.firaSans,
    fontSize: 17,
  },
  keyboardView: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  noteInput: {
    height: 200,
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
    textAlignVertical: 'top',
  },
  noteInputContainer: {
    margin: 15,
  },
  toggleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 65,
    paddingHorizontal: 15,
  },
  toggleText: {
    fontFamily: FONTS.firaSans,
    fontSize: 17,
  },
});

export default NoteEditScreen;
