import * as TemperatureProtectionState from '../temperatureProtectionState';
import * as TemperatureStatus from '../status/temperatureStatus';
import * as TemperatureUnits from '../units/temperatureUnits';
import { formatValue } from '../../utils/dataStreamsUtil';
import { BACKGROUND_COLOR_ALPHA_VALUE } from '../../styles';

export class CriticalTemperature {
  constructor(criticalTemperature, translation) {
    this.criticalTemperature = criticalTemperature;
    this.translation = translation;
  }

  status() {
    let status = this.translation('protection_status_error');

    if (this.criticalTemperature) {
      switch (this.criticalTemperature.status) {
        case TemperatureStatus.HIGH:
          status = this.translation('protection_status_high');
          break;
        case TemperatureStatus.MEDIUM:
          status = this.translation('protection_status_medium');
          break;
        case TemperatureStatus.LOW:
          status = this.translation('protection_status_low');
          break;
      }
    }

    return status.toUpperCase();
  }

  statusColor() {
    let statusColor = TemperatureStatus.colorForStatus('ERROR');

    if (this.criticalTemperature) {
      statusColor = TemperatureStatus.colorForStatus(this.criticalTemperature.status);
    }

    return statusColor;
  }

  statusBackgroundColor() {
    return `${this.statusColor()}${BACKGROUND_COLOR_ALPHA_VALUE}`;
  }

  formattedTemperature() {
    let formattedTemperature = '';

    if (this.criticalTemperature) {
      const value = this.criticalTemperature.value;
      const unit = this.criticalTemperature.unit;

      if (value != null && unit != null) {
        formattedTemperature = `${formatValue(value)}${TemperatureUnits[unit].displayValue}`;
      }
    }

    return formattedTemperature;
  }

  protectionState() {
    let protectionState = '';

    if (this.criticalTemperature) {
      switch (this.criticalTemperature.protectionState) {
        case TemperatureProtectionState.FROST:
          protectionState = this.translation('protection_state_frost');
          break;
        case TemperatureProtectionState.HEAT:
          protectionState = this.translation('protection_state_heat');
          break;
      }
    }
    return protectionState;
  }

  icon() {
    let icon = TemperatureStatus.iconForStatus('');

    if (this.criticalTemperature) {
      icon = TemperatureStatus.iconForStatus(this.criticalTemperature.status);
    }

    return icon;
  }
}
