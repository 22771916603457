import icnIrrigationAlias from '../../assets/icons/icnIrrigationAlias.png';
import BlockAliasPresenter from './BlockAliasPresenter';

export default class IrrigationBlockAliasPresenter extends BlockAliasPresenter {
  constructor(block) {
    super(block);
  }

  getOverlayIcon() {
    if (this.block.isIrrigating()) {
      return {
        icon: icnIrrigationAlias,
        testID: 'irrigation-alias-icon',
        style: {
          position: 'absolute',
          right: -12,
          top: -8,
        },
      };
    }
  }

  unit() {
    return this.block.tensionCapability().tensionReadings().length > 0
      ? this.block.tensionCapability().tensionReadings()[0].unit
      : undefined;
  }
}
