import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// propType shape
import { navigationShape } from '../../shapes/navigation';

//model
import BlueBandIndexStatuses from '../../models/entities/blueBandIndexStatuses';

// services
import { useTranslation } from '../../hooks/useTranslation';
import ANALYTICS from '../../services/AnalyticsEvents';

// presenters
import ListBlockAliasPresenter from '../../presenters/blockAlias/ListBlockAliasPresenter';
import { IrrigationBlockPresenter } from '../../presenters/IrrigationBlockPresenter';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import ROUTES from '../../navigation/routes';
import { TENSION } from '../../models/capability';
import { DETAILS } from '../blockDetails/BlockDetailsTabs';

// components
import SensorData from '../../components/block/SensorData';
import BlockAliasButton from '../../components/block/BlockAliasButton';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ListBox from '../../components/list/ListBox';

const SiteBlueBandIndexItem = ({ block, navigation, keyIndex, testId }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const blueBandIndexStatuses = new BlueBandIndexStatuses(block.tensionCapability().blueBandIndex());
  const blueBandIndexString = blueBandIndexStatuses.formattedValues(t);
  const blockAliasPresenter = new ListBlockAliasPresenter(block, TENSION);
  const irrigationBlockPresenter = new IrrigationBlockPresenter(block);
  const dispatch = useDispatch();

  const blockDetailsNavigationProps = {
    capability: TENSION,
    block,
    defaultSelectedStreamIds: block
      .tensionCapability()
      .tensionReadings()
      .map((reading) => reading.id),
    unit: irrigationBlockPresenter.unit(),
    routeToGoBackTo: ROUTES.SITE_BLUE_BAND_INDEX,
    displayTabs: true,
  };

  const goToDetails = async () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      activeTab: DETAILS,
      ...blockDetailsNavigationProps,
    });
  };

  return (
    <ListBox
      testID={testId}
      customStyle={styles.listBox}
      renderContent={() => (
        <>
          <BlockAliasButton
            testId={`siteBlueBandIndex__blockAlias-button-${keyIndex}`}
            presenter={blockAliasPresenter}
            buttonStyle={styles.buttonStyle}
            onClick={goToDetails}
          />
          <SensorData containerStyle={styles.blueBandSection} value={blueBandIndexString.wet} textStyle={styles.blueBandText} />
          <SensorData containerStyle={styles.blueBandSection} value={blueBandIndexString.good} textStyle={styles.blueBandText} />
          <SensorData containerStyle={styles.blueBandSection} value={blueBandIndexString.dry} textStyle={styles.blueBandText} />
        </>
      )}
    />
  );
};

SiteBlueBandIndexItem.propTypes = {
  block: PropTypes.object.isRequired,
  keyIndex: PropTypes.number,
  navigation: navigationShape.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  blueBandSection: {
    flexGrow: 1,
    alignSelf: 'center',
    flexBasis: 0,
  },
  buttonStyle: {
    width: 60,
    marginRight: 25,
    marginLeft: 15,
  },
  blueBandText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  listBox: {
    paddingHorizontal: 0,
  },
});

export default SiteBlueBandIndexItem;
