import { getApolloClient } from '../clients/apollo';
import { DATASTREAMS } from './datastreams.graphql';

async function fetchDataStreams(siteId, dataStreamIds, startDate, endDate) {
  const { loading, error, data } = await getApolloClient().query({
    query: DATASTREAMS,
    variables: {
      siteId,
      dataStreamIds,
      startDate,
      endDate,
    },
  });

  let dataStreams;
  if (data) {
    dataStreams = data.viewer.site.dataStreams;
  }

  return {
    loading,
    error,
    dataStreams,
  };
}

export { fetchDataStreams };
