import moment from 'moment-timezone';

export default class NoteEntity {
  constructor(note, blockID) {
    if (note) {
      this.id = note.id;
      this.content = note.content;
      this.createdBy = note.createdBy;
      this.createdAt = note.createdAt;
      this.startDate = note.startDate ? moment.utc(note.startDate) : note.startDate;
      this.endDate = note.endDate ? moment.utc(note.endDate) : note.endDate;
      this.blockID = blockID;
    }
  }

  isEffective(startDate, endDate) {
    if (!startDate || !endDate || !this.startDate || !this.endDate) {
      return false;
    }
    return (
      this.startDate.isBetween(startDate, endDate, undefined, []) || this.endDate.isBetween(startDate, endDate, undefined, [])
    );
  }
  isGeneralNote() {
    return !this.blockID;
  }
}
