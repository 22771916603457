import React from 'react';
import { Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import FONTS from '../../fonts';

// shape
import { styleShape } from '../../shapes/style';

// styles
import { MINIMUM_FONT_SCALE } from '../../styles.js';

const BlockAlias = ({ alias, color, ellipsizeMode, style }) => {
  return (
    <Text
      ellipsizeMode={ellipsizeMode}
      numberOfLines={1}
      adjustsFontSizeToFit
      maxFontSizeMultiplier={1}
      minimumFontScale={MINIMUM_FONT_SCALE}
      style={{
        color,
        ...styles.aliasDefaultFont,
        ...style,
      }}>
      {alias}
    </Text>
  );
};

BlockAlias.propTypes = {
  alias: PropTypes.string.isRequired,
  color: PropTypes.string,
  ellipsizeMode: PropTypes.string.isRequired,
  style: styleShape,
};

const styles = StyleSheet.create({
  aliasDefaultFont: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 13,
    letterSpacing: 0.66,
  },
});

export default BlockAlias;
