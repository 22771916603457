import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View, Text } from 'react-native';

// icons
import icnStation from '../../../assets/icons/icnStation.png';

// constants
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

const City = ({ name, loading }) => {
  return (
    <View style={styles.location}>
      <Image source={icnStation} testID={'city_location_icon'} />
      {!loading ? <Text style={styles.nameText}>{name}</Text> : null}
    </View>
  );
};

City.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  location: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameText: {
    marginLeft: 10,
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
});

export default City;
