//icon
import icnPumpStart from '../assets/icons/icnPumpStart.png';
import icnPumpStop from '../assets/icons/icnPumpStop.png';
import icnAutomationStart from '../assets/icons/icnAutomationStart.png';
import icnAutomationStop from '../assets/icons/icnAutomationStop.png';
import icnAutomationAddRemove from '../assets/icons/icnAutomationAddRemove.png';
import ANALYTICS from '../services/AnalyticsEvents';

export const FORCE_START = {
  name: 'FORCE_START',
  id: 1,
  groupID: 1,
};
export const FORCE_STOP = {
  name: 'FORCE_STOP',
  id: 2,
  groupID: 2,
};
export const ADD_TIME = {
  name: 'ADD_TIME',
  id: 3,
  groupID: 3,
};
export const REMOVE_TIME = {
  name: 'REMOVE_TIME',
  id: 4,
  groupID: 3,
};
export const UNKNOWN = {
  name: 'UNKNOWN',
  id: -1,
  groupID: -1,
};

const commandTypeByID = {
  1: FORCE_START,
  2: FORCE_STOP,
  3: ADD_TIME,
  4: REMOVE_TIME,
};

const commandAnalyticsByType = {
  FORCE_START: ANALYTICS.automationStartButton,
  FORCE_STOP: ANALYTICS.automationStopButton,
  ADD_TIME: ANALYTICS.automationModifyButton,
  REMOVE_TIME: ANALYTICS.automationModifyButton,
  UNKNOWN: undefined,
};

const commandIconByType = {
  FORCE_START: icnPumpStart,
  FORCE_STOP: icnPumpStop,
  ADD_TIME: icnPumpStart,
  REMOVE_TIME: icnPumpStart,
  UNKNOWN: undefined,
};

const commandButtonIconByType = {
  FORCE_START: icnAutomationStart,
  FORCE_STOP: icnAutomationStop,
  ADD_TIME: icnAutomationAddRemove,
  REMOVE_TIME: icnAutomationAddRemove,
  UNKNOWN: undefined,
};

const commandButtonTextByType = {
  FORCE_START: 'automation_start_button',
  FORCE_STOP: 'automation_stop_button',
  ADD_TIME: 'automation_modify_button',
  REMOVE_TIME: 'automation_modify_button',
  UNKNOWN: undefined,
};

const defaultDurationByType = {
  FORCE_START: { hours: 2, minutes: 0 },
  FORCE_STOP: { hours: 0, minutes: 0 },
  ADD_TIME: { hours: 2, minutes: 0 },
  REMOVE_TIME: { hours: 2, minutes: 0 },
  UNKNOWN: { hours: 0, minutes: 0 },
};

const questionTranslationKeyByType = {
  FORCE_START: 'automation_start_question_text',
  FORCE_STOP: 'automation_stop_question_text',
  ADD_TIME: 'automation_modify_question_text',
  REMOVE_TIME: 'automation_modify_question_text',
  UNKNOWN: undefined,
};

const runningTranslationKeyByType = {
  FORCE_START: 'automation_start_running_text',
  FORCE_STOP: 'automation_stop_running_text',
  UNKNOWN: undefined,
};

export const isForceStopCommand = (type) => type.name === FORCE_STOP.name;

export const commandTypeForID = (id) => commandTypeByID[id] || UNKNOWN;

export const commandAnalyticsForType = (type) => commandAnalyticsByType[type.name];

export const commandIconForType = (type) => commandIconByType[type.name];

export const commandButtonIconForType = (type) => commandButtonIconByType[type.name];

export const commandButtonTextForType = (type) => commandButtonTextByType[type.name];

export const defaultDurationForType = (type) => defaultDurationByType[type.name];

export const runningTranslationKeyForType = (type) => runningTranslationKeyByType[type.name];

export const questionTranslationKeyForType = (type) => questionTranslationKeyByType[type.name];
