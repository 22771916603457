import React, { useEffect, useState } from 'react';
import { StyleSheet, Pressable, Image, View } from 'react-native';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import PropTypes from 'prop-types';

// constants
import COLORS from '../colors';

// shape
import { styleShape } from '../shapes/style';

const FloatingActionButton = ({
  buttonStyle,
  buttonTouchStyle,
  iconStyle,
  icon,
  onPress,
  testId,
  animated = false,
  show = true,
}) => {
  const opacity = useSharedValue(0);
  const [isIconPressed, setIsIconPressed] = useState(false);

  useEffect(() => {
    if (animated) {
      opacity.value = withTiming(show ? 1 : 0, {
        duration: 300,
      });
    }
  }, [show, animated, opacity]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  const Container = animated ? Animated.View : View;

  return (
    <Container
      style={[styles.main, buttonStyle, isIconPressed && styles.iconPressed && buttonTouchStyle, animated && animatedStyle]}>
      <Pressable
        style={iconStyle}
        testID={testId}
        onPress={() => onPress()}
        onPressIn={() => setIsIconPressed(true)}
        onPressOut={() => setIsIconPressed(false)}>
        <Image source={icon} style={styles.baseIcon} />
      </Pressable>
    </Container>
  );
};

FloatingActionButton.propTypes = {
  buttonStyle: styleShape,
  buttonTouchStyle: styleShape,
  iconStyle: styleShape,
  icon: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string,
  show: PropTypes.bool,
  animated: PropTypes.bool,
};

const styles = StyleSheet.create({
  main: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
  },
  baseIcon: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    tintColor: COLORS.white,
  },
  iconPressed: {
    tintColor: COLORS.greyishBrown,
  },
});

export default FloatingActionButton;
