import { useCallback } from 'react';
import { BackHandler } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

// services
import { isAndroid } from '../services/PlatformService';

const BACK_PRESS = 'hardwarebackpress';

const useBackHandlerRouteTo = (navigation, route) => {
  const routeTo = route?.params?.routeToGoBackTo;

  useFocusEffect(
    useCallback(() => {
      if (isAndroid()) {
        const goBack = () => {
          if (routeTo) {
            navigation.navigate(routeTo);
            return true;
          }
        };

        const subscription = BackHandler.addEventListener(BACK_PRESS, goBack);

        return () => {
          subscription?.remove();
        };
      }
    }, [navigation, routeTo]),
  );
};

const useBackHandler = (goBack) => {
  useFocusEffect(
    useCallback(() => {
      if (isAndroid()) {
        const subscription = BackHandler.addEventListener(BACK_PRESS, goBack);

        return () => {
          subscription?.remove();
        };
      }
    }, [goBack]),
  );
};

const exitApp = () => {
  BackHandler.exitApp();
  return true;
};

const useBackExitApp = () => {
  useBackHandler(exitApp);
};

export { useBackHandler, useBackHandlerRouteTo, useBackExitApp };
