import { useEffect, useReducer, useRef } from 'react';
import { AppState } from 'react-native';

const useAppState = () => {
  const appState = useRef(AppState.currentState);
  const [state, setState] = useReducer((currentState, newState) => ({ ...currentState, ...newState }), {
    currentAppState: AppState.currentState,
    previousAppState: 'background',
  });

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    // istanbul ignore next
    return () => {
      subscription?.remove();
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    const previousAppState = appState.current;
    appState.current = nextAppState;
    setState({ currentAppState: nextAppState, previousAppState });
  };

  return state;
};

export default useAppState;
