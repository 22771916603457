import { gql } from 'graphql-tag';

export const MAP_ITEMS = gql`
  query MapItems($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        mapItems {
          type
          features {
            id
            type
            geometry {
              type
              coordinates
            }
            properties {
              name
              itemType
              status
              value
              reference {
                id
                type
              }
            }
          }
        }
      }
    }
  }
`;
