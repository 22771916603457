import React, { useEffect } from 'react';
import { Image, Linking, ScrollView, StyleSheet, Text, Pressable, useWindowDimensions, View } from 'react-native';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';

// icons
import icnCancel from '../../assets/icons/icnCancel.png';
import icnBrowser from '../../assets/icons/newTab.png';

// services
import { useTranslation } from '../../hooks/useTranslation';
import ANALYTICS from '../../services/AnalyticsEvents';
import { recordError } from '../../services/CrashlyticsService';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// styles
import { globalStyles } from '../../styles';

// components
import Divider from '../../components/Divider';
import NDVILegendItems from '../../components/map/NDVILegendItems';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';

const SLIDE_TOGGLE_ANIMATION_DURATION = 200;

const NDVILegendDetails = ({ enabled = false, onClose, show }) => {
  const { t } = useTranslation();
  const window = useWindowDimensions();
  const analyticsService = useAnalyticsContext();

  const horizontalOffset = useSharedValue(-window.width);
  const ndviFAQLink = t('map_ndvi_faq_link');

  useEffect(() => {
    if (show === undefined) {
      return;
    }

    if (show) {
      animateView(0);
    } else if (enabled) {
      animateView(-window.width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const animateView = (toValue) => {
    horizontalOffset.value = withTiming(toValue, { duration: SLIDE_TOGGLE_ANIMATION_DURATION });
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: horizontalOffset.value }],
    };
  });

  const openFAQLink = () => {
    Linking.openURL(ndviFAQLink)
      .then(() => {
        analyticsService.trackEvent(ANALYTICS.NDVIFaq);
      })
      .catch((error) => recordError(error));
  };

  return (
    <Animated.View style={[styles.container, animatedStyle]}>
      <SafeAreaView edges={['right', 'left', 'bottom']}>
        <Divider />
        <View style={styles.subContainer}>
          <View style={styles.header}>
            <Text style={styles.headerTitle}>{t('map_ndvi_legend')}</Text>
            <Pressable
              onPress={onClose}
              style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.closeButton]}
              testID={'mapLegend-close-button'}>
              <Image source={icnCancel} />
            </Pressable>
          </View>
          <ScrollView>
            <NDVILegendItems />
          </ScrollView>
          <View style={styles.footer}>
            <Pressable
              onPress={openFAQLink}
              style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.faqButton]}
              testID={'mapLegend-faqLink-button'}>
              <Image source={icnBrowser} style={styles.browserIcon} />
              <Text style={styles.faqText}>{t('map_ndvi_faq')}</Text>
            </Pressable>
          </View>
        </View>
      </SafeAreaView>
    </Animated.View>
  );
};

NDVILegendDetails.propTypes = {
  enabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

const styles = StyleSheet.create({
  browserIcon: {
    marginEnd: 11,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  container: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.white,
  },
  faqButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  faqText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSansItalic,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    paddingBottom: 8,
  },
  headerTitle: {
    marginHorizontal: 40,
    marginTop: 10,
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  subContainer: {
    height: '100%',
  },
});

export default NDVILegendDetails;
