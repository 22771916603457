import React from 'react';
import { StyleSheet, Text, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// styles
import { globalStyles } from '../../styles';

const NoteItem = ({ note, onNotePress, testId }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  if (!note) {
    return null;
  }

  return (
    <Pressable
      style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
      onPress={() => onNotePress(note)}
      disabled={currentUser.username !== note.createdBy}>
      <View style={styles.noteContainer} testID={testId}>
        <Text style={styles.noteContent}>{note.content}</Text>
        <View style={styles.metadata}>
          <Text style={styles.metaText}>{moment(note.createdAt).format('ll')} </Text>
          <Text style={styles.metaText}>({note.createdBy})</Text>
        </View>
      </View>
    </Pressable>
  );
};

NoteItem.propTypes = {
  note: PropTypes.object,
  onNotePress: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  metadata: {
    flexDirection: 'row',
  },
  metaText: {
    fontFamily: FONTS.notoSansItalic,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  noteContainer: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
    marginBottom: 3,
    backgroundColor: COLORS.whiteTwo,
  },
  noteContent: {
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
});

export default NoteItem;
