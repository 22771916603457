//queries
import { fetchBlockBlueBandIndexes } from '../queries/blockBlueBandIndexes';

//entity
import { sortBlockBlueBandIndexes } from '../screens/blockBlueBandIndex/sorting';
import BlueBandIndexDay from './entities/blueBandIndexDay';

//service
import { executeApolloQuery } from './apolloHelper';

export const blueBandIndex = {
  state: {
    blueBandIndexSite: undefined,
    blockBlueBandIndexes: [],
    sortedBlockBlueBandIndexes: [],
  },

  reducers: {
    updateBlueBandIndexSite(state, dataBlueBandIndex) {
      return { ...state, blueBandIndexSite: dataBlueBandIndex };
    },
    updateBlueBandIndexesBlock(state, blueBandIndexesData) {
      const blockBlueBandIndexes = blueBandIndexesData.map((item) => {
        return new BlueBandIndexDay(item.date, item.value);
      });
      blockBlueBandIndexes.reverse();
      return { ...state, blockBlueBandIndexes, sortedBlockBlueBandIndexes: blockBlueBandIndexes };
    },
    updateSortedBlueBandIndexesBlock(state, sortedBlockBlueBandIndexes) {
      return { ...state, sortedBlockBlueBandIndexes };
    },
  },

  effects: (dispatch) => ({
    async loadBlockBlueBandIndexes({ startDate, endDate }, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => {
          if (rootState.blocks.selectedBlock) {
            return await fetchBlockBlueBandIndexes(
              rootState.site.currentSite.id,
              rootState.blocks.selectedBlock.id,
              startDate,
              endDate,
            );
          }
          return { data: { blockBlueBandIndexes: [] } };
        },
        (data) => {
          dispatch.blueBandIndex.updateBlueBandIndexesBlock(data.blockBlueBandIndexes);
        },
      );
    },

    async sortBlockBlueBandIndexes({ column, order }, rootState) {
      const sortedBlockBlueBandIndexes = [...rootState.blueBandIndex.blockBlueBandIndexes];
      sortBlockBlueBandIndexes(sortedBlockBlueBandIndexes, column, order);
      dispatch.blueBandIndex.updateSortedBlueBandIndexesBlock(sortedBlockBlueBandIndexes);
    },
  }),
};
