import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

// presenter
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';

// components
import BlockName from '../block/BlockName';
import LastUpdate from '../block/LastUpdate';
import BlockAliasButton from '../block/BlockAliasButton';

const WeatherStationBlockHeader = ({ presenter, testId }) => {
  return (
    <View style={styles.container} testID={testId}>
      <View style={styles.leftSection}>
        <BlockAliasButton presenter={presenter} buttonStyle={styles.button} testId={'weather__blockAlias-button'} />
        <BlockName
          blockName={presenter.name()}
          style={styles.blockName}
          testId={'weather__weatherStationBlockHeader-blockname'}
        />
      </View>
      <LastUpdate date={presenter.lastUpdated()} containerStyle={styles.lastUpdate} />
    </View>
  );
};

WeatherStationBlockHeader.propTypes = {
  presenter: PropTypes.instanceOf(BlockAliasPresenter).isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  blockName: {
    alignSelf: 'center',
    marginLeft: 20,
  },
  button: {
    width: 57,
    height: 44,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 8,
  },
  lastUpdate: {
    alignContent: 'flex-end',
    paddingRight: 5,
  },
  leftSection: {
    flexDirection: 'row',
  },
});

export default React.memo(WeatherStationBlockHeader);
