import fetch from 'cross-fetch';
import pRetry, { AbortError } from 'p-retry';

// util
import { consoleWarn } from '../utils/loggerUtil';

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000; // with exponential backoff
const RETRY_ON = [429, 503]; // http code to retry

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const RETRY_OPTIONS = {
  retries: MAX_RETRIES,
  minTimeout: RETRY_DELAY_MS,
  randomize: true,
  onFailedAttempt: (error) => consoleWarn(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`),
};

const fetchJSON = async (url, options) => {
  let response = await fetch(url, options);

  // Abort retrying if the resource doesn't exist
  if (response.status === 404) {
    return Promise.reject(new AbortError(response.statusText));
  }
  // Retry server unavailable
  if (RETRY_ON.includes(response.status)) {
    return Promise.reject(new Error(`Retry on http code ${response.status}`));
  }

  // no content
  if (response.status === 204) {
    return response;
  }

  response.data = await response.json();

  return response;
};

const postJson = async (url, json) => {
  const options = { method: 'post', headers: HEADERS, body: JSON.stringify(json) };

  return await pRetry(() => fetchJSON(url, options), RETRY_OPTIONS);
};

export { postJson };
