import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { PickerIOS, Picker } from '@react-native-picker/picker';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';
import { isIos } from '../../../services/PlatformService';

// models
import {
  FORCE_START,
  FORCE_STOP,
  ADD_TIME,
  REMOVE_TIME,
  UNKNOWN,
  isForceStopCommand,
  commandIconForType,
  defaultDurationForType,
  questionTranslationKeyForType,
  runningTranslationKeyForType,
  commandTypeForID,
} from '../../../models/automationCommandType';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// component
import CustomModal from '../../modal/CustomModal';
import DurationPicker from '../../durationPicker/DurationPicker';
import AutomationModalBottom from './AutomationModalBottom';

const AutomationConfirmModal = ({ commandType, onCancel, onChangeCommandType, onClosePanel, onConfirm }) => {
  const { t } = useTranslation();

  const [duration, setDuration] = useState(moment.duration(defaultDurationForType(commandType)));
  const [selectedCommandType, setSelectedCommandType] = useState(commandType.id);

  const iconSource = commandIconForType(commandType);
  const questionText = t(questionTranslationKeyForType(commandType));
  const runningText = t(runningTranslationKeyForType(commandType));
  const displayActions = commandType.id === ADD_TIME.id || commandType.id === REMOVE_TIME.id;

  useEffect(() => {
    setDuration(moment.duration(defaultDurationForType(commandType)));
  }, [commandType]);

  const handleConfirm = () => {
    onConfirm(duration.asMinutes());
  };

  const handleCancel = () => {
    onCancel();
  };

  const minimumDurationForCommandType = () => {
    return isForceStopCommand(commandType) ? 0 : 1;
  };

  const onSelectCommandType = (itemValue, _) => {
    const newCommandType = commandTypeForID(itemValue);
    setSelectedCommandType(itemValue);
    onChangeCommandType(newCommandType);
  };

  /* istanbul ignore next */
  const renderCommandOptions = () => {
    if (isIos()) {
      return (
        <PickerIOS
          testID={'automation_modify_action_picker_ios'}
          selectedValue={selectedCommandType}
          onValueChange={onSelectCommandType}
          itemStyle={styles.pickerItem}
          style={[styles.pickerBox, styles.pickerBoxIos]}>
          <PickerIOS.Item label={t('automation_modify_add_time')} value={ADD_TIME.id} />
          <PickerIOS.Item label={t('automation_modify_remove_time')} value={REMOVE_TIME.id} />
        </PickerIOS>
      );
    }

    // Cannot test with unit tests because of https://github.com/react-native-picker/picker/issues/145
    return (
      <View style={[styles.pickerBox, styles.pickerBoxAndroid]}>
        <Picker mode={'dropdown'} selectedValue={selectedCommandType} onValueChange={onSelectCommandType}>
          <Picker.Item label={t('automation_modify_add_time')} value={ADD_TIME.id} style={styles.pickerItem} />
          <Picker.Item label={t('automation_modify_remove_time')} value={REMOVE_TIME.id} style={styles.pickerItem} />
        </Picker>
      </View>
    );
  };

  return (
    <CustomModal isVisible={true} closeOnOutsidePress={false} onCloseModal={onClosePanel}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Image style={styles.icon} source={iconSource} />
        </View>
        <View style={styles.questionContainer}>
          <Text style={styles.mainFont}>{questionText}</Text>
        </View>

        <View style={styles.durationLabelContainer}>
          <Text style={styles.durationLabelFont}>{runningText}</Text>
        </View>

        {displayActions ? renderCommandOptions() : null}

        <View style={styles.pickerContainer}>
          <DurationPicker
            duration={duration}
            setDuration={setDuration}
            minimumDurationInMinutes={minimumDurationForCommandType()}
          />
        </View>

        <View style={styles.confirmContainer}>
          <Text style={styles.mainFont}>{t('automation_question_confirmation')}</Text>
        </View>

        <View style={styles.warnContainer}>
          <Text style={styles.warnFont}>{t('automation_warning_confirmation')}</Text>
        </View>

        <AutomationModalBottom onCancel={handleCancel} onConfirm={handleConfirm} />
      </View>
    </CustomModal>
  );
};

AutomationConfirmModal.propTypes = {
  commandType: PropTypes.oneOf([FORCE_START, FORCE_STOP, ADD_TIME, REMOVE_TIME, UNKNOWN]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onChangeCommandType: PropTypes.func,
  onClosePanel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 0,
    alignSelf: 'center',
    width: '90%',
    borderRadius: 12,
    backgroundColor: COLORS.white,
  },
  confirmContainer: {
    marginHorizontal: '5%',
    marginVertical: 14,
  },
  durationLabelContainer: {
    marginTop: 15,
  },
  durationLabelFont: {
    marginLeft: 20,
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  icon: {
    alignSelf: 'center',
    width: 61,
    height: 61,
  },
  iconContainer: {
    position: 'absolute',
    top: -30,
    width: '100%',
  },
  mainFont: {
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
  pickerBox: {
    alignSelf: 'center',
    width: 170,
  },
  pickerBoxAndroid: {
    borderColor: COLORS.lightPeach,
    borderWidth: 1,
    borderRadius: 5,
  },
  pickerBoxIos: {
    height: 100,
  },
  pickerContainer: {
    alignSelf: 'center',
    width: 310,
  },
  pickerItem: {
    height: 100,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  questionContainer: {
    marginHorizontal: '5%',
    marginTop: 40,
  },
  warnFont: {
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  warnContainer: {
    alignSelf: 'center',
    marginHorizontal: '5%',
    marginVertical: 14,
  },
});

export default React.memo(AutomationConfirmModal);
