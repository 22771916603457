// Queries
import { fetchDevice, fetchDevices } from '../queries/devices';

//services
import { sortDevicesHealth } from '../screens/devices/sorting';
import { executeApolloQuery } from './apolloHelper';
import DeviceEntity from './entities/deviceEntity';

export const devices = {
  state: {
    devices: [],
    sortedDevices: [],
    selectedDevice: undefined,
  },

  reducers: {
    updateDevices(state, dataDevices) {
      const entities = dataDevices.map((device) => new DeviceEntity(device));
      return { ...state, devices: entities, sortedDevices: entities };
    },

    updateSortedDevices(state, sortedDevices) {
      return { ...state, sortedDevices };
    },

    updateSelectedDevice(state, selectedDevice) {
      return { ...state, selectedDevice };
    },
  },

  effects: (dispatch) => ({
    async loadDevices(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchDevices(rootState.site.currentSite.id),
        (data) => {
          dispatch.devices.updateDevices(data.devices);
        },
      );
    },

    async loadDevice(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchDevice(rootState.site.currentSite.id, payload.deviceId),
        (data) => {
          dispatch.devices.updateSelectedDevice(new DeviceEntity(data.device));
        },
      );
    },

    async sortDevices(payload, rootState) {
      const { column, order } = payload;
      const sortedDevices = [...rootState.devices.devices];
      sortDevicesHealth(sortedDevices, column, order);
      dispatch.devices.updateSortedDevices(sortedDevices);
    },
  }),
};
