import React from 'react';
import PropTypes from 'prop-types';
import HeaderText from '../header/HeaderText';
import Logo from './Logo';

/* istanbul ignore next */
const noop = () => {};

const AuthHeader = ({ onLogoClick = noop, headerText }) => {
  return (
    <>
      <Logo onClick={onLogoClick} />
      <HeaderText text={headerText} />
    </>
  );
};

AuthHeader.propTypes = {
  onLogoClick: PropTypes.func,
  headerText: PropTypes.string.isRequired,
};

export default AuthHeader;
