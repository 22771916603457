import { setItem, getItem, clearMultiple } from '../services/PreferenceService';

export const SELECTED_SITE_KEY = 'selected-site';
export const AUTHENTICATION_TOKEN_KEY = 'authentication-token';
export const MAP_MODE_KEY = 'map-mode';
export const MAP_STYLE_KEY = 'map-style';
export const USER_INFO = 'user-info';
export const REPORT_TAB_INDEX = 'report-tab-index';

export const preferences = {
  state: {
    selectedSite: undefined,
    authenticationTokenLoaded: false,
  },

  reducers: {
    setSelectedSite(state, selectedSite) {
      return { ...state, selectedSite };
    },
    authenticationTokenLoaded(state) {
      return { ...state, authenticationTokenLoaded: true };
    },
  },

  effects: (dispatch) => ({
    async writeSelectedSite({ selectedSiteId }) {
      await setItem(SELECTED_SITE_KEY, selectedSiteId);

      dispatch.preferences.setSelectedSite(selectedSiteId);
    },

    async readSelectedSiteFromStorage() {
      const selectedSiteId = await getItem(SELECTED_SITE_KEY);
      dispatch.preferences.setSelectedSite(selectedSiteId);
    },

    async writeAuthenticationToken({ authenticationToken }) {
      await setItem(AUTHENTICATION_TOKEN_KEY, JSON.stringify(authenticationToken));
    },

    async readAuthenticationTokenFromStorage() {
      const authenticationTokenAsString = await getItem(AUTHENTICATION_TOKEN_KEY);

      if (authenticationTokenAsString) {
        dispatch.authentication.updateToken(JSON.parse(authenticationTokenAsString));
      }

      dispatch.preferences.authenticationTokenLoaded();
    },

    async writeMapMode({ mapMode }) {
      await setItem(MAP_MODE_KEY, mapMode);
    },

    async writeMapStyle({ mapStyle }) {
      await setItem(MAP_STYLE_KEY, mapStyle);
    },

    async writeReportTab({ tabIndex }) {
      await setItem(REPORT_TAB_INDEX, tabIndex.toString());
      dispatch.report.setReportTabIndex(tabIndex);
    },

    async readReportPreferenceFromStorage() {
      const reportTabIndex = await getItem(REPORT_TAB_INDEX);
      const index = parseInt(reportTabIndex, 10);

      if (!isNaN(index)) {
        dispatch.report.setReportTabIndex(index);
      }
    },

    async readMapPreferenceFromStorage() {
      const mapModeAsString = await getItem(MAP_MODE_KEY);
      const mapStyleAsString = await getItem(MAP_STYLE_KEY);

      if (mapModeAsString) {
        dispatch.map.setMapMode(mapModeAsString);
      }

      if (mapStyleAsString) {
        dispatch.map.setMapStyle(mapStyleAsString);
      }
    },

    async clearMultiple() {
      await clearMultiple([SELECTED_SITE_KEY, AUTHENTICATION_TOKEN_KEY, MAP_MODE_KEY, MAP_STYLE_KEY, USER_INFO]);
    },
  }),
};

const getUserIdentity = async () => {
  const user = await getItem(USER_INFO);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

const setUserIdentity = async (user) => {
  await setItem(USER_INFO, JSON.stringify(user));
};

export { getUserIdentity, setUserIdentity };
