import React, { useCallback, useEffect, useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet, Text, View } from 'react-native';
import { SvgXml } from 'react-native-svg';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useFocusEffect } from '@react-navigation/native';

// icons
import mapIcon from '../../assets/icons/icnMenuMap.svg';
import scheduleIcon from '../../assets/icons/icnMenuSchedule.svg';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { formatDateForAPI } from '../../utils/dateUtils';
import { DashboardPresenter } from '../../presenters/DashboardPresenter';
import { MapDisplayMode, MapStyleMode } from '../../presenters/MapDisplayMode';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import OPACITIES from '../../opacities';
import ROUTES from '../../navigation/routes';
import ANALYTICS from '../../services/AnalyticsEvents';
import { navigationShape } from '../../shapes/navigation';

// styles
import { globalStyles } from '../../styles';

// components
import CustomButton from '../../components/button/CustomButton';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import TemperatureProtection from '../../components/dashboard/TemperatureProtection';
import IrrigationBox from '../../components/dashboard/IrrigationBox';
import PestProphet from '../../components/dashboard/PestProphet';

const MARGIN_BETWEEN_COMPONENT = 11;

const DashboardScreen = ({ navigation, route }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();
  const store = useStore();

  const dashboardData = useSelector((state) => state.dashboard.dashboard);
  const criticalBlocks = useSelector((state) => state.dashboard.criticalBlocks);
  const scheduleData = useSelector((state) => state.dashboard.schedule);
  const loading = useSelector((state) => !!state.loading.effects.dashboard.loadDashboard);
  const blueBandIndexData = useSelector((state) => state.blueBandIndex.blueBandIndexSite);
  const mapStyle = useSelector((state) => state.map.style);
  const hasPestModule = useSelector(store.select.site.hasPestModule);
  const [dashboardPresenter, setDashboardPresenter] = useState(new DashboardPresenter(null, t));
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    if (!dashboardData) {
      return;
    }

    const presenter = new DashboardPresenter(dashboardData, t);
    setDashboardPresenter(presenter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      if (route.params?.reloadData) {
        reloadDashboard();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  useEffect(() => {
    setShowSkeleton(isRefreshing ? false : loading);
  }, [loading, isRefreshing]);

  const reloadDashboard = async () => {
    const now = moment();
    const startDate = now.clone().startOf('day');
    const endDate = now.clone().endOf('day');

    await dispatch.dashboard.loadDashboard({
      startDate: formatDateForAPI(startDate),
      endDate: formatDateForAPI(endDate),
    });
  };

  const goToSchedule = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewSchedule);
    navigation.navigate(ROUTES.SCHEDULE, { routeToGoBackTo: ROUTES.DASHBOARD });
  };

  const goToMap = () => {
    if (mapStyle === MapStyleMode.NDVI) {
      dispatch.map.setMapStyle(MapStyleMode.SATELLITE);
    }
    dispatch.map.setMapMode(MapDisplayMode.TENSION);
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewMap);
    dispatch.map.loadMapBlocksForCurrentSite();
    navigation.navigate(ROUTES.MAP, { routeToGoBackTo: ROUTES.DASHBOARD });
  };

  const refresh = async () => {
    setIsRefreshing(true);
    await reloadDashboard();
    setIsRefreshing(false);
  };

  return (
    <View testID={'dashboard__screen-container'} style={globalStyles.bottomContainer}>
      <ScrollView
        refreshControl={<RefreshControl testID="dashboard__refresh-controller" onRefresh={refresh} refreshing={isRefreshing} />}
        testID={'dashboard__sroll-view'}
        contentContainerStyle={globalStyles.scrollContainer}
        showsVerticalScrollIndicator={false}>
        <IrrigationBox
          contentStyle={[styles.componentStyle, styles.componentMargin]}
          criticalBlocks={criticalBlocks}
          schedule={scheduleData}
          blueBandIndex={blueBandIndexData}
          loading={showSkeleton}
          navigation={navigation}
          testId={'dashboard__irrigationBox'}
        />

        <TemperatureProtection
          contentStyle={[styles.componentStyle, styles.componentMargin]}
          dashboardPresenter={dashboardPresenter}
          loading={showSkeleton}
          navigation={navigation}
          testId={'dashboard__temperatureProtection'}
        />

        {hasPestModule ? (
          <PestProphet contentStyle={[styles.componentStyle, styles.componentMargin]} testId={'dashboard__pestProphet'} />
        ) : null}

        <View style={[styles.bigButtonsContainer, styles.componentMargin, { marginBottom: MARGIN_BETWEEN_COMPONENT }]}>
          <CustomButton
            onPress={goToSchedule}
            buttonStyle={[styles.componentStyle, styles.bigButtonSizeRight]}
            testId={'dashboard_schedule__bigButton'}
            underlayColor={COLORS.whiteTwo}>
            <View style={styles.bigButtonContent}>
              <SvgXml xml={scheduleIcon} fill={COLORS.warmGrey} width={30} height={30} />
              <Text style={styles.bigButtonText}>{t('menu_schedule_button')}</Text>
            </View>
          </CustomButton>

          <CustomButton
            onPress={goToMap}
            buttonStyle={[styles.componentStyle, styles.bigButtonSizeLeft]}
            testId={'dashboard_map__bigButton'}
            underlayColor={COLORS.whiteTwo}>
            <View style={styles.bigButtonContent}>
              <SvgXml xml={mapIcon} fill={COLORS.warmGrey} width={30} height={30} />
              <Text style={styles.bigButtonText}>{t('menu_map_button')}</Text>
            </View>
          </CustomButton>
        </View>
      </ScrollView>
    </View>
  );
};

DashboardScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

const styles = StyleSheet.create({
  bigButtonContent: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  bigButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  bigButtonSizeRight: {
    flex: 1,
    height: 105,
    marginRight: MARGIN_BETWEEN_COMPONENT / 2,
  },
  bigButtonSizeLeft: {
    flex: 1,
    height: 105,
    marginLeft: MARGIN_BETWEEN_COMPONENT / 2,
  },
  bigButtonText: {
    paddingTop: MARGIN_BETWEEN_COMPONENT,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  componentMargin: {
    marginHorizontal: MARGIN_BETWEEN_COMPONENT,
    marginTop: MARGIN_BETWEEN_COMPONENT,
  },
  componentStyle: {
    borderRadius: 4,
    backgroundColor: COLORS.white,
    elevation: 2,
    shadowColor: COLORS.greyShadow,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 2,
  },
});

export default DashboardScreen;
