import { round } from '../utils/mathUtil';

// models
import { INCHES } from '../models/units/depthUnit';
import { getWeatherIcon, getWeatherImage, getWeatherTranslationKey } from '../models/weather';
import { getWindDirectionName } from '../models/windDirections';

export class WeatherForecastPresenter {
  constructor(
    translation,
    etoUnit,
    etoValue,
    rainPrecipitation,
    rainProbability,
    rainUnit,
    temperatureMax,
    temperatureMin,
    temperatureUnit,
    weatherCode,
    windDirection,
    windSpeed,
    windUnit,
    todayTemperature = undefined,
  ) {
    this.translation = translation;

    this.etoValue = etoValue;
    this.etoUnit = etoUnit;
    this.temperatureMax = temperatureMax;
    this.temperatureMin = temperatureMin;
    this.rainPrecipitation = rainPrecipitation;
    this.rainUnit = rainUnit;
    this.temperatureUnit = temperatureUnit;
    this.windDirection = windDirection;
    this.windSpeed = windSpeed;
    this.windUnit = windUnit;
    this.weatherCode = weatherCode;
    this.todayTemperature = todayTemperature;
    this.rainProbability = rainProbability;
  }

  icon() {
    return getWeatherIcon(this.weatherCode);
  }

  image() {
    return getWeatherImage(this.weatherCode);
  }

  translatedDescription() {
    return this.translation(getWeatherTranslationKey(this.weatherCode));
  }

  formattedEto() {
    let eto = this.etoValue;
    if (eto === undefined) {
      return '--';
    }

    let translatedEtoUnit;
    if (!this.etoUnit || this.etoUnit === INCHES.name) {
      translatedEtoUnit = INCHES.displayValue;
    } else {
      translatedEtoUnit = this.translation(`unit_${this.etoUnit.toLowerCase()}`);

      if (eto < 1) {
        return `< 1${translatedEtoUnit}`;
      } else {
        eto = round(eto);
      }
    }

    return `${eto}${translatedEtoUnit}`;
  }

  formattedTemperatureToday() {
    if (this.todayTemperature === undefined) {
      return '--';
    }

    const translatedTemperatureUnit = this.translation(`unit_${this.temperatureUnit.toLowerCase()}`);

    return `${this.todayTemperature}${translatedTemperatureUnit}`;
  }

  formattedTemperatureMax() {
    if (this.temperatureMax === undefined) {
      return '--';
    }

    const translatedTemperatureUnit = this.translation(`unit_${this.temperatureUnit.toLowerCase()}`);

    return `${this.temperatureMax}${translatedTemperatureUnit}`;
  }

  formattedTemperatureMin() {
    if (this.temperatureMin === undefined) {
      return '--';
    }

    const translatedTemperatureUnit = this.translation(`unit_${this.temperatureUnit.toLowerCase()}`);

    return `${this.temperatureMin}${translatedTemperatureUnit}`;
  }

  formattedRainPrecipitation() {
    let rain = this.rainPrecipitation;
    if (rain === undefined) {
      return '--';
    }

    let translatedRainUnit;
    if (!this.rainUnit || this.rainUnit === INCHES.name) {
      translatedRainUnit = INCHES.displayValue;
    } else {
      translatedRainUnit = this.translation(`unit_${this.rainUnit.toLowerCase()}`);

      if (rain < 1 && rain !== 0) {
        return `< 1${translatedRainUnit}`;
      } else {
        rain = round(rain);
      }
    }

    return `${rain}${translatedRainUnit}`;
  }

  formattedRainProbability() {
    if (this.rainProbability === undefined) {
      return '--';
    }

    const translatedProbabilityUnit = this.translation('unit_percent');

    return `${this.rainProbability}${translatedProbabilityUnit}`;
  }

  formattedWindSpeed() {
    if (this.windSpeed === undefined) {
      return '--';
    }

    const translatedWindUnit = this.translation(`unit_${this.windUnit.toLowerCase()}`);
    const translatedWindDirection = this.translation(getWindDirectionName(this.windDirection));

    return `${this.windSpeed} ${translatedWindUnit} ${translatedWindDirection}`;
  }
}
