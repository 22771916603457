export default class BlockCapabilities {
  constructor(capabilities) {
    this.capabilities = capabilities;
  }

  tension() {
    return this.capabilities ? this.capabilities.tension : null;
  }

  irrigation() {
    return this.capabilities ? this.capabilities.irrigation : null;
  }

  flowStation() {
    return this.capabilities ? this.capabilities.flowStation : null;
  }

  temperature() {
    return this.capabilities ? this.capabilities.temperature : null;
  }

  weatherStation() {
    return this.capabilities ? this.capabilities.weatherStation : null;
  }
}
