import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

// constant
import COLORS from '../../colors';

// components
import SkeletonPlaceholder from './SkeletonPlaceholder';

const BORDER_RADIUS = 6;

const SkeletonMultiRow = ({ repetition, rows }) => {
  return (
    <View testID="loadingActivity">
      {[...Array(repetition)].map((_e, i) => (
        <View style={styles.skeletonContainer} key={'container' + i}>
          <View style={styles.skeletonItem}>
            <SkeletonPlaceholder width={57} height={44} style={styles.skeletonBlock} />
            <SkeletonPlaceholder width={50} height={15} />
          </View>
          <View style={styles.skeletonList}>
            {[...Array(rows)].map((_v, j) => (
              <View style={styles.skeletonRow} key={'row' + j}>
                <SkeletonPlaceholder width={93} height={15} style={styles.skeletonText1} />
                <SkeletonPlaceholder width={131} height={15} />
              </View>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
};

SkeletonMultiRow.propTypes = {
  repetition: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  skeletonBlock: {
    marginRight: 10,
  },
  skeletonContainer: {
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  skeletonItem: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 13,
    borderColor: COLORS.greyBorderColor,
    borderWidth: 1,
    backgroundColor: COLORS.white,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
  },
  skeletonList: {
    alignSelf: 'stretch',
    padding: 10,
    borderColor: COLORS.greyBorderColor,
    marginBottom: 11,
    backgroundColor: COLORS.white,
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  skeletonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  skeletonText1: {
    marginRight: 52,
  },
});

export default React.memo(SkeletonMultiRow);
