import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// models
import IrrigationEvent from '../../models/entities/irrigationEvent';

// services
import { navigationShape } from '../../shapes/navigation';
import { colorForStatus } from '../../models/status/tensionStatus';
import ANALYTICS from '../../services/AnalyticsEvents';

// hooks
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// styles
import { globalStyles } from '../../styles';

//constants
import ROUTES from '../../navigation/routes';
import { SchedulerBlockShape } from '../../shapes/block';

// components
import DayEvents from '../../components/event/DayEvents';
import Divider from '../../components/Divider';
import HeaderBack from '../../components/header/HeaderBack';

const ScheduleEventListScreen = ({ navigation, route }) => {
  const { block, day, dailyEvents } = route.params || {};

  const dispatch = useDispatch();

  const blockAliasColor = colorForStatus(block?.capabilities?.tension?.status);
  const analyticsService = useAnalyticsContext();

  const goBack = useCallback(() => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewSchedule);
    return navigateToSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, navigation]);

  useBackHandler(goBack);

  // istanbul ignore next
  const goBackFromEventManagement = useCallback(() => {
    return navigateToSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  const navigateToSchedule = () => {
    navigation.navigate(ROUTES.SCHEDULE);
    return true; // prevent event bubble up (Android will close the app)
  };

  const navigateToManageEvent = (selectedEvent) => {
    dispatch.schedule.updateSelectedBlockId(block?.id);
    dispatch.schedule.updateSelectedEvent(selectedEvent);
    navigation.navigate(ROUTES.MANAGE_EVENT, { onGoBack: goBackFromEventManagement });
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header} testID="schedule-event-list__subscreen-container">
        <HeaderBack
          screenName="schedule-event-list"
          navigation={navigation}
          goBack={goBack}
          title={block?.alias}
          subtitle={block?.name}
          titleColor={blockAliasColor}
        />
      </View>

      <Divider />

      <View style={globalStyles.bottomContainer}>
        <ScrollView contentContainerStyle={globalStyles.scrollContainer} showsVerticalScrollIndicator={false}>
          {day && dailyEvents ? <DayEvents day={day} events={dailyEvents} navigateToManageEvent={navigateToManageEvent} /> : null}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

ScheduleEventListScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      block: SchedulerBlockShape,
      dailyEvents: PropTypes.arrayOf(PropTypes.instanceOf(IrrigationEvent)),
      day: PropTypes.instanceOf(moment),
    }),
  }),
};

export default ScheduleEventListScreen;
