import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import moment from 'moment-timezone';
import { useSharedValue } from 'react-native-reanimated';

// services
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { useTranslation } from '../../hooks/useTranslation';
import { compareEventByStartDate, SORT_ORDER } from '../../utils/sorting';

// components
import ListBox from '../list/ListBox';
import EventItem from './eventItem/EventItem';

const DayEvents = ({ events, day, navigateToManageEvent }) => {
  const { t } = useTranslation();
  const irrigationAnimatedOpacity = useSharedValue(1);

  const getDateFormat = () => {
    if (moment().dayOfYear() === day.dayOfYear()) {
      return t('to_day');
    }
    return day.format(t('date_format_month_day_year'));
  };

  const sortedEvents = events.sort((a, b) => compareEventByStartDate(a, b, SORT_ORDER.ASC, true));

  const renderEvents = () =>
    sortedEvents.map((event) => (
      <View style={styles.eventContainer} key={event.id}>
        <EventItem event={event} navigateToManageEvent={navigateToManageEvent} animatedOpacity={irrigationAnimatedOpacity} />
      </View>
    ));

  return (
    <ListBox
      customStyle={styles.listBox}
      renderContent={() => <Text style={styles.title}>{getDateFormat()}</Text>}
      renderSubContent={() => renderEvents()}
    />
  );
};

DayEvents.propTypes = {
  events: PropTypes.array.isRequired,
  day: PropTypes.instanceOf(moment).isRequired,
  navigateToManageEvent: PropTypes.func,
};

const styles = StyleSheet.create({
  eventContainer: {
    marginVertical: 3,
  },
  listBox: {
    paddingLeft: 8,
  },
  title: {
    fontFamily: FONTS.firaSansBold,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
});

export default DayEvents;
