import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import messaging from '@react-native-firebase/messaging';

// service
import { isIos } from '../../services/PlatformService';

const AnalyticsContext = React.createContext({});
const Provider = AnalyticsContext.Provider;

const AnalyticsProvider = ({ analyticsService, children }) => {
  useEffect(() => {
    const connectToAnalytics = async () => {
      if (isIos()) {
        messaging()
          .getIsHeadless()
          .then(async (isHeadless) => {
            await analyticsService.connect(isHeadless);
          });
      } else {
        await analyticsService.connect();
      }
    };

    connectToAnalytics();
  }, [analyticsService]);

  return <Provider value={analyticsService}>{children}</Provider>;
};

AnalyticsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  analyticsService: PropTypes.object.isRequired,
};

const useAnalyticsContext = () => useContext(AnalyticsContext);

export { AnalyticsProvider, useAnalyticsContext };
