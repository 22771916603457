// icons
import icnDrizzle175 from '../assets/icons/weatherForecastIcons/icnDrizzle175.png';
import icnNa175 from '../assets/icons/weatherForecastIcons/icnNa175.png';
import icnClouds175 from '../assets/icons/weatherForecastIcons/icnClouds175.png';
import icnMist175 from '../assets/icons/weatherForecastIcons/icnMist175.png';
import icnSnow175 from '../assets/icons/weatherForecastIcons/icnSnow175.png';
import icnSun175 from '../assets/icons/weatherForecastIcons/icnSun175.png';
import icnThunderstorm175 from '../assets/icons/weatherForecastIcons/icnThunderstorm175.png';
import icnRain175 from '../assets/icons/weatherForecastIcons/icnRain175.png';

import icnDrizzle from '../assets/icons/weatherForecastIcons/icnDrizzle.png';
import icnNa from '../assets/icons/weatherForecastIcons/icnNa.png';
import icnClouds from '../assets/icons/weatherForecastIcons/icnClouds.png';
import icnMist from '../assets/icons/weatherForecastIcons/icnMist.png';
import icnSnow from '../assets/icons/weatherForecastIcons/icnSnow.png';
import icnSun from '../assets/icons/weatherForecastIcons/icnSun.png';
import icnThunderstorm from '../assets/icons/weatherForecastIcons/icnThunderstorm.png';
import icnRain from '../assets/icons/weatherForecastIcons/icnRain.png';

export const ATMOSPHERE = 'ATMOSPHERE';
export const CLEAR = 'CLEAR';
export const CLOUDS = 'CLOUDS';
export const DRIZZLE = 'DRIZZLE';
export const RAIN = 'RAIN';
export const SNOW = 'SNOW';
export const THUNDERSTORM = 'THUNDERSTORM';
export const NA_WEATHER_CODE = 'NA';

export const weatherTranslationByCode = {
  MIST: 'weather_forecast_mist',
  SMOKE: 'weather_forecast_smoke',
  HAZE: 'weather_forecast_haze',
  DUST: 'weather_forecast_dust',
  FOG: 'weather_forecast_fog',
  SAND: 'weather_forecast_sand',
  ASH: 'weather_forecast_ash',
  SQALL: 'weather_forecast_sqall',
  TORNADO: 'weather_forecast_tornado',
  CLEAR: 'weather_forecast_clear',
  CLOUDS: 'weather_forecast_clouds',
  DRIZZLE: 'weather_forecast_drizzle',
  RAIN: 'weather_forecast_rain',
  SNOW: 'weather_forecast_snow',
  THUNDERSTORM: 'weather_forecast_thunderstorm',
  [undefined]: 'weather_forecast_default',
  default: 'weather_forecast_default',
};

export const weatherIconByCode = {
  MIST: icnMist,
  SMOKE: icnMist,
  HAZE: icnMist,
  DUST: icnMist,
  FOG: icnMist,
  SAND: icnMist,
  ASH: icnMist,
  SQALL: icnMist,
  TORNADO: icnMist,
  CLEAR: icnSun,
  CLOUDS: icnClouds,
  DRIZZLE: icnDrizzle,
  RAIN: icnRain,
  SNOW: icnSnow,
  THUNDERSTORM: icnThunderstorm,
  [undefined]: icnNa,
  default: icnNa,
};

export const weatherImageByCode = {
  MIST: icnMist175,
  SMOKE: icnMist175,
  HAZE: icnMist175,
  DUST: icnMist175,
  FOG: icnMist175,
  SAND: icnMist175,
  ASH: icnMist175,
  SQALL: icnMist175,
  TORNADO: icnMist175,
  CLEAR: icnSun175,
  CLOUDS: icnClouds175,
  DRIZZLE: icnDrizzle175,
  RAIN: icnRain175,
  SNOW: icnSnow175,
  THUNDERSTORM: icnThunderstorm175,
  [undefined]: icnNa175,
  default: icnNa175,
};

export const getWeatherImage = (weatherCode) => weatherImageByCode[weatherCode] || weatherImageByCode.default;

export const getWeatherIcon = (weatherCode) => weatherIconByCode[weatherCode] || weatherIconByCode.default;

export const getWeatherTranslationKey = (weatherCode) =>
  weatherTranslationByCode[weatherCode] || weatherTranslationByCode.default;
