import React, { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import PropTypes from 'prop-types';

// styles
import { globalStyles } from '../styles';

// components
import BlockEntity from '../models/entities/blockEntity';

const SortableList = forwardRef(
  ({ loading, refreshing, elements, screenName, renderItem, renderEmptyView, onRefresh, initialNumToRender }, ref) => {
    return (
      <FlashList
        ref={ref}
        testID={`${screenName}__subscreen-main`}
        data={loading && !refreshing ? [] : elements}
        renderItem={renderItem}
        initialNumToRender={initialNumToRender}
        removeClippedSubviews={true}
        ListFooterComponent={<View style={styles.itemSeparator} />}
        ListHeaderComponent={<View style={styles.itemSeparator} />}
        ListEmptyComponent={renderEmptyView}
        contentContainerStyle={globalStyles.scrollContainer}
        onRefresh={onRefresh}
        refreshing={refreshing}
        showsVerticalScrollIndicator={false}
        estimatedItemSize={100}
      />
    );
  },
);

SortableList.displayName = 'SortableList';

SortableList.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(BlockEntity)])),
  loading: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderEmptyView: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  screenName: PropTypes.string.isRequired,
  initialNumToRender: PropTypes.number,
};

const styles = StyleSheet.create({
  main: {
    height: '100%',
  },
  itemSeparator: {
    height: 8,
  },
});

export default SortableList;
