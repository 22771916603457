const KEY_PREFIX = 'data_stream_button_name_';
const NO_VALUE_TEXT = '- -';
const ROUND_DECIMALS = 4;

const dataStreamName = (dataStreamType, description, t) => {
  if (description) {
    return description;
  }

  const key = `${KEY_PREFIX}${dataStreamType}`.toLowerCase();
  const translation = t(key);
  return translation === key ? dataStreamType : translation;
};

const mapDataStreamsIdsToDataStreams = (dataStreamsIds, dataStreams) => {
  const mappedDataStreams = [];

  if (dataStreamsIds && dataStreams) {
    dataStreamsIds.forEach((metadata) => {
      // TODO conditional check to support both Graph 1.0 and 2.0
      const id = metadata.id ? metadata.id : metadata;
      const color = metadata.color ? metadata.color : null;
      const foundDataStream = dataStreams.find((dataStream) => dataStream.id === id);

      if (foundDataStream) {
        foundDataStream.color = color;
        mappedDataStreams.push(foundDataStream);
      }
    });
  }

  return mappedDataStreams;
};

const formatValue = (value) => {
  if (value == null) {
    return NO_VALUE_TEXT;
  } else {
    var croppedValue = parseFloat(value.toFixed(ROUND_DECIMALS));
    return croppedValue.toString();
  }
};

const readingsToDataStream = (readings) => {
  if (!readings) {
    return [];
  }

  return readings.reduce((accumulator, reading) => {
    const dataStream = reading.dataStream;

    if (dataStream) {
      if (reading.colorCode) {
        dataStream.color = '#' + reading.colorCode.toLowerCase();
      }
      return accumulator.concat(dataStream);
    }

    return accumulator;
  }, []);
};

export { dataStreamName, mapDataStreamsIdsToDataStreams, formatValue, readingsToDataStream };
