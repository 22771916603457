import React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useSelector } from 'react-redux';

// screens
import MapScreen from '../screens/map/MapScreen';
import MainScreen from '../screens/main/MainScreen';
import LoadingScreen from '../screens/LoadingScreen';
import LoginScreen from '../screens/login/LoginScreen';
import WeatherScreen from '../screens/weather/WeatherScreen';
import WeatherPreviewScreen from '../screens/previews/WeatherPreviewScreen';
import DevicesScreen from '../screens/devices/DevicesScreen';
import DeviceDetailsScreen from '../screens/deviceDetails/DeviceDetailsScreen';
import ScheduleScreen from '../screens/schedule/ScheduleScreen';
import DashboardScreen from '../screens/dashboard/DashboardScreen';
import IrrigationScreen from '../screens/irrigation/IrrigationScreen';
import FlowStationScreen from '../screens/flowStation/FlowStationScreen';
import FlowStationPreviewScreen from '../screens/previews/FlowStationPreviewScreen';
import ReportScreen from '../screens/report/ReportScreen';
import ReportPreviewScreen from '../screens/previews/ReportPreviewScreen';
import TemperatureScreen from '../screens/temperature/TemperatureScreen';
import TemperaturePreviewScreen from '../screens/previews/TemperaturePreviewScreen';
import ForgotUsernameScreen from '../screens/forgot/ForgotUsernameScreen';
import ForgotPasswordScreen from '../screens/forgot/ForgotPasswordScreen';
import SiteSelectorScreen from '../screens/siteSelector/SiteSelectorScreen';
import BlockDetailsScreen from '../screens/blockDetails/BlockDetailsScreen';
import BlockBlueBandIndexScreen from '../screens/blockBlueBandIndex/BlockBlueBandIndexScreen';
import SiteBlueBandIndexScreen from '../screens/siteBlueBandIndex/SiteBlueBandIndexScreen';
import NotesScreen from '../screens/notes/NotesScreen';
import NoteEditScreen from '../screens/notes/NoteEditScreen';
import NoteBlockListScreen from '../screens/notes/NoteBlockListScreen';
import AutomationScreen from '../screens/automation/AutomationScreen';
import AutomationPreviewScreen from '../screens/previews/AutomationPreviewScreen';
import AutomationRuleEditScreen from '../screens/automationRules/AutomationRuleEditScreen';
import AutomationRulesScreen from '../screens/automationRules/AutomationRulesScreen';
import ScheduleEventListScreen from '../screens/scheduleEventList/ScheduleEventListScreen';
import BlockEventListScreen from '../screens/blockEventList/BlockEventListScreen';
import ManageEventScreen from '../screens/event/ManageEventScreen';
import WeatherForecastsScreen from '../screens/weatherForecasts/WeatherForecastsScreen';
import ConsoleScreen from '../screens/console/ConsoleScreen';
import ConsoleDetailsScreen from '../screens/console/ConsoleDetailsScreen';
import ProfileScreen from '../screens/profile/ProfileScreen';

// components
import SideMenu from '../components/navigation/SideMenu';

// images
import { backgroundImage } from '../assets/index';

// services
import { isIos } from '../services/PlatformService';
import { sideBarDrawerType } from '../services/EnvironmentService';

// constants
import COLORS from '../colors';
import ROUTES from './routes';

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: COLORS.transparent,
  },
};

const SUBSCREENS = {
  Dashboard: {
    subScreenName: 'dashboard',
    subScreen: DashboardScreen,
  },
  Irrigation: {
    subScreenName: 'irrigation',
    subScreen: IrrigationScreen,
  },
  FlowStation: {
    subScreenName: 'flow-station',
    subScreen: FlowStationScreen,
  },
  Temperature: {
    subScreenName: 'temperature',
    subScreen: TemperatureScreen,
  },
};

const drawerWidth = { width: '75%', maxWidth: 310 };

/* istanbul ignore next */
const sideMenuContent = (props) => <SideMenu {...props} />;

const Drawer = createDrawerNavigator();
/* istanbul ignore next */
export const DrawerStack = () => (
  <Drawer.Navigator
    backBehavior="none"
    initialRouteName={ROUTES.MAP}
    screenOptions={{ headerShown: false, drawerStyle: drawerWidth }}
    drawerContent={sideMenuContent}
    drawerType={sideBarDrawerType()}
    overlayColor="transparent">
    <Drawer.Screen name={ROUTES.MAP} component={MapScreen} />
    <Drawer.Screen name={ROUTES.DASHBOARD} component={MainScreen} initialParams={{ ...SUBSCREENS.Dashboard }} />
    <Drawer.Screen name={ROUTES.SCHEDULE} component={ScheduleScreen} />
    <Drawer.Screen name={ROUTES.IRRIGATION} component={MainScreen} initialParams={{ ...SUBSCREENS.Irrigation }} />
    <Drawer.Screen name={ROUTES.FLOW_STATION} component={MainScreen} initialParams={{ ...SUBSCREENS.FlowStation }} />
    <Drawer.Screen name={ROUTES.FLOW_STATION_PREVIEW} component={FlowStationPreviewScreen} />
    <Drawer.Screen name={ROUTES.TEMPERATURE} component={MainScreen} initialParams={{ ...SUBSCREENS.Temperature }} />
    <Drawer.Screen name={ROUTES.TEMPERATURE_PREVIEW} component={TemperaturePreviewScreen} />
    <Drawer.Screen name={ROUTES.REPORT} component={ReportScreen} />
    <Drawer.Screen name={ROUTES.REPORT_PREVIEW} component={ReportPreviewScreen} />
    <Drawer.Screen name={ROUTES.DEVICES} component={DevicesScreen} />
    <Drawer.Screen name={ROUTES.CONSOLE} component={ConsoleScreen} />
    <Drawer.Screen name={ROUTES.PROFILE} component={ProfileScreen} />
    <Drawer.Screen name={ROUTES.SITE_SELECTOR} component={SiteSelectorScreen} />
    <Drawer.Screen
      name={ROUTES.AUTOMATION}
      component={AutomationScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen name={ROUTES.AUTOMATION_PREVIEW} component={AutomationPreviewScreen} />
    <Drawer.Screen
      name={ROUTES.AUTOMATION_RULE_EDIT}
      component={AutomationRuleEditScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.AUTOMATION_RULES}
      component={AutomationRulesScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.BLOCK_DETAILS}
      component={BlockDetailsScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.BLOCK_BLUE_BAND_INDEX}
      component={BlockBlueBandIndexScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.DEVICE_DETAILS}
      component={DeviceDetailsScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.CONSOLE_DETAILS}
      component={ConsoleDetailsScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.SITE_BLUE_BAND_INDEX}
      component={SiteBlueBandIndexScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.WEATHER}
      component={WeatherScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen name={ROUTES.WEATHER_PREVIEW} component={WeatherPreviewScreen} />
    <Drawer.Screen
      name={ROUTES.NOTES}
      component={NotesScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.NOTE_EDIT}
      component={NoteEditScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.NOTE_BLOCK_LIST}
      component={NoteBlockListScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.SCHEDULE_EVENT_LIST}
      component={ScheduleEventListScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.BLOCK_EVENT_LIST}
      component={BlockEventListScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.MANAGE_EVENT}
      component={ManageEventScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
    <Drawer.Screen
      name={ROUTES.WEATHER_FORECASTS}
      component={WeatherForecastsScreen}
      options={{ drawerLockMode: isIos() ? 'locked-closed' : 'unlocked' }}
    />
  </Drawer.Navigator>
);

const Auth = createNativeStackNavigator();
const AuthStack = () => (
  <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
    <Auth.Navigator screenOptions={{ headerShown: false, animation: 'slide_from_right' }} initialRouteName={ROUTES.SIGN_IN}>
      <Auth.Screen name={ROUTES.SIGN_IN} component={LoginScreen} />
      <Auth.Screen name={ROUTES.FORGOT_USERNAME} component={ForgotUsernameScreen} />
      <Auth.Screen name={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
    </Auth.Navigator>
  </ImageBackground>
);

const Stack = createNativeStackNavigator();

const AppContainer = () => {
  const authentication = useSelector((state) => state.authentication);
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentSite = useSelector((state) => state.site.currentSite);

  return (
    <SafeAreaProvider>
      <NavigationContainer theme={navTheme}>
        <Stack.Navigator screenOptions={{ headerShown: false, animation: 'fade' }} initialRouteName={ROUTES.LOADING}>
          {authentication.token && currentUser?.id && currentSite?.id ? (
            <Stack.Screen name={ROUTES.APP} component={DrawerStack} />
          ) : (
            <>
              <Stack.Screen name={ROUTES.LOADING} component={LoadingScreen} />
              <Stack.Screen name={ROUTES.AUTH} component={AuthStack} options={{ gestureEnabled: false }} />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
});

export default AppContainer;
