import moment from 'moment-timezone';
import { DATA_STREAMS_TYPES, DEFAULT_PRIORITY } from '../models/dataStreamTypes';

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
};

const compareBlock = (a, b, sortOrder) => {
  const order1 = a.order !== null && a.order !== undefined ? a.order : Number.MAX_VALUE;
  const order2 = b.order !== null && b.order !== undefined ? b.order : Number.MAX_VALUE;
  if (order1 !== order2) {
    if (order1 > order2) {
      return sortOrder === SORT_ORDER.ASC ? 1 : -1;
    }
    return sortOrder === SORT_ORDER.ASC ? -1 : 1;
  }

  const alias1 = a.alias || '';
  const alias2 = b.alias || '';
  let compareValue = alias1.localeCompare(alias2);
  if (compareValue === 0) {
    const name1 = a.name || '';
    const name2 = b.name || '';
    compareValue = name1.localeCompare(name2);
  }
  return sortOrder === SORT_ORDER.ASC ? compareValue : -compareValue;
};

const compareDataStreamByPriority = (dataStreamA, dataStreamB) => {
  const dataStreamAPriority = extractDataStreamTypePriority(dataStreamA);
  const dataStreamBPriority = extractDataStreamTypePriority(dataStreamB);

  if (dataStreamAPriority > dataStreamBPriority) {
    return 1;
  } else if (dataStreamAPriority < dataStreamBPriority) {
    return -1;
  }

  const byStreamType = dataStreamA.dataStreamType.localeCompare(dataStreamB.dataStreamType);
  if (byStreamType === 0) {
    return dataStreamA.description?.localeCompare(dataStreamB.description) || 0;
  }
  return byStreamType;
};

const extractDataStreamTypePriority = (dataStream) => {
  return DATA_STREAMS_TYPES[dataStream.dataStreamType.toUpperCase()]
    ? DATA_STREAMS_TYPES[dataStream.dataStreamType.toUpperCase()].priority
    : DEFAULT_PRIORITY;
};

const compareEventByStartDate = (eventA, eventB, sortOrder, anytimeEventFirst = false) => {
  if (anytimeEventFirst) {
    if (eventA.anytimeDayEvent) {
      return sortOrder === SORT_ORDER.ASC ? -1 : 1;
    } else if (eventB.anytimeDayEvent) {
      return sortOrder === SORT_ORDER.ASC ? 1 : -1;
    }
  }

  const startDateA = moment(eventA.startDate);
  const startDateB = moment(eventB.startDate);

  if (startDateA.isAfter(startDateB)) {
    return sortOrder === SORT_ORDER.ASC ? 1 : -1;
  } else if (startDateA.isBefore(startDateB)) {
    return sortOrder === SORT_ORDER.ASC ? -1 : 1;
  }
  return 0;
};

const toggleSort = (sortedColumn, setSortedColumn, initialState) => {
  return (sortKey) => {
    if (sortedColumn[sortKey] === SORT_ORDER.NONE) {
      setSortedColumn({ ...initialState, [sortKey]: SORT_ORDER.ASC });
    } else if (sortedColumn[sortKey] === SORT_ORDER.ASC) {
      setSortedColumn({ ...initialState, [sortKey]: SORT_ORDER.DESC });
    } else {
      setSortedColumn({ ...initialState, [sortKey]: SORT_ORDER.ASC });
    }
  };
};

export { compareBlock, compareDataStreamByPriority, compareEventByStartDate, SORT_ORDER, toggleSort };
