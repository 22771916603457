import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

//components
import TensionValueElement from './TensionValueElement';

const TensionValuesList = ({ list }) => {
  const renderItem = (item, index) => {
    return <TensionValueElement value={item.value} colorCode={item.color} key={index} />;
  };

  return <View style={styles.tensionListContainer}>{list.map((item, index) => renderItem(item, index))}</View>;
};

TensionValuesList.propTypes = {
  list: PropTypes.array,
};

const styles = StyleSheet.create({
  tensionListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export default TensionValuesList;
