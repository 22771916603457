// constants
import COLORS from '../../colors';

const MAX_LATITUDE = 90;
const MIN_LATITUDE = -90;
const MAX_LONGITUDE = 180;
const MIN_LONGITUDE = -180;
export const DEFAULT_COORDINATE = 0.001;

export const getLatLongBounds = (coordinates) => {
  if (!coordinates || coordinates.length < 2) {
    throw new Error('At least 2 coordinates are required');
  }
  let minLat = MAX_LATITUDE;
  let maxLat = MIN_LATITUDE;
  let minLong = MAX_LONGITUDE;
  let maxLong = MIN_LONGITUDE;

  coordinates.forEach((coordinate) => {
    const latitude = coordinate.latitude;
    const longitude = coordinate.longitude;
    minLat = Math.min(minLat, latitude);
    minLong = Math.min(minLong, longitude);
    maxLat = Math.max(maxLat, latitude);
    maxLong = Math.max(maxLong, longitude);
  });

  return {
    northEast: normalizeCoordinate([maxLong, maxLat]),
    southWest: normalizeCoordinate([minLong, minLat]),
    areDifferent: maxLong !== minLong || maxLat !== minLat,
  };
};

export const normalizeCoordinate = (latLong) => {
  const longitude = latLong[0];
  const latitude = latLong[1];

  return [longitude ? longitude : DEFAULT_COORDINATE, latitude ? latitude : DEFAULT_COORDINATE];
};

export const getNDVILegends = (t) => {
  return [
    { color: COLORS.grey, text: t('map_ndvi_barren') },
    { color: COLORS.malibu, text: t('map_ndvi_very_low_photo') },
    { color: COLORS.red, text: t('map_ndvi_low_photo') },
    { color: COLORS.yellow, text: t('map_ndvi_medium_photo') },
    { color: COLORS.green, text: t('map_ndvi_high_photo') },
    { color: COLORS.magenta, text: t('map_ndvi_unatural') },
  ];
};
