import { StyleSheet } from 'react-native';

import COLORS from '../../../../colors';
import FONTS from '../../../../fonts';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
    paddingRight: 10,
    paddingLeft: 16,
  },
  divider: {
    marginLeft: 16,
  },
  iosPickerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iosPicker: {
    height: 118,
  },
  legendText: {
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
  valueText: {
    fontFamily: FONTS.firaSansMedium,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
});

export default styles;
