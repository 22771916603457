export const MINIMUM_X_AXIS_WEEKS = 52;
const ONE_HOUR_IN_MILLISECONDS = 3600000;
const ONE_WEEK_IN_MILLISECONDS = 604800000;

export const tensionForecastInHours = (timeScale) => {
  switch (timeScale) {
    case 6:
      return 6;
    case 12:
      return 6;
    case 24:
      return 12;
    case 48:
      return 24;
    case 168:
      return 72;
    case 336:
      return 168;
    case 720:
      return 168;
    case 2160:
      return 0;
    case 8640:
      return 0;
    default:
      return 0;
  }
};

export const maxDateAsMilliseconds = () => Date.now();
export const maxDateWithForecast = (timeScale) => Date.now() + tensionForecastInHours(timeScale) * ONE_HOUR_IN_MILLISECONDS;
export const maxDateWithFullForecast = () => Date.now() + ONE_WEEK_IN_MILLISECONDS;
export const minViewDateAsMilliseconds = (timeScale) => Date.now() - timeScale * ONE_HOUR_IN_MILLISECONDS;
export const minDateAsMilliseconds = () => Date.now() - MINIMUM_X_AXIS_WEEKS * ONE_WEEK_IN_MILLISECONDS;
