/* istanbul ignore file */
import React from 'react';

const Graph = () => {
  return <></>;
};

Graph.displayName = 'Graph';

export default Graph;
