import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AuthorizedModule = ({ module, children, isPreviewMode = false }) => {
  const currentSite = useSelector((state) => state.site.currentSite);
  if (isPreviewMode) {
    return currentSite.modules.includes(module) ? null : children;
  }
  return currentSite.modules.includes(module) ? children : null;
};

AuthorizedModule.propTypes = {
  module: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isPreviewMode: PropTypes.bool,
};

export default AuthorizedModule;
