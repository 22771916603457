import React from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import PropTypes from 'prop-types';

//presenter
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

//components
import LastUpdate from '../block/LastUpdate';
import BlockAliasButton from '../block/BlockAliasButton';

/* istanbul ignore next */
const noop = () => {};

const GraphHeader = ({ blockAliasPresenter, showBorder = true, onAliasPress = noop, onRowPress = noop }) => {
  const headerStyle = showBorder ? [styles.headerBar, styles.headerBorder] : [styles.headerBar];

  return (
    <Pressable style={headerStyle} onPress={onRowPress} testID="graph__clickable-header">
      <View style={styles.leftSection}>
        <BlockAliasButton
          testId={`block__alias-button-${blockAliasPresenter.id()}`}
          presenter={blockAliasPresenter}
          onClick={onAliasPress}
        />
        <Text style={styles.blockName} ellipsizeMode="tail" numberOfLines={1}>
          {blockAliasPresenter.name()}
        </Text>
      </View>

      <LastUpdate date={blockAliasPresenter.lastUpdated()} />
    </Pressable>
  );
};

GraphHeader.propTypes = {
  blockAliasPresenter: PropTypes.instanceOf(BlockAliasPresenter),
  onAliasPress: PropTypes.func,
  onRowPress: PropTypes.func,
  showBorder: PropTypes.bool,
};

const styles = StyleSheet.create({
  headerBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    paddingRight: 17,
    paddingLeft: 15,
  },
  headerBorder: {
    borderBottomColor: COLORS.lightGrey,
    borderBottomWidth: 1,
  },
  leftSection: {
    flexDirection: 'row',
  },
  blockName: {
    alignSelf: 'center',
    marginLeft: 22,
    textAlign: 'left',
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
});

export default React.memo(GraphHeader);
