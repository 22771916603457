import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// services
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

// constants
import { getBlockItemWidth, ScheduleConstants } from '../../screens/schedule/ScheduleConstants';

// components
import BlockGroup from './BlockGroup';
import BlockContainer from './BlockContainer';

const BlockPanel = ({ schedulePresenter, blockPanelWidth, expanded }) => {
  const virtualBlocksWithoutGroup = schedulePresenter.getVirtualBlocksWithoutGroup();
  const blockGroups = schedulePresenter.getBlockGroups();
  const blocks = schedulePresenter.getBlocksWithoutGroup();

  const containerPaddingLeft = blockGroups.length ? blockPanelWidth - getBlockItemWidth(expanded) : 0;

  return (
    <View style={[styles.leftLabelContainer, { width: blockPanelWidth }]}>
      {virtualBlocksWithoutGroup.map((virtualBlock, index) => (
        <BlockContainer
          expanded={expanded}
          index={index}
          block={virtualBlock}
          paddingLeft={containerPaddingLeft}
          key={`label-${virtualBlock.id}`}
        />
      ))}
      {blockGroups.map((group, index) => (
        <BlockGroup
          groupIndex={index}
          group={group}
          blocks={schedulePresenter.getBlocksWithinGroup(group.id)}
          expanded={expanded}
          key={`label-${group.id}`}
        />
      ))}
      {blocks.map((block, index) => (
        <BlockContainer
          expanded={expanded}
          index={index}
          block={block}
          paddingLeft={containerPaddingLeft}
          key={`label-${block.id}`}
        />
      ))}
    </View>
  );
};

BlockPanel.propTypes = {
  blockPanelWidth: PropTypes.number,
  expanded: PropTypes.bool.isRequired,
  schedulePresenter: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  leftLabelContainer: {
    zIndex: 1,
    borderColor: ScheduleConstants.BORDER_COLOR,
    borderBottomWidth: 1,
    elevation: 14,
    shadowColor: COLORS.greyishBrown,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
  },
});

export default BlockPanel;
