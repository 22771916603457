import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import FONTS from '../../fonts';

const ConsoleListHeader = ({ status, title }) => {
  return (
    <View style={styles.header}>
      <Text style={styles.status}>{status}</Text>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.empty} />
    </View>
  );
};

ConsoleListHeader.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  header: {
    zIndex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 48,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  status: {
    flex: 0.2,
    paddingLeft: 15,
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrownTwo,
  },
  title: {
    flex: 0.6,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrownTwo,
  },
  empty: {
    flex: 0.2,
    paddingRight: 15,
  },
});

export default ConsoleListHeader;
