const COLORS = {
  apricot: 'rgb(255, 188, 97)',
  azure: 'rgb(0, 162, 225)',
  babyPurple: '#cd9bf8',
  black: 'black',
  blackSqueeze: 'rgb(236, 236, 232)',
  blackWithLessOpacity: 'rgba(0, 0, 0, 0.5)',
  blue: 'blue',
  azureRadiance: '#0082FC', // bluetooth
  blueDeepMidnight: '#2C2C44',
  royalBlue: 'rgb(36, 73, 227)',
  blueGrey: 'rgb(5, 192, 212)',
  blueishGrey: '#c6d3da',
  bluishGrey: '#6b8e9b',
  brownGrey: 'rgba(151, 151, 151, 0.2)',
  brownGreyLight: 'rgba(151, 151, 151, 0.5)',
  brownGreyOpaque: 'rgb(151, 151, 151)',
  cerulean: '#0aa3d5',
  cerulean2: '#00a2e1',
  darkBlue: '#52637a',
  darkGrey: 'darkgray',
  darkishPink: '#e46679',
  darkSeafoamGreen: '#3bae70',
  darkSkyBlue: '#3db5e3',
  deepRed: '#ab1001',
  desertStorm: '#f1f1ef',
  disabled: '#EBEBE4',
  divider: '#bcbbc1',
  dustyOrange: '#f07048',
  duckEggBlue: '#b9e6f7',
  emerald: '#48cd83',
  emerald2: '#44D459',
  emeraldRush: '#51c379',
  fadedBlue: '#5ab7b9',
  fadedOrange: '#f4ae4e',
  fuscousGray: '#4e4a44',
  green: '#00c800',
  greenTrue: '#00ff00',
  grey: '#7f7f7f',
  greyBorderColor: 'rgb(222, 227, 228)',
  greyBorderColor2: 'rgb(236,236,233)',
  greyishLight: 'rgb(178, 175, 172)',
  greyish19: 'rgb(191, 188, 183)',
  greyish20: 'rgb(241, 245, 247)',
  greyish21: 'rgb(212, 212, 212)',
  greyish22: 'rgb(244, 244, 243)',
  greyishDark: '#878787',
  greyishBrown: '#65615a',
  greyishBrownDarker: 'rgb(62, 60, 55)',
  greyishBrownHalf: 'rgba(101, 97, 90, 0.5)',
  greyishBrownTwo: '#5a564f',
  greyShadow: 'rgb(205, 205, 205)',
  greyishThree: '#bab5ad',
  havelockBlue: '#4782cd',
  iceBlue: 'rgb(239, 244, 247)',
  pictonBlue: '#45b2e4', // irrigationEvent
  lightBlueGrey: 'rgb(197, 230, 233)',
  lightGreen: 'rgb(239, 252, 244)',
  lightGrey: '#ececec',
  lightGrey2: '#edede8',
  lightGreyBlue: '#9acbde',
  lightGreyBlue12: 'rgba(154, 203, 222, 0.12)',
  lightPeach: 'rgba(221, 219, 216, 1)',
  lightSage: '#c1eed1',
  lightSeafoamBlue: '#6ed19120',
  magenta: '#ff00ef',
  maize: '#fad344',
  malibu: '#8c8cff',
  midGrey: 'rgb(238, 238, 239)',
  paleGreyThree: 'rgb(241, 241, 242)',
  paleGreyFour: 'rgb(233, 238, 240)',
  paleAqua: 'rgb(197, 255, 217)',
  paleTeal: '#7ecaab',
  pinkishBrown: '#bf7159',
  pinkishGrey: '#bbb8b3',
  pinkishGrey2: 'rgba(187, 184, 179, 0.44)',
  seafoamBlue: '#6ed191',
  splashBackground: '#777D85',
  signalBlue: '#577D9B',
  silverChalice: '#b2b2b2',
  steelBlue: '#577d9c',
  stone: '#a7957b',
  red: '#d70000',
  transparent: 'transparent',
  tomato: '#d94436',
  warmGrey: '#98948e',
  waterBlue: '#1096d9',
  watermelon: '#f83e63',
  whisper: '#e7e7e7',
  white: 'white',
  whiteTwo: 'rgb(245, 245, 245)',
  whiteThree: 'rgb(231, 231, 231)',
  whiteFour: '#f4f4f3',
  whiteFive: '#f0f0f0',
  whiteWithEvenLessOpacity: 'rgba(255, 255, 255, 0.3)', // submit disable button background
  yellow: '#ffff00',
  zorba: '#98948d',
};

export default COLORS;
