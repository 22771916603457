import COLORS from '../../colors';
import outOfFieldIcon from '../../assets/icons/icnPelletOutField.png';
import irrigationIcon from '../../assets/icons/icnIrrigationAlias.png';
import BlockAliasPresenter from './BlockAliasPresenter';

export default class MapItemAliasPresenter extends BlockAliasPresenter {
  constructor(item, mapMode) {
    super(item);
    this.item = item;
    this.mapMode = mapMode;
  }

  alias() {
    return this.item.alias();
  }

  name() {
    return this.item.name();
  }

  id() {
    return this.item.id();
  }

  lastUpdated() {
    return this.item.lastUpdated();
  }

  getOverlayIcon() {
    if (!this.item.block) {
      return;
    }

    if (this.item.block.isOutOfField()) {
      return {
        icon: outOfFieldIcon,
        testID: 'out-of-field-icon',
        style: {
          position: 'absolute',
          left: 50,
          bottom: 10,
        },
      };
    }

    if (this.item.block.isIrrigating()) {
      return {
        icon: irrigationIcon,
        testID: 'irrigation-icon',
        style: { position: 'absolute', left: 59, bottom: 23, width: 22, height: 22 },
      };
    }
  }

  getBlockHeaderColor() {
    if (!this.item) {
      return COLORS.darkBlue;
    }

    return this.item.color(this.mapMode, null);
  }
}
