import gql from 'graphql-tag';

export const TENSION_FORECAST = gql`
  query TensionForecast($siteId: ID!, $blockId: ID!, $startTimeStamp: Int, $endTimeStamp: Int) {
    viewer {
      site(id: $siteId) {
        block(id: $blockId) {
          futureIrrigation {
            startDate
            endDate
            type
          }
          tensionForecast(startTimeStamp: $startTimeStamp, endTimeStamp: $endTimeStamp) {
            tensionSensorID
            tensionValues {
              x
              y
            }
          }
        }
      }
    }
  }
`;
