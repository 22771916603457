import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import COLORS from '../../colors';
import InputWithValidation from '../../components/InputWithValidation';
import FONTS from '../../fonts';
import SubmitButton from '../../components/button/SubmitButton';
import { useTranslation } from '../../hooks/useTranslation';
import OPACITIES from '../../opacities';

const LoginForm = ({ loading = false, onSubmit }) => {
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const { t } = useTranslation();

  const isSubmittable = () => {
    return username && password;
  };

  const onSubmitUsername = () => {
    if (username === undefined) {
      setUsername('');
    }
    this.passwordField.focus();
  };

  const onSubmitPassword = () => {
    if (password === undefined) {
      setPassword('');
    }
    if (isSubmittable()) {
      onSubmit({ username, password });
    }
  };

  const onSubmitUsernameAndPassword = () => {
    onSubmit({ username, password });
  };

  return (
    <View style={styles.container}>
      <View style={styles.loginFields}>
        <InputWithValidation
          style={styles.inputField}
          placeholder={t('login_view_username_placeholder')}
          value={username}
          onChangeText={(text) => setUsername(text)}
          errorMessage={t('login_view_invalid_username')}
          returnKeyType="next"
          isSecureText={false}
          onSubmitEditing={onSubmitUsername}
          isRequired
          blurOnSubmit={false}
          testId="login__username"
          placeholderTextColor={COLORS.darkGrey}
        />
        <View style={styles.separator} />
        <InputWithValidation
          style={styles.inputField}
          placeholder={t('login_view_password_placeholder')}
          value={password}
          onChangeText={(text) => setPassword(text)}
          errorMessage={t('login_view_invalid_password')}
          refToFocus={(input) => {
            this.passwordField = input;
          }}
          onSubmitEditing={onSubmitPassword}
          returnKeyType="go"
          isSecureText
          isRequired
          testId="login__password"
          placeholderTextColor={COLORS.darkGrey}
        />
      </View>
      <SubmitButton
        isSubmittable={isSubmittable}
        loading={loading}
        onSubmit={onSubmitUsernameAndPassword}
        title={t('login_view_login_button')}
        testId="login_button"
      />
    </View>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    marginVertical: 8,
    backgroundColor: COLORS.greyishBrown,
  },
  loginFields: {
    padding: 21,
    borderRadius: 4,
    backgroundColor: COLORS.white,
    shadowColor: COLORS.black,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 9,
  },
  inputField: {
    fontFamily: FONTS.firaSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
});

export default LoginForm;
