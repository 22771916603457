import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Image, Text } from 'react-native';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// hook
import { useTranslation } from '../../hooks/useTranslation';

// constants
import FONTS from '../../fonts';
import * as TensionStatus from '../../models/status/tensionStatus';
import { BACKGROUND_COLOR_ALPHA_VALUE } from '../../styles';

// shape
import { styleShape } from '../../shapes/style';

const BlockTensionStatus = ({ block, containerStyle }) => {
  const { t } = useTranslation();

  const tensionStatus = block.tensionCapability().status();

  const getStatusParameter = (status) => {
    return {
      icon: TensionStatus.iconForStatus(status),
      color: TensionStatus.colorForStatus(status),
      labelKey: TensionStatus.translationForStatus(status),
    };
  };

  const { icon, color, labelKey } = getStatusParameter(tensionStatus);
  const backgroundColor = `${color}${BACKGROUND_COLOR_ALPHA_VALUE}`;

  return (
    <View style={[containerStyle, styles.container, { backgroundColor }]}>
      <View style={styles.iconContainer}>{icon && <Image source={icon} style={styles.statusIcon} />}</View>
      <Text style={[styles.statusText, { color }]}>{t(labelKey)}</Text>
    </View>
  );
};

BlockTensionStatus.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  containerStyle: styleShape,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderRadius: 2,
  },
  iconContainer: {
    alignSelf: 'center',
    width: 48,
  },
  statusIcon: {
    alignSelf: 'center',
  },
  statusText: {
    alignSelf: 'center',
    marginRight: 14,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 14,
    letterSpacing: 0.6,
  },
});

export default React.memo(BlockTensionStatus);
