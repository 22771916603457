import { getApolloClient } from '../clients/apollo';

//constants
import { BLOCK_ORDER } from '../models/blockSorting';
import { TENSION_BLOCKS } from './tensionBlocks.graphql';

const fetchTensionBlocks = async (
  siteId,
  sortBlocksBy = BLOCK_ORDER,
  blockFilters = { capabilities: [], tensionStatuses: [] },
) => {
  const { loading, error, data } = await getApolloClient().query({
    query: TENSION_BLOCKS,
    variables: {
      siteId,
      blockSorting: sortBlocksBy,
      filters: blockFilters,
    },
  });
  let tensionBlocks;
  if (data) {
    tensionBlocks = data.viewer.site.blocks;
  }

  return {
    loading,
    error,
    tensionBlocks,
  };
};

export { fetchTensionBlocks };
