//client
import { getApolloClient } from '../clients/apollo';

//constants
import { WEATHER_DATA } from '../models/dataStreamTypes';

//graphQL
import { WEATHER_STATION_BLOCKS } from './weatherStation.graphql';

const fetchWeatherStationBlocks = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: WEATHER_STATION_BLOCKS,
    variables: { siteId, dataStreamTypes: WEATHER_DATA },
  });

  let weatherStationBlocks;
  if (data) {
    weatherStationBlocks = data.viewer.site.blocks;
  }

  return {
    loading,
    error,
    weatherStationBlocks,
  };
};

export { fetchWeatherStationBlocks };
