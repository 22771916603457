import { getApolloClient } from '../clients/apollo';
import { BLOCK_BLUE_BAND_INDEXES } from './blockBlueBandIndexes.graphql';

const fetchBlockBlueBandIndexes = async (siteId, blockId, startDate, endDate) => {
  const { loading, error, data } = await getApolloClient().query({
    query: BLOCK_BLUE_BAND_INDEXES,
    variables: {
      siteId,
      blockId,
      startDate,
      endDate,
    },
  });

  let blockBlueBandIndexes = [];
  if (data) {
    const tensionCapability = data.viewer.site.block.capabilities.tension;
    if (tensionCapability && tensionCapability.blueBandIndexes) {
      blockBlueBandIndexes = tensionCapability.blueBandIndexes.dates;
    }
  }

  return {
    loading,
    error,
    blockBlueBandIndexes,
  };
};

export { fetchBlockBlueBandIndexes };
