// icons
import icnAutomatedPlannedEventSingle from '../assets/icons/icnAutomatedPlannedEventSingle.png';
import icnAutomatedPlannedEventMultiple from '../assets/icons/icnAutomatedPlannedEventMultiple.png';
import icnInferredEventMultiple from '../assets/icons/icnInferredEventMultiple.png';
import icnInferredEventSingle from '../assets/icons/icnInferredEventSingle.png';
import icnPlannedEventMultiple from '../assets/icons/icnPlannedEventMultiple.png';
import icnPlannedEventSingle from '../assets/icons/icnPlannedEventSingle.png';

// constants
import COLORS from '../colors';

import { EVENT_TYPES } from './eventType';

// TODO: use only event types
export const PLANNED = 'PLANNED';
export const INFERRED = 'INFERRED';
export const AUTOMATED_PLANNED = 'AUTOMATED_PLANNED';
export const UNKNOWN = 'UNKNOWN';

export const findEventType = (event) => {
  switch (true) {
    case event.calendarType === PLANNED:
      return PLANNED;
    case event.calendarType === INFERRED:
      return INFERRED;
    case event.type === EVENT_TYPES.manualIrrigation:
      return PLANNED;
    case event.type === EVENT_TYPES.automatedIrrigation:
      return AUTOMATED_PLANNED;
    default:
      return UNKNOWN;
  }
};

export const findEventLabel = (type) => {
  switch (type) {
    case PLANNED:
      return 'manual';
    case INFERRED:
      return null;
    case AUTOMATED_PLANNED:
      return 'automated';
    default:
      return null;
  }
};

export const findEventIcon = (type, isMultiple) => {
  switch (type) {
    case PLANNED:
      if (isMultiple) {
        return icnPlannedEventMultiple;
      }
      return icnPlannedEventSingle;
    case INFERRED:
      if (isMultiple) {
        return icnInferredEventMultiple;
      }
      return icnInferredEventSingle;
    case AUTOMATED_PLANNED:
      if (isMultiple) {
        return icnAutomatedPlannedEventMultiple;
      }
      return icnAutomatedPlannedEventSingle;
  }
};

export const findEventColor = (type) => {
  switch (type) {
    case PLANNED:
      return COLORS.azure;
    case INFERRED:
      return COLORS.duckEggBlue;
    case AUTOMATED_PLANNED:
      return COLORS.royalBlue;
  }
};
