import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

//constants
import COLORS from '../colors';
import FONTS from '../fonts';

// icons
import alertIcon from '../assets/icons/icnMenuNotif.png';
import successIcon from '../assets/icons/icnToastSuccess.png';

export const HEIGHT = 70;

const getTypeStyle = (type) => {
  switch (type) {
    case 'successToast':
      return { icon: successIcon, color: COLORS.seafoamBlue, width: 25, height: 25 };
    case 'alertToast':
      return { icon: alertIcon, color: COLORS.dustyOrange, width: 18, height: 25 };
    default:
      return { icon: null, color: null };
  }
};

const renderToastNotif = (props) => {
  const { icon, color, width, height } = getTypeStyle(props.type);

  return (
    <View style={[styles.base, styles.borderLeft, { borderLeftColor: color }]}>
      <View style={styles.iconContainer}>
        <Image source={icon} style={{ tintColor: color, width, height }} />
      </View>
      <View style={styles.contentContainer}>
        <View>
          {props.text1 !== undefined && (
            <View>
              <Text style={styles.text1} numberOfLines={1}>
                {props.text1}
              </Text>
            </View>
          )}
          {props.text2 !== undefined && (
            <View>
              <Text style={styles.text2} numberOfLines={2}>
                {props.text2}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const ToastView = {
  successToast: (internalState) => renderToastNotif(internalState),
  alertToast: (internalState) => renderToastNotif(internalState),
  success: () => null, // Workaround: https://github.com/calintamas/react-native-toast-message/issues/428
};

const styles = StyleSheet.create({
  base: {
    flexDirection: 'row',
    width: '90%',
    height: HEIGHT,
    borderRadius: 6,
    backgroundColor: COLORS.white,
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6,
  },
  borderLeft: {
    borderLeftWidth: 5,
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 14,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 12,
  },
  text1: {
    paddingBottom: 5,
    fontFamily: FONTS.firaSansBold,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  text2: {
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
});

export default ToastView;
