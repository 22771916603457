import { getApolloClient } from '../clients/apollo';
import { MAP_GRAPH } from './mapGraph.graphql';

const MAX_NUMBER_OF_DATASTREAMS_POINTS = 200;

async function fetchMapGraph(siteId, blockId, dataStreamIds, startDate, endDate) {
  const { loading, error, data } = await getApolloClient().query({
    query: MAP_GRAPH,
    variables: {
      siteId,
      blockId,
      dataStreamIds,
      startDate,
      endDate,
      maxNumberOfPoints: MAX_NUMBER_OF_DATASTREAMS_POINTS,
    },
  });

  let dataStreams, graphEvents;
  if (data) {
    dataStreams = data.viewer.site.dataStreams;
    graphEvents = data.viewer.site.block.events;
  }

  return {
    loading,
    error,
    dataStreams,
    graphEvents,
  };
}

export { fetchMapGraph };
