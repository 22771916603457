import icnTemperatureMapShielded from '../assets/icons/temperature/icnTemperatureMapShielded.png';
import icnTemperatureMapUnshielded from '../assets/icons/temperature/icnTemperatureMapUnshielded.png';
import { DATA_STREAMS_TYPES } from '../models/dataStreamTypes';
import { TemperatureSensorPresenter } from './TemperatureSensorPresenter';

export class MapTemperatureSensorPresenter extends TemperatureSensorPresenter {
  constructor(temperatureSensor) {
    super(temperatureSensor);
  }

  icon() {
    if (this.type === DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code) {
      return {
        name: 'icnTemperatureMapShielded',
        icon: icnTemperatureMapShielded,
      };
    }

    return {
      name: 'icnTemperatureMapUnshielded',
      icon: icnTemperatureMapUnshielded,
    };
  }
}
