//queries
import { fetchSite } from '../queries/site';
import { fetchSites } from '../queries/sites';

//services
import CancellableManager from '../utils/CancellableManager';
import { setDefaultTimeZone } from '../services/MomentService';
import * as CrashlyticsService from '../services/CrashlyticsService';
import { getTrackingService } from '../services/AnalyticsTrackingService';
import { executeApolloQuery } from './apolloHelper';
import * as Modules from './modules';

const TWO_WEEKS_IN_HOURS = 336;

export const site = {
  state: {
    currentSite: {
      name: '',
      id: undefined,
      modules: [],
      defaultTimeScaleInHours: TWO_WEEKS_IN_HOURS,
      supportsTensionForecast: false,
    },
    sites: undefined,
  },

  selectors: (slice) => ({
    hasMultipleSites() {
      // eslint-disable-next-line no-shadow
      return slice((site) => site.sites?.length > 1);
    },

    hasAutomations() {
      // eslint-disable-next-line no-shadow
      return slice((site) => site.currentSite?.capabilities?.automationIsIrrigationSchedulable);
    },

    hasPestModule() {
      // eslint-disable-next-line no-shadow
      return slice((site) => site.currentSite?.modules?.includes(Modules.PEST));
    },

    hasPreviewModules() {
      const modulesWithPreviews = [
        Modules.FLOW_STATION,
        Modules.AUTOMATION,
        Modules.TEMPERATURE,
        Modules.WEATHER,
        Modules.REPORT,
      ];
      // eslint-disable-next-line no-shadow
      return slice((site) => modulesWithPreviews.some((module) => !site.currentSite?.modules?.includes(module)));
    },

    getTimezone() {
      // eslint-disable-next-line no-shadow
      return slice((site) => site?.currentSite?.timezone);
    },

    getAutomationEventConstraints() {
      // eslint-disable-next-line no-shadow
      return slice((site) => site?.currentSite?.eventConstraints?.automatedEventConstraints);
    },

    getManualEventConstraints() {
      const manualEventConstraints = { minimumDurationInMinutes: 1 };
      return slice(() => manualEventConstraints);
    },
  }),

  reducers: {
    updateCurrentSite(state, currentSite) {
      return { ...state, currentSite };
    },
    updateSites(state, sites) {
      return { ...state, sites };
    },
  },

  effects: (dispatch) => ({
    async setCurrentSite(payload, rootState) {
      CancellableManager.cancelAll();
      dispatch.map.updateMap({ blocks: [], automations: [] });
      dispatch.blocks.reset();
      dispatch.graph.reset();

      const selectedSiteId = rootState.preferences.selectedSite;
      const defaultSiteId = rootState.user.currentUser?.defaultSite;
      const currentSiteId = payload ? payload : selectedSiteId ? selectedSiteId : defaultSiteId;

      await executeApolloQuery(
        dispatch,
        async () => await fetchSite(currentSiteId),
        async (data) => {
          /* istanbul ignore next */
          if (data.site === null) {
            // if user don't have access to the currentSiteId anymore
            data = await fetchSite(defaultSiteId);
          }
          const currentSite = data.site;
          setDefaultTimeZone(currentSite.timezone);

          dispatch.preferences.writeSelectedSite({ selectedSiteId: currentSite.id });
          getTrackingService().changeSite(currentSite.name);
          CrashlyticsService.setCurrentSite(currentSite);

          dispatch.site.updateCurrentSite(currentSite);
          dispatch.user.loadPermissions();
        },
      );
    },

    async loadSites() {
      await executeApolloQuery(
        dispatch,
        async () => await fetchSites(),
        (data) => dispatch.site.updateSites(data.sites),
      );
    },
  }),
};
