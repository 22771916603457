import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../assets/translation.en.json';
import translationFR from '../assets/translation.fr.json';
import translationES from '../assets/translation.es.json';
import { getLanguage, fallbackLanguage } from './localize';

/* istanbul ignore next */
const noop = () => {};

/* istanbul ignore next */
const languageDetector = {
  type: 'languageDetector',
  async: false,
  detect: () => getLanguage(),
  init: () => noop,
  cacheUserLanguage: () => noop,
};

/* istanbul ignore next */
i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      es: { translation: translationES },
    },
    fallbackLng: fallbackLanguage,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

export default i18next;
