import { gql } from 'graphql-tag';

export const REPORT = gql`
  query WaterReport($siteId: ID!, $filter: MainSwitchStatsFilter!) {
    viewer {
      site(id: $siteId) {
        mainSwitchStats(filter: $filter) {
          name
          alias
          mainSwitches {
            name
            type
            calendar {
              x
              y
            }
          }
        }
      }
    }
  }
`;
