import * as Modules from '../models/modules';

// services
import ANALYTICS from '../services/AnalyticsEvents';
import { getLatLongBounds, normalizeCoordinate } from '../screens/map/MapService';

import {
  buildFeatureCollectionFromPresenters,
  getPresenters,
  getCoordinatesFromCollection,
  getCoordinatesFromPresenters,
} from '../screens/map/MapHelpers';
import { isNullOrUndefined } from '../utils/util';
import { findMapStyleTag, MapDisplayMode, MapStyleMode } from './MapDisplayMode';

const SET_CAMERA_ANIMATION_DURATION = 1000;
const CAMERA_MODE_FLIGHT = 'flyTo';

export const nextMapDisplayMode = (mapDisplayMode) =>
  mapDisplayMode === MapDisplayMode.TENSION ? MapDisplayMode.TEMPERATURE : MapDisplayMode.TENSION;

export const shouldDisplayMapMode = (site) =>
  site.modules.includes(Modules.TEMPERATURE) || site.modules.includes(Modules.WEATHER);

export const filteredMapBlockPresenters = (presenters, mapDisplayMode, mapStyleMode) => {
  if (mapStyleMode === MapStyleMode.NDVI) {
    return presenters.filter(
      (presenter) =>
        presenter.block?.isTensionCapable() ||
        presenter.block?.isFlowStationCapable() ||
        presenter.block?.isTemperatureCapable() ||
        presenter.block?.isWaterLevelCapable() ||
        presenter.block?.isWeatherStationCapable(),
    );
  }
  if (mapDisplayMode === MapDisplayMode.TENSION) {
    return presenters.filter(
      (presenter) =>
        presenter.block?.isTensionCapable() ||
        presenter.block?.isFlowStationCapable() ||
        presenter.block?.isWaterLevelCapable() ||
        presenter.automation,
    );
  }
  if (mapDisplayMode === MapDisplayMode.TEMPERATURE) {
    return presenters.filter(
      (presenter) =>
        presenter.block?.isTemperatureCapable() || presenter.block?.isWeatherStationCapable() || presenter.automation,
    );
  }
  return presenters;
};

export const MAP_ACTIONS = {
  LOAD_BLOCKS_WITHOUT_PINS_RESET: 'loadBlocksWithoutPinsReset',
  CHANGE_MAP_MODE: 'changeMapMode',
  CHANGE_MAP_STYLE: 'changeMapStyle',
  RESET_ACTIVE_PIN: 'resetActivePin',
  RESET_PINS: 'resetPins',
  ZOOM_ON_PINS: 'zoomOnPins',
  CHANGE_PINS: 'changePins',
  MOVE_TO_ACTIVE_PIN: 'moveToActivePin',
  RECEIVE_BLOCKS: 'receiveBlocks',
  PRESS_PIN: 'pressPin',
  PRESS_MAP: 'pressMap',
  SNAP_ITEM: 'snapItem',
};
export const EMPTY_FEATURE_COLLECTION = { features: [], type: 'FeatureCollection' };

export const INITIAL_STATE = {
  activeBlockId: undefined,
  featureCollection: EMPTY_FEATURE_COLLECTION,
  markerCollection: EMPTY_FEATURE_COLLECTION,
  presentersForMapDisplayMode: [],
  pinHasBeenPressed: false,
  shouldZoomOnPins: true,
  shouldResetPins: true,
};

export const loadBlocksAndZoom = (state, currentSite, loading, mapMode, mapStyle, loadMapBlocksForCurrentSite) => {
  if (currentSite?.id && !loading) {
    loadMapBlocksForCurrentSite();
  }

  let collection = EMPTY_FEATURE_COLLECTION;
  if (state.presentersForMapDisplayMode.length > 0) {
    collection = getFeatureCollectionFromPresenters(state, mapMode, mapStyle);
  }

  return {
    ...INITIAL_STATE,
    presentersForMapDisplayMode: state.presentersForMapDisplayMode,
    featureCollection: collection,
    shouldZoomOnPins: true,
    shouldResetPins: false,
  };
};

export const changeMapMode = (
  state,
  currentSite,
  loading,
  presenters,
  mapMode,
  mapStyle,
  analyticsService,
  lastActiveBlockIdWithValue,
  setMapMode,
) => {
  lastActiveBlockIdWithValue.current = null;

  analyticsService.trackEvent(ANALYTICS.eventViewMap, { display_mode: mapMode });

  if (!shouldDisplayMapMode(currentSite)) {
    setMapMode(MapDisplayMode.TENSION);
  }

  const newState = { activeBlockId: undefined, pinHasBeenPressed: false };
  if (!loading && presenters.current) {
    let filteredPresenters = filteredMapBlockPresenters(presenters.current, mapMode, mapStyle);
    newState.presentersForMapDisplayMode = resetPresentersGraphs(filteredPresenters);
  }

  return {
    ...state,
    ...newState,
  };
};

export const changeMapStyleMode = (state, loading, presenters, mapMode, mapStyle, analyticsService) => {
  analyticsService.trackEvent(ANALYTICS.eventViewMapStyle, { display_style: findMapStyleTag(mapStyle) });

  const newState = {};
  if (!loading && presenters.current) {
    newState.presentersForMapDisplayMode = filteredMapBlockPresenters(presenters.current, mapMode, mapStyle);
  }

  return {
    ...state,
    ...newState,
  };
};

export const resetActivePin = (state) => {
  return {
    ...state,
    activeBlockId: undefined,
    pinHasBeenPressed: false,
  };
};

export const resetPins = (state) => {
  if (state.shouldResetPins) {
    return {
      ...state,
      presentersForMapDisplayMode: [],
      activeBlockId: undefined,
      pinHasBeenPressed: false,
      featureCollection: EMPTY_FEATURE_COLLECTION,
      markerCollection: EMPTY_FEATURE_COLLECTION,
    };
  }

  return { ...state };
};

export const zoomOnPins = (state, mapMode, mapStyle, mapCamera, gotoUserLocation) => {
  if (state.shouldZoomOnPins) {
    if (state.presentersForMapDisplayMode.length + state.markerCollection.features.length > 0) {
      const featureCollectionBuilded = getFeatureCollectionFromPresenters(state, mapMode, mapStyle);
      const coordinates = getCoordinatesFromPresenters(state.presentersForMapDisplayMode).concat(
        getCoordinatesFromCollection(state.markerCollection),
      );

      if (mapCamera?.current && !state.activeBlockId) {
        if (coordinates.length > 1) {
          const bounds = getLatLongBounds(coordinates);

          if (bounds.areDifferent) {
            showRegionWithAllBlocks(mapCamera, bounds);
          } else {
            flyToFirstBlock(mapCamera, coordinates[0]);
          }
        } else {
          flyToFirstBlock(mapCamera, coordinates[0]);
        }
      }

      return { ...state, shouldZoomOnPins: false, featureCollection: featureCollectionBuilded };
    } else {
      gotoUserLocation();
      return { ...state, shouldZoomOnPins: false, featureCollection: EMPTY_FEATURE_COLLECTION };
    }
  }

  return { ...state };
};

const getFeatureCollectionFromPresenters = (state, mapMode, mapStyle) => {
  const collection = buildFeatureCollectionFromPresenters(
    state.presentersForMapDisplayMode,
    mapMode,
    mapStyle,
    state.activeBlockId,
  );

  return { features: collection, type: 'FeatureCollection' };
};

const flyToFirstBlock = (mapCamera, { longitude, latitude }) => {
  mapCamera?.current?.setCamera({
    animationDuration: SET_CAMERA_ANIMATION_DURATION,
    animationMode: CAMERA_MODE_FLIGHT,
    centerCoordinate: normalizeCoordinate([longitude, latitude]),
    zoomLevel: 12,
  });
};

const showRegionWithAllBlocks = (mapCamera, bounds) => {
  mapCamera?.current?.setCamera({
    animationDuration: SET_CAMERA_ANIMATION_DURATION,
    animationMode: CAMERA_MODE_FLIGHT,
    bounds: {
      paddingTop: 50,
      paddingBottom: 50,
      paddingRight: 50,
      paddingLeft: 50,
      ne: bounds.northEast,
      sw: bounds.southWest,
    },
  });
};

export const changePins = (state, mapMode, mapStyle) => {
  if (state.presentersForMapDisplayMode.length > 0) {
    const collection = getFeatureCollectionFromPresenters(state, mapMode, mapStyle);
    return { ...state, featureCollection: collection };
  }

  return { ...state, featureCollection: EMPTY_FEATURE_COLLECTION };
};

export const moveToActivePin = (state, mapCamera) => {
  const activePresenter = state.presentersForMapDisplayMode.find(
    (presenter) => getIdFromPresenter(presenter) === state.activeBlockId,
  );
  if (mapCamera?.current && activePresenter) {
    let coordinates = activePresenter.coordinates();

    if (!isNullOrUndefined(coordinates?.longitude)) {
      coordinates = [coordinates.longitude, coordinates.latitude];
    }

    mapCamera.current.moveTo(normalizeCoordinate(coordinates), 200);
  }

  return { ...state };
};

export const receiveBlocks = (state, blocks, automations, mapMode, mapStyle, presenters, lastActiveBlockIdWithValue) => {
  const data = getPresenters(blocks, automations);

  if (data.length > 0) {
    presenters.current = data;

    return {
      ...state,
      presentersForMapDisplayMode: filteredMapBlockPresenters(presenters.current, mapMode, mapStyle),
    };
  } else {
    presenters.current = [];
    lastActiveBlockIdWithValue.current = null;
    return { ...INITIAL_STATE };
  }
};

export const pressPin = (state, action) => {
  return {
    ...state,
    pinHasBeenPressed: true,
    activeBlockId: action.featureId,
  };
};

export const pressMap = (state) => {
  return { ...state, activeBlockId: undefined, pinHasBeenPressed: false };
};

export const snapItem = (state, action, lastActiveBlockIdWithValue) => {
  const activeBlockId = getIdFromPresenter(action.presenter);
  lastActiveBlockIdWithValue.current = activeBlockId;

  return { ...state, activeBlockId };
};

const resetPresentersGraphs = (presenters) => {
  presenters.forEach((presenter) => {
    presenter.setGraphPresenter(null);
  });
  return presenters;
};

const getIdFromPresenter = (presenter) => {
  return presenter.id();
};
