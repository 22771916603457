import icnHail24H from '../icnHail24H.png';
import icnWeather from '../icnWeather.png';
import icnWindDirection from '../icnWindDirection.png';
import icnWindGust from '../icnWindGust.png';
import icnWindSpeed from '../icnWindSpeed.png';
import icnSolarRadiation from '../icnSolarRadiation.png';

export const WEATHER_DATA_STREAM_ICONS = {
  HAIL: {
    name: 'icn_hail_24h',
    icon: icnHail24H,
  },
  SOLAR_RADIATION: {
    name: 'icn_solar_radiation',
    icon: icnSolarRadiation,
  },
  WEATHER: {
    name: 'icn_weather',
    icon: icnWeather,
  },
  WIND_DIRECTION: {
    name: 'icn_wind_direction',
    icon: icnWindDirection,
  },
  WIND_GUST: {
    name: 'icn_wind_gust',
    icon: icnWindGust,
  },
  WIND_SPEED: {
    name: 'icn_wind_speed',
    icon: icnWindSpeed,
  },
};
