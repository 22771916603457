import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useDebouncedCallback } from 'use-debounce';

// hooks
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// proptype
import { navigationShape } from '../../shapes/navigation';

// models
import DeviceEntity from '../../models/entities/deviceEntity';

// services
import ANALYTICS from '../../services/AnalyticsEvents';

// styles
import { globalStyles } from '../../styles';

// components
import DeviceDetail from '../../components/deviceDetails/DeviceDetails';
import Divider from '../../components/Divider';
import HeaderBack from '../../components/header/HeaderBack';
import { computeInitialDateRange } from '../../components/graph/ChartXAxisHelper';

// constants
import ROUTES from '../../navigation/routes';

const DeviceDetailsScreen = ({ navigation }) => {
  const isFocused = useIsFocused();
  const loadedSelectedDataStreamsCache = useRef([]);
  const chartRef = useRef();

  const currentSite = useSelector((state) => state.site.currentSite);
  const selectedDataStreams = useSelector((state) => state.graph.selectedDataStreams);
  const device = useSelector((state) => state.devices.selectedDevice);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [dataRange, setDataRange] = useState(computeInitialDateRange(currentSite));
  const dispatch = useDispatch();

  const analyticsService = useAnalyticsContext();

  /* istanbul ignore next */
  const goBack = useCallback(() => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewDevices);
    navigation.navigate(ROUTES.DEVICES);
    return true; // prevent event bubble up (Android will close the app)
  }, [analyticsService, navigation]);

  useBackHandler(goBack);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      onRefresh();

      return () => {
        loadedSelectedDataStreamsCache.current = [];
        dispatch.graph.reset();
        dispatch.devices.updateSelectedDevice(new DeviceEntity({}));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation, device?.deviceId]),
  );

  useEffect(() => {
    const defaultSelectedDataStreams = device ? device.defaultSelectedDataStreams() : [];
    const buildedDataStreamsAxis = buildDefaultSelectedDataStreams(defaultSelectedDataStreams);

    if (buildedDataStreamsAxis.left.unit) {
      if (buildedDataStreamsAxis.right.unit) {
        dispatch.graph.setDefaultSelectedDataStreams({
          left: buildedDataStreamsAxis.left,
          right: buildedDataStreamsAxis.right,
        });
      } else {
        dispatch.graph.setDefaultSelectedDataStreams({
          left: buildedDataStreamsAxis.left,
        });
      }
    }
  }, [device, dispatch.graph]);

  /* istanbul ignore next */
  useEffect(() => {
    const idsNeeded = [...selectedDataStreams.left.ids, ...selectedDataStreams.right.ids];
    if (needToReloadData(idsNeeded)) {
      const idsToReload = [...new Set([...loadedSelectedDataStreamsCache.current, ...idsNeeded])];
      loadedSelectedDataStreamsCache.current = idsToReload;
      loadSelectedDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataStreams.left.ids, selectedDataStreams.right.ids]);

  useEffect(() => {
    loadSelectedDataStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRange]);

  const loadSelectedDataStreams = () => {
    if (isFocused && dataRange.length > 0) {
      const [startDate, endDate] = dataRange;
      debouncedLoadDataStreams({
        siteId: currentSite.id,
        dataStreamIds: loadedSelectedDataStreamsCache.current,
        startDate,
        endDate,
      });
    }
  };

  /* istanbul ignore next */
  const needToReloadData = (ids) => ids.some((id) => loadedSelectedDataStreamsCache.current.indexOf(id) === -1);

  const [debouncedLoadDataStreams] = useDebouncedCallback(dispatch.graph.loadDataStreams, 200);

  const onGraphInteraction = (newDateRange) => setDataRange(newDateRange);

  const buildDefaultSelectedDataStreams = (defaultSelectedDataStreams) => {
    const selectedDataStreamsPerUnit = defaultSelectedDataStreams.reduce((acc, datastream) => {
      if (!acc.has(datastream.unit)) {
        acc.set(datastream.unit, []);
      }
      const ids = acc.get(datastream.unit);
      acc.set(datastream.unit, [...ids, datastream.id]);

      return acc;
    }, new Map());

    const iterator = selectedDataStreamsPerUnit.keys();
    const leftUnit = iterator.next().value;
    const leftIds = selectedDataStreamsPerUnit.get(leftUnit);
    const rightUnit = iterator.next().value;
    const rightIds = selectedDataStreamsPerUnit.get(rightUnit);

    return {
      left: { ids: leftIds ? leftIds : [], unit: leftUnit },
      right: { ids: rightIds ? rightIds : [], unit: rightUnit },
    };
  };

  if (!device || !isFocused) {
    return null;
  }

  const onRefresh = async () => {
    if (!device?.deviceId) {
      return;
    }

    setIsRefreshing(true);

    const [startDate, endDate] = dataRange;

    const loadDevice = dispatch.devices.loadDevice({ deviceId: device.deviceId });

    const loadGraph = dispatch.graph.loadDataStreams({
      siteId: currentSite.id,
      dataStreamIds: loadedSelectedDataStreamsCache.current,
      startDate,
      endDate,
    });

    await Promise.all([loadDevice, loadGraph]);

    chartRef.current.refresh();
    setIsRefreshing(false);
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderBack
          screenName="device-details"
          navigation={navigation}
          goBack={goBack}
          title={device.block?.alias}
          subtitle={device.block?.name}
        />
      </View>

      <Divider />

      <View style={globalStyles.bottomContainer}>
        <ScrollView
          contentContainerStyle={globalStyles.scrollContainerFlex}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl testID="device-details__refresh-controller" onRefresh={onRefresh} refreshing={isRefreshing} />
          }>
          <DeviceDetail
            ref={chartRef}
            device={device}
            onGraphInteraction={onGraphInteraction}
            selectedDataStreams={selectedDataStreams}
          />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

DeviceDetailsScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default DeviceDetailsScreen;
