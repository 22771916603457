import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// services
import { isAndroid } from '../../services/PlatformService';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ANALYTICS from '../../services/AnalyticsEvents';
import { formatWeekLabel } from '../../utils/dateUtils';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandler } from '../../hooks/useBackHandler';

// style
import { globalStyles } from '../../styles';

// constants
import COLORS from '../../colors';
import { navigationShape } from '../../shapes/navigation';
import ROUTES from '../../navigation/routes';

// components
import BlocksNotes from '../../components/note/BlocksNotes';
import Divider from '../../components/Divider';
import FloatingActionButton from '../../components/FloatingActionButton';
import GeneralNotes from '../../components/note/GeneralNotes';
import HeaderBack from '../../components/header/HeaderBack';
import IOSAddButton from '../../components/button/IOSAddButton';
import LoadingOverlay from '../../components/LoadingOverlay';

// icons
import icnAddEventFab from '../../assets/icons/icnAddEventFab.png';

const NotesScreen = ({ navigation, route }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const { dateRange, reloadData } = route.params || {};
  const endDate = dateRange?.to.clone().subtract(1, 'day');
  const headerLabel = formatWeekLabel(dateRange?.from, endDate);

  const loading = useSelector((state) => !!state.loading.effects.notes.loadNotes);
  const notes = useSelector((state) => state.notes);

  const areThereBlocksNotesAndSiteNotes = notes?.blocks.length > 0 && notes?.notes.length > 0;

  const onNotePress = (note) => {
    navigation.navigate(ROUTES.NOTE_EDIT, { dateRange, headerLabel, note });
  };

  const goBack = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewSchedule);
    navigation.navigate(ROUTES.SCHEDULE, { reloadData });
    return true;
  };

  useBackHandler(goBack);

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header} testID="notes__subscreen-container">
        <HeaderBack
          screenName={'notes'}
          navigation={navigation}
          goBack={goBack}
          title={t('note_title_plural')}
          subtitle={headerLabel}
          testID={'notes__header'}
          actions={<IOSAddButton onPress={onNotePress} testId={'notes__add-button-ios'} />}
        />
      </View>

      <Divider />

      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={globalStyles.scrollContainer}
        showsVerticalScrollIndicator={false}>
        {!loading ? (
          <>
            <BlocksNotes blocks={notes.blocks} onNotePress={onNotePress} testId="notes__blocks-note-container" />

            {areThereBlocksNotesAndSiteNotes ? <Divider style={styles.notesDividerStyle} testID="notes-divider" /> : null}

            <GeneralNotes notes={notes.notes} onNotePress={onNotePress} testId="notes__general-note-container" />
          </>
        ) : null}
      </ScrollView>
      <LoadingOverlay isLoading={loading} />

      {isAndroid() && (
        <FloatingActionButton
          icon={icnAddEventFab}
          buttonStyle={[globalStyles.fabButtonStyle, styles.fabButtonPosition]}
          buttonTouchStyle={globalStyles.fabButtonTouchStyle}
          iconStyle={styles.addNoteIconStyle}
          onPress={onNotePress}
          testId={'notes__add-button-android'}
        />
      )}
    </SafeAreaView>
  );
};

NotesScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
      dateRange: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

const styles = StyleSheet.create({
  addNoteIconStyle: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: COLORS.warmGrey,
  },
  notesDividerStyle: {
    paddingTop: 7,
    marginHorizontal: 10,
    borderBottomColor: COLORS.pinkishGrey,
  },
  fabButtonPosition: {
    right: 15,
    bottom: 20,
  },
  scrollView: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
});

export default NotesScreen;
