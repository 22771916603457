import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

// presenters
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';

// constants
import COLORS from '../../colors';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// hooks
import useGraph from '../../hooks/useGraph';

// models
import { GraphDataType } from '../../models/graphDataType';

// components
import Graph from '../graph/Graph';
import DataStreams from '../graph/DataStreams';
import ShadowedDivider from '../ShadowedDivider';
import WeatherStationBlockHeader from './WeatherStationBlockHeader';
import WeatherStationInformations from './WeatherStationInformations';

const BORDER_RADIUS = 6;

const WeatherStationDetail = forwardRef(({ weatherStation, onGraphInteraction, selectedDataStreams }, ref) => {
  const dataStreams = useSelector((state) => state.graph.dataStreams);
  const weatherDataStreams = useSelector((state) => state.graph.weatherDataStreams);
  const currentSite = useSelector((state) => state.site.currentSite);
  const loadingDataStreams = useSelector((state) => !!state.loading.effects.graph.loadDataStreams);

  const [state, onToggleDataStreams] = useGraph({
    dataStreams,
    legendStreams: weatherDataStreams,
    selectedDataStreams,
    block: weatherStation,
    defaultTimeScaleInHours: currentSite.defaultTimeScaleInHours,
    dataType: GraphDataType.WEATHER,
    isLoading: loadingDataStreams,
    resetEnabled: true,
  });

  const blockAliasPresenter = new BlockAliasPresenter(weatherStation);

  const onGraphRefresh = (dataRange) => {
    onGraphInteraction(dataRange);
  };

  if (!weatherStation) {
    return null;
  }

  return (
    <View style={styles.container} testID={'weather__weatherStationDetail'}>
      <View style={styles.item}>
        <WeatherStationBlockHeader presenter={blockAliasPresenter} testId={'weather__weatherStationBlockHeader'} />

        <ShadowedDivider />

        <View style={styles.informationsContainer}>
          <WeatherStationInformations block={weatherStation} testId={'weather__weatherStationInformations'} />
        </View>

        <ShadowedDivider />

        <Graph
          ref={ref}
          graphPresenter={state.graphPresenter}
          onRefresh={onGraphRefresh}
          isLoading={loadingDataStreams || !state.graphPresenter.dataType}
          testId={'weather__graph'}
        />

        <DataStreams
          block={weatherStation}
          onToggle={onToggleDataStreams}
          selectedDataStreams={state.graphPresenter.selectedDataStreams}
          weatherStreams={weatherDataStreams}
        />
      </View>
    </View>
  );
});

WeatherStationDetail.displayName = 'WeatherStationDetail';

WeatherStationDetail.propTypes = {
  onGraphInteraction: PropTypes.func.isRequired,
  weatherStation: PropTypes.instanceOf(BlockEntity),
  selectedDataStreams: PropTypes.object,
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingHorizontal: 12,
    paddingVertical: 10,
    backgroundColor: COLORS.greyish20,
  },
  informationsContainer: {
    marginTop: 30,
    marginBottom: 45,
  },
  item: {
    flex: 1,
    flexDirection: 'column',
    borderColor: COLORS.greyBorderColor,
    borderWidth: 1,
    borderRadius: BORDER_RADIUS,
    backgroundColor: COLORS.white,
  },
});

export default React.memo(WeatherStationDetail);
