/* istanbul ignore file */
const setItem = (key, value) => {
  window.localStorage.setItem(key, value);
};

const getItem = (key) => {
  return window.localStorage.getItem(key);
};

const clearMultiple = (keys) => {
  keys.forEach((key) => {
    window.localStorage.removeItem(key);
  });
};

export { setItem, getItem, clearMultiple };
