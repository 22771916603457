export class DashboardSchedulePresenter {
  constructor(schedule, translation) {
    if (schedule != null) {
      this.schedule = schedule;
    } else {
      this.schedule = { ongoingCount: 0, lateCount: 0, plannedCount: 0 };
    }
    this.translation = translation;
  }

  shouldDisplayEmptyScheduleMessage() {
    return this.schedule.ongoingCount === 0 && this.schedule.lateCount === 0 && this.schedule.plannedCount === 0;
  }

  ongoingCount() {
    return this.schedule.ongoingCount;
  }

  ongoingMessage() {
    if (this.schedule.ongoingCount === 0) {
      return this.translation('dashboard_schedule_ongoing_zero');
    } else if (this.schedule.ongoingCount === 1) {
      return this.translation('dashboard_schedule_ongoing_one');
    }
    return this.translation('dashboard_schedule_ongoing_other');
  }

  lateCount() {
    return this.schedule.lateCount;
  }

  lateMessage() {
    if (this.schedule.lateCount === 0) {
      return this.translation('dashboard_schedule_late_zero');
    } else if (this.schedule.lateCount === 1) {
      return this.translation('dashboard_schedule_late_one');
    }
    return this.translation('dashboard_schedule_late_other');
  }

  plannedCount() {
    return this.schedule.plannedCount;
  }

  plannedMessage() {
    if (this.schedule.plannedCount === 0) {
      return this.translation('dashboard_schedule_empty');
    } else if (this.schedule.plannedCount === 1) {
      return this.translation('dashboard_schedule_planned_one');
    }
    return this.translation('dashboard_schedule_planned_other');
  }
}
