import * as React from 'react';
import { Image, Pressable, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

// constants and helpers
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { findIrrigationIcon, EVENT_TYPES, isManualIrrigation } from '../../models/eventType';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

const IrrigationButton = ({ type, isActive, onPress }) => {
  const { t } = useTranslation();

  let icon = findIrrigationIcon(type, isActive);

  return (
    <Pressable onPress={onPress} style={styles.container}>
      <Image source={icon} style={styles.icon} />

      <Text style={[styles.text, isActive ? styles.textActive : styles.textInactive]}>
        {isManualIrrigation(type) ? t('manual') : t('automated')}
      </Text>
    </Pressable>
  );
};

IrrigationButton.propTypes = {
  type: PropTypes.oneOf([EVENT_TYPES.manualIrrigation, EVENT_TYPES.automatedIrrigation]).isRequired,
  isActive: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  icon: {
    width: 55,
    height: 55,
  },
  text: {
    paddingTop: 5,
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    letterSpacing: 0.6,
  },
  textActive: {
    color: COLORS.greyishBrown,
  },
  textInactive: {
    color: COLORS.greyish21,
  },
});

export default IrrigationButton;
