import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// services
import { useTranslation } from '../../hooks/useTranslation';

//constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// components
import SensorData from '../../components/block/SensorData';
import ListBox from '../../components/list/ListBox';

const DISPLAY_DATE_FORMAT = 'MMM D';

const BlockBlueBandIndexItem = ({ blueBandIndex, testId }) => {
  const { t } = useTranslation();
  const blueBandIndexValues = blueBandIndex.value.formattedValues(t);
  const formattedDate = moment(blueBandIndex.date).format(DISPLAY_DATE_FORMAT);

  return (
    <ListBox
      testID={testId}
      customStyle={styles.listBox}
      renderContent={() => (
        <>
          <View style={styles.dateContainer}>
            <Text style={styles.dateText}>{formattedDate}</Text>
          </View>
          <SensorData containerStyle={styles.blueBandSection} value={blueBandIndexValues.wet} textStyle={styles.blueBandText} />
          <SensorData containerStyle={styles.blueBandSection} value={blueBandIndexValues.good} textStyle={styles.blueBandText} />
          <SensorData containerStyle={styles.blueBandSection} value={blueBandIndexValues.dry} textStyle={styles.blueBandText} />
        </>
      )}
    />
  );
};

BlockBlueBandIndexItem.propTypes = {
  blueBandIndex: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  blueBandSection: {
    flexGrow: 1,
    alignSelf: 'center',
    flexBasis: 0,
  },
  blueBandText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  dateContainer: {
    justifyContent: 'center',
    width: 100,
    height: 65,
    backgroundColor: COLORS.whiteTwo,
  },
  dateText: {
    marginLeft: 15,
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  listBox: {
    padding: 0,
  },
});

export default BlockBlueBandIndexItem;
