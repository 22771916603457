import { getApolloClient } from '../clients/apollo';
import { SCHEDULE } from './schedule.graphql';

async function fetchSchedule(siteId, startDate, endDate) {
  const { loading, error, data } = await getApolloClient().query({
    query: SCHEDULE,
    variables: {
      siteId,
      startDate,
      endDate,
    },
  });

  let schedule;
  if (data) {
    schedule = data.viewer.site;
  }

  return {
    loading,
    error,
    schedule,
  };
}

export { fetchSchedule };
