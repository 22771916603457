import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

// proptype
import { navigationShape } from '../../shapes/navigation';

// constants
import ROUTES from '../../navigation/routes';
import * as CAPABILITIES from '../../models/capability';
import * as TABS from '../blockDetails/BlockDetailsTabs';

// services
import ANALYTICS from '../../services/AnalyticsEvents';

//presenter
import ListBlockAliasPresenter from '../../presenters/blockAlias/ListBlockAliasPresenter';

//entity
import BlockEntity from '../../models/entities/blockEntity';

//components
import BlockName from '../../components/block/BlockName';
import BlockAliasButton from '../../components/block/BlockAliasButton';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ListBox from '../../components/list/ListBox';
import TemperatureSensorItem from './TemperatureSensorItem';

const TemperatureBlockItem = ({ block, navigation, keyIndex }) => {
  const blockAliasPresenter = new ListBlockAliasPresenter(block, CAPABILITIES.TEMPERATURE);
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  const goToGraph = async (defaultSelectedStreams) => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewGraph);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      activeTab: TABS.GRAPH,
      block,
      defaultSelectedStreamIds: defaultSelectedStreams.map((stream) => stream.id),
      unit: defaultSelectedStreams.length > 0 ? defaultSelectedStreams[0].unit : undefined,
      routeToGoBackTo: ROUTES.TEMPERATURE,
      capability: CAPABILITIES.TEMPERATURE,
      displayTabs: true,
    });
  };

  const renderSensorList = () => {
    return block
      .temperatureCapability()
      .temperatures()
      .map((item, index) => (
        <TemperatureSensorItem
          temperature={item}
          key={index}
          index={index}
          onPress={() => goToGraph([item])}
          testID={`temperature__sensorItem-${index}`}
        />
      ));
  };

  return (
    <ListBox
      customStyle={styles.listBox}
      renderContent={() => (
        <>
          <BlockAliasButton
            presenter={blockAliasPresenter}
            buttonStyle={styles.button}
            onClick={() => goToGraph(block.temperatureCapability().temperatures())}
            testId={`temperature__blockAlias-button-${keyIndex}`}
          />
          <BlockName blockName={blockAliasPresenter.name()} />
        </>
      )}
      renderSubContent={renderSensorList}
    />
  );
};

TemperatureBlockItem.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  keyIndex: PropTypes.number,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  button: {
    width: 57,
  },
  listBox: {
    paddingVertical: 13,
  },
});

export default TemperatureBlockItem;
