import moment from 'moment-timezone';

// constants
import { PLANNED } from '../calendarType';
import { EVENT_TYPES } from '../eventType';

export default class IrrigationEvent {
  constructor(event) {
    const startDate = event.startDate === null ? null : moment(event.startDate);
    const endDate = event.endDate === null ? moment() : moment(event.endDate);

    this.id = event.id;
    this.isActive = event.endDate === null;
    this.anytimeDayEvent = event.anytimeDayEvent || event.anytime || false;
    this.type = event.type;
    this.calendarType = event.calendarType;
    this.statistics = event.statistics;

    if (startDate) {
      this.dayOfYear = startDate.dayOfYear();
      this.year = startDate.year();
      this.duration = endDate.toDate().getTime() - startDate.toDate().getTime();
    }

    this.startDate = startDate;
    this.endDate = endDate;
  }

  isManualIrrigation() {
    return this.calendarType === PLANNED || this.type === EVENT_TYPES.manualIrrigation;
  }
}
