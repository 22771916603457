import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// models
import DeviceEntity from '../../models/entities/deviceEntity';

// services
import { modulo } from '../../utils/mathUtil';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// components
import ShadowedDivider from '../ShadowedDivider';
import DataStreamButtonPlaceholder from './DataStreamButtonPlaceholder';
import DataStreamButton from './DataStreamButton';

const DeviceDataStreams = ({ device, onToggle, selectedDataStreams }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedDataStreamsAllAxes = selectedDataStreams.left.ids.concat(selectedDataStreams.right.ids);
  const deviceDataList = device.sortedDataStreams().map((dataStream) => {
    const foundColoredDataStream = selectedDataStreamsAllAxes.find((coloredDataStream) => coloredDataStream.id === dataStream.id);
    return { ...dataStream, color: foundColoredDataStream ? foundColoredDataStream.color : undefined };
  });

  const onDataStreamToggle = (id, unit) => {
    onToggle();
    dispatch.graph.toggleSelectedDataStream({ id, unit });
  };

  const isSelected = (id) => selectedDataStreamsAllAxes.some((selectedDataStream) => selectedDataStream.id === id);

  return (
    <View style={styles.container}>
      <ShadowedDivider />
      <Text style={styles.sectionHeader}>{t('graph_view_health_data')}</Text>
      <View style={styles.dataSection}>
        {deviceDataList.map((item) => (
          <View style={styles.item} key={item.id}>
            <DataStreamButton
              isSelected={isSelected(item.id)}
              dataStream={item}
              onPress={onDataStreamToggle}
              iconColor={item.color}
            />
          </View>
        ))}
        <DataStreamButtonPlaceholder numberOfItems={modulo(-Math.abs(deviceDataList.length), 3)} style={styles.item} />
      </View>
    </View>
  );
};

DeviceDataStreams.propTypes = {
  device: PropTypes.instanceOf(DeviceEntity).isRequired,
  onToggle: PropTypes.func.isRequired,
  selectedDataStreams: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    marginBottom: 5,
  },
  dataSection: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 20,
  },
  sectionHeader: {
    paddingHorizontal: 10,
    paddingTop: 10,
    fontFamily: FONTS.firaSansLight,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  item: {
    flex: 1,
    minWidth: 95,
    maxWidth: 120,
    marginHorizontal: 3,
    marginBottom: 10,
  },
});

export default DeviceDataStreams;
