import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// constants
import COLORS from '../../../../colors';
import FONTS from '../../../../fonts';
import OPACITIES from '../../../../opacities';

// services
import { useTranslation } from '../../../../hooks/useTranslation';

// styles
import { globalStyles } from '../../../../styles';

//components
import Divider from '../../../Divider';
import HeaderEdition from '../../../header/HeaderEdition';
import SelectBlockItem from './SelectBlockItem';

const SelectBlocks = ({ onCancel, onConfirm, availableBlocks, selectedBlocks }) => {
  const { t } = useTranslation();

  const hasBlocks = availableBlocks?.length > 0;

  const [blockSelection, setBlockSelection] = useState(selectedBlocks);
  const addBlocks = () => onConfirm(blockSelection);

  // istanbul ignore next
  const onSelectBlock = (block) =>
    blockSelection.findIndex((selectedBlock) => block.id === selectedBlock.id) === -1
      ? setBlockSelection([...blockSelection, block])
      : setBlockSelection(blockSelection.filter((selectedBlock) => selectedBlock.id !== block.id));

  const renderItem = (element) => (
    <SelectBlockItem
      key={element.item.id}
      block={element.item}
      onSelect={onSelectBlock}
      selectedBlocks={blockSelection}
      testID={`block_item_${element.item.alias}`}
    />
  );

  const dividerItem = () => <Divider style={styles.itemDivider} />;

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderEdition onCancel={onCancel} onSave={addBlocks} title={t('edit_event_select_blocks_dialog_title')} />
      </View>

      <Divider style={styles.headerDivider} />

      {hasBlocks ? (
        <ScrollView contentContainerStyle={globalStyles.scrollContainer}>
          {availableBlocks.map((block, index) => (
            <View key={index}>
              {renderItem({ item: block })}
              {index < availableBlocks.length - 1 && dividerItem()}
            </View>
          ))}
          {dividerItem()}
        </ScrollView>
      ) : (
        <View style={styles.emptyViewTextContainer}>
          <Text style={styles.emptyViewText} testID="empty-view__text">
            {t('block_list_empty')}
          </Text>
        </View>
      )}
    </SafeAreaView>
  );
};

SelectBlocks.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  availableBlocks: PropTypes.array,
  selectedBlocks: PropTypes.array,
};

const styles = StyleSheet.create({
  emptyViewText: {
    width: 150,
    paddingHorizontal: 22,
    paddingVertical: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSansItalic,
    fontSize: 13,
    lineHeight: 16,
    color: COLORS.greyishBrown,
  },
  emptyViewTextContainer: {
    borderColor: COLORS.lightGrey,
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 10,
    backgroundColor: COLORS.white,
    shadowColor: COLORS.darkGrey,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 5,
  },
  headerDivider: {
    borderBottomColor: COLORS.pinkishGrey,
  },
  itemDivider: {
    borderBottomColor: COLORS.whiteThree,
  },
});

export default SelectBlocks;
