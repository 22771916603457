import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// icons
import ICON_BLUETOOTH_ON from '../../assets/icons/console/icnBluetoothOn.png';

// components
import ListBox from '../list/ListBox';

const ConsoleListItem = ({ onPress, serialNumber, testId }) => {
  return (
    <ListBox
      customStyle={styles.listBox}
      onPress={onPress}
      testID={testId}
      renderContent={() => (
        <>
          <Image source={ICON_BLUETOOTH_ON} style={styles.icon} />
          <Text style={styles.serialText}>{serialNumber}</Text>
          <View styles={styles.hiddenItem} />
        </>
      )}
    />
  );
};

ConsoleListItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  serialNumber: PropTypes.string,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  listBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 68,
    borderColor: COLORS.greyBorderColor,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: COLORS.white,
  },
  icon: {
    width: 22,
    height: 22,
  },
  serialText: {
    width: 80,
    height: 15,
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
  },
  hiddenItem: {
    width: 22,
    height: 22,
  },
});

export default ConsoleListItem;
