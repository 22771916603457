import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector, useStore } from 'react-redux';

// icons
import icnCheck from '../../assets/icons/icnCheck.png';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandler } from '../../hooks/useBackHandler';

// constants
import OPACITIES from '../../opacities';
import COLORS from '../../colors';
import FONTS from '../../fonts';
import ROUTES from '../../navigation/routes';
import { navigationShape } from '../../shapes/navigation';

// model
import BlockEntity from '../../models/entities/blockEntity';
import NoteEntity from '../../models/entities/noteEntity';

// styles
import { globalStyles, PADDING } from '../../styles';

// components
import Divider from '../../components/Divider';
import HeaderBack from '../../components/header/HeaderBack';

const NoteBlockListScreen = ({ navigation, route }) => {
  const { selectedBlock, setSelectedBlock, dateRange, headerLabel, note } = route.params || {};
  const { t } = useTranslation();
  const store = useStore();

  const blocks = useSelector(store.select.schedule.getActiveBlocks);
  const hasBlocks = () => blocks?.length > 0;

  const handleBlockSelection = (block) => {
    setSelectedBlock(block);
    goBack();
  };

  const goBack = useCallback(() => {
    navigation.navigate(ROUTES.NOTE_EDIT, { dateRange, headerLabel, note });
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  useBackHandler(goBack);

  const renderItem = (element) => (
    <Pressable
      style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.itemContainer]}
      onPress={() => handleBlockSelection(element.item)}
      testID={`note-block-${element.index}`}>
      <Text style={styles.textContent}>{element.item.name}</Text>
      <View style={styles.check}>
        {element.item.id === selectedBlock?.id ? <Image source={icnCheck} style={styles.checkIcon} /> : null}
      </View>
    </Pressable>
  );

  renderItem.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  };

  const dividerItem = () => <Divider style={styles.itemDivider} />;

  return (
    <SafeAreaView
      style={globalStyles.topContainer}
      edges={['top', 'right', 'left']}
      testID={'note-edit-select-block__subscreen-container'}>
      <View style={globalStyles.header}>
        <HeaderBack
          screenName={'note-edit-select-block'}
          navigation={navigation}
          goBack={goBack}
          title={t('note_edit_select_block_dialog_title')}
        />
      </View>

      <Divider />

      {!hasBlocks() ? (
        <View style={styles.emptyViewTextContainer}>
          <Text style={styles.emptyViewText} testID="empty-view__text">
            {t('block_list_empty')}
          </Text>
        </View>
      ) : (
        <FlashList
          data={blocks}
          renderItem={renderItem}
          estimatedItemSize={52}
          contentContainerStyle={styles.blockListContainer}
          showsVerticalScrollIndicator={false}
          ItemSeparatorComponent={dividerItem}
          ListFooterComponent={dividerItem}
        />
      )}
    </SafeAreaView>
  );
};

NoteBlockListScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      selectedBlock: PropTypes.instanceOf(BlockEntity),
      setSelectedBlock: PropTypes.func.isRequired,
      dateRange: PropTypes.object.isRequired,
      headerLabel: PropTypes.string,
      note: PropTypes.instanceOf(NoteEntity),
    }),
  }),
};

const styles = StyleSheet.create({
  blockListContainer: {
    paddingBottom: PADDING.LARGE,
  },
  check: {
    position: 'absolute',
    right: 20,
  },
  checkIcon: {
    width: 15,
    height: 13,
  },
  emptyViewTextContainer: {
    borderColor: COLORS.lightGrey,
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 10,
    backgroundColor: COLORS.white,
    shadowColor: COLORS.darkGrey,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 5,
  },
  emptyViewText: {
    width: 150,
    paddingHorizontal: 22,
    paddingVertical: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSansItalic,
    fontSize: 13,
    lineHeight: 16,
    color: COLORS.greyishBrown,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: COLORS.white,
  },
  itemDivider: {
    borderBottomColor: COLORS.whiteThree,
  },
  textContent: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
});

export default NoteBlockListScreen;
