import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, Pressable, StyleSheet, View, Text, TextInput } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { SvgXml } from 'react-native-svg';

// styles
import { globalStyles } from '../../styles';

// icons
import icnArrowDown from '../../assets/icons/console/icnDropDown2.svg';
import icnArrowUp from '../../assets/icons/console/icnDropUp2.svg';

// constants
import ROUTES from '../../navigation/routes';
import FONTS from '../../fonts';
import COLORS from '../../colors';
import { AlertTypes } from '../main/AlertConstants';
import { commandForAction, CONSOLE_DEFAULT_ACTIONS, iconForAction, nameForAction } from '../../models/consoleActionType';

// services
import ANALYTICS from '../../services/AnalyticsEvents';
import { navigationShape } from '../../shapes/navigation';

// components
import HeaderBack from '../../components/header/HeaderBack';
import Divider from '../../components/Divider';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import Card from '../../components/Card';
import ConsoleActionButton from '../../components/console/ConsoleActionButton';
import ConsoleDiscoveryView from '../../components/console/ConsoleDiscoveryView';
import Terminal from '../../components/console/Terminal';
import Alert from '../main/Alert';
import ToggleSwitch from '../../components/button/ToggleSwitch';

// hook
import { useBackHandler } from '../../hooks/useBackHandler';
import { useTranslation } from '../../hooks/useTranslation';

const ConsoleDetailsScreen = ({ navigation }) => {
  const selectedDevice = useSelector((state) => state.console.selectedDevice);
  const isConnected = useSelector((state) => state.console.selectedDevice?.connected);
  const traces = useSelector((state) => state.console.selectedDevice?.traces.slice()); // selector unknown warning
  const hasLostConnection = useSelector((state) => state.console.selectedDevice?.connectionLost);
  const hasConnectionError = useSelector((state) => state.console.selectedDevice?.connectionError);
  const showDeviceData = useSelector((state) => state.console.selectedDevice?.showData);
  const isFocused = useIsFocused();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();
  const [isManualInputVisible, setManualInputVisible] = useState(false);
  const [manualCommand, setManualCommand] = useState('');
  const textInputRef = useRef(null);
  const store = useStore();
  const isHortauStaff = useSelector(store.select.user.isHortauStaff);
  const { t } = useTranslation();

  useEffect(() => {
    if (isManualInputVisible) {
      textInputRef.current.focus();
    }
  }, [isManualInputVisible]);

  /* istanbul ignore next */
  const goBack = useCallback(() => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewConsole);
    navigation.navigate(ROUTES.CONSOLE);
    setManualInputVisible(false);
    return true; // prevent event bubble up (Android will close the app)
  }, [analyticsService, navigation]);

  useBackHandler(goBack);

  /* istanbul ignore next */
  const connectToDevice = async (value) => {
    await dispatch.console.connect(value);
  };

  const reconnect = () => {
    connectToDevice(selectedDevice);
    dispatch.console.updateSelectedDevice(selectedDevice);
  };

  const sendCommandToDevice = (action) => {
    return () => {
      const command = commandForAction(action);
      dispatch.console.sendCommand({ device: selectedDevice, command });
      analyticsService.trackEvent(ANALYTICS.eventConsoleAction + command);
      setManualCommand('');
      setManualInputVisible(false);
    };
  };

  const onManualCommandSend = () => {
    dispatch.console.sendCommand({ device: selectedDevice, command: manualCommand });
    setManualCommand('');
    setManualInputVisible(false);
  };

  const onShowDataToggle = () => {
    dispatch.console.setShowData({ device: selectedDevice, shouldShow: !showDeviceData });
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      connectToDevice(selectedDevice);
      return async () => {
        await dispatch.console.disconnect(selectedDevice);
        dispatch.console.setSelectedDevice(null);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDevice]),
  );

  if (!isFocused) {
    return null;
  }

  function renderView() {
    if (!isConnected && !hasConnectionError && !hasLostConnection) {
      return <ConsoleDiscoveryView />;
    }

    if (hasConnectionError) {
      return (
        <View style={globalStyles.bottomContainer}>
          <Alert
            alertType={AlertTypes.WARNING}
            onPress={reconnect}
            onCancel={goBack}
            show={hasConnectionError}
            title={t('console_details_connection_error_title')}
            message={t('console_details_connection_error_message')}
            isError={false}
            testID={'console_details_error-modal'}
          />
        </View>
      );
    }

    if (hasLostConnection) {
      return (
        <View style={globalStyles.bottomContainer}>
          <Alert
            alertType={AlertTypes.WARNING}
            onPress={reconnect}
            onCancel={goBack}
            show={hasLostConnection}
            title={t('console_details_connection_lost_title')}
            message={t('console_details_connection_lost_message')}
            isError={false}
            testID={'console_details_lost-modal'}
          />
        </View>
      );
    }

    return (
      <View style={[globalStyles.bottomContainer, styles.mainView]}>
        {isManualInputVisible ? null : (
          <Card
            title={t('console_card_terminal_title')}
            cardStyle={styles.terminalCardStyle}
            contentStyle={styles.terminalCardContentStyle}
            headerComponent={
              <View style={styles.dataToggleView}>
                <Pressable onPress={onShowDataToggle} testID={'console_card_manual_input_toggle_text'}>
                  <View style={styles.labelContainer}>
                    <Text style={styles.dataToggleLabel}>{t('console_card_terminal_show_data')}</Text>
                  </View>
                </Pressable>
                <ToggleSwitch
                  onValueChange={onShowDataToggle}
                  style={styles.toggleSwitch}
                  value={showDeviceData}
                  testID={'console-details-show-data__toggle'}
                />
              </View>
            }>
            <Terminal lines={traces?.reverse()} />
          </Card>
        )}
        <Card
          cardStyle={styles.actionCardStyle}
          contentStyle={styles.actionsCardContentStyle}
          headerComponent={
            isHortauStaff ? (
              <Pressable onPress={() => setManualInputVisible(!isManualInputVisible)} testID={'console_card_manual_input_toggle'}>
                <View style={styles.manualInputView}>
                  <Text style={styles.manualInput}>{t('console_card_terminal_manual_input')}</Text>
                  <SvgXml xml={isManualInputVisible ? icnArrowUp : icnArrowDown} />
                </View>
              </Pressable>
            ) : null
          }
          title={t('console_card_actions_title')}
          testId={'consoleActionCard'}>
          <View style={styles.actionsCardButtonContentStyle}>
            {CONSOLE_DEFAULT_ACTIONS.map((action, index) => (
              <ConsoleActionButton
                key={index}
                icon={iconForAction(action)}
                onPress={sendCommandToDevice(action)}
                testId={'ActionButtonTestId_' + t(nameForAction(action))}
                text={t(nameForAction(action)).toUpperCase()}
              />
            ))}
          </View>
          {isManualInputVisible ? (
            <View style={styles.textInputContainer}>
              <TextInput
                ref={textInputRef}
                autoCapitalize="none"
                placeholder={t('console_card_manual_action_placeholder')}
                onChangeText={setManualCommand}
                isRequired
                testID="manual__command-input"
                style={styles.textInput}
              />
              <Pressable
                testID="console_manual_input_send_button"
                style={({ pressed }) => [
                  styles.buttonStyle,
                  manualCommand === '' && styles.disabledButton,
                  /* istanbul ignore next */ pressed && globalStyles.touchOpacity,
                ]}
                onPress={onManualCommandSend}
                disabled={manualCommand === ''}>
                <Text style={[styles.buttonTitle, manualCommand === '' && styles.disabledButtonTitle]}>
                  {t('console_card_manual_action_send')}
                </Text>
              </Pressable>
            </View>
          ) : null}
        </Card>
      </View>
    );
  }

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderBack screenName="console-details" navigation={navigation} goBack={goBack} title={selectedDevice?.getName()} />
      </View>

      <Divider />

      {renderView()}
    </SafeAreaView>
  );
};

ConsoleDetailsScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  terminalCardStyle: {
    flex: 1,
    marginBottom: 5,
  },
  terminalCardContentStyle: {
    flex: 1,
  },
  actionCardStyle: {
    marginBottom: 5,
  },
  actionsCardContentStyle: {
    justifyContent: 'space-between',
    padding: 15,
  },
  actionsCardButtonContentStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
  },
  manualInput: {
    marginRight: 7,
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  manualInputView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textInputContainer: {
    flexDirection: 'column',
  },
  textInput: {
    ...Platform.select({
      ios: {
        height: 40,
      },
    }),
    paddingHorizontal: 21,
    borderColor: COLORS.greyish22,
    borderWidth: 2,
    borderRadius: 4,
    marginBottom: 12,
    backgroundColor: COLORS.white,
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
  toggleSwitch: {
    transform: [{ scale: 0.8 }],
  },
  dataToggleView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  labelContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  dataToggleLabel: {
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  buttonTitle: {
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.white,
  },
  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    borderRadius: 5,
    backgroundColor: COLORS.havelockBlue,
  },
  disabledButton: {
    backgroundColor: COLORS.greyish22,
  },
  disabledButtonTitle: {
    color: COLORS.greyish19,
  },
});

export default ConsoleDetailsScreen;
