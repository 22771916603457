import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';

// shape
import { styleShape } from '../../shapes/style';

const SensorData = ({ value, containerStyle, textStyle }) => {
  return (
    <View style={containerStyle}>
      <Text style={textStyle}>{value}</Text>
    </View>
  );
};

SensorData.propTypes = {
  value: PropTypes.string.isRequired,
  containerStyle: styleShape,
  textStyle: styleShape,
};

export default React.memo(SensorData);
