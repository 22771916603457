/* istanbul ignore file */

import { useEffect } from 'react';

const SymbolLayer = ({ map, blocks, items, activeBlockId }) => {
  useEffect(() => {
    if (!map.current || blocks.features.length === 0 || !map.current.loaded()) {
      return;
    }

    const shapesSource = map.current.getSource('shapes');

    if (shapesSource) {
      shapesSource.setData(blocks);
    } else {
      map.current.addSource('shapes', {
        type: 'geojson',
        data: blocks,
      });
    }

    if (!map.current.getLayer('pins-with-label-inactive')) {
      map.current.addLayer({
        id: 'pins-with-label-inactive',
        type: 'symbol',
        source: 'shapes',
        minZoom: 11,
        layout: {
          'icon-image': ['get', 'icon'],
          'text-field': ['get', 'name'],
          'icon-allow-overlap': true,
        },
        filter: ['!=', 'filterId', activeBlockId || ''],
      });
    }

    if (!map.current.getLayer('pins-without-label-inactive')) {
      map.current.addLayer({
        id: 'pins-without-label-inactive',
        type: 'symbol',
        source: 'shapes',
        maxZoom: 12,
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-allow-overlap': true,
        },
        filter: ['!=', 'filterId', activeBlockId || ''],
      });
    }

    if (!map.current.getLayer('pins-with-label-active')) {
      map.current.addLayer(
        {
          id: 'pins-with-label-active',
          type: 'symbol',
          source: 'shapes',
          minZoom: 11,
          layout: {
            'icon-image': ['get', 'icon'],
            'text-field': ['get', 'name'],
            'icon-allow-overlap': true,
          },
          filter: ['==', 'filterId', activeBlockId || ''],
        },
        'pins-with-label-inactive',
      );
    }

    if (!map.current.getLayer('pins-without-label-active')) {
      map.current.addLayer(
        {
          id: 'pins-without-label-active',
          type: 'symbol',
          source: 'shapes',
          maxZoom: 12,
          layout: {
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
          },
          filter: ['==', 'filterId', activeBlockId || ''],
        },
        'pins-without-label-inactive',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocks]);

  useEffect(() => {
    if (!map.current || items.features.length === 0 || !map.current.loaded()) {
      return;
    }

    const itemsSource = map.current.getSource('map-items');

    if (itemsSource) {
      itemsSource.setData(items);
    } else {
      map.current.addSource('map-items', {
        type: 'geojson',
        data: items,
      });
    }

    if (!map.current.getLayer('pins-marker-label')) {
      map.current.addLayer({
        id: 'pins-marker-label',
        type: 'symbol',
        source: 'map-items',
        minZoom: 11,
        layout: {
          'icon-image': ['get', 'icon'],
          'text-field': ['get', 'name'],
          'icon-allow-overlap': true,
        },
        filter: ['!=', 'filterId', activeBlockId || ''],
      });
    }

    if (!map.current.getLayer('pins-marker')) {
      map.current.addLayer({
        id: 'pins-marker',
        type: 'symbol',
        source: 'map-items',
        maxZoom: 12,
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-allow-overlap': true,
        },
        filter: ['!=', 'filterId', activeBlockId || ''],
      });
    }

    if (!map.current.getLayer('pins-marker-active')) {
      map.current.addLayer(
        {
          id: 'pins-marker-active',
          type: 'symbol',
          source: 'map-items',
          minZoom: 11,
          layout: {
            'icon-image': ['get', 'icon'],
            'text-field': ['get', 'name'],
            'icon-allow-overlap': true,
          },
          filter: ['==', 'filterId', activeBlockId || ''],
        },
        'pins-marker-label',
      );
    }

    if (!map.current.getLayer('pins-marker-label-active')) {
      map.current.addLayer(
        {
          id: 'pins-marker-label-active',
          type: 'symbol',
          source: 'map-items',
          maxZoom: 12,
          layout: {
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
          },
          filter: ['==', 'filterId', activeBlockId || ''],
        },
        'pins-marker',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
};

export default SymbolLayer;
