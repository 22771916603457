import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View } from 'react-native';

// helpers
import { getWeatherIcon, NA_WEATHER_CODE } from '../../models/weather';

const WeatherIcon = ({ weatherCode = NA_WEATHER_CODE }) => (
  <View style={styles.icon}>
    <Image source={getWeatherIcon(weatherCode)} accessibilityLabel={weatherCode} />
  </View>
);

WeatherIcon.propTypes = {
  weatherCode: PropTypes.string,
};

const styles = StyleSheet.create({
  icon: {
    height: 21,
  },
});

export default WeatherIcon;
