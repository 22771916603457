import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDrawerStatus } from '@react-navigation/drawer';

// styles
import { globalStyles } from '../../styles';

// proptype
import { navigationShape } from '../../shapes/navigation';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';

// components
import Divider from '../Divider';

// icons
import icnDropDown from '../../assets/icons/icnDropDown.png';
import mapIcon from '../../assets/icons/icnMenuMap.svg';
import farmIcon from '../../assets/icons/icnMenuFarm.svg';
import logoutIcon from '../../assets/icons/icnMenuLogout.svg';
import devicesIcon from '../../assets/icons/icnMenuHealth.svg';
import weatherIcon from '../../assets/icons/icnMenuWeather.svg';
import scheduleIcon from '../../assets/icons/icnMenuSchedule.svg';
import dashboardIcon from '../../assets/icons/icnMenuDashboard.svg';
import flowStationIcon from '../../assets/icons/icnMenuFlowmeter.svg';
import temperatureIcon from '../../assets/icons/icnMenuTemperature.svg';
import icnMenuIrrigation from '../../assets/icons/icnMenuIrrigation.svg';
import automationIcon from '../../assets/icons/icnMenuAutomation.svg';
import reportIcon from '../../assets/icons/icnMenuReport.svg';
import profileIcon from '../../assets/icons/icnMenuProfile.svg';

//services
import { isIos } from '../../services/PlatformService';
import ANALYTICS from '../../services/AnalyticsEvents';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import ROUTES from '../../navigation/routes';
import { AUTOMATION, FLOW_STATION, REPORT, TEMPERATURE, WEATHER } from '../../models/modules';

//components
import AppVersion from '../AppVersion';
import AuthorizedModule from '../AuthorizedModule';
import NavigationItem, { COLOR_MODE } from './NavigationItem';

const SideMenu = ({ state, navigation }) => {
  const activeItemKey = state.routeNames[state.index];
  const isCurrentRoute = (route) => route === activeItemKey;
  const analyticsService = useAnalyticsContext();
  const { t } = useTranslation();
  const isDrawerOpen = useDrawerStatus() === 'open';

  const dispatch = useDispatch();
  const store = useStore();

  const scrollViewRef = useRef();

  const currentSite = useSelector((stateProp) => stateProp.site.currentSite);
  const mapMode = useSelector((stateProp) => stateProp.map.mode);

  const isBetaUser = useSelector(store.select.user.isBetaUser);

  const hasMultipleSites = useSelector(store.select.site.hasMultipleSites);
  const hasPreviewModules = useSelector(store.select.site.hasPreviewModules);

  useEffect(() => {
    dispatch.site.loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isDrawerOpen) {
      scrollViewRef?.current?.scrollTo({ y: 0 });
    }
  }, [isDrawerOpen]);

  return (
    <SafeAreaView
      style={[globalStyles.topContainer, isDrawerOpen && styles.containerShadow]}
      testID="main__banner"
      edges={['top', 'right', 'left']}>
      <ScrollView bounces={false} ref={scrollViewRef} testID="navigation__sideMenu-items">
        <Divider style={styles.divider} />

        <NavigationItem
          isCurrentItem={false}
          navigation={navigation}
          navigationItemStyle={styles.currentSiteItem}
          route={ROUTES.SITE_SELECTOR}
          title={currentSite.name}
          rightImage={hasMultipleSites ? icnDropDown : null}
          leftSvg={farmIcon}
          leftSvgColor={COLORS.warmGrey}
          testId={'side-menu__siteSelector'}
          textStyle={styles.currentSiteText}
          params={{ routeToGoBackTo: activeItemKey }}
          hasList={hasMultipleSites}
          disabled={!hasMultipleSites}
        />

        <Divider style={[styles.divider, styles.currentSiteDivider]} />

        <NavigationItem
          isCurrentItem={isCurrentRoute(ROUTES.MAP)}
          navigation={navigation}
          route={ROUTES.MAP}
          title={t('menu_map_button')}
          leftSvg={mapIcon}
          beforeNavigate={() => {
            dispatch.map.loadMapBlocksForCurrentSite();
            analyticsService.trackNavigationEvent(ANALYTICS.eventViewMap, { display_mode: mapMode });
          }}
          preventNavigationToSameItem={false}
          testId={'side-menu__map'}
        />

        <NavigationItem
          isCurrentItem={isCurrentRoute(ROUTES.DASHBOARD)}
          navigation={navigation}
          route={ROUTES.DASHBOARD}
          title={t('menu_dashboard_button')}
          leftSvg={dashboardIcon}
          beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewDashboard)}
          params={{ reloadData: true }}
          testId={'side-menu__dashboard'}
        />

        <NavigationItem
          isCurrentItem={isCurrentRoute(ROUTES.SCHEDULE)}
          navigation={navigation}
          route={ROUTES.SCHEDULE}
          title={t('menu_schedule_button')}
          leftSvg={scheduleIcon}
          beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewSchedule)}
          testId={'side-menu__schedule'}
        />

        <AuthorizedModule module={AUTOMATION}>
          <NavigationItem
            isCurrentItem={isCurrentRoute(ROUTES.AUTOMATION)}
            navigation={navigation}
            route={ROUTES.AUTOMATION}
            title={t('menu_automation_button')}
            leftSvg={automationIcon}
            leftSvgColor={COLORS.pinkishGrey}
            beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewAutomation)}
            params={{ reloadData: true }}
            testId={'side-menu__automation'}
          />
        </AuthorizedModule>

        <NavigationItem
          isCurrentItem={isCurrentRoute(ROUTES.IRRIGATION)}
          navigation={navigation}
          route={ROUTES.IRRIGATION}
          title={t('menu_irrigation_button')}
          leftSvg={icnMenuIrrigation}
          leftSvgColorMode={COLOR_MODE.STROKE}
          beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewIrrigation)}
          testId={'side-menu__irrigation'}
        />

        <AuthorizedModule module={FLOW_STATION}>
          <NavigationItem
            isCurrentItem={isCurrentRoute(ROUTES.FLOW_STATION)}
            navigation={navigation}
            route={ROUTES.FLOW_STATION}
            title={t('menu_flow_station_button')}
            leftSvg={flowStationIcon}
            beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewFlowStation)}
            testId={'side-menu__flow-station'}
          />
        </AuthorizedModule>

        <AuthorizedModule module={TEMPERATURE}>
          <NavigationItem
            isCurrentItem={isCurrentRoute(ROUTES.TEMPERATURE)}
            navigation={navigation}
            route={ROUTES.TEMPERATURE}
            title={t('menu_temperature_button')}
            leftSvg={temperatureIcon}
            beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewTemperature)}
            testId={'side-menu__temperature'}
          />
        </AuthorizedModule>

        <AuthorizedModule module={WEATHER}>
          <NavigationItem
            isCurrentItem={isCurrentRoute(ROUTES.WEATHER)}
            navigation={navigation}
            route={ROUTES.WEATHER}
            title={t('menu_weather_button')}
            leftSvg={weatherIcon}
            beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewWeather)}
            testId={'side-menu__weather'}
            params={{ initialWeatherStationId: undefined }}
          />
        </AuthorizedModule>

        <AuthorizedModule module={REPORT}>
          <NavigationItem
            isCurrentItem={isCurrentRoute(ROUTES.REPORT)}
            navigation={navigation}
            route={ROUTES.REPORT}
            title={t('menu_report_button')}
            leftSvg={reportIcon}
            leftSvgColor={COLORS.pinkishGrey}
            beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewReport)}
            testId={'side-menu__report'}
            params={{ reloadData: true }}
          />
        </AuthorizedModule>

        <NavigationItem
          isCurrentItem={isCurrentRoute(ROUTES.DEVICES)}
          navigation={navigation}
          route={ROUTES.DEVICES}
          title={t('menu_devices_button')}
          leftSvg={devicesIcon}
          beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewDevices)}
          testId={'side-menu__devices'}
          params={{ reloadData: true }}
        />

        {hasPreviewModules ? (
          <View style={styles.preview}>
            <Text style={styles.previewHeader}>{t('menu_preview_header')}</Text>
            <AuthorizedModule module={AUTOMATION} isPreviewMode={true}>
              <NavigationItem
                isCurrentItem={isCurrentRoute(ROUTES.AUTOMATION_PREVIEW)}
                navigation={navigation}
                route={ROUTES.AUTOMATION_PREVIEW}
                title={t('menu_automation_button')}
                leftSvg={automationIcon}
                leftSvgColor={COLORS.pinkishGrey}
                beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewAutomationPreview)}
                testId={'side-menu__automation-preview'}
              />
            </AuthorizedModule>
            <AuthorizedModule module={FLOW_STATION} isPreviewMode={true}>
              <NavigationItem
                isCurrentItem={isCurrentRoute(ROUTES.FLOW_STATION_PREVIEW)}
                navigation={navigation}
                route={ROUTES.FLOW_STATION_PREVIEW}
                title={t('menu_flow_station_button')}
                leftSvg={flowStationIcon}
                beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewFlowStationPreview)}
                testId={'side-menu__flow-station-preview'}
              />
            </AuthorizedModule>
            <AuthorizedModule module={TEMPERATURE} isPreviewMode={true}>
              <NavigationItem
                isCurrentItem={isCurrentRoute(ROUTES.TEMPERATURE_PREVIEW)}
                navigation={navigation}
                route={ROUTES.TEMPERATURE_PREVIEW}
                title={t('menu_temperature_button')}
                leftSvg={temperatureIcon}
                beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewTemperaturePreview)}
                testId={'side-menu__temperature-preview'}
              />
            </AuthorizedModule>
            <AuthorizedModule module={WEATHER} isPreviewMode={true}>
              <NavigationItem
                isCurrentItem={isCurrentRoute(ROUTES.WEATHER_PREVIEW)}
                navigation={navigation}
                route={ROUTES.WEATHER_PREVIEW}
                title={t('menu_weather_button')}
                leftSvg={weatherIcon}
                beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewWeatherPreview)}
                testId={'side-menu__weather-preview'}
              />
            </AuthorizedModule>
            <AuthorizedModule module={REPORT} isPreviewMode={true}>
              <NavigationItem
                isCurrentItem={isCurrentRoute(ROUTES.REPORT_PREVIEW)}
                navigation={navigation}
                route={ROUTES.REPORT_PREVIEW}
                title={t('menu_report_button')}
                leftSvg={reportIcon}
                leftSvgColor={COLORS.pinkishGrey}
                beforeNavigate={() => analyticsService.trackNavigationEvent(ANALYTICS.eventViewReportPreview)}
                testId={'side-menu__report-preview'}
              />
            </AuthorizedModule>
          </View>
        ) : null}
      </ScrollView>

      <View>
        <View style={styles.spacer} />

        {isBetaUser ? (
          <NavigationItem
            navigationItemStyle={styles.footerItem}
            navigation={navigation}
            route={ROUTES.PROFILE}
            title={t('menu_profile_button')}
            leftSvg={profileIcon}
            testId="side-menu__profile"
          />
        ) : null}

        <Divider style={styles.divider} />

        <NavigationItem
          navigationItemStyle={styles.footerItem}
          navigation={navigation}
          route={ROUTES.AUTH}
          beforeNavigate={async () => await dispatch.authentication.logOut(analyticsService)}
          title={t('menu_logout_button')}
          leftSvg={logoutIcon}
          testId="side-menu__log-out"
        />
        <AppVersion />
      </View>
    </SafeAreaView>
  );
};

SideMenu.propTypes = {
  state: PropTypes.object,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  containerShadow: {
    elevation: 6,
    shadowColor: COLORS.greyish19,
    shadowOffset: {
      width: 4,
    },
    shadowOpacity: OPACITIES.drawerBorder,
    shadowRadius: 2,
  },
  currentSiteDivider: {
    marginBottom: 20,
  },
  currentSiteItem: {
    paddingVertical: 10,
    paddingLeft: 26,
    marginBottom: 0,
  },
  currentSiteText: {
    marginLeft: 8,
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    lineHeight: 30,
    letterSpacing: isIos() ? 0.81 : undefined,
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.brownGrey,
  },
  footerItem: {
    paddingVertical: 10,
    marginBottom: 0,
  },
  spacer: {
    flex: 1,
    width: '100%',
  },
  preview: {
    marginTop: 12,
    backgroundColor: COLORS.whiteTwo,
  },
  previewHeader: {
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    fontStyle: 'italic',
    lineHeight: 37,
    letterSpacing: 0.88,
    color: COLORS.greyishBrown,
  },
});

export default SideMenu;
