// icons
import iconSync from '../assets/icons/console/icnSync.svg';
import iconScan from '../assets/icons/console/icnScan.svg';
import iconReboot from '../assets/icons/console/icnReboot.svg';

export const SYNC = 'SYNC';
export const SCAN = 'SCAN';
export const REBOOT = 'REBOOT';

const consoleActionIconByType = {
  SYNC: iconSync,
  SCAN: iconScan,
  REBOOT: iconReboot,
  [undefined]: '',
  default: '',
};

const consoleActionNameByType = {
  SYNC: 'console_action_sync_name',
  SCAN: 'console_action_scan_name',
  REBOOT: 'console_action_reboot_name',
  [undefined]: 'console_action_unknown_name',
  default: 'console_action_unknown_name',
};

const consoleActionDescriptionByType = {
  SYNC: 'console_action_sync_description',
  SCAN: 'console_action_scan_description',
  REBOOT: 'console_action_reboot_description',
  [undefined]: 'console_action_unknown_description',
  default: 'console_action_unknown_description',
};

const consoleActionCommandByType = {
  SYNC: 'sync',
  SCAN: 'scan',
  REBOOT: 'reboot',
  [undefined]: '',
  default: '',
};

export const CONSOLE_DEFAULT_ACTIONS = [SYNC, SCAN, REBOOT];
export const iconForAction = (type) => consoleActionIconByType[type] || consoleActionIconByType.default;
export const nameForAction = (type) => consoleActionNameByType[type] || consoleActionNameByType.default;
export const descriptionForAction = (type) => consoleActionDescriptionByType[type] || consoleActionDescriptionByType.default;
export const commandForAction = (type) => consoleActionCommandByType[type] || consoleActionCommandByType.default;
