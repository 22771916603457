import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';

// shape
import { styleShape } from '../../shapes/style';

// services
import COLORS from '../../colors';
import FONTS from '../../fonts';

const circleSize = 42;

const ScheduleActivityCounter = ({ style, count, countColor, text }) => {
  return (
    <View style={[styles.centerRow, style]} testID="activity__container">
      <View style={styles.circle}>
        <Text style={[styles.count, { color: countColor }]} testID="activity__count">
          {count}
        </Text>
      </View>
      <Text style={styles.text} testID="activity__text">
        {text}
      </Text>
    </View>
  );
};

ScheduleActivityCounter.propTypes = {
  count: PropTypes.number,
  countColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  style: styleShape,
};

const styles = StyleSheet.create({
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: circleSize,
    height: circleSize,
    borderColor: COLORS.whisper,
    borderWidth: 2,
    borderRadius: circleSize / 2,
  },
  count: {
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 16,
  },
  text: {
    marginLeft: 11,
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    letterSpacing: 0.7,
    color: COLORS.greyishBrown,
    flexShrink: 1,
  },
});

export default ScheduleActivityCounter;
