import { Pressable, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { useTranslation } from '../../hooks/useTranslation';
import SensorName from '../../components/block/SensorName';
import SensorData from '../../components/block/SensorData';
import { FlowmeterPresenter } from '../../presenters/FlowmeterPresenter';
import Irrigation from '../../components/block/Irrigation';
import { FlowmeterShape } from '../../shapes/flowmeter';

const FlowmeterItem = ({ flowmeter, index, onPress, animatedOpacity }) => {
  const { t, i18n } = useTranslation();
  const presenter = new FlowmeterPresenter(flowmeter);
  const outflowId = flowmeter.outflow ? flowmeter.outflow.id : '';

  return (
    <Pressable style={presenter.containerStyle(index)} testID={`flow-station__flowmeter-${outflowId}`} onPress={onPress}>
      <SensorName presenter={presenter} containerStyle={styles.nameContainer} />
      <Irrigation style={styles.irrigationContainer} irrigating={presenter.isIrrigating()} animatedOpacity={animatedOpacity} />
      <SensorData value={presenter.flowValue(t)} containerStyle={styles.flowContainer} textStyle={styles.flow} />
      <SensorData value={presenter.totalVolumeValue(t, i18n)} containerStyle={styles.volumeContainer} textStyle={styles.volume} />
    </Pressable>
  );
};

FlowmeterItem.propTypes = {
  index: PropTypes.number.isRequired,
  flowmeter: FlowmeterShape.isRequired,
  onPress: PropTypes.func.isRequired,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  flow: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    letterSpacing: -0.03,
    color: COLORS.greyishBrown,
  },
  flowContainer: {
    flex: 1,
    alignItems: 'flex-start',
    paddingLeft: 5,
  },
  irrigationContainer: {
    width: 35,
  },
  nameContainer: {
    width: 145,
  },
  volumeContainer: {
    width: 65,
  },
  volume: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    letterSpacing: -0.03,
    color: COLORS.greyishBrown,
  },
});

export default React.memo(FlowmeterItem);
