import icnBattery37V from '../icnBattery37V.png';
import icnExternalSensor from '../icnExternalSensor.png';
import icnSignalStrength from '../icnSignalStrength.png';
import icnTslr from '../icnTslr.png';
import icnWebBaseValveNo from '../icnWebBaseValveNo.png';
import icnInternalHumidity from '../icnInternalHumidity.png';
import icnCpu from '../icnCpu.png';
import icnInternalTemperature from '../icnInternalTemperature.png';

export const HEALTH_DATA_STREAM_ICONS = {
  BATTERY: {
    name: 'icn_battery_37V',
    icon: icnBattery37V,
  },
  CPU: {
    name: 'icn_cpu',
    icon: icnCpu,
  },
  EDXTERNAL_SENSOR: {
    name: 'icn_external_sensor',
    icon: icnExternalSensor,
  },
  INTERNAL_HUMIDITY: {
    name: 'icn_internal_humidity',
    icon: icnInternalHumidity,
  },
  INTERNAL_TEMPERATURE: {
    name: 'icn_internal_temperature',
    icon: icnInternalTemperature,
  },
  SIGNAL_STRENGTH: {
    name: 'signal_strength',
    icon: icnSignalStrength,
  },
  TIME_SINCE_LAST_REPORT: {
    name: 'icn_tslr',
    icon: icnTslr,
  },
  VALVE: {
    name: 'icn_web_base_valve_no',
    icon: icnWebBaseValveNo,
  },
};
