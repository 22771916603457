import React, { useEffect, useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

// shapes
import { navigationShape } from '../../shapes/navigation';

// services
import { readingsToDataStream } from '../../utils/dataStreamsUtil';
import { MapDisplayMode } from '../../presenters/MapDisplayMode';
import { GraphDataType } from '../../models/graphDataType';
import GraphPresenter from '../../presenters/GraphPresenter';

// components
import MapItemDetail from './MapItemDetail';

const ScrollableItemsDetails = ({
  xAxisStartDate,
  loadingGraph = false,
  presenters,
  carouselRef,
  onItemSnapped,
  navigation,
  showTensionForecast,
}) => {
  const window = useWindowDimensions();
  const itemWidth = window.width;
  const mapMode = useSelector((state) => state.map.mode);
  const currentSite = useSelector((state) => state.site.currentSite);
  const selectedBlock = useSelector((state) => state.blocks.selectedBlock);
  const dataStreams = useSelector((state) => state.graph.dataStreams);
  const tensionForecast = useSelector((state) => state.graph.tensionForecast);
  const futureIrrigationEvents = useSelector((state) => state.graph.futureIrrigationEvents);
  const irrigationEvents = useSelector((state) => state.graph.irrigationEvents);
  const selectedDataStreams = useSelector((state) => state.graph.selectedDataStreams);
  const previousMapMode = useRef();
  const isFocused = useIsFocused();

  useEffect(() => {
    previousMapMode.current = mapMode;
  }, [mapMode]);

  const onSnap = (blockIndex) => onItemSnapped(presenters[blockIndex]);

  const getReadingsInfo = (mapBlock) => {
    let mapDataType, readings;
    const isTension = mapMode === MapDisplayMode.TENSION && mapBlock.isTensionCapable();
    const isFlow = mapMode === MapDisplayMode.TENSION && mapBlock.isFlowStationCapable();
    const isTemperature = mapMode === MapDisplayMode.TEMPERATURE && mapBlock.isTemperatureCapable();
    const isWaterLevel = mapMode === MapDisplayMode.TENSION && mapBlock.isWaterLevelCapable();

    switch (true) {
      case isTension:
        mapDataType = GraphDataType.TENSION;
        readings = mapBlock.tensionCapability().tensionReadings();
        break;
      case isFlow:
        mapDataType = GraphDataType.FLOW;
        readings = mapBlock.flowStationCapability().readings();
        break;
      case isTemperature:
        mapDataType = GraphDataType.TEMPERATURE;
        readings = readingsToDataStream(mapBlock.temperatureCapability().readings());
        break;
      case isWaterLevel:
        mapDataType = GraphDataType.WATER_LEVEL;
        readings = mapBlock.dataStreams();
        break;
    }
    return { mapDataType, readings };
  };

  const renderItem = (element) => {
    const item = element.item;
    const block = item.block;

    let graphPresenter = item.getGraphPresenter();
    let loading = false;

    /* istanbul ignore else */
    if (isFocused && block && selectedBlock?.id === block.id) {
      loading = loadingGraph;

      const selectedDataStreamsIds = selectedDataStreams.left.ids; // On the map only a single Y axe is used
      const dataStreamsIds = dataStreams?.map((dataStream) => dataStream.id);
      const currentDataStreamsMatch = dataStreamsIds ? selectedDataStreamsIds.some((id) => dataStreamsIds.includes(id)) : false;

      if (currentDataStreamsMatch) {
        const { mapDataType, readings } = getReadingsInfo(block);

        graphPresenter = new GraphPresenter({
          dataStreams,
          tensionForecast,
          legendStreams: readings,
          selectedDataStreams,
          assignedDataStreamsColors: null,
          block,
          defaultTimeScaleInHours: currentSite.defaultTimeScaleInHours,
          irrigationEvents,
          dataType: mapDataType,
          futureIrrigationEvents,
        });
        item.setGraphPresenter(graphPresenter);
      }
    } else if (graphPresenter && previousMapMode.current && previousMapMode.current !== mapMode) {
      // Reset graph data when switching map mode
      graphPresenter = null;
      item.setGraphPresenter(graphPresenter);
    }

    return (
      <MapItemDetail
        xAxisStartDate={xAxisStartDate}
        loadingGraph={loading}
        item={item}
        mapMode={mapMode}
        navigation={navigation}
        slideStyle={{ width: itemWidth }}
        slideContainerStyle={{ width: itemWidth }}
        key={item.id()}
        showTensionForecast={showTensionForecast && block?.isTensionCapable()}
      />
    );
  };

  return (
    <Carousel
      data={presenters}
      loop={presenters.length > 1}
      scrollAnimationDuration={200}
      onSnapToItem={onSnap}
      ref={carouselRef}
      renderItem={renderItem}
      width={itemWidth}
      testID="scrollable-items-details__carousel"
    />
  );
};

ScrollableItemsDetails.propTypes = {
  presenters: PropTypes.arrayOf(PropTypes.object).isRequired,
  carouselRef: PropTypes.any.isRequired,
  onItemSnapped: PropTypes.func.isRequired,
  navigation: navigationShape.isRequired,
  loadingGraph: PropTypes.bool,
  xAxisStartDate: PropTypes.number,
  showTensionForecast: PropTypes.bool,
};

export default ScrollableItemsDetails;
