// models
import { TENSION } from '../../models/capability';
import { iconForMapItemType, mapItemTypesForLayer } from '../../models/itemType';
import MapItemEntity from '../../models/entities/mapItemEntity';
import { isNullOrUndefined } from '../../utils/util';

export const getPresenters = (blocks, automations) => {
  const presenters = [];
  if (blocks && blocks.length > 0) {
    blocks.forEach((block) => {
      presenters.push(new MapItemEntity(block));
    });
  }

  if (automations && automations.length > 0) {
    automations.forEach((automation) => {
      presenters.push(new MapItemEntity(null, automation));
    });
  }

  return presenters;
};

export function buildFeatureCollectionFromPresenters(presenters, mapMode, mapStyle, activeBlockId) {
  const featuresFromBlocks = [];

  presenters.forEach((presenter) => {
    if (presenter.isForMapV1()) {
      const icon = presenter.icon(mapMode, mapStyle);
      featuresFromBlocks.push({
        type: 'Feature',
        id: presenter.block.id,
        properties: {
          icon: presenter.block.id === activeBlockId ? icon.active.name : icon.normal.name,
          value: presenter.valueForDisplay(mapMode, mapStyle),
          'symbol-sort-key': 0,
          filterId: presenter.block.id,
        },
        geometry: {
          type: 'Point',
          coordinates: [presenter.coordinates()?.longitude, presenter.coordinates()?.latitude],
        },
      });
    }
  });

  return featuresFromBlocks;
}

export const buildFeatureCollectionFromMapItems = (state, loading, mapItems, mapMode, mapStyle, activeBlockId) => {
  if (loading) {
    return state.markerCollection;
  }

  const featuresFromMapItems = [];
  mapItems.forEach((feature) => {
    const supportedTypes = mapItemTypesForLayer(mapStyle, mapMode);
    if (supportedTypes.some((element) => element === feature.properties.itemType) === false) {
      return;
    }

    const icon = iconForMapItemType(feature.properties.itemType, feature.properties.status, mapStyle);
    featuresFromMapItems.push({
      ...feature,
      properties: {
        icon: feature.id === activeBlockId ? icon?.active.name : icon?.normal.name,
        value: feature.properties.name,
        filterId: feature.id,
      },
      geometry: {
        type: feature.geometry.type,
        coordinates: JSON.parse(feature.geometry.coordinates),
      },
    });
  });

  return { type: 'FeatureCollection', features: featuresFromMapItems };
};

export function getCoordinatesFromPresenters(presenters) {
  const coordinates = [];

  presenters.forEach((presenter) => {
    const longitude = presenter.coordinates()?.longitude;
    const latitude = presenter.coordinates()?.latitude;

    if (!isNullOrUndefined(longitude) && !isNullOrUndefined(latitude)) {
      coordinates.push({ longitude, latitude });
    }
  });

  return coordinates;
}

export function getCoordinatesFromCollection({ features }) {
  const coordinates = [];

  features.forEach((item) => {
    coordinates.push({
      longitude: item.geometry.coordinates[0],
      latitude: item.geometry.coordinates[1],
    });
  });

  return coordinates;
}

export const getSelectedStreamsAndUnit = (mapMode, block, filterVirtualSensors) => {
  let defaultSelectedStreamIds = [];
  let unit;

  if (mapMode === TENSION) {
    const selectedReadings = getSelectedStreamsAndUnitForTension(block);
    defaultSelectedStreamIds = selectedReadings.defaultSelectedStreamIds;
    unit = selectedReadings.unit;
  } else {
    let temperatures = block.temperatureCapability().temperatures();
    if (filterVirtualSensors) {
      temperatures = temperatures.filter((t) => t.isVirtual === false);
    }
    defaultSelectedStreamIds = temperatures.map((dataStream) => dataStream.id);
    if (block.temperatureCapability().temperatures().length > 0) {
      unit = block.temperatureCapability().unit();
    }
  }

  return { defaultSelectedStreamIds, unit };
};

export const getSelectedReadingsAndUnit = (mapMode, block) => {
  let defaultSelectedStreamIds = [];
  let unit;

  if (mapMode === TENSION) {
    const selectedReadings = getSelectedStreamsAndUnitForTension(block);
    defaultSelectedStreamIds = selectedReadings.defaultSelectedStreamIds;
    unit = selectedReadings.unit;
  } else {
    let temperatures = block.temperatureCapability().readings();
    defaultSelectedStreamIds = temperatures.map((reading) => reading.id);
    if (temperatures.length > 0) {
      unit = temperatures[0].unit;
    }
  }

  return { defaultSelectedStreamIds, unit };
};

const getSelectedStreamsAndUnitForTension = (block) => {
  let defaultSelectedStreamIds = [];
  let unit;

  if (block.isTensionCapable()) {
    defaultSelectedStreamIds = block
      .tensionCapability()
      .tensionReadings()
      .map((reading) => reading.id);
    unit = block.tensionCapability().unit();
  } else if (block.isFlowStationCapable()) {
    defaultSelectedStreamIds = block
      .flowStationCapability()
      .readings()
      .map((reading) => reading.id);
    unit = block.flowStationCapability().unit();
  } else if (block.isWaterLevelCapable()) {
    defaultSelectedStreamIds = block.waterCapability().map((dataStream) => dataStream.id);
    unit = block.waterCapability().map((dataStream) => dataStream.unit)[0];
  }

  return { defaultSelectedStreamIds, unit };
};
