import { DATA_STREAMS_TYPES } from '../dataStreamTypes';

export default class FlowStationCapability {
  constructor(flowStation, sensors) {
    this.flowStation = flowStation;
    this.sensors = sensors;
  }

  status() {
    return this.flowStation?.status;
  }

  readings() {
    if (!this.cachedReadings) {
      this.cachedReadings = [];
      if (this.sensors) {
        this.sensors.forEach((sensor) => {
          sensor.dataStreams.forEach((dataStream) => {
            if (dataStream.dataStreamType === DATA_STREAMS_TYPES.FLOW.code) {
              this.cachedReadings.push(dataStream);
            }
          });
        });
      }
    }
    return this.cachedReadings;
  }

  flowmeters() {
    if (!this.cachedFlowmeters) {
      this.cachedFlowmeters = [];
      if (this.flowStation.flowmeters) {
        this.flowStation.flowmeters.forEach((flowmeter) => {
          this.cachedFlowmeters.push({ ...flowmeter, flowStationStatus: this.flowStation.status });
        });
      }
    }
    return this.cachedFlowmeters;
  }

  unit() {
    if (this.readings().length > 0) {
      return this.cachedReadings[0].unit;
    }
    return undefined;
  }
}
