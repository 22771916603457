import { gql } from 'graphql-tag';

export const GRAPH_EVENTS = gql`
  query graphEvents($siteId: ID!, $blockId: ID!, $startDate: String!, $endDate: String!) {
    viewer {
      site(id: $siteId) {
        id
        block(id: $blockId) {
          events(startDate: $startDate, endDate: $endDate) {
            id
            startDate
            endDate
            subSensorId
          }
        }
      }
    }
  }
`;
