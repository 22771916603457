import moment from 'moment-timezone';
import { formattedTimeDifference, isInLessThanAWeek, isToday, isTomorrow } from '../utils/dateUtils';

export class IrrigationBlockPresenter {
  constructor(block, timezone, translation) {
    this.block = block;
    this.timezone = timezone;
    this.translation = translation;
  }

  dateAsMoment(date) {
    if (this.timezone) {
      return moment(date).tz(this.timezone);
    }
    return moment(date);
  }

  blueBandIndex() {
    if (this.block.tensionCapability().blueBandIndex().good !== null) {
      return `${this.block.tensionCapability().blueBandIndex().good} %`;
    }
    return this.translation('blue_band_index_na');
  }

  scheduledStatusAndTime() {
    if (this.block.isIrrigationCapable()) {
      const irrigationCapableBlock = this.block.irrigationCapability();
      if (!irrigationCapableBlock.hasCurrentOrNextEvent()) {
        return { status: this.translation('irrigation_schedule_not_scheduled') };
      }

      if (irrigationCapableBlock.hasCurrentEvent() && this.isActive(irrigationCapableBlock.currentEvent())) {
        return {
          status: this.translation('irrigation_schedule_elapsed'),
          value: formattedTimeDifference(this.dateAsMoment(irrigationCapableBlock.currentEvent().startDate), this.dateAsMoment()),
        };
      }
      const nextEvent = irrigationCapableBlock.nextEvent();

      let label, subValue;
      if (isToday(this.dateAsMoment(nextEvent.startDate), this.timezone)) {
        label = this.translation('irrigation_schedule_today');
      } else if (isTomorrow(this.dateAsMoment(nextEvent.startDate), this.timezone)) {
        label = this.translation('irrigation_schedule_tomorrow');
      } else if (isInLessThanAWeek(this.dateAsMoment(nextEvent.startDate))) {
        label = this.dateAsMoment(nextEvent.startDate).calendar();
      } else {
        label = this.dateAsMoment(nextEvent.startDate).format('MM/DD/YYYY');
      }

      if (nextEvent.endDate && (nextEvent.anytime === false || !nextEvent.anytime)) {
        subValue = `${this.dateAsMoment(nextEvent.startDate).format('LT')} - ${this.dateAsMoment(nextEvent.endDate).format(
          'LT',
        )}`;
      }
      return { status: this.translation('irrigation_schedule_next'), value: label, subValue };
    }
    return { status: this.translation('irrigation_schedule_not_scheduled') };
  }

  isActive(event) {
    return !event.anytimeDayEvent && !event.endDate;
  }

  unit() {
    return this.block.tensionCapability().tensionReadings().length > 0
      ? this.block.tensionCapability().tensionReadings()[0].unit
      : undefined;
  }
}
