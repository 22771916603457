import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

// routes
import ROUTES from '../../navigation/routes';

// shapes
import { styleShape } from '../../shapes/style';

// icons
import icnNavRight from '../../assets/icons/icnNavRight.png';

// services
import { navigationShape } from '../../shapes/navigation';
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { useTranslation } from '../../hooks/useTranslation';
import ANALYTICS from '../../services/AnalyticsEvents';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// components
import Divider from '../Divider';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import BlueBandIndexPie from '../blueBandIndex/BlueBandIndexPie';
import ShadowedDivider from '../ShadowedDivider';
import CriticalBlocks from './CriticalBlocks';
import ScheduleSummary from './ScheduleSummary';

const IrrigationBox = ({ contentStyle, blueBandIndex, criticalBlocks, schedule, loading, navigation, testId }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const goToSiteBlueBand = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewSiteBlueBand);
    navigation.navigate(ROUTES.SITE_BLUE_BAND_INDEX);
  };

  return (
    <View style={contentStyle} testID={testId}>
      <View style={[styles.header, styles.centerRow]}>
        <Text style={styles.title}>{t('irrigation')}</Text>
      </View>
      <Divider style={styles.headerDivider} />

      <LinearGradient colors={[COLORS.whiteTwo, COLORS.white]} style={styles.linearGradient}>
        <View style={[styles.header, styles.centerRow]}>
          <Text style={styles.subtitle}>{t('block_detail_blue_band_title')}</Text>

          <Pressable style={styles.centerRow} onPress={goToSiteBlueBand} testID={'blueBandIndex-details-button'}>
            <Text style={styles.details}>{t('index_details')}</Text>
            <Image source={icnNavRight} style={styles.arrowSpacing} />
          </Pressable>
        </View>

        <BlueBandIndexPie
          blueBandIndexData={blueBandIndex}
          loading={loading}
          onPress={goToSiteBlueBand}
          testId={'dashboard_blueBandIndex-pie-graph'}
        />

        <ShadowedDivider style={styles.divider} />

        <CriticalBlocks
          contentStyle={[styles.componentStyle, styles.componentMargin]}
          blocks={criticalBlocks}
          loading={loading}
          navigation={navigation}
          testId={'dashboard__criticalBlocks'}
        />

        <ShadowedDivider style={styles.divider} />

        <ScheduleSummary
          contentStyle={[styles.componentStyle, styles.componentMargin]}
          data={schedule}
          loading={loading}
          navigation={navigation}
          testId={'dashboard__scheduleSummary'}
        />
      </LinearGradient>
    </View>
  );
};

IrrigationBox.propTypes = {
  contentStyle: styleShape,
  blueBandIndex: PropTypes.object,
  criticalBlocks: PropTypes.arrayOf(PropTypes.instanceOf(BlockEntity)),
  schedule: PropTypes.object,
  loading: PropTypes.bool,
  navigation: navigationShape.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  arrowSpacing: {
    marginLeft: 10,
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  details: {
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  divider: {
    marginHorizontal: 15,
  },
  header: {
    justifyContent: 'space-between',
    height: 50,
    paddingRight: 17,
    paddingLeft: 15,
  },
  headerDivider: {
    height: 1,
    backgroundColor: COLORS.brownGrey,
  },
  linearGradient: {
    flex: 1,
  },
  subtitle: {
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  title: {
    fontFamily: FONTS.firaSansMedium,
    fontSize: 18,
    color: COLORS.greyishBrown,
  },
});

export default IrrigationBox;
