import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet, Image, View } from 'react-native';
import COLORS from '../colors';
import errorIcon from '../assets/icons/icnErreur.png';
import FONTS from '../fonts';

// shape
import { styleShape } from '../shapes/style';

const ErrorMessageBanner = ({ errorMessage, bannerStyle, testId }) => {
  return (
    <View style={[styles.errorBanner, bannerStyle]}>
      <Image source={errorIcon} style={styles.errorIcon} />
      <Text style={styles.errorText} testID={testId}>
        {errorMessage}
      </Text>
    </View>
  );
};

ErrorMessageBanner.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  bannerStyle: styleShape,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  errorBanner: {
    flexDirection: 'row',
    padding: 8,
    borderRadius: 16.5,
    backgroundColor: COLORS.white,
  },
  errorIcon: {
    width: 18,
    height: 18,
  },
  errorText: {
    alignSelf: 'center',
    paddingLeft: 10,
    fontFamily: FONTS.firaSansItalic,
    fontSize: 12,
    color: COLORS.dustyOrange,
  },
});

export default ErrorMessageBanner;
