import { NativeEventEmitter, NativeModules } from 'react-native';
import BleManager from 'react-native-ble-manager';

import { consoleDebug, consoleError } from '../utils/loggerUtil';
import { isAndroid, isIos } from './PlatformService';
import { requestPermissions } from './BluetoothPermission';

const Gen6MinSerialNumber = 1310000000;
const Gen6MaxSerialNumber = 1600000000;
const SCAN_MODE_LOW_LATENCY = 2;
const IOS_CONNECT_TIMEOUT = 5000;
const ANDROID_MTU_SIZE = 128;
const NUMBER_OF_CONNECT_RETRY = 3;
const btService = '49535343-FE7D-4AE5-8FA9-9FAFD205E455';
const btReadCharacteristic = '49535343-1E4D-4BD9-BA61-23C647249616';
const btWriteCharacteristic = '49535343-8841-43F4-A8D4-ECBE34729BB3';

const BLE_ON = 'on';

let bluetoothService;

const initBluetooth = () => {
  bluetoothService = new BluetoothService();
};

const isGen6Device = (name) => {
  const serialNumber = parseInt(name, 10);
  return !isNaN(serialNumber) && serialNumber >= Gen6MinSerialNumber && serialNumber < Gen6MaxSerialNumber;
};

const validatePermissions = async () =>
  new Promise(async (resolve, reject) => {
    const granted = await requestPermissions();
    if (!granted) {
      reject('Missing permissions');
    }
    resolve();
  });

class BluetoothService {
  constructor() {
    this.foundDevices = [];
    this.bleManagerModule = NativeModules.BleManager;
    this.bleManagerEmitter = new NativeEventEmitter(this.bleManagerModule);
    this.deviceDiscoveredListener = null;
    this.stopScanListener = null;
    this.started = false;
  }

  async checkState() {
    return await BleManager.checkState();
  }

  // android only
  async enableBluetooth() {
    return await BleManager.enableBluetooth();
  }

  async start() {
    // As per documentation : Don't call this multiple times.
    if (!this.started) {
      consoleDebug('BluetoothService - Service - Starting ...');
      await BleManager.start({ showAlert: true });
      this.started = true;
      consoleDebug('BluetoothService - Service - Started');
    }
  }

  stop() {
    consoleDebug('BluetoothService - Service - Stopped');
  }

  async scan(scanTimeSec) {
    return new Promise(async (resolve, reject) => {
      try {
        await validatePermissions();
        await BleManager.scan([], scanTimeSec, false, { scanMode: SCAN_MODE_LOW_LATENCY });
        this.foundDevices = [];
        this.deviceDiscoveredListener = this.bleManagerEmitter.addListener(
          'BleManagerDiscoverPeripheral',
          this.onDiscoverPeripheral(),
        );
        this.stopScanListener = this.bleManagerEmitter.addListener('BleManagerStopScan', this.onStopScan(resolve));
      } catch (err) {
        consoleError(`BluetoothService - Scan - Error : ${err}`);
        reject(`BluetoothService - Scan - Error : ${err}`);
      }
    });
  }

  onStopScan(resolve) {
    return () => {
      this.deviceDiscoveredListener?.remove();
      this.deviceDiscoveredListener = null;
      this.stopScanListener?.remove();
      this.stopScanListener = null;
      consoleDebug('BluetoothService - Scan - Finished');
      resolve(this.foundDevices);
    };
  }

  onDiscoverPeripheral() {
    return (peripheral) => {
      if (isGen6Device(peripheral?.name)) {
        if (!this.foundDevices.find((d) => d?.name === peripheral?.name)) {
          this.foundDevices.push(peripheral);
          consoleDebug(`BluetoothService - Scan - Device discovered : ${peripheral.name}`);
        }
      }
    };
  }

  connect(deviceId, onData, onDisconnect) {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < NUMBER_OF_CONNECT_RETRY; i++) {
        const result = await this.connectToDevice(deviceId);
        if (result) {
          const onDisconnectHandler = this.registerDisconnectHandler(deviceId, onDisconnect);
          const onDataHandler = this.registerDataHandler(deviceId, onData);

          resolve({ deviceId, onDisconnectHandler, onDataHandler });
          return;
        }
      }

      reject('BluetoothService - Connect - Error');
    });
  }

  async connectToDevice(deviceId) {
    try {
      consoleDebug('BluetoothService - Connect - ...');
      const timeout = this.setSoftConnectionTimeout(deviceId);
      await BleManager.connect(deviceId);
      this.clearSoftConnectionTimeout(timeout);

      consoleDebug('BluetoothService - Retrieve Services - ...');
      await BleManager.retrieveServices(deviceId);

      await this.requestMtu(deviceId);

      consoleDebug('BluetoothService - Start Notifications - ...');
      await BleManager.startNotification(deviceId, btService, btReadCharacteristic);
    } catch (e) {
      consoleError(`BluetoothService - Connect - Error : ${e}`);
      return false;
    }
    return true;
  }

  /*
  In iOS, attempts to connect to a peripheral do not time out (please see Apple's doc),
  so you might need to set a timer explicitly if you don't want this behavior.
  */
  setSoftConnectionTimeout(deviceId) {
    if (isIos()) {
      const timeout = setTimeout(async () => {
        const isConnected = await BleManager.isPeripheralConnected(deviceId, []);
        if (!isConnected) {
          consoleDebug('BluetoothService - Connect - Timeout (iOs)');
          await BleManager.disconnect(deviceId);
        }
      }, IOS_CONNECT_TIMEOUT);
      return timeout;
    }
    return null;
  }

  clearSoftConnectionTimeout(timeout) {
    if (isIos() && timeout != null) {
      clearTimeout(timeout);
    }
  }

  async requestMtu(deviceId) {
    if (isAndroid()) {
      consoleDebug('BluetoothService - Requesting MTU - ...');
      await BleManager.requestMTU(deviceId, ANDROID_MTU_SIZE);
      consoleDebug(`BluetoothService - MTU Requested - ${ANDROID_MTU_SIZE}`);
    }
  }

  registerDisconnectHandler(deviceId, onDisconnect) {
    const onDisconnectHandler = this.bleManagerEmitter.addListener('BleManagerDisconnectPeripheral', (data) => {
      if (data.peripheral === deviceId) {
        onDisconnect();
      }
    });
    return onDisconnectHandler;
  }

  registerDataHandler(deviceId, onData) {
    const onDataHandler = this.bleManagerEmitter.addListener(
      'BleManagerDidUpdateValueForCharacteristic',
      ({ value, peripheral }) => {
        if (peripheral === deviceId) {
          onData(value);
        }
      },
    );
    return onDataHandler;
  }

  async send(deviceId, payload) {
    await BleManager.write(deviceId, btService, btWriteCharacteristic, payload);
  }

  async disconnect(handle) {
    handle.onDisconnectHandler.remove();
    handle.onDataHandler.remove();
    await BleManager.disconnect(handle.deviceId);
  }
}

const getBluetoothService = () => bluetoothService;

export { BLE_ON, getBluetoothService, initBluetooth, validatePermissions };
