import { getApolloClient } from '../clients/apollo';
import { FLOW_STATION_BLOCKS } from './flowStationBlocks.graphql';

const fetchFlowStationBlocks = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: FLOW_STATION_BLOCKS,
    variables: {
      siteId,
    },
  });
  let flowStationBlocks;
  if (data) {
    flowStationBlocks = data.viewer.site.blocks;
  }

  return {
    loading,
    error,
    flowStationBlocks,
  };
};

export { fetchFlowStationBlocks };
