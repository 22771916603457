import Config from 'react-native-config';

export const isDebug = () => __DEV__ === true;
export const isRelease = () => !isDebug();

export const isProductionEnvironment = () => Config.ENV_NAME === 'production';

export const environmentName = () => Config.ENV_NAME || '';

export const disableContinuousAnimations = () => Config.DISABLE_CONTINUOUS_ANIMATIONS === 'true';

export const sideBarDrawerType = () => Config.SIDEBAR_DRAWER_TYPE || 'slide';

export const isAnalyticsEnabled = () => Config.ANALYTICS_ENABLED === 'true';

export const killswitchCachingSeconds = () => parseInt(Config.KILLSWITCH_CACHING_SECONDS || 0, 10);

export const isStorybookMode = () => Config.STORYBOOK_MODE === 'true';
