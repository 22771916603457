// services
import { DATA_STREAMS_TYPES } from '../dataStreamTypes';
import { formatValue } from '../../utils/dataStreamsUtil';
import { iconForStatus, COMMUNICATION_ERROR, NORMAL, OUT_OF_FIELD } from '../status/deviceStatus';
import { compareDataStreamByPriority } from '../../utils/sorting';

// entities
import BlockEntity from './blockEntity';

export default class DeviceEntity {
  constructor(device) {
    if (device) {
      this.block = new BlockEntity(device.block);
      this.id = `${device.id}-${this.block.id}`;
      this.deviceId = device.id;
      this.serialNumber = device.serialNumber;
      this.lastUpdated = device.lastUpdated;
      this.status = device.status;
      this.dataStreams = device.dataStreams;

      if (this.dataStreams) {
        this.batteryDataStream = this.dataStreams.find(
          (d) =>
            !d.isVirtual &&
            (d.dataStreamType === DATA_STREAMS_TYPES.BATTERY_3_7_V.code ||
              d.dataStreamType === DATA_STREAMS_TYPES.BATTERY_3V.code ||
              d.dataStreamType === DATA_STREAMS_TYPES.BATTERY_12V.code),
        );

        this.signalDataStream = this.dataStreams.find(
          (d) => !d.isVirtual && d.dataStreamType === DATA_STREAMS_TYPES.SIGNAL_STRENGTH_DB.code,
        );
      }
    }
  }

  statusIcon() {
    return iconForStatus(this.status);
  }

  statusText(t) {
    let statusText = t('unknown_status');

    if (this.status) {
      switch (this.status) {
        case COMMUNICATION_ERROR:
          statusText = t('device_status_error');
          break;
        case NORMAL:
          statusText = t('device_status_normal');
          break;
        case OUT_OF_FIELD:
          statusText = t('device_status_out_of_field');
          break;
      }
    }

    return statusText;
  }

  batteryValue() {
    return this.dataStreamValue(this.batteryDataStream);
  }

  batteryText(t) {
    return this.dataStreamText(this.batteryDataStream, this.batteryValue(), t);
  }

  signalValue() {
    return this.dataStreamValue(this.signalDataStream);
  }

  signalText(t) {
    return this.dataStreamText(this.signalDataStream, this.signalValue(), t);
  }

  dataStreamValue(dataStream) {
    return dataStream && dataStream.value ? dataStream.value : 0;
  }

  dataStreamText(dataStream, dataStreamValue, t) {
    let dataStreamText = null;

    if (dataStream) {
      const value = formatValue(dataStreamValue);
      dataStreamText = `${value}${t(`unit_${dataStream.unit.toLowerCase()}`)}`;
    }

    return dataStreamText;
  }

  sortedDataStreams() {
    if (!this.cachedDataStreams) {
      if (this.dataStreams) {
        this.cachedDataStreams = this.dataStreams;
        this.cachedDataStreams.sort(compareDataStreamByPriority);
      } else {
        this.cachedDataStreams = [];
      }
    }
    return this.cachedDataStreams;
  }

  defaultSelectedDataStreams() {
    if (!this.dataStreams) {
      return [];
    }

    return this.dataStreams.reduce((acc, dataStream) => {
      switch (dataStream.dataStreamType) {
        case DATA_STREAMS_TYPES.TIME_SINCE_LAST_REPORT.code:
        case DATA_STREAMS_TYPES.BATTERY_VOLTAGE_SUPPLY.code:
        case DATA_STREAMS_TYPES.BATTERY_3_7_V.code:
          acc.push(dataStream);
      }

      return acc;
    }, []);
  }
}
