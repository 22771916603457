// icons
import icnAutomWaterLevelEmpty from '../assets/icons/icnAutomWaterLevelEmpty.png';
import icnAutomWaterLevelFill from '../assets/icons/icnAutomWaterLevelFill.png';
import icnFrostProtection from '../assets/icons/icnFrostProtection.png';
import icnHeatProtection from '../assets/icons/icnHeatProtection.png';
import icnAutomUnknown from '../assets/icons/icnAutomUnknown.png';
import icnAutomWaterLevelEmptyDisabled from '../assets/icons/icnAutomWaterLevelEmptyDisabled.png';
import icnAutomWaterLevelFillDisabled from '../assets/icons/icnAutomWaterLevelFillDisabled.png';
import icnFrostProtectionDisabled from '../assets/icons/icnFrostProtectionDisabled.png';
import icnHeatProtectionDisabled from '../assets/icons/icnHeatProtectionDisabled.png';
import icnAutomUnknownDisabled from '../assets/icons/icnAutomUnknownDisabled.png';
import { UPDATE_AUTOMATION_HIGH_RULE, UPDATE_AUTOMATION_LOW_RULE } from '../mutations/updateAutomationRule.graphql';

export const FROST = 'FROST';
export const HEAT = 'HEAT';
export const WATER_EMPTY = 'WATER_EMPTY';
export const WATER_FILL = 'WATER_FILL';

export const RULE_SORT_ORDER = [FROST, HEAT, WATER_EMPTY, WATER_FILL];

const automationRuleIconByType = {
  FROST: icnFrostProtection,
  HEAT: icnHeatProtection,
  WATER_EMPTY: icnAutomWaterLevelEmpty,
  WATER_FILL: icnAutomWaterLevelFill,
  [undefined]: icnAutomUnknown,
  default: icnAutomUnknown,
};

const automationRuleIconDisabledByType = {
  FROST: icnFrostProtectionDisabled,
  HEAT: icnHeatProtectionDisabled,
  WATER_EMPTY: icnAutomWaterLevelEmptyDisabled,
  WATER_FILL: icnAutomWaterLevelFillDisabled,
  [undefined]: icnAutomUnknownDisabled,
  default: icnAutomUnknownDisabled,
};

const automationRuleTranslationByType = {
  FROST: 'automation_rule_frost',
  HEAT: 'automation_rule_heat',
  WATER_EMPTY: 'automation_rule_water_empty',
  WATER_FILL: 'automation_rule_water_fill',
  [undefined]: 'automation_rule_unknown',
  default: 'automation_rule_unknown',
};

const mutationQueryByType = {
  FROST: UPDATE_AUTOMATION_LOW_RULE,
  HEAT: UPDATE_AUTOMATION_HIGH_RULE,
  WATER_EMPTY: UPDATE_AUTOMATION_HIGH_RULE,
  WATER_FILL: UPDATE_AUTOMATION_LOW_RULE,
};

const thresholdsTranslationByType = {
  FROST: { start: 'automation_rule_start_below', stop: 'automation_rule_stop_above' },
  HEAT: { start: 'automation_rule_start_above', stop: 'automation_rule_stop_below' },
  WATER_EMPTY: { start: 'automation_rule_start_above', stop: 'automation_rule_stop_below' },
  WATER_FILL: { start: 'automation_rule_start_below', stop: 'automation_rule_stop_above' },
};

export const iconForRule = (type, enabled) => {
  if (enabled) {
    return automationRuleIconByType[type] || automationRuleIconByType.default;
  }

  return automationRuleIconDisabledByType[type] || automationRuleIconDisabledByType.default;
};

export const mutationQueryForRule = (type) => mutationQueryByType[type] || null;

export const translationForRule = (type) => automationRuleTranslationByType[type] || automationRuleTranslationByType.default;

export const translationForRuleThresholds = (type) => thresholdsTranslationByType[type] || null;
