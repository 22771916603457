import React, { useCallback, useState } from 'react';
import { Keyboard, KeyboardAvoidingView, StyleSheet, View, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';

// constants
import { useFocusEffect } from '@react-navigation/native';
import COLORS from '../../colors';
import { navigationShape } from '../../shapes/navigation';

// styles
import { globalStyles } from '../../styles';

// services
import { isIos } from '../../services/PlatformService';
import { updateAutomationRule } from '../../mutations/updateAutomationRule';
import { getAutomationRuleErrorTranslationKey } from '../../models/errors/AutomationRuleErrorMapper';
import { parseFloatV2 } from '../../utils/util';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandler } from '../../hooks/useBackHandler';

// components
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import HeaderEdition from '../../components/header/HeaderEdition';
import GenericSection from '../../components/layouts/GenericSection';
import ComponentAlias from '../../components/automation/ComponentAlias';
import AutomationRuleEditThreshold from '../../components/automation/rule/AutomationRuleEditThreshold';
import AutomationRuleEditRestart from '../../components/automation/rule/AutomationRuleEditRestart';
import ToggleSwitch from '../../components/button/ToggleSwitch';
import RowSection from '../../components/layouts/RowSection';
import LoadingOverlay from '../../components/LoadingOverlay';
import Alert from '../main/Alert';
import { AlertTypes } from '../main/AlertConstants';
import ANALYTICS from '../../services/AnalyticsEvents';
import { EVENT_ACTIONS } from './eventActions';
import useAutomationRule from './useAutomationRule';

const initialAlertState = {
  show: false,
  alertType: null,
  title: '',
  message: '',
  onPress: null,
  onCancel: null,
  okButtonKey: '',
  cancelButtonKey: '',
  cancelDisabled: false,
};

const AutomationRuleEditScreen = ({ navigation, route }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const { routeToGoBackTo, routeToGoBackFromParent } = route.params || {};
  const currentSite = useSelector((state) => state.site.currentSite);
  const ruleDetail = useSelector((state) => state.automations.selectedAutomationRule);
  const automationEventConstraints = useSelector((state) => state.site.getAutomationEventConstraints);
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState(initialAlertState);
  const [state, dispatch] = useAutomationRule({ ruleDetail });

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      dispatch({ type: EVENT_ACTIONS.RESET });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleDetail]),
  );

  const goBack = useCallback(
    /* istanbul ignore next */
    async (reloadData = false) => {
      if (routeToGoBackTo) {
        navigation.navigate(routeToGoBackTo, { reloadData, routeToGoBackTo: routeToGoBackFromParent });
      }
      return true;
    },
    [navigation, routeToGoBackTo, routeToGoBackFromParent],
  );

  useBackHandler(goBack);

  const goBackWithoutReload = () => {
    Keyboard.dismiss();
    goBack(false);
  };

  const handleHideAlert = () => {
    setAlertState(initialAlertState);
  };

  const setEnabled = () => {
    dispatch({ type: EVENT_ACTIONS.SET_RULE_ENABLED, payload: !state.enabled });
  };

  const setStartValue = (value) => {
    const payload = parseFloatV2(value);
    dispatch({ type: EVENT_ACTIONS.SET_START_VALUE, payload });
  };

  const setStopValue = (value) => {
    const payload = parseFloatV2(value);
    dispatch({ type: EVENT_ACTIONS.SET_STOP_VALUE, payload });
  };

  const setEnabledRestart = () => {
    dispatch({ type: EVENT_ACTIONS.SET_RESTART_ENABLED, payload: !state.cycling.enabled });
  };

  const setRestartValue = (value) => {
    const payload = parseFloatV2(value);
    dispatch({ type: EVENT_ACTIONS.SET_RESTART_VALUE, payload });
  };

  /* istanbul ignore next */
  const setRunForValue = (value) => {
    dispatch({ type: EVENT_ACTIONS.SET_RUN_FOR_VALUE, payload: value });
  };

  const handleSaveRule = async () => {
    return updateAutomationRule(state, currentSite.id);
  };

  const onSave = async () => {
    setLoading(true);
    Keyboard.dismiss();
    const response = await handleSaveRule();

    if (response?.errors) {
      const error0 = response.errors[0];
      const errorCode = error0.extensions?.code;
      const message = t(getAutomationRuleErrorTranslationKey(errorCode));

      setAlertState({
        show: true,
        alertType: AlertTypes.ERROR,
        title: t('generic_error_server_error_title'),
        message: t(message),
        onPress: handleHideAlert,
        onCancel: handleHideAlert,
        cancelDisabled: false,
      });
    } else {
      analyticsService.trackEvent(ANALYTICS.eventSaveAutomationRule);
      goBack(true);
    }

    setLoading(false);
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <Alert
        show={alertState.show}
        alertType={alertState.alertType}
        title={alertState.title}
        message={alertState.message}
        onPress={alertState.onPress}
        onCancel={alertState.onCancel}
        cancelDisabled={alertState.cancelDisabled}
      />

      <LoadingOverlay isLoading={loading} handleTouchEvents={false} />

      <View style={globalStyles.header}>
        <HeaderEdition title={state.name} onCancel={goBackWithoutReload} onSave={onSave} deleteEnabled={false} />
      </View>

      {state?.id ? (
        <KeyboardAvoidingView behavior={isIos() ? 'padding' : 'height'} style={styles.keyboardView} keyboardVerticalOffset={30}>
          <ScrollView keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator={false}>
            <GenericSection title={state.translatedTypeName}>
              <RowSection onPress={setEnabled} title={t('automation_rule_enable')}>
                <ToggleSwitch onValueChange={setEnabled} value={state.enabled} testID={'automation_rule__enabled_switch'} />
              </RowSection>
            </GenericSection>

            <GenericSection title={t('automation_component_multiple')}>
              <View style={styles.container}>
                {state.componentsToAutomate?.map((component, index) => (
                  <ComponentAlias
                    key={index}
                    alias={component.summaryAlias}
                    backgroundColor={COLORS.white}
                    color={COLORS.brownGreyOpaque}
                  />
                ))}
              </View>
            </GenericSection>

            <AutomationRuleEditThreshold
              startValue={state.startValue}
              stopValue={state.stopValue}
              onChangeStartValue={setStartValue}
              onChangeStopValue={setStopValue}
              ruleType={state.ruleType}
              unit={state.translatedUnit}
            />

            {state.cycling ? (
              <AutomationRuleEditRestart
                durationConstraints={automationEventConstraints}
                value={state.cycling.cyclingRestartTrigger}
                valueUnit={state.translatedUnit}
                enabled={state.cycling.enabled}
                onValueChanged={setRestartValue}
                onEnabledChanged={setEnabledRestart}
                onRunForValueChanged={setRunForValue}
                runForValue={state.cycling.cyclingDurationMoment}
              />
            ) : null}
          </ScrollView>
        </KeyboardAvoidingView>
      ) : null}
    </SafeAreaView>
  );
};

AutomationRuleEditScreen.propTypes = {
  navigation: navigationShape,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingHorizontal: 15,
    paddingVertical: 11,
    gap: 7,
  },
  keyboardView: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
});

export default AutomationRuleEditScreen;
