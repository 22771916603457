import icnChillPortions from '../icnChillPortions.png';
import icnChillingHours from '../icnChillingHours.png';
import icnDecAirTemperature from '../icnDecAirTemperature.png';
import icnDegreeDays from '../icnDegreeDays.png';
import icnDielectricPermittivity from '../icnDielectricPermittivity.png';
import icnElectricalConductivity from '../icnElectricalConductivity.png';
import icnLeafWetness from '../icnLeafWetness.png';
import icnLeafWetnessHours from '../icnLeafWetnessHours.png';
import icnPH from '../icnPH.png';
import icnRotten from '../icnRotten.png';
import icnPoreWaterConductivity from '../icnPoreWaterConductivity.png';
import icnPressure from '../icnPressure.png';
import icnDewPt from '../icnDewPt.png';
import icnRain24H from '../icnRain24H.png';
import icnRelativeHumidity from '../icnRelativeHumidity.png';
import icnSalinitySoil from '../icnSalinitySoil.png';
import icnSalinityWater from '../icnWaterSalinity.png';
import icnSoilTemperature from '../icnTemperatureSoil.png';
import icnTemperatureShielded from '../icnTemperatureShielded.png';
import icnTensionHours from '../icnTensionHours.png';
import icnTemperatureUnshielded from '../icnTemperatureUnshielded.png';
import icnVpd from '../icnVpd.png';
import icnWaterDepth from '../icnWaterDepth.png';
import icnTension from '../icnTension.png';
import icnTotalVolume from '../icnTotalVolume.png';
import icnFlowmeter from '../icnFlowmeter.png';
import icnPump from '../icnPump.png';
import icnEtoDaily from '../icnEToDaily.png';
import icnWellDepth from '../icnWellDepth.png';

export const LOCAL_DATA_STREAM_ICONS = {
  CHILL_PORTIONS: {
    name: 'icn_chill_portions',
    icon: icnChillPortions,
  },
  CHILLING_HOURS: {
    name: 'icn_chilling_hours',
    icon: icnChillingHours,
  },
  DEC_AIR_TEMPERATURE: {
    name: 'icn_dec_air_temperature',
    icon: icnDecAirTemperature,
  },
  DEGREE_DAYS: {
    name: 'icn_degree_days',
    icon: icnDegreeDays,
  },
  DIELECTRIC_PERMITTIVITY: {
    name: 'icn_dielectric_permittivity',
    icon: icnDielectricPermittivity,
  },
  ELECTRICAL_CONDUCTIVITY: {
    name: 'icn_electrical_conductivity',
    icon: icnElectricalConductivity,
  },
  ETO_DAILY: {
    name: 'icn_eto_daily',
    icon: icnEtoDaily,
  },
  FLOW: {
    name: 'icn_flow',
    icon: icnFlowmeter,
  },
  LEAF_WETNESS: {
    name: 'icn_leaf_wetness',
    icon: icnLeafWetness,
  },
  LEAF_WETNESS_HOURS: {
    name: 'icn_leaf_wetness_hours',
    icon: icnLeafWetnessHours,
  },
  ROTTEN: {
    name: 'icn_rotten',
    icon: icnRotten,
  },
  PH_200MV: {
    name: 'icn_ph',
    icon: icnPH,
  },
  PIPE_PRESSURE: {
    name: 'icn_pipe_pressure',
    icon: icnPressure,
  },
  PORE_WATER_CONDUCTIVITY: {
    name: 'icn_pore_water_conductivity',
    icon: icnPoreWaterConductivity,
  },
  PUMPING_STATION: {
    name: 'icn_pumping_station',
    icon: icnPump,
  },
  DEW: {
    name: 'icn_dew_pt',
    icon: icnDewPt,
  },
  RAIN_FALL: {
    name: 'icn_rain_fall',
    icon: icnRain24H,
  },
  RELATIVE_HUMIDITY: {
    name: 'icn_relative_humidity',
    icon: icnRelativeHumidity,
  },
  SALINITY_WATER: {
    name: 'icn_salinity_water',
    icon: icnSalinityWater,
  },
  SHIELDED_TEMPERATURE: {
    name: 'icn_temperature_shielded',
    icon: icnTemperatureShielded,
  },
  SOIL_ELECTRICAL_CONDUCTIVITY: {
    name: 'icn_salinity_soil',
    icon: icnSalinitySoil,
  },
  SOIL_TEMPERATURE: {
    name: 'icn_soil_temperature',
    icon: icnSoilTemperature,
  },
  TENSION_HOURS: {
    name: 'icn_tension_hours',
    icon: icnTensionHours,
  },
  TOTAL_VOLUME: {
    name: 'icn_total_volume',
    icon: icnTotalVolume,
  },
  UNSHIELDED_TEMPERATURE: {
    name: 'icn_temperature_unshielded',
    icon: icnTemperatureUnshielded,
  },
  VPD: {
    name: 'icn_vpd',
    icon: icnVpd,
  },
  WATER_DEPTH: {
    name: 'icn_water_depth',
    icon: icnWaterDepth,
  },
  WATER_TENSION: {
    name: 'icn_water_tension',
    icon: icnTension,
  },
  WATER_TEMPERATURE: {
    name: 'icn_water_temperature',
    icon: icnTemperatureShielded,
  },
  WELL_DEPTH: {
    name: 'icn_well_depth',
    icon: icnWellDepth,
  },
};
