import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

// services
import COLORS from '../../colors';
import FONTS from '../../fonts';

// constants
import { ScheduleConstants } from '../../screens/schedule/ScheduleConstants';

// presenters
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';

// components
import BlockAlias from '../block/BlockAlias';

const Block = ({ expanded, block, testId }) => {
  const blockAliasPresenter = new BlockAliasPresenter(block);

  return (
    <View style={styles.leftLabelItem} testID={testId}>
      {expanded && (
        <>
          <View style={styles.blockNameOpened}>
            <Text style={styles.text} ellipsizeMode="middle" numberOfLines={2}>
              {blockAliasPresenter.name()}
            </Text>
          </View>
          <View style={styles.blockAliasContainer}>
            <BlockAlias alias={blockAliasPresenter.alias()} ellipsizeMode="tail" style={styles.leftLabelItemText} />
          </View>
        </>
      )}
      {!expanded && <BlockAlias alias={blockAliasPresenter.alias()} ellipsizeMode="tail" style={styles.leftLabelItemText} />}
    </View>
  );
};

Block.propTypes = {
  block: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  leftLabelItem: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: ScheduleConstants.CELL_HEIGHT,
    borderColor: ScheduleConstants.BORDER_COLOR,
    backgroundColor: COLORS.whiteTwo,
    borderBottomWidth: 1,
  },
  leftLabelItemText: {
    transform: [{ rotate: '270deg' }],
    width: ScheduleConstants.CELL_HEIGHT,
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    color: COLORS.greyishBrown,
    textAlignVertical: 'center',
  },
  blockNameOpened: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 5,
  },
  blockAliasContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },
  text: {
    marginLeft: 10,
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
});

export default Block;
