import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useSharedValue } from 'react-native-reanimated';

import PropTypes from 'prop-types';

// hooks
import { useBackHandlerRouteTo } from '../../hooks/useBackHandler';

// constants
import COLORS from '../../colors';

// services
import { BLOCK_ORDER } from '../../models/blockSorting';
import { IRRIGATION, TENSION } from '../../models/capability';
import { navigationShape } from '../../shapes/navigation';
import { SORT_ORDER, toggleSort } from '../../utils/sorting';

// styles
import { globalStyles } from '../../styles';

// components
import SkeletonPlaceholder from '../../components/skeletons/SkeletonPlaceholder';
import SortableList from '../../components/SortableList';
import IrrigationBlockItem from './IrrigationBlockItem';
import SortableIrrigationHeader from './SortableIrrigationHeader';
import { SORT_COLUMNS } from './sorting';

const IrrigationScreen = ({ navigation, route }) => {
  useBackHandlerRouteTo(navigation, route);
  const loading = useSelector((state) => !!state.loading.effects.blocks.loadIrrigationBlocksForCurrentSite);
  const sortLoading = useSelector((state) => !!state.loading.effects.blocks.sortIrrigationBlocks);
  const [refreshing, setRefreshing] = useState(false);
  const irrigationBlocks = useSelector((state) => state.blocks.sortedIrrigationBlocks);
  const irrigationAnimatedOpacity = useSharedValue(1);
  const listRef = useRef(undefined);
  const initialSortOrderState = {
    block: SORT_ORDER.NONE,
    irrigation: SORT_ORDER.NONE,
    blueBandIndex: SORT_ORDER.NONE,
    tension: SORT_ORDER.NONE,
  };
  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);
  const dispatch = useDispatch();

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setSortedColumn(initialSortOrderState);
      dispatch.blocks.loadIrrigationBlocksForCurrentSite({
        sortBlocksBy: BLOCK_ORDER,
        blockFilters: { capabilities: [TENSION, IRRIGATION] },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  useEffect(() => {
    sortIrrigationBlocksFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const sortIrrigationBlocksFromSortedColumn = () => {
    if (sortedColumn.block !== SORT_ORDER.NONE) {
      dispatch.blocks.sortIrrigationBlocks({ column: SORT_COLUMNS.BLOCK, order: sortedColumn.block });
    } else if (sortedColumn.irrigation !== SORT_ORDER.NONE) {
      dispatch.blocks.sortIrrigationBlocks({ column: SORT_COLUMNS.IRRIGATION, order: sortedColumn.irrigation });
    } else if (sortedColumn.blueBandIndex !== SORT_ORDER.NONE) {
      dispatch.blocks.sortIrrigationBlocks({ column: SORT_COLUMNS.BLUEBAND_INDEX, order: sortedColumn.blueBandIndex });
    } else if (sortedColumn.tension !== SORT_ORDER.NONE) {
      dispatch.blocks.sortIrrigationBlocks({ column: SORT_COLUMNS.TENSION, order: sortedColumn.tension });
    }
  };

  const loadAndSortBlocks = async () => {
    await dispatch.blocks.loadIrrigationBlocksForCurrentSite({
      sortBlocksBy: BLOCK_ORDER,
      blockFilters: { capabilities: [TENSION, IRRIGATION] },
    });
    sortIrrigationBlocksFromSortedColumn();
  };

  const refreshBlocks = async () => {
    setRefreshing(true);
    await loadAndSortBlocks();
    setRefreshing(false);
  };

  const renderIrrigationBlockItem = (element) => {
    return (
      <IrrigationBlockItem
        navigation={navigation}
        block={element.item}
        keyIndex={element.index}
        animatedOpacity={irrigationAnimatedOpacity}
      />
    );
  };

  const renderEmptyView = () => {
    return (
      <View testID="loadingActivity">
        {[...Array(5)].map((_e, i) => (
          <View style={styles.skeletonContainer} key={'row' + i}>
            <SkeletonPlaceholder width={57} height={43} />
            <View style={styles.skeletonRow}>
              <View style={styles.skeletonSectionSchedule}>
                <SkeletonPlaceholder width={90} height={15} />
              </View>
              <View style={styles.skeletonSectionBlueBand}>
                <SkeletonPlaceholder width={42} height={15} />
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const toggleSortAndScrollToTop = (payload) => {
    /* istanbul ignore next */
    if (listRef.current && !loading) {
      listRef.current.scrollToOffset({ x: 0, y: 0, animated: false });
      toggle(payload);
    }
  };

  return (
    <View testID="irrigation__subscreen-container" style={globalStyles.bottomContainer}>
      <SortableIrrigationHeader sortedColumn={sortedColumn} toggle={toggleSortAndScrollToTop} loading={sortLoading} />
      <SortableList
        testID={'irrigation__subscreen_list'}
        ref={listRef}
        elements={irrigationBlocks}
        loading={loading}
        refreshing={refreshing}
        sort={dispatch.blocks.sortIrrigationBlocks}
        renderEmptyView={renderEmptyView}
        renderItem={renderIrrigationBlockItem}
        screenName={'irrigation'}
        onRefresh={refreshBlocks}
      />
    </View>
  );
};

IrrigationScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      routeToGoBackTo: PropTypes.string,
    }),
  }),
};

const styles = StyleSheet.create({
  skeletonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderColor: COLORS.greyish21,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: COLORS.white,
  },
  skeletonSectionBlueBand: {
    justifyContent: 'center',
    width: 42,
    marginRight: 10,
  },
  skeletonSectionSchedule: {
    flexGrow: 1,
    justifyContent: 'center',
    width: '100%',
    marginLeft: 80,
    flexShrink: 1,
  },
  skeletonRow: {
    flexGrow: 1,
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
});

export default IrrigationScreen;
