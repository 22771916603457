import { gql } from 'graphql-tag';

export const FLOW_STATION_BLOCKS = gql`
  query flowstations($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        blocks(filters: { capabilities: [FLOW_STATION] }) {
          id
          name
          alias
          order
          capabilities {
            flowStation {
              isFlowing
              status
              flowmeters {
                description
                isFlowing
                totalVolume {
                  dataStream {
                    unit
                    value
                  }
                }
                outflow {
                  id
                  dataStream {
                    unit
                    value
                  }
                }
              }
            }
          }
          lastUpdated
        }
      }
    }
  }
`;
