import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

// service
import { iconForRule, translationForRule } from '../../../models/automationRuleType';

// components
import ListBox from '../../list/ListBox';
import AutomationRuleThresholds from './AutomationRuleThresholds';

const AutomationRuleItem = ({ enabled = true, keyIndex, onSelect, name, ruleID, ruleType, thresholdUp, thresholdDown, unit }) => {
  const { t } = useTranslation();
  const icon = iconForRule(ruleType, enabled);
  const type = t(translationForRule(ruleType));

  const selectRule = () => {
    onSelect(ruleID);
  };

  return (
    <ListBox
      testID={`automation__ruleItem-${keyIndex}`}
      customStyle={styles.listBox}
      onPress={selectRule}
      renderContent={() => (
        <View style={styles.container}>
          <View style={styles.columnIcon}>
            <Image source={icon} style={styles.icon} />
          </View>

          <View style={styles.columnType}>
            <Text style={styles.font}>{type}</Text>
          </View>

          <View style={styles.containerEnd}>
            <Text style={[styles.font, styles.columnName]}>{name}</Text>

            <AutomationRuleThresholds thresholdDown={thresholdDown} thresholdUp={thresholdUp} unit={unit} />
          </View>
        </View>
      )}
    />
  );
};

AutomationRuleItem.propTypes = {
  enabled: PropTypes.bool,
  keyIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  ruleID: PropTypes.string.isRequired,
  ruleType: PropTypes.string.isRequired,
  thresholdUp: PropTypes.number.isRequired,
  thresholdDown: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  columnIcon: {
    width: 23,
  },
  columnName: {
    maxWidth: '80%',
  },
  columnType: {
    width: '25%',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerEnd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  font: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
  },
  icon: {
    width: 18,
    height: 31,
  },
  listBox: {
    height: 68,
    paddingHorizontal: 11,
  },
});

export default AutomationRuleItem;
