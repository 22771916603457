import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';

//constants
import { TENSION_FORECAST } from './tensionForecast.graphql';

const fetchTensionForecast = async (siteId, blockId, startTimeStamp, endTimeStamp) => {
  const { loading, error, data } = await getApolloClient().query({
    query: TENSION_FORECAST,
    variables: {
      siteId,
      blockId,
      startTimeStamp,
      endTimeStamp,
    },
    context: { version: GRAPHQL_VERSION_V2 },
  });
  let tensionForecast, futureIrrigation;

  if (data) {
    tensionForecast = data?.viewer?.site?.block?.tensionForecast;
    futureIrrigation = data?.viewer?.site?.block?.futureIrrigation;
  }

  return {
    loading,
    error,
    tensionForecast,
    futureIrrigation,
  };
};

export { fetchTensionForecast };
