import React from 'react';
import { Image, StyleSheet, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';

// styles
import { globalStyles } from '../../styles';

// icons
import icnCancel from '../../assets/icons/icnCancel.png';
import icnSave from '../../assets/icons/icnCheck.png';
import icnTrash from '../../assets/icons/icnTrash.png';

// components
import Title from './Title';

const HeaderEdition = ({ title, subtitle, onCancel, onDelete, onSave, deleteEnabled = false }) => {
  return (
    <View style={globalStyles.headerContainer}>
      <View style={globalStyles.navSpacerContainer}>
        <Pressable style={styles.closeIcon} onPress={onCancel} testID="close-icon-button">
          <Image source={icnCancel} style={styles.icnCancel} />
        </Pressable>
      </View>

      <View style={globalStyles.headerTitle}>
        <Title value={title} type="title" />

        {subtitle ? <Title value={subtitle} type="subtitle" /> : null}
      </View>

      <View style={globalStyles.actionsSpacerContainer}>
        {deleteEnabled ? (
          <Pressable style={styles.deleteIcon} onPress={onDelete} testID="delete-icon-button">
            <Image source={icnTrash} style={styles.icnTrash} />
          </Pressable>
        ) : null}

        <Pressable style={styles.saveIcon} onPress={onSave} testID="save-icon-button">
          <Image source={icnSave} style={styles.icnSave} />
        </Pressable>
      </View>
    </View>
  );
};

HeaderEdition.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  deleteEnabled: PropTypes.bool,
};

const styles = StyleSheet.create({
  closeIcon: {
    paddingHorizontal: 13,
    paddingVertical: 13,
  },
  deleteIcon: {
    paddingHorizontal: 12,
    paddingVertical: 11,
  },
  saveIcon: {
    paddingHorizontal: 11,
    paddingVertical: 13,
  },
  icnCancel: {
    width: 18,
    height: 18,
  },
  icnTrash: {
    width: 19,
    height: 22,
  },
  icnSave: {
    width: 21,
    height: 18,
  },
});

export default HeaderEdition;
