// queries
import { fetchAutomations } from '../queries/automations';
import { fetchAutomationRules } from '../queries/automationRules';

// services
import { sortAutomationComponents } from '../screens/automation/sorting';
import { sortAutomationRules } from '../screens/automationRules/sorting';
import { executeApolloQuery } from './apolloHelper';

// entity
import AutomationEntity from './entities/automationEntity';
import AutomationRuleEntity from './entities/automationRuleEntity';
import { RULE_SORT_ORDER } from './automationRuleType';

export const automations = {
  state: {
    automationComponents: [],
    sortedAutomationComponents: [],
    selectedAutomationComponent: undefined,
    automationRules: [],
    sortedAutomationRules: [],
    selectedAutomationRule: undefined,
  },

  selectors: (slice) => ({
    hasAutomationRules() {
      return slice((automation) => automation.automationRules?.length > 0);
    },
    hasEnabledAutomationRules() {
      return slice((automation) => automation.automationRules?.some((rule) => rule.enabled));
    },
    subSensorsHaveRPM() {
      return slice((automation) =>
        automation.automationComponents.some((component) =>
          component.subSensorsCurrentData?.some((subSensor) => subSensor.unit === 'RPM'),
        ),
      );
    },
  }),

  reducers: {
    updateAutomationComponents(state, components) {
      const automationEntities = components ? components.map((component) => new AutomationEntity(component)) : [];

      return { ...state, automationComponents: automationEntities, sortedAutomationComponents: automationEntities };
    },
    updateAutomationRules(state, rules) {
      let automationRules = rules ? rules.filter((rule) => rule !== undefined).map((rule) => new AutomationRuleEntity(rule)) : [];
      automationRules = automationRules.filter((rule) => RULE_SORT_ORDER.includes(rule.ruleType));

      return { ...state, automationRules, sortedAutomationRules: automationRules };
    },
    updateSortedAutomationComponents(state, sortedAutomationComponents) {
      return { ...state, sortedAutomationComponents };
    },
    updateSortedAutomationRules(state, sortedAutomationRules) {
      return { ...state, sortedAutomationRules };
    },
    updateSelectedAutomationComponent(state, component) {
      return { ...state, selectedAutomationComponent: component };
    },
    updateSelectedAutomationRule(state, id) {
      const selectedAutomationRule = id ? state.automationRules.find((automationRule) => automationRule.id === id) : null;

      return { ...state, selectedAutomationRule };
    },
  },

  effects: (dispatch) => ({
    async loadAutomationComponents(payload, rootState) {
      await this.loadAutomationRules(payload, rootState);
      executeApolloQuery(
        dispatch,
        async () => await fetchAutomations(rootState.site.currentSite.id),
        (data) => dispatch.automations.updateAutomationComponents(data.automations),
      );
    },

    async loadAutomationRules(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchAutomationRules(rootState.site.currentSite.id),
        (data) => dispatch.automations.updateAutomationRules(data.automationRules),
      );
    },

    async sortAutomationComponents(payload, rootState) {
      const { column, order } = payload;
      const sortedComponents = [...rootState.automations.automationComponents];
      sortAutomationComponents(sortedComponents, column, order);
      dispatch.automations.updateSortedAutomationComponents(sortedComponents);
    },

    async sortAutomationRules(payload, rootState) {
      const { column, order } = payload;
      const sortedRules = [...rootState.automations.automationRules];
      sortAutomationRules(sortedRules, column, order);
      dispatch.automations.updateSortedAutomationRules(sortedRules);
    },

    async reset() {
      dispatch.automations.updateAutomationComponents([]);
      dispatch.automations.updateAutomationRules([]);
    },
  }),
};
