// queries
import { fetchReport } from '../queries/report';

// services
import { executeApolloQuery } from './apolloHelper';

export const report = {
  state: {
    defaultTabIndex: 1,
    volumeUnit: '',
    statistics: [],
  },

  reducers: {
    setReportTabIndex(state, index) {
      return { ...state, defaultTabIndex: index };
    },

    updateStatistics(state, site) {
      const volumeUnit = site?.report?.volumeUnit || state.volumeUnit; // For Hortau Staff only

      return { ...state, statistics: site.mainSwitchStats, volumeUnit };
    },
  },

  effects: (dispatch) => ({
    async loadStatistics({ startDate, endDate, groupBy }, rootState) {
      const isHortau = rootState.user.currentUser?.userAccount?.isHortauStaff === true;

      await executeApolloQuery(
        dispatch,
        async () => await fetchReport(rootState.site.currentSite.id, startDate, endDate, groupBy, isHortau),
        (data) => dispatch.report.updateStatistics(data.site),
      );
    },
  }),
};
