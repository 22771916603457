import { useReducer } from 'react';
import moment from 'moment-timezone';
import { NAVIGATION, WEEKLY_TAB, MONTHLY_TAB, getPeriod, setDatatable } from '@hortau/knit';

// services
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useTranslation } from '../../hooks/useTranslation';

// constant
import { DataStreamButtonPresenter } from '../../presenters/DataStreamButtonPresenter';
import { EVENT_ACTIONS } from './eventActions';

const TABS = [WEEKLY_TAB, MONTHLY_TAB];

const getVolumeUnitTranslationKey = (volumeUnit) => {
  const volumeUnitKey = volumeUnit.split('__');
  return volumeUnitKey.length > 1 && volumeUnitKey[1] !== '' ? `unit_${volumeUnitKey[1].toLowerCase()}` : '';
};

const useReport = ({ defaultTabIndex, volumeUnit = '', isHortau = false }) => {
  const analyticsService = useAnalyticsContext();
  const { t } = useTranslation();
  const periodInfos = getPeriod(defaultTabIndex, moment(), moment, NAVIGATION.CURRENT);
  const tabNames = TABS.map((tab) => t(tab.key));

  const volumeUnitKey = getVolumeUnitTranslationKey(volumeUnit);
  let volumeUnitLabel = t(volumeUnitKey);
  volumeUnitLabel = volumeUnitLabel !== '' && volumeUnitLabel !== volumeUnitKey ? `(${volumeUnitLabel})` : '';

  const labelsAlpha = [t('report_app_rate'), t('report_total_volume', volumeUnitLabel)];
  const totalLabels = isHortau ? [t('report_total_hours'), ...labelsAlpha] : [t('report_total_hours')];

  const initialState = {
    nextPeriodDisabled: true,
    previousPeriodDisabled: false,
    selectedTabIndex: defaultTabIndex,
    selectedPeriod: periodInfos.selectedPeriod,
    selectedPeriodText: periodInfos.selectedPeriodText,
    startDatePeriod: periodInfos.startDate,
    endDatePeriod: periodInfos.endDate,
    groupBy: periodInfos.groupBy,
    tabNames,
    scrollToColIndex: 0,
    tableRowHeader: [],
    tableColHeader: [],
    tableData: undefined,
    tableTotals: [],
  };

  const initializeState = () => ({ ...initialState });

  function processPayload(action) {
    action.payload.forEach((statistic) => {
      statistic.mainSwitches.forEach((mainSwitch) => {
        let presenter;
        mainSwitch.dataStreamType = mainSwitch.type;
        mainSwitch.isVirtual = false;
        presenter = new DataStreamButtonPresenter(mainSwitch, t);
        mainSwitch.translatedType = presenter.getDescription();
      });
    });
  }

  const manageReportReducer = (state, action) => {
    switch (action.type) {
      case EVENT_ACTIONS.SET_DATATABLE: {
        processPayload(action);

        const { rowHeader, colHeader, data, totals, nowIndex } = setDatatable(
          action.payload,
          state.startDatePeriod,
          state.endDatePeriod,
          state.selectedTabIndex,
          totalLabels,
          moment,
          isHortau,
          true,
        );

        return {
          ...state,
          tableRowHeader: rowHeader,
          tableColHeader: colHeader,
          tableData: data,
          tableTotals: totals,
          scrollToColIndex: nowIndex,
        };
      }
      case EVENT_ACTIONS.SET_TAB_INDEX: {
        const index = action.payload;
        const newActiveTab = TABS.find((tab) => tab.index === index);
        const { nextPeriodDisabled, previousPeriodDisabled, selectedPeriod, selectedPeriodText, startDate, endDate, groupBy } =
          getPeriod(index, state.selectedPeriod, moment, NAVIGATION.CURRENT);
        analyticsService.trackEvent(newActiveTab.analyticsEvent);

        return {
          ...state,
          selectedTabIndex: index,
          nextPeriodDisabled,
          previousPeriodDisabled,
          selectedPeriod,
          selectedPeriodText,
          startDatePeriod: startDate,
          endDatePeriod: endDate,
          groupBy,
          tableRowHeader: [],
          tableColHeader: [],
          tableData: undefined,
          tableTotals: [],
        };
      }
      case EVENT_ACTIONS.SET_NEXT_PERIOD: {
        const { nextPeriodDisabled, previousPeriodDisabled, selectedPeriod, selectedPeriodText, startDate, endDate, groupBy } =
          getPeriod(state.selectedTabIndex, state.selectedPeriod, moment, NAVIGATION.NEXT);

        return {
          ...state,
          nextPeriodDisabled,
          previousPeriodDisabled,
          selectedPeriod,
          selectedPeriodText,
          startDatePeriod: startDate,
          endDatePeriod: endDate,
          groupBy,
          tableRowHeader: [],
          tableColHeader: [],
          tableData: undefined,
          tableTotals: [],
        };
      }
      case EVENT_ACTIONS.SET_PREVIOUS_PERIOD: {
        const { nextPeriodDisabled, previousPeriodDisabled, selectedPeriod, selectedPeriodText, startDate, endDate, groupBy } =
          getPeriod(state.selectedTabIndex, state.selectedPeriod, moment, NAVIGATION.PREVIOUS);

        return {
          ...state,
          nextPeriodDisabled,
          previousPeriodDisabled,
          selectedPeriod,
          selectedPeriodText,
          startDatePeriod: startDate,
          endDatePeriod: endDate,
          groupBy,
          tableRowHeader: [],
          tableColHeader: [],
          tableData: undefined,
          tableTotals: [],
        };
      }
      case EVENT_ACTIONS.RESET: {
        return {
          ...initialState,
          selectedTabIndex: defaultTabIndex,
        };
      }
      default: {
        return state;
      }
    }
  };

  return useReducer(manageReportReducer, initialState, initializeState);
};

export default useReport;
