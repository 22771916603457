import { getApolloClient } from '../clients/apollo';
import { HEALTH_DATA } from '../models/dataStreamTypes';
import { DEVICE } from './device.graphql';
import { DEVICES } from './devices.graphql';

const fetchDevice = async (siteId, deviceId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: DEVICE,
    variables: { siteId, deviceId, dataStreamTypes: HEALTH_DATA },
  });

  let device;
  if (data) {
    device = data.viewer.site.device;
  }

  return {
    loading,
    error,
    device,
  };
};

const fetchDevices = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: DEVICES,
    variables: { siteId, dataStreamTypes: HEALTH_DATA },
  });

  let devices;
  if (data) {
    devices = data.viewer.site.devices;
  }

  return {
    loading,
    error,
    devices,
  };
};

export { fetchDevice, fetchDevices };
