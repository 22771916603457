import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';

// icon
import icnSchedule from '../../../assets/icons/icnSchedule.png';

// proptype
import BlockEntity from '../../../models/entities/blockEntity';
import { navigationShape } from '../../../shapes/navigation';
import { styleShape } from '../../../shapes/style';

// services
import { useTranslation } from '../../../hooks/useTranslation';
import { useAnalyticsContext } from '../../initialization/AnalyticsProvider';
import ANALYTICS from '../../../services/AnalyticsEvents';

// constants
import COLORS from '../../../colors';
import ROUTES from '../../../navigation/routes';

// components
import ShadowedDivider from '../../ShadowedDivider';
import EmptySectionView from '../../EmptySectionView';
import BlockDetailComponentHeader from '../BlockDetailComponentHeader';
import IrrigationActivity from './IrrigationActivity';

const BlockDetailIrrigation = ({ block, containerStyle, navigation }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const irrigationCapability = block.irrigationCapability();
  const irrigationAnimatedOpacity = useSharedValue(1);

  const onPressHeaderLink = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockSchedule);
    navigation.navigate(ROUTES.BLOCK_EVENT_LIST, { block });
  };

  const createCurrentOrNextActivity = () => {
    if (irrigationCapability.hasCurrentEvent()) {
      return (
        <IrrigationActivity
          event={irrigationCapability.currentEvent()}
          titleText={t('block_detail_current_schedule')}
          isDateVisible={false}
          navigation={navigation}
          animatedOpacity={irrigationAnimatedOpacity}
        />
      );
    }
    return (
      <IrrigationActivity
        event={irrigationCapability.nextEvent()}
        titleText={t('block_detail_next_schedule')}
        navigation={navigation}
        animatedOpacity={irrigationAnimatedOpacity}
      />
    );
  };

  const createActivitiesSection = () => {
    if (irrigationCapability.hasEvents()) {
      return (
        <View style={styles.activitySection}>
          {irrigationCapability.hasPreviousEvent() && (
            <View>
              <ShadowedDivider />
              <IrrigationActivity
                event={irrigationCapability.previousEvent()}
                titleText={t('block_detail_previous_schedule')}
                navigation={navigation}
                animatedOpacity={irrigationAnimatedOpacity}
              />
            </View>
          )}
          {irrigationCapability.hasCurrentOrNextEvent() ? (
            <View>
              <ShadowedDivider />
              {createCurrentOrNextActivity()}
            </View>
          ) : null}
        </View>
      );
    }
    return (
      <View style={styles.activitySection}>
        <ShadowedDivider />
        <View style={styles.emptySection}>
          <EmptySectionView
            icon={icnSchedule}
            iconSizeStyle={styles.scheduleIconSize}
            textKey="block_detail_no_schedule"
            textColor={styles.noScheduleTextColor}
          />
        </View>
      </View>
    );
  };

  return (
    <View style={[containerStyle, styles.container]} testID="block-details__irrigation-container">
      <BlockDetailComponentHeader
        handleOnPress={onPressHeaderLink}
        linkName={t('see_activities')}
        buttonTestId={'block-details__irrigation-activities-button'}
        title={t('block_detail_irrigation_title')}
      />
      {createActivitiesSection()}
    </View>
  );
};

BlockDetailIrrigation.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  containerStyle: styleShape,
  navigation: navigationShape,
};

const styles = StyleSheet.create({
  activitySection: {
    justifyContent: 'center',
    backgroundColor: COLORS.white,
  },
  container: {
    flex: 1,
  },
  emptySection: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  noScheduleTextColor: {
    color: COLORS.brownGreyOpaque,
  },
  scheduleIconSize: {
    width: 26,
    height: 26,
  },
});

export default BlockDetailIrrigation;
