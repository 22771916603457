const filterBlocksWithAutomation = (eventBlocks) => {
  return eventBlocks?.filter((b) => b.automationId);
};

const filterBlocksWithoutAutomation = (eventBlocks) => {
  return eventBlocks?.filter((b) => !b.automationId);
};

const filterOnlyVirtualBlocks = (eventBlocks) => {
  return eventBlocks?.filter((b) => b.isVirtual);
};

const filterNonVirtualBlocks = (eventBlocks) => {
  return eventBlocks?.filter((b) => b.isVirtual !== true);
};

const areBlocksWithoutAutomationAreSelected = (eventBlocks) => {
  return filterBlocksWithoutAutomation(eventBlocks)?.length !== 0;
};

const areVirtualBlocksSelected = (eventBlocks) => {
  return filterOnlyVirtualBlocks(eventBlocks)?.length !== 0;
};

export { filterBlocksWithAutomation, areBlocksWithoutAutomationAreSelected, areVirtualBlocksSelected, filterNonVirtualBlocks };
