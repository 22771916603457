import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Image } from 'react-native';

// constants
import COLORS from '../../colors';

const TouchIconButton = ({ icon, onPress, testId, iconStyle, disabled = false }) => {
  const [isIconPressed, setIsIconPressed] = useState(false);

  const handleOnPress = () => {
    if (!disabled) {
      onPress();
    }
  };

  return (
    <Pressable
      style={styles.buttonStyle}
      disabled={disabled}
      testID={testId}
      onPress={handleOnPress}
      onPressIn={() => setIsIconPressed(true)}
      onPressOut={() => setIsIconPressed(false)}>
      <Image
        source={icon}
        style={[styles.baseIcon, iconStyle, isIconPressed && styles.iconPressed, disabled && styles.disabled]}
      />
    </Pressable>
  );
};

TouchIconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string,
  iconStyle: PropTypes.object,
};

const styles = StyleSheet.create({
  baseIcon: {
    tintColor: COLORS.pinkishGrey,
  },
  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44,
  },
  iconPressed: {
    tintColor: COLORS.greyishBrown,
  },
  disabled: {
    tintColor: COLORS.disabled,
  },
});

export default TouchIconButton;
