import { useReducer } from 'react';

// models
import { translationForRule } from '../../models/automationRuleType';
import { useTranslation } from '../../hooks/useTranslation';
import { EVENT_ACTIONS } from './eventActions';

const useAutomationRule = ({ ruleDetail }) => {
  const { t } = useTranslation();
  const ruleDetailCopy = JSON.parse(JSON.stringify(ruleDetail));

  if (ruleDetail?.cycling?.cyclingDurationMoment) {
    ruleDetailCopy.cycling.cyclingDurationMoment = ruleDetail?.cycling?.cyclingDurationMoment.clone();
  }

  const initialState = {
    ...ruleDetailCopy,
    translatedTypeName: t(translationForRule(ruleDetailCopy?.ruleType)),
    translatedUnit: t(`unit_${ruleDetailCopy?.unit.toLowerCase()}`),
  };

  const initializeState = () => {
    return {
      ...initialState,
    };
  };

  const automationRuleReducer = (state, action) => {
    switch (action.type) {
      case EVENT_ACTIONS.SET_RESTART_ENABLED: {
        const cycling = state.cycling;
        cycling.enabled = action.payload;

        return { ...state, cycling };
      }
      case EVENT_ACTIONS.SET_RESTART_VALUE: {
        const cycling = state.cycling;
        cycling.cyclingRestartTrigger = action.payload;

        return { ...state, cycling };
      }
      case EVENT_ACTIONS.SET_RUN_FOR_VALUE: {
        const cycling = state.cycling;
        cycling.cyclingDurationMoment = action.payload;
        cycling.cyclingDuration = cycling.cyclingDurationMoment.asSeconds();

        return { ...state, cycling };
      }
      case EVENT_ACTIONS.SET_RULE_ENABLED: {
        return { ...state, enabled: action.payload };
      }
      case EVENT_ACTIONS.SET_START_VALUE: {
        return { ...state, startValue: action.payload };
      }
      case EVENT_ACTIONS.SET_STOP_VALUE: {
        return { ...state, stopValue: action.payload };
      }
      case EVENT_ACTIONS.RESET: {
        return initializeState();
      }
      default: {
        return state;
      }
    }
  };

  return useReducer(automationRuleReducer, initialState, initializeState);
};

export default useAutomationRule;
