import moment from 'moment-timezone';

// services
import { width } from '../../services/ScreenRatioService';
import COLORS from '../../colors';
import { isIos } from '../../services/PlatformService';

const numberOfWeeksToLoadBySide = 20;

const closedBlockPanelWidth = 46;

const groupItemWidthClosedPanel = 41;
const groupItemWidthOpenedPanel = 193;

const blockItemWidthClosedPanel = 41;
const blockItemWidthOpenedPanel = 143;

const getWithoutGroupsBlockPanelWidth = (expanded) => (expanded ? blockItemWidthOpenedPanel : closedBlockPanelWidth);

const getWithGroupsBlockPanelWidth = (expanded) => (expanded ? groupItemWidthOpenedPanel : closedBlockPanelWidth);

const getGroupItemWidth = (expanded) => (expanded ? groupItemWidthOpenedPanel : groupItemWidthClosedPanel);

const getBlockItemWidth = (expanded) => (expanded ? blockItemWidthOpenedPanel : blockItemWidthClosedPanel);

const getCurrentWeekStart = () => moment().startOf('isoWeek');

const getScheduleEndDate = () =>
  getCurrentWeekStart()
    .clone()
    .add({ weeks: numberOfWeeksToLoadBySide + 1 });

const getScheduleStartDate = () => getCurrentWeekStart().clone().subtract({ weeks: numberOfWeeksToLoadBySide });

const getCurrentWeekEnd = () => getCurrentWeekStart().clone().add(1, 'week');

const getInitialScrollIndex = () => getCurrentWeekStart().diff(getScheduleStartDate(), 'days');

const topLabelHeight = isIos() ? 56 : 60;
const daysInWeek = 7;
const cellWidth = (width - closedBlockPanelWidth) / daysInWeek;

const ScheduleConstants = {
  BORDER_COLOR: COLORS.paleGreyFour,
  CELL_HEIGHT: 80,
  CELL_WIDTH: cellWidth,
  DAYS_IN_A_WEEK: daysInWeek,
  DEFAULT_BLOCK_PANEL_WIDTH: closedBlockPanelWidth,
  TOTAL_WEEKS: numberOfWeeksToLoadBySide * daysInWeek * 2,
  TOP_LABEL_HEIGHT: topLabelHeight,
  MAX_WIDTH: cellWidth * 7,
};

export {
  getBlockItemWidth,
  getCurrentWeekStart,
  getCurrentWeekEnd,
  getGroupItemWidth,
  getInitialScrollIndex,
  getScheduleEndDate,
  getScheduleStartDate,
  getWithoutGroupsBlockPanelWidth,
  getWithGroupsBlockPanelWidth,
  ScheduleConstants,
};
