import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// utils
import { formatMoment, formattedTimeDifferenceForGraph } from '../../utils/dateUtils';
import { isNullOrUndefined } from '../../utils/util';

// components
import ProgressBar from './ProgressBar';

const ProgressBarDuration = ({ startDate, endDate }) => {
  const now = moment();
  const nowInMS = now.valueOf();
  const nowInString = formatMoment(now);
  const startDateInMS = moment(startDate).valueOf();
  const endDateInMS = moment(endDate).valueOf();

  const isStillRunning = endDateInMS > nowInMS;
  const elapsed = formattedTimeDifferenceForGraph(startDate, nowInString);
  const remaining = isStillRunning ? formattedTimeDifferenceForGraph(nowInString, endDate) : '0min';
  const progress = isStillRunning ? (nowInMS - startDateInMS) / (endDateInMS - startDateInMS) : 1;
  const value = isNullOrUndefined(endDate) ? 0 : progress;

  return <ProgressBar labelStart={elapsed} labelEnd={remaining} value={value} />;
};

ProgressBarDuration.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default ProgressBarDuration;
