/* istanbul ignore file */
import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import Config from 'react-native-config';

// services
import { initStore } from './models/store';
import { initApolloClient } from './clients/apollo';
import AppContainer from './navigation/navigationStack';
import './i18n/i18n';
import * as EnvironmentService from './services/EnvironmentService';
import { initAnalytics } from './services/AnalyticsTrackingService';
import useAppState from './hooks/useAppState';
import CancellableManager from './utils/CancellableManager';
import ignoreLogs from './ignoreLogs';

// components
import Bootstrapper from './components/initialization/Bootstrapper';
import { AnalyticsProvider } from './components/initialization/AnalyticsProvider';
import { MapBoxBootstrapper } from './components/initialization/MapBoxBootstrapper';

const trackingService = initAnalytics(Config.GAINSIGHT_API_TOKEN);

const store = initStore();

const App = () => {
  ignoreLogs();

  const { currentAppState, previousAppState } = useAppState();

  useEffect(() => {
    if (previousAppState !== 'active') {
      return;
    }

    if (currentAppState.match(/inactive|background/)) {
      CancellableManager.cancelAll();
    }
  }, [currentAppState, previousAppState]);

  if (EnvironmentService.isDebug()) {
    console.reportErrorsAsExceptions = false;
  }

  return (
    <ApolloProvider client={initApolloClient(store)}>
      <Provider store={store}>
        <AnalyticsProvider analyticsService={trackingService}>
          <Bootstrapper />
          <MapBoxBootstrapper accessToken={Config.MAPBOX_KEY} />
          <AppContainer />
        </AnalyticsProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
