//constants
import * as BlockType from '../blockType';
import * as TemperatureUnits from '../units/temperatureUnits';
import * as WindSpeedUnits from '../units/windSpeedUnits';
import { COMMUNICATION_ERROR } from '../status/weatherStationStatus';

//services
import { getWindDirectionName } from '../windDirections';
import { isNullOrUndefined } from '../../utils/util';

export default class WeatherStationCapability {
  constructor(weather) {
    this.weather = weather;
  }

  blockType() {
    return BlockType.WEATHER;
  }

  status() {
    return this.weather?.status;
  }

  formattedAirTemperature() {
    const status = this.weather?.status;
    const airTemperature = this.weather?.airTemperature;

    if (!airTemperature) {
      return null;
    }

    const unit = TemperatureUnits[airTemperature.unit].displayValue;

    const hasValue = !isNullOrUndefined(airTemperature.value);

    if (status !== COMMUNICATION_ERROR && hasValue) {
      return `${airTemperature.value}${unit}`;
    } else {
      return null;
    }
  }

  formattedWindSpeed(t) {
    const status = this.weather?.status;
    const windSpeed = this.weather?.windSpeed;

    if (!windSpeed) {
      return null;
    }

    const unit = t(WindSpeedUnits[windSpeed.unit].translationReference);

    const hasValue = windSpeed.value !== null && windSpeed.value !== undefined;

    if (status !== COMMUNICATION_ERROR && hasValue) {
      return `${windSpeed.value} ${unit}`;
    } else {
      return null;
    }
  }

  formattedWindDirection(t) {
    const status = this.weather?.status;
    const windDirection = this.weather?.windDirection;

    if (!windDirection) {
      return null;
    }

    const hasValue = windDirection.value !== null && windDirection.value !== undefined;

    if (status !== COMMUNICATION_ERROR && hasValue) {
      return t(getWindDirectionName(windDirection.value));
    } else {
      return null;
    }
  }
}
