import React from 'react';
import { Pressable, Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// icons
import menuIcon from '../../assets/icons/icnMenu.png';

// constants
import { navigationShape } from '../../shapes/navigation';
import OPACITIES from '../../opacities';

// services
import { isAndroid } from '../../services/PlatformService';

const BurgerMenuButton = ({ screenName, navigation, androidOnly = false }) => {
  if (androidOnly && !isAndroid()) {
    return null;
  }

  return (
    <Pressable
      style={
        /* istanbul ignore next */ ({ pressed }) => [pressed ? { opacity: OPACITIES.pressedButton } : {}, styles.imageContainer]
      }
      onPress={() => navigation.toggleDrawer()}
      testID={`${screenName}-header__menu`}>
      <Image source={menuIcon} style={styles.menuIcon} />
    </Pressable>
  );
};

BurgerMenuButton.propTypes = {
  screenName: PropTypes.string.isRequired,
  navigation: navigationShape.isRequired,
  androidOnly: PropTypes.bool,
};

const styles = StyleSheet.create({
  imageContainer: {
    paddingHorizontal: 11,
    paddingVertical: 14,
  },
  menuIcon: {
    width: 22,
    height: 16,
  },
});

export default BurgerMenuButton;
