import { isIos } from './services/PlatformService';

const FONTS = {
  firaSans: 'FiraSans-Regular',
  firaSansItalic: 'FiraSans-Italic',
  firaSansBold: 'FiraSans-Bold',
  firaSansLight: 'FiraSans-Light',
  firaSansMedium: 'FiraSans-Medium',
  notoSans: 'NotoSans-Regular',
  notoSansBold: 'NotoSans-Bold',
  notoSansItalic: 'NotoSans-Italic',
  openSansBold: 'Open Sans Bold',
  monospace: isIos() ? 'Courier' : 'monospace',
};

export default FONTS;
