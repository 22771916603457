// constants
import COLORS from '../../colors';

//entity
import { BlueBandIndex } from './blueBandIndex';

export const BLUE_BAND_INDEX_TYPE = {
  dry: 'dry',
  good: 'good',
  wet: 'wet',
};
export default class BlueBandIndexStatuses {
  constructor(blueBandIndex) {
    this.blueBandIndex = blueBandIndex;
  }

  dry() {
    return this.blueBandIndex ? this.blueBandIndex.dry : null;
  }

  good() {
    return this.blueBandIndex ? this.blueBandIndex.good : null;
  }

  wet() {
    return this.blueBandIndex ? this.blueBandIndex.wet : null;
  }

  statuses(translation) {
    let statuses = {};

    if (this.blueBandIndex) {
      const dry = new BlueBandIndex(
        translation('blue_band_index_dry'),
        COLORS.dustyOrange,
        this.blueBandIndex.dry,
        BLUE_BAND_INDEX_TYPE.dry,
      );
      const good = new BlueBandIndex(
        translation('blue_band_index_good'),
        COLORS.emerald,
        this.blueBandIndex.good,
        BLUE_BAND_INDEX_TYPE.good,
      );
      const wet = new BlueBandIndex(
        translation('blue_band_index_wet'),
        COLORS.havelockBlue,
        this.blueBandIndex.wet,
        BLUE_BAND_INDEX_TYPE.wet,
      );

      statuses = { dry, good, wet };
    }
    return statuses;
  }

  formattedValues(translation) {
    if (this.blueBandIndex) {
      const dry = createBlueBandIndexString(translation, this.blueBandIndex.dry);
      const good = createBlueBandIndexString(translation, this.blueBandIndex.good);
      const wet = createBlueBandIndexString(translation, this.blueBandIndex.wet);
      return { dry, good, wet };
    }
    const stringNA = translation('blue_band_index_na');
    return {
      dry: stringNA,
      good: stringNA,
      wet: stringNA,
    };
  }
}

function createBlueBandIndexString(translation, value) {
  if (value !== null) {
    return `${value} %`;
  }
  return translation('blue_band_index_na');
}
