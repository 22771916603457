import { getApolloClient } from '../clients/apollo';
import { IRRIGATION_BLOCK } from './irrigationBlock.graphql';

const fetchIrrigationBlock = async (siteId, blockId, startDate, endDate) => {
  const { loading, error, data } = await getApolloClient().query({
    query: IRRIGATION_BLOCK,
    variables: {
      siteId,
      blockId,
      startDate,
      endDate,
    },
  });

  let irrigationBlock;
  if (data) {
    irrigationBlock = data.viewer.site.block;
  }

  return {
    loading,
    error,
    irrigationBlock,
  };
};

export { fetchIrrigationBlock };
