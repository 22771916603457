export const EVENT_START_TOO_SOON_CODE = 5403;

export const getEventErrorTranslationKey = (errorCode) => {
  switch (errorCode) {
    case 1003:
      return 'error_access_denied';
    case 2006:
      return 'edit_event_error_maximum_event_duration_exceeded';
    case 2007:
      return 'edit_event_error_event_date_is_passed';
    case 2008:
      return 'edit_event_error_event_must_stay_in_past_or_future';
    case 2009:
      return 'edit_event_error_events_overlapping';
    case 2014:
      return 'edit_event_error_maximum_anytime_events_per_day';
    case 3003:
      return 'edit_event_error_minimum_event_duration_failed';
    case 3004:
      return 'edit_event_error_no_blocks_selected';
    case 3007:
      return 'edit_event_error_started_events_overlapping';
    case 5402:
      return 'edit_event_error_events_overlapping';
    case EVENT_START_TOO_SOON_CODE:
      return 'event_error_start_too_soon';
    case 5404:
      return 'edit_event_error_event_date_is_passed';
    case 5405:
      return 'edit_event_error_maximum_event_duration_exceeded';
    case 5406:
      return 'edit_event_error_minimum_event_duration_failed';
    case 5407:
      return 'event_error_invalid_type';
    case 5410:
      return 'edit_event_error_no_blocks_selected';
    case 5412:
      return 'edit_event_invalid_start_date';
    case 5413:
      return 'edit_event_invalid_end_date';
    case 5415:
      return 'event_error_automation_configuration_broken';
    default:
      return 'edit_event_error_generic';
  }
};
