import React from 'react';
import { StyleSheet, Pressable, View } from 'react-native';
import { useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// services
import { formattedTimeDifferenceForSchedule } from '../../../utils/dateUtils';
import { useTranslation } from '../../../hooks/useTranslation';

// constants
import COLORS from '../../../colors';

// models
import IrrigationEvent from '../../../models/entities/irrigationEvent';
import { AUTOMATED_PLANNED, findEventLabel, findEventType } from '../../../models/calendarType';

// styles
import { globalStyles } from '../../../styles';

// components
import Divider from '../../Divider';
import EventItemDescription from './EventItemDescription';
import EventItemDividerAndNumberOfDays from './EventItemDividerAndNumberOfDays';
import EventItemDuration from './EventItemDuration';
import EventItemTime from './EventItemTime';
import EventItemIcon from './EventItemIcon';
import EventItemStatisticItem from './EventItemStatisticItem';

const EventItem = ({ event, editingDisabled, navigateToManageEvent, animatedOpacity }) => {
  const { t } = useTranslation();
  const store = useStore();

  const canModifyAutomationEvents = useSelector(store.select.user.hasModifyAutomationEventsPermission);
  const automationEventConstraints = useSelector(store.select.site.getAutomationEventConstraints);
  const minimumAllowedStartDelayFromNowInMinutes = automationEventConstraints?.minimumAllowedStartDelayFromNowInMinutes;

  const startTime = event.anytimeDayEvent ? t('anytime') : event.startDate.format('LT');
  const endTime = event.isActive || event.anytimeDayEvent ? '' : event.endDate.format('LT');

  const momentStartDate = event.startDate.clone().startOf('day');
  const momentEndDate = event.endDate.clone().add(-1, 'minutes').startOf('day');
  const numberOfDays = momentEndDate.diff(momentStartDate, 'days');

  const eventType = findEventType(event);
  const isAutomatedEvent = eventType === AUTOMATED_PLANNED;

  const eventTitle = 'irrigation';
  const eventSubtitle = findEventLabel(eventType);

  const isEventActive = event.isActive;

  const duration = formattedTimeDifferenceForSchedule(event.duration);

  const hasStatistics = event.statistics?.length > 0;

  const modalDisabled = () => {
    if (editingDisabled) {
      return true;
    }

    const now = moment();
    const automatedEventDelay = now.clone().add(minimumAllowedStartDelayFromNowInMinutes, 'minutes');

    if (isAutomatedEvent) {
      if (!canModifyAutomationEvents) {
        return true;
      }

      return event.endDate.isBefore(now) || (event.startDate.isAfter(now) && event.startDate.isBefore(automatedEventDelay));
    }

    return event.startDate.isBefore(now) && !(event.anytimeDayEvent && event.startDate.isSame(now, 'day'));
  };

  const handlePress = () => {
    navigateToManageEvent(event);
  };

  return (
    <Pressable
      style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.container]}
      disabled={modalDisabled()}
      onPress={handlePress}
      testID={'event__edit-event-button'}>
      <View style={styles.detail}>
        <EventItemTime startTime={startTime} endTime={endTime} />

        <EventItemDividerAndNumberOfDays eventType={eventType} numberOfDays={numberOfDays} />

        <EventItemIcon eventType={eventType} isEventActive={isEventActive} animatedOpacity={animatedOpacity} />

        <EventItemDescription title={eventTitle} subtitle={eventSubtitle} />

        <EventItemDuration duration={duration} isEventActive={isEventActive} />
      </View>

      {hasStatistics ? (
        <View style={styles.statisticContainer}>
          <Divider style={styles.divider} />

          {event.statistics.map((statistic) => (
            <EventItemStatisticItem key={statistic.id} statistic={statistic} />
          ))}
        </View>
      ) : null}
    </Pressable>
  );
};

EventItem.propTypes = {
  editingDisabled: PropTypes.bool,
  event: PropTypes.instanceOf(IrrigationEvent),
  navigateToManageEvent: PropTypes.func,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginHorizontal: 6,
    marginVertical: 3,
    backgroundColor: COLORS.whiteTwo,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 54,
  },
  divider: {
    left: '2%',
    width: '94%',
  },
  statisticContainer: {
    justifyContent: 'center',
  },
});

export default React.memo(EventItem);
