import React, { useState } from 'react';
import { View, Pressable, StyleSheet, Image, Text } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// icons
import icnScheduleToday from '../../assets/icons/icnScheduleToday.png';

// styles
import { MINIMUM_FONT_SCALE } from '../../styles.js';

const TodayButton = ({ onPress, testId, disabled = false }) => {
  const [isIconPressed, setIsIconPressed] = useState(false);
  const today = moment().format('D');

  const handleOnPress = () => {
    if (!disabled) {
      onPress();
    }
  };

  return (
    <Pressable
      testID={testId}
      onPress={handleOnPress}
      onPressIn={() => setIsIconPressed(true)}
      onPressOut={() => setIsIconPressed(false)}
      disabled={disabled}>
      <View style={styles.spacing}>
        <Image source={icnScheduleToday} style={[styles.baseIcon, isIconPressed && styles.iconPressed]} />
        <Text style={styles.textForDate} adjustsFontSizeToFit minimumFontScale={MINIMUM_FONT_SCALE}>
          {today}
        </Text>
      </View>
    </Pressable>
  );
};

TodayButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

const styles = StyleSheet.create({
  spacing: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44,
  },
  baseIcon: {
    width: 26,
    height: 30,
    tintColor: COLORS.pinkishGrey,
  },
  iconPressed: {
    tintColor: COLORS.greyishBrown,
  },
  textForDate: {
    position: 'absolute',
    fontFamily: FONTS.notoSansBold,
    fontSize: 10,
    color: COLORS.pinkishGrey,
  },
});

export default TodayButton;
