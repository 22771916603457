import { DEFAULT_STREAM_COLORS, TEMPERATURE_STREAM_COLORS } from '../components/graph/ChartConstants';
import { categoryForDataStreamType, DATA_STREAM_CATEGORY } from '../models/dataStreamTypes';
import { buildLineData } from '../components/graph/LineDataBuilder';
import { mapDataStreamsIdsToDataStreams } from '../utils/dataStreamsUtil';
import { GraphDataType } from '../models/graphDataType';

export default class GraphPresenter {
  constructor({
    dataStreams,
    legendStreams,
    selectedDataStreams,
    assignedDataStreamsColors,
    block,
    defaultTimeScaleInHours,
    irrigationEvents,
    dataType,
    resetEnabled = false,
    tensionForecast,
    futureIrrigationEvents,
  }) {
    this.blockId = block?.id;
    this.dataStreams = dataStreams;
    this.legendStreams = legendStreams;
    this.selectedDataStreams = selectedDataStreams ? JSON.parse(JSON.stringify(selectedDataStreams)) : null;
    this.selectedFullDataStreams = this.#getFullSelectedDataStreams();
    this.comfortZone = dataType === GraphDataType.TENSION ? block?.tensionCapability().tensionComfortZone() : null;
    this.defaultTimeScaleInHours = defaultTimeScaleInHours;
    this.irrigationEvents = irrigationEvents;
    this.hasData = this.#hasDataStreams();
    this.dataType = dataType;
    this.#setDataStreamColors(assignedDataStreamsColors);
    this.assignedDataStreamsColors = selectedDataStreams
      ? this.selectedDataStreams.left.ids.concat(this.selectedDataStreams.right.ids)
      : null;
    this.resetEnabled = resetEnabled;
    this.eventProducers = this.#setEventProducers(block?.eventProducers);
    this.tensionForecast = tensionForecast;
    this.futureIrrigationEvents = futureIrrigationEvents;
  }

  getData(yAxisConfig, graphIrrigationEventPresenter) {
    const dataStreamsHeadersWithColor = [...this.selectedFullDataStreams.left, ...this.selectedFullDataStreams.right];
    const filteredIrrigationEvents = this.hasData ? this.irrigationEvents : [];
    const filteredFutureIrrigationEvents = this.hasData ? this.futureIrrigationEvents : [];

    return buildLineData(
      dataStreamsHeadersWithColor,
      this.selectedFullDataStreams,
      this.comfortZone,
      this.hasData,
      dataStreamsHeadersWithColor,
      filteredIrrigationEvents,
      yAxisConfig,
      graphIrrigationEventPresenter,
      this.eventProducers,
      this.tensionForecast,
      filteredFutureIrrigationEvents,
    );
  }

  #hasDataStreams = () => {
    if (!this.selectedDataStreams || !this.dataStreams) {
      return false;
    }

    const leftDataStreamIds = this.selectedDataStreams.left.ids.map((id) => id);
    const rightDataStreamIds = this.selectedDataStreams.right.ids.map((id) => id);

    const leftDataStream = this.dataStreams.filter((dataStream) => leftDataStreamIds.includes(dataStream.id));
    const rightDataStream = this.dataStreams.filter((dataStream) => rightDataStreamIds.includes(dataStream.id));
    const dataStreamToDisplay = [...leftDataStream, ...rightDataStream];
    return dataStreamToDisplay && dataStreamToDisplay.some((stream) => stream.dataStreamData?.length > 0);
  };

  #getFullSelectedDataStreams = () => {
    if (!this.selectedDataStreams || !this.dataStreams || !this.legendStreams?.length) {
      return { left: [], right: [] };
    }

    return {
      left: mapDataStreamsIdsToDataStreams(this.selectedDataStreams.left.ids, this.dataStreams),
      right: mapDataStreamsIdsToDataStreams(this.selectedDataStreams.right.ids, this.dataStreams),
    };
  };

  #setDataStreamColors = (assignedDataStreamsColors) => {
    if (this.selectedDataStreams) {
      this.assignedDataStreamsColors = assignedDataStreamsColors;
      const assignedColors = this.#assignDataStreamColors();
      this.legendStreams = assignedColors;

      this.selectedDataStreams.left.ids = this.selectedDataStreams.left.ids.map((id) =>
        this.#setSelectedDataStreamsColor(assignedColors, id),
      );
      this.selectedDataStreams.right.ids = this.selectedDataStreams.right.ids.map((id) =>
        this.#setSelectedDataStreamsColor(assignedColors, id),
      );
      this.selectedFullDataStreams = this.#getFullSelectedDataStreams();
    }
  };

  #setSelectedDataStreamsColor = (assignedColors, id) => {
    const dataStreams = assignedColors.find((dataStream) => dataStream.id === id);

    return { id, color: dataStreams?.color };
  };

  #initUsedColors = (usedColors) => {
    if (this.assignedDataStreamsColors) {
      this.assignedDataStreamsColors.map((dataStreamColor) => {
        usedColors.push(dataStreamColor.color);
      });
    }
  };

  #assignDataStreamColors = () => {
    const usedColors = [];
    this.#initUsedColors(usedColors);
    const allSelectedDataStreams = this.selectedDataStreams.left.ids.concat(this.selectedDataStreams.right.ids);
    const dataStreamTemperatureCategory = [
      DATA_STREAM_CATEGORY.SHIELDED_TEMPERATURE,
      DATA_STREAM_CATEGORY.UNSHIELDED_TEMPERATURE,
    ];

    if (!this.legendStreams) {
      return [];
    }

    const temperatureDataStreamsWithColor = this.#getAssignedStreamsColorByCategory(
      dataStreamTemperatureCategory,
      allSelectedDataStreams,
      usedColors,
    );
    const otherDataStreamsWithColor = this.#getAssignedStreamsColorByCategory(
      dataStreamTemperatureCategory,
      allSelectedDataStreams,
      usedColors,
      true,
    );
    return temperatureDataStreamsWithColor.concat(otherDataStreamsWithColor);
  };

  #getAssignedStreamsColorByCategory = (dataStreamCategory, allSelectedDataStreams, usedColors, excludeCategory = false) => {
    return this.legendStreams
      .filter((dataStream) => {
        const filterDataStreamCategory = excludeCategory
          ? !dataStreamCategory.includes(categoryForDataStreamType(dataStream.dataStreamType))
          : dataStreamCategory.includes(categoryForDataStreamType(dataStream.dataStreamType));

        return filterDataStreamCategory && allSelectedDataStreams.includes(dataStream.id);
      })
      .map((dataStream) => {
        const color = this.#getDataStreamColor(dataStream, usedColors);

        return {
          ...dataStream,
          color,
        };
      });
  };

  #getDataStreamColor = (dataStream, usedColors) => {
    // Apply default color of the dataStream
    let color = dataStream.colorCode ? `#${dataStream.colorCode.toLowerCase()}` : dataStream.color;

    // Else apply already selected color of the dataStream
    if (!color && this.assignedDataStreamsColors) {
      const assignedDataStreamColor = this.assignedDataStreamsColors.find(
        (dataStreamColor) => dataStreamColor.id === dataStream.id,
      );
      if (assignedDataStreamColor) {
        color = assignedDataStreamColor.color;
      }
    }

    // Else find a color to apply
    if (!color) {
      const dataStreamCategory = categoryForDataStreamType(dataStream.dataStreamType);
      const preferredColor = dataStreamCategory.preferredColor;

      if (!usedColors.includes(preferredColor)) {
        color = preferredColor;
      }

      if (!color) {
        color = this.#getNextAvailableColor(dataStreamCategory, preferredColor, usedColors);
      }
      usedColors.push(color);
    }

    return color;
  };

  #getNextAvailableColor = (dataStreamCategory, preferredColor, usedColors) => {
    let result = preferredColor;

    let colorsForCategory;
    if ([DATA_STREAM_CATEGORY.SHIELDED_TEMPERATURE, DATA_STREAM_CATEGORY.UNSHIELDED_TEMPERATURE].includes(dataStreamCategory)) {
      colorsForCategory = TEMPERATURE_STREAM_COLORS;
    } else {
      colorsForCategory = DEFAULT_STREAM_COLORS;
    }

    colorsForCategory.forEach((color) => {
      if (!usedColors.includes(color)) {
        result = color;
      }
    });
    return result;
  };

  #setEventProducers = (eventProducers) => {
    const result = {};

    if (!eventProducers) {
      return result;
    }

    eventProducers.map((eventProducer) => {
      result[eventProducer.id] = eventProducer;
    });

    return result;
  };
}
