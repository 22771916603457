import React from 'react';
import { StyleSheet, View, Text, Image, Pressable } from 'react-native';
import PropTypes from 'prop-types';

//shapes
import { imageSourceShape } from '../../shapes/imageSource';

//constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { styleShape } from '../../shapes/style';

const AutomationButton = ({ icon, iconStyle, id, onPress, text, testId }) => {
  const onPressable = () => {
    onPress(id);
  };
  const style = iconStyle ? iconStyle : styles.icon;

  return (
    <View>
      <Pressable onPress={onPressable}>
        <Image style={style} source={icon} testID={testId} />
      </Pressable>

      {text ? (
        <View style={styles.buttonTextContainer}>
          <Text style={styles.buttonText}>{text}</Text>
        </View>
      ) : null}
    </View>
  );
};

AutomationButton.propTypes = {
  icon: imageSourceShape.isRequired,
  iconStyle: styleShape,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPress: PropTypes.func,
  text: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  buttonText: {
    alignSelf: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 13,
    letterSpacing: 0.61,
    color: COLORS.greyishBrown,
  },
  buttonTextContainer: {
    marginTop: 13,
  },
  icon: {
    width: 125,
    height: 125,
  },
});

export default React.memo(AutomationButton);
