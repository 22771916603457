import moment from 'moment-timezone';
import { isNullOrUndefined } from '../../utils/util';

export default class AutomationRuleEntity {
  constructor(rule) {
    this.id = rule.id;
    this.name = rule.name;
    this.enabled = rule.enabled;
    this.ruleType = rule.properties?.ruleType;
    this.triggerValues = rule.properties?.triggerValues;
    this.cycling = this.triggerValues?.cycling ? JSON.parse(JSON.stringify(this.triggerValues?.cycling)) : undefined;
    this.unit = this.triggerValues?.unit || 'UNKNOWN';
    this.componentsToAutomate = rule.componentsToAutomate;

    if (this.triggerValues) {
      if (isNullOrUndefined(this.triggerValues.stopAbove)) {
        this.thresholdUp = this.triggerValues.startAbove;
        this.startValue = this.triggerValues.startAbove;
      } else {
        this.thresholdUp = this.triggerValues.stopAbove;
        this.stopValue = this.triggerValues.stopAbove;
      }

      if (isNullOrUndefined(this.triggerValues.startBelow)) {
        this.thresholdDown = this.triggerValues.stopBelow;
        this.stopValue = this.triggerValues.stopBelow;
      } else {
        this.thresholdDown = this.triggerValues.startBelow;
        this.startValue = this.triggerValues.startBelow;
      }
    }

    if (this.cycling) {
      this.cycling.cyclingDurationMoment = moment.duration(this.cycling.cyclingDuration, 'second');
    }
  }
}

export const formatAutomationRule = (ruleDetail) => {
  let cycling, startBelow, startAbove, stopBelow, stopAbove;
  let ruleDetailCopy = JSON.parse(JSON.stringify(ruleDetail));

  if (ruleDetailCopy.triggerValues) {
    if (isNullOrUndefined(ruleDetailCopy.triggerValues.stopAbove)) {
      startAbove = ruleDetailCopy.startValue;
    } else {
      stopAbove = ruleDetailCopy.stopValue;
    }

    if (isNullOrUndefined(ruleDetailCopy.triggerValues.startBelow)) {
      stopBelow = ruleDetailCopy.stopValue;
    } else {
      startBelow = ruleDetailCopy.startValue;
    }
  }

  if (ruleDetailCopy.cycling) {
    cycling = {
      enabled: ruleDetailCopy.cycling.enabled,
      cyclingDuration: ruleDetailCopy.cycling.cyclingDuration,
      cyclingRestartTrigger: ruleDetailCopy.cycling.cyclingRestartTrigger,
    };
  }

  return {
    id: ruleDetailCopy.id,
    enabled: ruleDetailCopy.enabled,
    cycling,
    startBelow,
    startAbove,
    stopBelow,
    stopAbove,
  };
};
