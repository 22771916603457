import { gql } from 'graphql-tag';

export const TEMPERATURE_BLOCKS = gql`
  query temperatures($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        blocks(filters: { capabilities: [TEMPERATURE] }) {
          id
          name
          alias
          order
          lastUpdated
          sensors {
            dataStreams(dataStreamTypes: [SHIELDED_TEMPERATURE, UNSHIELDED_TEMPERATURE, WATER_TEMPERATURE]) {
              dataStreamType
              id
              isVirtual
              lastUpdated
              unit
              value
              description
            }
          }
          capabilities {
            temperature {
              status
            }
          }
        }
      }
    }
  }
`;
