import { getApolloClient } from '../clients/apollo';
import { BLOCK_ORDER } from '../models/blockSorting';
import { IRRIGATION_BLOCKS } from './irrigationBlocks.graphql';

const fetchIrrigationBlocks = async (
  siteId,
  sortBlocksBy = BLOCK_ORDER,
  blockFilters = { capabilities: [], tensionStatuses: [] },
) => {
  const { loading, error, data } = await getApolloClient().query({
    query: IRRIGATION_BLOCKS,
    variables: {
      siteId,
      blockSorting: sortBlocksBy,
      filters: blockFilters,
    },
  });

  let irrigationBlocks;
  if (data) {
    irrigationBlocks = data.viewer.site.blocks;
  }

  return {
    loading,
    error,
    irrigationBlocks,
  };
};

export { fetchIrrigationBlocks };
