/* istanbul ignore file */

import { useReducer } from 'react';

import { MapStyleMode } from '../presenters/MapDisplayMode';
import {
  loadBlocksAndZoom,
  changeMapMode,
  changeMapStyleMode,
  resetPins,
  zoomOnPins,
  changePins,
  moveToActivePin,
  receiveBlocks,
  pressPin,
  pressMap,
  snapItem,
  resetActivePin,
} from '../presenters/MapPresenter';

import { buildFeatureCollectionFromMapItems } from '../screens/map/MapHelpers';

const actions = {
  LOAD_BLOCKS_WITHOUT_PINS_RESET: 'loadBlocksWithoutPinsReset',
  CHANGE_MAP_MODE: 'changeMapMode',
  CHANGE_MAP_STYLE: 'changeMapStyle',
  RESET_ACTIVE_PIN: 'resetActivePin',
  RESET_PINS: 'resetPins',
  ZOOM_ON_PINS: 'zoomOnPins',
  CHANGE_PINS: 'changePins',
  MOVE_TO_ACTIVE_PIN: 'moveToActivePin',
  RECEIVE_BLOCKS: 'receiveBlocks',
  PRESS_PIN: 'pressPin',
  PRESS_MAP: 'pressMap',
  SNAP_ITEM: 'snapItem',
};

const EMPTY_FEATURE_COLLECTION = { features: [], type: 'FeatureCollection' };

const useMap = ({
  showItemDetails,
  setShowItemDetails,
  dispatchRef,
  currentSite,
  loading,
  mapMode,
  mapStyle,
  presenters,
  analyticsService,
  lastActiveBlockIdWithValue,
  mapCamera,
  blocks,
  automations,
  mapItems,
  gotoUserLocation,
}) => {
  const initialState = {
    activeBlockId: undefined,
    featureCollection: EMPTY_FEATURE_COLLECTION,
    markerCollection: EMPTY_FEATURE_COLLECTION,
    presentersForMapDisplayMode: [],
    pinHasBeenPressed: false,
    shouldZoomOnPins: true,
    shouldResetPins: true,
  };

  const initializeState = () => {
    return {
      ...initialState,
    };
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case actions.LOAD_BLOCKS_WITHOUT_PINS_RESET: {
        if (showItemDetails) {
          setShowItemDetails(false);
          dispatchRef.graph.reset();
        }
        const newState = loadBlocksAndZoom(
          state,
          currentSite,
          loading,
          mapMode,
          mapStyle,
          dispatchRef.map.loadMapBlocksForCurrentSite,
        );
        newState.markerCollection = buildFeatureCollectionFromMapItems(
          state,
          loading,
          mapItems,
          mapMode,
          mapStyle,
          lastActiveBlockIdWithValue,
        );
        return newState;
      }
      case actions.CHANGE_MAP_STYLE: {
        const newState = changeMapStyleMode(state, loading, presenters, mapMode, mapStyle, analyticsService);
        newState.markerCollection = buildFeatureCollectionFromMapItems(
          state,
          loading,
          mapItems,
          mapMode,
          mapStyle,
          lastActiveBlockIdWithValue,
        );
        return newState;
      }
      case actions.CHANGE_MAP_MODE: {
        const newState = changeMapMode(
          state,
          currentSite,
          loading,
          presenters,
          mapMode,
          mapStyle,
          analyticsService,
          lastActiveBlockIdWithValue,
          dispatchRef.map.setMapMode,
        );
        newState.markerCollection = buildFeatureCollectionFromMapItems(
          state,
          loading,
          mapItems,
          mapMode,
          mapStyle,
          lastActiveBlockIdWithValue,
        );
        return newState;
      }
      case actions.RESET_ACTIVE_PIN: {
        return resetActivePin(state);
      }
      case actions.RESET_PINS: {
        if (showItemDetails) {
          setShowItemDetails(false);
        }
        return resetPins(state);
      }
      case actions.ZOOM_ON_PINS: {
        return zoomOnPins(state, mapMode, mapStyle, mapCamera, gotoUserLocation);
      }
      case actions.CHANGE_PINS: {
        const newState = changePins(state, mapMode, mapStyle);
        newState.markerCollection = buildFeatureCollectionFromMapItems(
          state,
          loading,
          mapItems,
          mapMode,
          mapStyle,
          state.activeBlockId,
        );
        return newState;
      }
      case actions.MOVE_TO_ACTIVE_PIN: {
        if (mapStyle !== MapStyleMode.NDVI) {
          if (!showItemDetails) {
            setShowItemDetails(true);
          }
        }
        return moveToActivePin(state, mapCamera);
      }
      case actions.RECEIVE_BLOCKS: {
        const newState = receiveBlocks(state, blocks, automations, mapMode, mapStyle, presenters, lastActiveBlockIdWithValue);
        newState.markerCollection = buildFeatureCollectionFromMapItems(
          state,
          loading,
          mapItems,
          mapMode,
          mapStyle,
          lastActiveBlockIdWithValue,
        );
        return newState;
      }
      case actions.PRESS_PIN: {
        return pressPin(state, action);
      }
      case actions.PRESS_MAP: {
        if (showItemDetails) {
          setShowItemDetails(false);
        }
        return pressMap(state);
      }
      case actions.SNAP_ITEM: {
        return snapItem(state, action, lastActiveBlockIdWithValue);
      }
      default:
        throw new Error('Unknown map action type');
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState, initializeState);

  return [state, dispatch];
};

export default useMap;
