import moment from 'moment-timezone';

// utils
import { daysToMilliseconds, formatMoment } from '../../utils/dateUtils';

// constant
import { TIME_CONSTANTS } from './ChartConstants';
import { minDateAsMilliseconds, maxDateAsMilliseconds } from './DateChartConstants';

const daysBetween = (bounds) => bounds.right.diff(bounds.left, 'days', true);

export const shouldLoadData = (left, right, dataRange, action) => {
  const [start, end] = dataRange;
  const dataBounds = {
    left: moment(start).clone().startOf('day'),
    realLeft: moment(start),
    right: moment(end).clone().startOf('day'),
    realRight: moment(end),
  };

  const visibleBounds = {
    left: moment(left),
    right: moment(right),
  };

  if (
    (dataBounds.realLeft.isAfter(visibleBounds.left, 'minute') && visibleBounds.left.isBefore(moment(), 'minute')) ||
    (dataBounds.realRight.isBefore(visibleBounds.right, 'minute') && visibleBounds.right.isBefore(moment(), 'minute'))
  ) {
    return true;
  }

  if (action === 'chartTranslated') {
    return false;
  }

  if (daysBetween(dataBounds) > 14 && daysBetween(visibleBounds) > 14) {
    return false;
  }

  if (daysBetween(dataBounds) === 14 && daysBetween(visibleBounds) > 5) {
    return false;
  }

  if (daysBetween(dataBounds) === 5) {
    return false;
  }

  return true;
};

export const padValues = (left, right, start, end, totalPadding) => {
  const p = totalPadding / 2;

  const x1 = left;
  const x2 = right;
  const min = start;
  const max = end;
  let p1 = p;
  let p2 = p;

  if (x1 - p1 < min) {
    p1 = x1 - min;

    const d = min - (x1 - p);
    if (x2 + d > max) {
      p2 = max - x2;
    } else {
      p2 = p2 + d;
    }
  }

  if (x2 + p2 > max) {
    p2 = max - x2;
    p1 = p1 + (x2 + p - max);
  }

  return {
    left: x1 - p1,
    right: x2 + p2,
  };
};

export const getPaddingStrategy = (left, right, action) => {
  const visibleBounds = {
    left: moment(left),
    right: moment(right),
  };

  const days = daysBetween(visibleBounds);
  if (action === 'chartTranslated' || days > 14) {
    return right - left;
  } else if (days > 5) {
    return daysToMilliseconds(14) - (right - left);
  }

  return daysToMilliseconds(5) - (right - left);
};

export const computeInitialDateRange = (currentSite) => {
  const MAX_DATE_AS_MILLISECONDS = maxDateAsMilliseconds();
  const MIN_DATE_AS_MILLISECONDS = minDateAsMilliseconds();
  const right = MAX_DATE_AS_MILLISECONDS;

  const left = right - currentSite.defaultTimeScaleInHours * TIME_CONSTANTS.ONE_HOUR_IN_MS;

  const newValues = padValues(left, right, MIN_DATE_AS_MILLISECONDS, MAX_DATE_AS_MILLISECONDS, right - left);

  return [formatMoment(moment(newValues.left)), formatMoment(moment(newValues.right))];
};
