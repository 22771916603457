import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet, Pressable } from 'react-native';

//propsTypes
import { tensionReadingShape } from '../../shapes/dataStreams';

//hook
import { useTranslation } from '../../hooks/useTranslation';

//service
import { TensionSensorPresenter } from '../../presenters/TensionSensorPresenter';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

// components
import RoundedUnderline from '../RoundedUnderline';

const TensionDataStreamButton = ({ isSelected = false, onPress, tensionReading }) => {
  const { t } = useTranslation();
  const tensionReadingPresenter = new TensionSensorPresenter(tensionReading, t);

  const backgroundColor = { backgroundColor: isSelected ? COLORS.blackSqueeze : COLORS.white };

  const depth = tensionReadingPresenter.depth();
  const value = tensionReadingPresenter.value();
  const unit = tensionReadingPresenter.unit();
  const depthColor = tensionReadingPresenter.color();

  const shadow = isSelected
    ? {
        shadowColor: COLORS.greyShadow,
        shadowOffset: { width: 2, height: 3 },
        shadowRadius: 2,
        shadowOpacity: OPACITIES.dataStreamButtonShadow,
        elevation: 6,
      }
    : {};

  const press = () => onPress(tensionReading.id, tensionReading.unit);

  return (
    <Pressable
      style={[styles.tensionButtonContainer, backgroundColor, shadow]}
      onPress={press}
      testID={`block-detail__tension-data-button-${tensionReading.id}`}>
      <View>
        {depth ? <Text style={[styles.tensionDepthStyle, { color: depthColor }]}>{depth}</Text> : null}
        <RoundedUnderline color={depthColor} />
      </View>

      <View style={styles.readingSection}>
        <Text style={styles.tensionValue}>{value}</Text>
        <Text style={styles.tensionUnit}>{unit}</Text>
      </View>
    </Pressable>
  );
};

TensionDataStreamButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  tensionReading: tensionReadingShape.isRequired,
};

const styles = StyleSheet.create({
  tensionButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
    paddingHorizontal: 10,
    borderColor: COLORS.greyBorderColor2,
    borderWidth: 1,
    borderRadius: 1,
  },
  readingSection: {
    flexDirection: 'row',
  },
  tensionValue: {
    marginRight: 5,
    fontFamily: FONTS.notoSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  tensionUnit: {
    alignSelf: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 10,
    color: COLORS.greyishBrown,
  },
  tensionDepthStyle: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 15,
  },
});

export default TensionDataStreamButton;
