import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';

import icnSortUp from '../../assets/icons/icnSortUp.png';
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { SORT_ORDER } from '../../utils/sorting';

// shape
import { styleShape } from '../../shapes/style';

const SortableHeaderItem = ({
  title,
  titleStyle,
  sortOrder = SORT_ORDER.NONE,
  onPress,
  sortKey,
  testID,
  style,
  loading,
  ignoreMargin = false,
}) => {
  const marginStyle = ignoreMargin ? null : styles.item;

  const handleSort = () => {
    if (!loading) {
      onPress(sortKey);
    }
  };

  const isAscending = () => {
    return sortOrder === SORT_ORDER.ASC;
  };
  const isDescending = () => {
    return sortOrder === SORT_ORDER.DESC;
  };

  function renderSortItem() {
    if (isAscending()) {
      return <Image source={icnSortUp} style={styles.ascendingImage} testID={`${testID}-image-asc`} />;
    } else if (isDescending()) {
      return <Image source={icnSortUp} style={descendingImageStyle} testID={`${testID}-image-desc`} />;
    }
    return null;
  }

  return (
    <View style={[marginStyle, style]}>
      <Pressable
        style={titleStyle ? [styles.touchableZone, titleStyle] : styles.touchableZone}
        onPress={handleSort}
        underlayColor={COLORS.brownGrey}
        testID={`${testID}-touchable`}>
        <Text style={sortOrder === SORT_ORDER.NONE ? styles.text : [styles.text, styles.selectedText]} testID={'list__header'}>
          {title}
        </Text>
        {renderSortItem()}
      </Pressable>
    </View>
  );
};

SortableHeaderItem.propTypes = {
  ignoreMargin: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.oneOf([SORT_ORDER.ASC, SORT_ORDER.DESC, SORT_ORDER.NONE]),
  style: styleShape,
  testID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
};

const styles = StyleSheet.create({
  ascendingImage: {
    width: 11,
    height: 9,
    marginLeft: 3,
    tintColor: COLORS.greyishBrownTwo,
  },
  item: {
    marginVertical: 5,
  },
  text: {
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    letterSpacing: 0.56,
    color: COLORS.greyishBrownTwo,
  },
  touchableZone: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
  },
  selectedText: {
    fontWeight: 'bold',
  },
});

const descendingImageStyle = {
  ...styles.ascendingImage,
  transform: [{ rotateX: '180deg' }],
};

export default SortableHeaderItem;
