import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import i18next from 'i18next';
import propTypes from 'prop-types';
import { navigationShape } from '../../shapes/navigation';

// styles
import { globalStyles } from '../../styles';

// components
import HeaderBar from '../../components/header/HeaderBar';
import Divider from '../../components/Divider';
import LocalizedImage from '../../components/LocalizedImage';

// constants
import COLORS from '../../colors';

const PreviewScreen = ({ navigation, screenName, testId, localizedImages }) => {
  const currentSite = useSelector((state) => state.site.currentSite);

  const [, forceUpdate] = useState();
  const handleLanguageChange = () => forceUpdate({});

  // istanbul ignore next
  useEffect(() => {
    i18next.on('languageChanged', handleLanguageChange);
    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <>
      <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
        <View style={globalStyles.header}>
          <HeaderBar siteName={currentSite.name} screenName={screenName} testId={testId + 'header'} navigation={navigation} />
        </View>
        <Divider />
        <View testId={testId + 'container'} style={styles.bottomContainer}>
          <LocalizedImage image={localizedImages} />
        </View>
      </SafeAreaView>
      <SafeAreaView style={styles.safeBottomView} edges={['bottom']} />
    </>
  );
};

PreviewScreen.propTypes = {
  navigation: navigationShape.isRequired,
  screenName: propTypes.string.isRequired,
  testId: propTypes.string.isRequired,
  localizedImages: propTypes.object.isRequired,
};

const styles = StyleSheet.create({
  bottomContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.blueDeepMidnight,
  },
  safeBottomView: {
    backgroundColor: COLORS.white,
  },
});

export default PreviewScreen;
