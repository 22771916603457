import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

// proptype
import { navigationShape } from '../../shapes/navigation';

// services
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ANALYTICS from '../../services/AnalyticsEvents';

// constants
import ROUTES from '../../navigation/routes';
import * as CAPABILITIES from '../../models/capability';
import * as TABS from '../blockDetails/BlockDetailsTabs';

//presenters
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';
import { FlowStationBlockPresenter } from '../../presenters/FlowStationBlockPresenter';
import ListBlockAliasPresenter from '../../presenters/blockAlias/ListBlockAliasPresenter';

//entity
import BlockEntity from '../../models/entities/blockEntity';

// components
import BlockName from '../../components/block/BlockName';
import BlockAliasButton from '../../components/block/BlockAliasButton';
import ListBox from '../../components/list/ListBox';
import FlowmeterItem from './FlowmeterItem';

const FlowStationBlockItem = ({ block, navigation, keyIndex, animatedOpacity }) => {
  const listBlockPresenter = new ListBlockAliasPresenter(block);
  const blockAliasPresenter = new BlockAliasPresenter(block);
  const flowStationBlockPresenter = new FlowStationBlockPresenter(block);
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  const defaultNavigationParams = {
    activeTab: TABS.GRAPH,
    block,
    capability: CAPABILITIES.FLOW_STATION,
    displayTabs: false,
    routeToGoBackTo: ROUTES.FLOW_STATION,
  };

  const goToGraphFromFlowmeter = async (stream) => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      ...defaultNavigationParams,
      defaultSelectedStreamIds: stream.outflow ? [stream.outflow.id] : [],
      unit: stream.outflow ? stream.outflow.dataStream.unit : undefined,
    });
  };

  const goToGraphFromBlock = async (ids, unit) => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      ...defaultNavigationParams,
      defaultSelectedStreamIds: ids,
      unit,
    });
  };

  const renderFlowmeterItem = (item, index) => {
    return (
      <FlowmeterItem
        flowmeter={item}
        index={index}
        key={`flow-${keyIndex}-${index}`}
        onPress={() => goToGraphFromFlowmeter(item)}
        animatedOpacity={animatedOpacity}
      />
    );
  };

  return (
    <ListBox
      renderContent={() => (
        <>
          <BlockAliasButton
            presenter={blockAliasPresenter}
            onClick={() => {
              goToGraphFromBlock(
                flowStationBlockPresenter.getFlowmetersFlowDataStreamsIds(),
                flowStationBlockPresenter.getFirstFlowmeterUnit(),
              );
            }}
            buttonStyle={styles.button}
            testId={`flow-station__blockAlias-button-${keyIndex}`}
          />
          <BlockName blockName={listBlockPresenter.name()} />
        </>
      )}
      renderSubContent={() => flowStationBlockPresenter.getFlowmeters().map((item, index) => renderFlowmeterItem(item, index))}
    />
  );
};

FlowStationBlockItem.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  keyIndex: PropTypes.number,
  navigation: navigationShape.isRequired,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  button: {
    width: 57,
    height: 44,
  },
});

export default FlowStationBlockItem;
