export const consoleDebug = (content) => {
  console.debug(content);
};

export const consoleInfo = (content) => {
  console.info(content);
};

export const consoleWarn = (content) => {
  console.warn(content);
};

export const consoleError = (content) => {
  console.error(content);
};
