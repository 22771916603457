import { gql } from 'graphql-tag';

export const BLOCK_BLUE_BAND_INDEXES = gql`
  query blockPerformances($siteId: ID!, $blockId: ID!, $startDate: String!, $endDate: String!) {
    viewer {
      site(id: $siteId) {
        block(id: $blockId) {
          id
          capabilities {
            tension {
              blueBandIndexes(startDate: $startDate, endDate: $endDate) {
                dates {
                  date
                  value {
                    wet
                    good
                    dry
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
