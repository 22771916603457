import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// proptypes
import { navigationShape } from '../../shapes/navigation';
import BlockEntity from '../../models/entities/blockEntity';

// services
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ANALYTICS from '../../services/AnalyticsEvents';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';
import { TENSION } from '../../models/capability';
import { DETAILS, GRAPH } from '../blockDetails/BlockDetailsTabs';

// presenters
import { IrrigationBlockPresenter } from '../../presenters/IrrigationBlockPresenter';
import ListBlockAliasPresenter from '../../presenters/blockAlias/ListBlockAliasPresenter';

// components
import Schedule from '../../components/block/Schedule';
import Irrigation from '../../components/block/Irrigation';
import SensorData from '../../components/block/SensorData';
import BlockAliasButton from '../../components/block/BlockAliasButton';
import ListBox from '../../components/list/ListBox';
import TensionValuesList from '../../components/irrigation/TensionValuesList';
import { TensionSensorPresenter } from '../../presenters/TensionSensorPresenter';

const IrrigationBlockItem = ({ block, navigation, keyIndex, animatedOpacity }) => {
  const timezone = useSelector((state) => state.site.currentSite.timezone);
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  const irrigationBlockPresenter = new IrrigationBlockPresenter(block, timezone, t);
  const blockAliasPresenter = new ListBlockAliasPresenter(block, TENSION);
  const tensionReadings = block.tensionCapability().tensionReadings();
  const tensionReadingsPresenter = tensionReadings.map((reading) => {
    const tensionSensorPresenter = new TensionSensorPresenter(reading, t);
    return {
      value: tensionSensorPresenter.value(),
      color: tensionSensorPresenter.color(),
    };
  });

  const blockDetailsNavigationProps = {
    capability: TENSION,
    block,
    defaultSelectedStreamIds: tensionReadings.map((reading) => reading.id),
    unit: irrigationBlockPresenter.unit(),
    routeToGoBackTo: ROUTES.IRRIGATION,
    displayTabs: true,
  };

  const goTo = async (tab) => {
    analyticsService.trackNavigationEvent(tab === DETAILS ? ANALYTICS.eventViewBlockDetail : ANALYTICS.eventViewGraph);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      activeTab: tab,
      ...blockDetailsNavigationProps,
    });
  };

  const renderSubContent = () => <TensionValuesList list={tensionReadingsPresenter} />;

  return (
    <ListBox
      usePadding={false}
      renderContent={() => (
        <>
          <View style={styles.leftSection}>
            <BlockAliasButton
              presenter={blockAliasPresenter}
              buttonStyle={styles.buttonStyle}
              onClick={() => goTo(DETAILS)}
              testId={`irrigation__blockAlias-button-${keyIndex}`}
            />
          </View>

          <Pressable style={styles.row} testID={`irrigation__blockItem-${keyIndex}`} onPress={() => goTo(GRAPH)}>
            <Irrigation style={styles.irrigationSection} irrigating={block.isIrrigating()} animatedOpacity={animatedOpacity} />
            <Schedule presenter={irrigationBlockPresenter} style={styles.scheduledSection} />
            <SensorData
              containerStyle={styles.blueBandSection}
              value={irrigationBlockPresenter.blueBandIndex()}
              textStyle={styles.blueBand}
            />
          </Pressable>
        </>
      )}
      renderSubContent={tensionReadingsPresenter.length === 0 ? null : renderSubContent}
    />
  );
};

IrrigationBlockItem.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  navigation: navigationShape.isRequired,
  keyIndex: PropTypes.number,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  blueBand: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  leftSection: {
    flexDirection: 'row',
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '90%',
    flexShrink: 1,
  },
  irrigationSection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 65,
    marginLeft: 15,
  },
  scheduledSection: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  blueBandSection: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 42,
  },
  buttonStyle: {
    width: 57,
  },
});

export default IrrigationBlockItem;
