// client
import { getApolloClient } from '../clients/apollo';
//graphQL
import { MANAGE_CREATE_NOTE, MANAGE_UPDATE_NOTE, MANAGE_DELETE_NOTE } from './manageNote.graphql';

async function createNote(blockId, content, startDate, endDate, siteId) {
  const query = {
    mutation: MANAGE_CREATE_NOTE,
    variables: {
      blockId,
      content,
      startDate,
      endDate,
      siteId,
    },
  };
  return execute(query);
}

async function updateNote(noteId, content) {
  const query = {
    mutation: MANAGE_UPDATE_NOTE,
    variables: {
      noteId,
      content,
    },
  };
  return execute(query);
}

async function deleteNote(noteId) {
  const query = {
    mutation: MANAGE_DELETE_NOTE,
    variables: {
      noteId,
    },
  };
  return execute(query);
}

async function execute(mutation) {
  try {
    const response = await getApolloClient().mutate(mutation);
    return { event: response };
  } catch (error) {
    // TODO: Remove this once there is a fix available for catching errors
    // https://github.com/apollographql/apollo-client/issues/5708
    if (error != null && typeof error.networkError.result && error.networkError.result != null) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}
export { createNote, deleteNote, updateNote };
