import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// models
import BlockEntity from '../../models/entities/blockEntity';

// services
import { modulo } from '../../utils/mathUtil';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// components
import ShadowedDivider from '../ShadowedDivider';
import DataStreamButtonPlaceholder from './DataStreamButtonPlaceholder';
import DataStreamButton from './DataStreamButton';
import TensionDataStreamButton from './TensionDataStreamButton';
import MainSwitchesLegend from './MainSwitchesLegend';

const DataStreams = ({ block, isPortrait = true, localStreams, onToggle, selectedDataStreams, weatherStreams }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedDataStreamsAllAxes = selectedDataStreams.left.ids.concat(selectedDataStreams.right.ids);
  const showLocalData = localStreams && localStreams.length > 0;
  const tensionReadings = block ? block.tensionCapability().tensionReadings() : [];
  const depthUnit = tensionReadings.find((tension) => tension.depthUnit !== undefined)?.depthUnit;
  const depthUnitDisplay = depthUnit ? ` (${t('graph_view_soil_tensions_depth')} ${t(`unit_${depthUnit.toLowerCase()}`)})` : '';

  const localDataList = localStreams?.map((dataStream) => {
    const foundColoredDataStream = selectedDataStreamsAllAxes.find((coloredDataStream) => coloredDataStream.id === dataStream.id);
    return { ...dataStream, color: foundColoredDataStream?.color };
  });
  const weatherDataList = weatherStreams?.map((dataStream) => {
    const foundColoredDataStream = selectedDataStreamsAllAxes.find((coloredDataStream) => coloredDataStream.id === dataStream.id);
    return { ...dataStream, color: foundColoredDataStream?.color };
  });

  const onDataStreamToggle = (id, unit) => {
    onToggle();
    dispatch.graph.toggleSelectedDataStream({ id, unit });
  };

  const isSelected = (id) => selectedDataStreamsAllAxes.some((selectedDataStream) => selectedDataStream.id === id);

  const tensionSelected = tensionReadings?.some((item) => isSelected(item.id));

  return (
    <View style={styles.container}>
      {tensionReadings.length > 0 ? (
        <>
          {block?.hasMultiSwitches() && tensionSelected ? (
            <MainSwitchesLegend switches={block.getMainSwitches()} isPortrait={isPortrait} />
          ) : null}
          <ShadowedDivider />
          <Text style={isPortrait ? styles.sectionHeader : styles.sectionHeaderLandscape}>
            {t('graph_view_soil_tensions')}
            {depthUnitDisplay}
          </Text>
          <View style={isPortrait ? styles.dataSection : styles.dataSectionLandscape}>
            {tensionReadings.map((item) => (
              <View style={isPortrait ? styles.item : styles.itemLandscape} key={item.id}>
                <TensionDataStreamButton isSelected={isSelected(item.id)} tensionReading={item} onPress={onDataStreamToggle} />
              </View>
            ))}
            {isPortrait ? (
              <DataStreamButtonPlaceholder
                numberOfItems={modulo(-Math.abs(block.tensionCapability().tensionReadings().length), 3)}
                style={styles.item}
              />
            ) : null}
          </View>
        </>
      ) : null}

      {showLocalData ? (
        <>
          <ShadowedDivider />
          <Text style={isPortrait ? styles.sectionHeader : styles.sectionHeaderLandscape}>{t('local_data')}</Text>
          <View style={isPortrait ? styles.dataSection : styles.dataSectionLandscape}>
            {block &&
              localDataList.map((item) => (
                <View style={isPortrait ? styles.item : styles.itemLandscape} key={item.id}>
                  <DataStreamButton
                    isSelected={isSelected(item.id)}
                    dataStream={item}
                    onPress={onDataStreamToggle}
                    iconColor={item.color}
                  />
                </View>
              ))}
            {isPortrait ? (
              <DataStreamButtonPlaceholder
                numberOfItems={block ? modulo(-Math.abs(localDataList.length), 3) : 0}
                style={styles.item}
              />
            ) : null}
          </View>
        </>
      ) : null}

      {weatherStreams?.length > 0 ? (
        <>
          <ShadowedDivider />
          <View style={styles.container}>
            <Text style={isPortrait ? styles.sectionHeader : styles.sectionHeaderLandscape}>{t('graph_view_weather_data')}</Text>
            <View style={isPortrait ? styles.dataSection : styles.dataSectionLandscape}>
              {weatherDataList.map((item) => (
                <View style={isPortrait ? styles.item : styles.itemLandscape} key={item.id}>
                  <DataStreamButton
                    isSelected={isSelected(item.id)}
                    dataStream={item}
                    onPress={onDataStreamToggle}
                    iconColor={item.color}
                  />
                </View>
              ))}
              {isPortrait ? (
                <DataStreamButtonPlaceholder numberOfItems={modulo(-Math.abs(weatherDataList.length), 3)} style={styles.item} />
              ) : null}
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
};

DataStreams.propTypes = {
  block: PropTypes.instanceOf(BlockEntity),
  isPortrait: PropTypes.bool,
  localStreams: PropTypes.arrayOf(PropTypes.object),
  onToggle: PropTypes.func.isRequired,
  selectedDataStreams: PropTypes.object.isRequired,
  weatherStreams: PropTypes.arrayOf(PropTypes.object),
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    marginBottom: 5,
  },
  dataSection: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 20,
  },
  dataSectionLandscape: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 20,
  },
  sectionHeader: {
    paddingHorizontal: 10,
    paddingTop: 10,
    fontFamily: FONTS.firaSansLight,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  sectionHeaderLandscape: {
    paddingHorizontal: 10,
    paddingTop: 10,
    textAlign: 'right',
    fontFamily: FONTS.firaSansLight,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  item: {
    flex: 1,
    minWidth: 95,
    maxWidth: 120,
    marginHorizontal: 3,
    marginBottom: 10,
  },
  itemLandscape: {
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    marginHorizontal: 3,
    marginBottom: 10,
  },
});

export default DataStreams;
