import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constant
import COLORS from '../../colors';

// shape
import { styleShape } from '../../shapes/style';

const CustomButton = ({ buttonStyle = {}, pressedStyle, onPress, onPressIn, onPressOut, testId, underlayColor, children }) => {
  return (
    <Pressable
      style={
        /* istanbul ignore next */ ({ pressed }) => [
          buttonStyle,
          pressed ? [styles.backgroundPressed, ...(pressedStyle ? pressedStyle : [])] : {},
        ]
      }
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      testID={testId}
      underlayColor={underlayColor}>
      {children}
    </Pressable>
  );
};

CustomButton.propTypes = {
  buttonStyle: styleShape,
  pressedStyle: styleShape,
  onPress: PropTypes.func.isRequired,
  onPressIn: PropTypes.func,
  onPressOut: PropTypes.func,
  testId: PropTypes.string,
  underlayColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const styles = StyleSheet.create({
  backgroundPressed: {
    backgroundColor: COLORS.greyish22,
  },
});

export default CustomButton;
