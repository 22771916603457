export class BlockPresenter {
  constructor(block, capability) {
    this.block = block;
    this.capability = capability;
  }

  name() {
    return this.block.name;
  }

  id() {
    return this.block.id;
  }
}
