import { INFERRED } from '../calendarType';

export default class IrrigationCapability {
  constructor(irrigation, schedule) {
    this.irrigation = irrigation || {};
    this.schedule = schedule || {};
  }

  hasCurrentEvent() {
    if (this.irrigation.currentEvent !== null && this.irrigation.currentEvent !== undefined) {
      return true;
    }
    return false;
  }

  currentEvent() {
    if (this.hasCurrentEvent()) {
      return { ...this.irrigation.currentEvent, calendarType: INFERRED };
    }
    return null;
  }

  hasNextEvent() {
    if (this.schedule.nextEvent !== null && this.schedule.nextEvent !== undefined) {
      return true;
    }
    return false;
  }

  nextEvent() {
    if (this.hasNextEvent()) {
      return this.schedule.nextEvent;
    }
    return null;
  }

  hasPreviousEvent() {
    if (this.irrigation.previousEvent !== null && this.irrigation.previousEvent !== undefined) {
      return true;
    }
    return false;
  }

  previousEvent() {
    if (this.hasPreviousEvent()) {
      return { ...this.irrigation.previousEvent, calendarType: INFERRED };
    }
    return null;
  }

  hasCurrentOrNextEvent() {
    return this.hasCurrentEvent() || this.hasNextEvent();
  }

  hasEvents() {
    return this.hasPreviousEvent() || this.hasCurrentOrNextEvent();
  }

  currentOrNextEvent() {
    if (this.hasCurrentEvent()) {
      return this.currentEvent();
    } else if (this.hasNextEvent()) {
      return this.nextEvent();
    }
    return null;
  }
}
