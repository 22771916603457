import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { VictoryLabel, VictoryPie } from 'victory-native';
import { Svg } from 'react-native-svg';

import FONTS from '../../fonts';
import COLORS from '../../colors';

// services
import { BlueBandIndex } from '../../models/entities/blueBandIndex';
import BlueBandIndexStatuses from '../../models/entities/blueBandIndexStatuses';
import { useTranslation } from '../../hooks/useTranslation';

// components
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';

const BlueBandIndexPie = ({ blueBandIndexData, loading, onPress, testId }) => {
  const { t } = useTranslation();
  let values = [];
  let colors = [];

  const blueBandIndexes = new BlueBandIndexStatuses(blueBandIndexData);
  const blueBandIndexStatuses = Object.values(blueBandIndexes.statuses(t));
  const hasData = blueBandIndexStatuses && blueBandIndexStatuses.length > 0;

  if (hasData) {
    for (let blueBandIndexStatus of blueBandIndexStatuses) {
      if (blueBandIndexStatus.value > 0) {
        values.push({ x: blueBandIndexStatus.name, y: blueBandIndexStatus.value });
        colors.push(blueBandIndexStatus.color);
      }
    }
  } else {
    const defaultStatus = new BlueBandIndex(t('blue_band_index_na'), COLORS.desertStorm, 100);
    values.push({ x: defaultStatus.name, y: defaultStatus.value });
    colors.push(defaultStatus.color);
  }

  const renderChart = () => {
    if (loading) {
      return (
        <View style={styles.skeletonContainer} testID="loadingBlueBandIndex">
          <SkeletonPlaceholder width={CIRCLE_SIZE} height={CIRCLE_SIZE} style={styles.skeletonCircle} />
        </View>
      );
    } else {
      return (
        <VictoryPie
          height={250}
          width={750}
          padding={{ top: 32, bottom: 32 }}
          innerRadius={50}
          padAngle={2}
          colorScale={colors}
          data={values}
          labelComponent={<VictoryLabel style={styles.labels} />}
          containerComponent={<Svg onPress={onPress} height={250} width={750} />}
        />
      );
    }
  };

  return (
    <View style={styles.container} testID={testId}>
      {renderChart()}
      <Text style={styles.titleText}>{t('blue_band_index_description')}</Text>
    </View>
  );
};

BlueBandIndexPie.propTypes = {
  blueBandIndexData: PropTypes.object,
  loading: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const CIRCLE_SIZE = 175;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  labels: {
    fill: COLORS.greyishBrown,
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    fontWeight: 'bold',
  },
  skeletonCircle: {
    borderRadius: CIRCLE_SIZE / 2,
  },
  skeletonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
  },
  titleText: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    paddingTop: 12,
    paddingBottom: 20,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
});

export default BlueBandIndexPie;
