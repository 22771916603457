import { getApolloClient } from '../clients/apollo';

// utils
import { isNullOrUndefined } from '../utils/util';

// models
import { GRAPHQL_VERSION_V2 } from '../consts';
import { mutationQueryForRule } from '../models/automationRuleType';
import { formatAutomationRule } from '../models/entities/automationRuleEntity';
import {
  ERROR_RESTART_TRIGGER,
  ERROR_START_ABOVE,
  ERROR_START_BELOW,
  ERROR_STOP_ABOVE,
  ERROR_STOP_BELOW,
} from '../models/errors/AutomationRuleErrorMapper';
import { UPDATE_AUTOMATION_LOW_RULE } from './updateAutomationRule.graphql';

async function updateAutomationRule(ruleDetail, siteId) {
  const mutation = mutationQueryForRule(ruleDetail.ruleType);

  if (isNullOrUndefined(mutation)) {
    return { errors: [{}] };
  }

  // Local validations
  if (isNaN(ruleDetail.startValue)) {
    const code = mutation === UPDATE_AUTOMATION_LOW_RULE ? ERROR_START_BELOW : ERROR_START_ABOVE;
    return { errors: [{ extensions: { code } }] };
  }

  if (isNaN(ruleDetail.stopValue)) {
    const code = mutation === UPDATE_AUTOMATION_LOW_RULE ? ERROR_STOP_ABOVE : ERROR_STOP_BELOW;
    return { errors: [{ extensions: { code } }] };
  }

  if (ruleDetail.cycling && isNaN(ruleDetail.cycling.cyclingRestartTrigger)) {
    return { errors: [{ extensions: { code: ERROR_RESTART_TRIGGER } }] };
  }

  try {
    const rule = formatAutomationRule(ruleDetail);

    const response = await getApolloClient().mutate({
      mutation,
      variables: {
        siteId,
        automation: rule,
      },
      context: { version: GRAPHQL_VERSION_V2 },
    });

    return { event: response };
  } catch (error) {
    if (typeof error.graphQLErrors && error.graphQLErrors?.length > 0) {
      return { errors: error.graphQLErrors };
    } else if (typeof error.networkError.result && error.networkError.result != null) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}

export { updateAutomationRule };
