import { StyleSheet } from 'react-native';

// constants
import COLORS from './colors';
import FONTS from './fonts';

export const PADDING = {
  LARGE: 34,
};

export const SIZES = {
  ICON: 44,
  HEADER: 48,
};

// Borders
export const BORDER_RADIUS_SMALL = 4;

// Titles
const FONT_TITLE_FAMILY = FONTS.notoSansBold;
const FONT_TITLE_SIZE = 16;
const FONT_TITLE_LETTER_SPACING = 1;
const FONT_TITLE_COLOR = COLORS.greyishBrown;

const FONT_SUBTITLE_FAMILY = FONTS.notoSansBold;
const FONT_SUBTITLE_SIZE = 11;
const FONT_SUBTITLE_LETTER_SPACING = 0.88;
const FONT_SUBTITLE_COLOR = COLORS.warmGrey;

export const MINIMUM_FONT_SCALE = 0.625;

// fab
const FAB_SIZE = 56;
const FAB_COLOR = COLORS.warmGrey;
const FAB_COLOR_TOUCHED = COLORS.greyishBrown;

export const BACKGROUND_COLOR_ALPHA_VALUE = '10';
export const BLOCK_DETAILS_CONTENT_HORIZONTAL_MARGIN = 13;
export const DAILY_WEATHER_ROW_HEIGHT = 60;

export const globalStyles = StyleSheet.create({
  actionsSpacerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: SIZES.ICON,
  },
  bottomContainer: {
    flex: 1,
    backgroundColor: COLORS.greyish20,
  },
  containerFullSizeRow: {
    flex: 1,
    flexDirection: 'row',
  },
  fabButtonStyle: {
    width: FAB_SIZE,
    height: FAB_SIZE,
    borderRadius: FAB_SIZE / 2,
    backgroundColor: FAB_COLOR,
  },
  fabButtonTouchStyle: {
    borderRadius: FAB_SIZE / 2,
    backgroundColor: FAB_COLOR_TOUCHED,
  },
  header: {
    height: SIZES.HEADER,
  },
  header2: {
    height: SIZES.HEADER * 2 + 7,
  },
  headerActions: {
    paddingHorizontal: SIZES.ICON / 2,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.white,
  },
  headerTitle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  icnNav: {
    width: 8,
    height: 14,
  },
  navSpacerContainer: {
    width: SIZES.ICON,
  },
  scrollContainer: {
    paddingBottom: PADDING.LARGE,
  },
  spacerContainer: {
    width: SIZES.ICON * 2,
  },
  scrollContainerFlex: {
    flexGrow: 1,
    paddingBottom: PADDING.LARGE,
  },
  subtitle: {
    marginRight: -FONT_SUBTITLE_LETTER_SPACING,
    fontFamily: FONT_SUBTITLE_FAMILY,
    fontSize: FONT_SUBTITLE_SIZE,
    letterSpacing: FONT_SUBTITLE_LETTER_SPACING,
    color: FONT_SUBTITLE_COLOR,
    textAlignVertical: 'center',
  },
  title: {
    marginRight: -FONT_TITLE_LETTER_SPACING,
    fontFamily: FONT_TITLE_FAMILY,
    fontSize: FONT_TITLE_SIZE,
    letterSpacing: FONT_TITLE_LETTER_SPACING,
    color: FONT_TITLE_COLOR,
    textAlignVertical: 'center',
  },
  topContainer: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  touchOpacity: {
    opacity: 0.2,
  },
});
