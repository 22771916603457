import COLORS from '../../colors';

export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const DISABLED = 'DISABLED';
export const SYNCHRONIZING = 'SYNCHRONIZING';
export const ONGOING = 'ONGOING';
export const OFF = 'OFF';
export const NO_ERROR = 'NO_ERROR';
export const IN_ERROR = 'IN_ERROR';
export const DEVICE_CONFIGURATION_ERROR = 'DEVICE_CONFIGURATION_ERROR';
export const ERROR_READING_COMPONENT = 'ERROR_READING_COMPONENT';
export const ERROR_READING_FEEDBACK = 'ERROR_READING_FEEDBACK';
export const ERROR_COMPONENT_WRONG_STATE = 'ERROR_COMPONENT_WRONG_STATE';
export const ERROR_FEEDBACK = 'ERROR_FEEDBACK';
export const ERROR_COMPONENT_NOT_READY = 'ERROR_COMPONENT_NOT_READY';
export const ERROR_READING_SENSOR = 'ERROR_READING_SENSOR';
export const ERROR_COMMUNICATION_LOST = 'ERROR_COMMUNICATION_LOST';
export const ERROR_CONTROL_RETRY = 'ERROR_CONTROL_RETRY';
export const ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF = 'ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF';
export const ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON = 'ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON';
export const ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON = 'ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON';
export const ERROR_MOTOR_CONTROLLER = 'ERROR_MOTOR_CONTROLLER';

const automationOrder = [
  ONGOING,
  OFF,
  SYNCHRONIZING,
  DISABLED,
  COMMUNICATION_ERROR,
  NO_ERROR,
  DEVICE_CONFIGURATION_ERROR,
  ERROR_READING_COMPONENT,
  ERROR_READING_FEEDBACK,
  ERROR_COMPONENT_WRONG_STATE,
  ERROR_FEEDBACK,
  ERROR_COMPONENT_NOT_READY,
  ERROR_READING_SENSOR,
  ERROR_COMMUNICATION_LOST,
  ERROR_CONTROL_RETRY,
  ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF,
  ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON,
  ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON,
  ERROR_MOTOR_CONTROLLER,
  IN_ERROR,
];

const automationColorByStatus = {
  COMMUNICATION_ERROR: COLORS.pinkishGrey,
  DISABLED: COLORS.pinkishGrey,
  SYNCHRONIZING: COLORS.maize,
  NO_ERROR: COLORS.darkBlue,
  IN_ERROR: COLORS.dustyOrange,
  DEVICE_CONFIGURATION_ERROR: COLORS.dustyOrange,
  ERROR_READING_COMPONENT: COLORS.dustyOrange,
  ERROR_READING_FEEDBACK: COLORS.dustyOrange,
  ERROR_COMPONENT_WRONG_STATE: COLORS.dustyOrange,
  ERROR_FEEDBACK: COLORS.dustyOrange,
  ERROR_COMPONENT_NOT_READY: COLORS.dustyOrange,
  ERROR_READING_SENSOR: COLORS.dustyOrange,
  ERROR_COMMUNICATION_LOST: COLORS.dustyOrange,
  ERROR_CONTROL_RETRY: COLORS.dustyOrange,
  ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF: COLORS.dustyOrange,
  ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON: COLORS.dustyOrange,
  ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON: COLORS.dustyOrange,
  ERROR_MOTOR_CONTROLLER: COLORS.dustyOrange,
  WRONG_STATE_ERROR: COLORS.dustyOrange,
  ONGOING: COLORS.darkSeafoamGreen,
  OFF: COLORS.darkSeafoamGreen,
  [undefined]: COLORS.darkBlue,
  default: COLORS.darkBlue,
};

const automationTranslationByStatus = {
  COMMUNICATION_ERROR: 'automation_status_communication_error',
  DEVICE_CONFIGURATION_ERROR: 'automation_status_device_configuration_error',
  DISABLED: 'automation_status_disabled',
  ERROR_COMMUNICATION_LOST: 'automation_status_error_communication_lost',
  ERROR_COMPONENT_NOT_READY: 'automation_status_error_component_not_ready',
  ERROR_COMPONENT_WRONG_STATE: 'automation_status_wrong_state',
  ERROR_CONTROL_RETRY: 'automation_status_error_control_retry',
  ERROR_FEEDBACK: 'automation_status_feedback_error',
  ERROR_MOTOR_CONTROLLER: 'automation_status_error_motor_controller',
  ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF: 'automation_status_error_output_feedback_high_while_off',
  ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON: 'automation_status_error_output_feedback_low_while_on',
  ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON: 'automation_status_error_output_feedback_too_high_while_on',
  ERROR_READING_COMPONENT: 'automation_status_reading_component',
  ERROR_READING_FEEDBACK: 'automation_status_reading_feedback',
  ERROR_READING_SENSOR: 'automation_status_reading_sensor',
  IN_ERROR: 'automation_status_error',
  NO_ERROR: 'automation_status_no_error',
  ONGOING: 'automation_status_running',
  OFF: 'automation_status_off',
  SYNCHRONIZING: 'automation_status_synchronization',
  [undefined]: 'unknown_status',
  default: 'unknown_status',
};

export const colorForStatus = (status) => automationColorByStatus[status] || automationColorByStatus.default;

export const orderByStatus = (status) => automationOrder.indexOf(status);

export const translationForStatus = (status) => automationTranslationByStatus[status] || automationTranslationByStatus.default;
