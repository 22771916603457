import moment from 'moment-timezone';

export default class GraphEvent {
  constructor(event) {
    this.id = event.id;
    this.startDate = moment(event.startDate);
    this.endDate = !event.endDate ? moment() : moment(event.endDate);
    this.subSensorId = event.subSensorId;
  }
}
