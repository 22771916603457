import { getScheduleResource } from '@hortau/knit';

// utils
import { formatDateForAPI } from '../../utils/dateUtils';

// models
import { EVENT_TYPES, IRRIGATION } from '../../models/eventType';

const formatManualIrrigation = (state) => {
  return {
    anytimeDayEvent: state.isAnytimeEvent,
    blockIds: state.eventBlocks.map((b) => b.id),
    duration: state.eventDuration.asMinutes(),
    eventType: IRRIGATION,
    startDate: formatDateForAPI(state.isAnytimeEvent ? state.eventDate.clone().startOf('day') : state.eventDate.clone()),
  };
};

const formatAutomatedIrrigation = (state) => {
  return {
    resources: state.eventBlocks.map((b) => getScheduleResource(b)),
    duration: state.eventDuration.asMinutes(),
    type: EVENT_TYPES.automatedIrrigation,
    startDate: formatDateForAPI(state.eventDate.clone()),
  };
};

export { formatManualIrrigation, formatAutomatedIrrigation };
