import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
//services
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

import { DATA_STREAMS_TYPES } from '../../../models/dataStreamTypes';
import { useTranslation } from '../../../hooks/useTranslation';

const EventItemStatisticItem = ({ statistic }) => {
  const { t } = useTranslation();

  const IRRIGATION_READING_PREFIX = 'irrigation_reading_';

  const toDisplay = () => {
    switch (statistic.subSensorType) {
      case DATA_STREAMS_TYPES.FLOW.code:
      case DATA_STREAMS_TYPES.PIPE_PRESSURE.code:
      case DATA_STREAMS_TYPES.TRANSDUCER_PRESSURE.code:
        return {
          title: t((IRRIGATION_READING_PREFIX + statistic.subSensorType).toLowerCase()),
          value:
            statistic.minValue === statistic.maxValue
              ? `${statistic.minValue} ${t(statistic.unit)}`
              : `${statistic.minValue}  -  ${statistic.maxValue}  ${t(statistic.unit)}`,
        };
      case DATA_STREAMS_TYPES.TOTAL_VOLUME.code:
        return {
          title: t((IRRIGATION_READING_PREFIX + statistic.subSensorType).toLowerCase()),
          value: `${statistic.maxValue - statistic.minValue}  ${t(statistic.unit)}`,
        };
      default:
        return {
          title: t('irrigation_reading_default'),
          value: `${statistic.maxValue} ${t(statistic.unit)}`,
        };
    }
  };

  const { title, value } = toDisplay();

  return (
    <View style={styles.container}>
      <Text style={styles.statistic}>{title}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

EventItemStatisticItem.propTypes = {
  statistic: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 8,
  },
  statistic: {
    marginRight: 5,
    marginLeft: 10,
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  value: {
    fontFamily: FONTS.firaSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
});

export default React.memo(EventItemStatisticItem);
