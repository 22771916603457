import Config from 'react-native-config';
import fetch from 'cross-fetch';

// service
import { isWeb } from '../services/PlatformService';

const DEFAULT_MAP_MIN_ZOOM_LEVEL = 1;
const DEFAULT_HERE_API_MAX_ZOOM_LEVEL = 20;
export const HERE_STYLE_SATELLITE_URL = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?size=512&style=explore.satellite.day&apiKey=${Config.HERE_API_KEY}`;

const SENTINEL_HUB_WMS_URL = `https://services.sentinel-hub.com/ogc/wms/${Config.SENTINEL_HUB_ID}`;
const WMS_PARAMS_NDVI = new URLSearchParams({
  service: 'WMS',
  version: '1.3.0',
  request: 'GetMap',
  width: '512',
  height: '512',
  layers: 'NDVI',
});
export const SENTINEL_HUB_WMS_NDVI = `${SENTINEL_HUB_WMS_URL}?bbox={bbox-epsg-3857}&${WMS_PARAMS_NDVI}`;
const WMS_PARAMS_FEATURE_NDVI = {
  service: 'WMS',
  version: '1.3.0',
  request: 'GetFeatureInfo',
  bbox: '',
  info_format: 'application/json',
  width: '512',
  height: '512',
  i: '256',
  j: '256',
  crs: 'CRS:84',
  query_layers: 'NDVI',
};

export const MapDisplayMode = {
  TENSION: 'TENSION',
  TEMPERATURE: 'TEMPERATURE',
};

export const MapStyleMode = {
  OUTDOORS: 'OUTDOORS',
  SATELLITE: 'SATELLITE',
  NDVI: 'NDVI',
};

const STYLE_URL_OUTDOORS = 'mapbox://styles/mapbox/outdoors-v11';
const STYLE_URL_NDVI = Config.MAPBOX_NDVI_STYLE_URL;

export const findMapStyleUrl = (mapStyle) => {
  switch (mapStyle) {
    case MapStyleMode.NDVI:
      return STYLE_URL_NDVI;
    case MapStyleMode.OUTDOORS:
      return STYLE_URL_OUTDOORS;
    case MapStyleMode.SATELLITE:
      if (isWeb()) {
        return getSatelliteStyle();
      }
      return STYLE_URL_OUTDOORS;
    default:
      return STYLE_URL_OUTDOORS;
  }
};

const mapStyleButtonOrder = [MapStyleMode.SATELLITE, MapStyleMode.OUTDOORS, MapStyleMode.NDVI];

export const findMapStyleTag = (mapStyle) => {
  switch (mapStyle) {
    case MapStyleMode.OUTDOORS:
      return 'outdoors_layer';
    case MapStyleMode.SATELLITE:
      return 'satellite_layer';
    case MapStyleMode.NDVI:
      return 'ndvi_layer';
    default:
      return 'unknown_layer';
  }
};

// position of the map style button can be 0 or 1
export const findMapStyleByPosition = (position, currentMapStyle) => {
  if (position >= 0 && position < 2) {
    const selectableMapStyles = mapStyleButtonOrder.filter((mapStyle) => currentMapStyle !== mapStyle);
    return selectableMapStyles[position];
  }
};

export const findMapStyleTranslationKey = (mapStyle) => {
  switch (mapStyle) {
    case MapStyleMode.OUTDOORS:
      return 'map_style_outdoor';
    case MapStyleMode.SATELLITE:
      return 'map_style_satellite';
    case MapStyleMode.NDVI:
      return 'map_style_ndvi';
    default:
      return '';
  }
};

export const getSatelliteStyle = () => {
  return {
    version: 8,
    sources: {
      'here-map-source': {
        type: 'raster',
        tiles: [HERE_STYLE_SATELLITE_URL],
        tileSize: 512,
      },
    },
    layers: [
      {
        id: 'here-map-layer',
        type: 'raster',
        source: 'here-map-source',
        minzoom: DEFAULT_MAP_MIN_ZOOM_LEVEL,
        maxzoom: DEFAULT_HERE_API_MAX_ZOOM_LEVEL,
      },
    ],
    glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  };
};

export const getNDVIDate = async (bounds) => {
  try {
    const [[neLng, neLat], [swLng, swLat]] = bounds;
    const params = new URLSearchParams({ ...WMS_PARAMS_FEATURE_NDVI, bbox: `${swLng},${swLat},${neLng},${neLat}` });
    const url = `${SENTINEL_HUB_WMS_URL}?${params.toString()}`;

    const response = await fetch(url);
    const result = await response.json();

    if (response.status === 200) {
      return { success: true, result };
    }
    return { success: false, error: null };
  } catch (error) {
    return { success: false, error };
  }
};
