import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { useSharedValue } from 'react-native-reanimated';

// services
import { SORT_ORDER, toggleSort } from '../../utils/sorting';
import { navigationShape } from '../../shapes/navigation';

// styles
import { globalStyles } from '../../styles';

// components
import SortableList from '../../components/SortableList';
import SkeletonMultiRowView from '../../components/skeletons/SkeletonMultiRow';
import SortableFlowStationHeader from './SortableFlowStationHeader';
import { SORT_COLUMNS } from './sorting';
import FlowStationBlockItem from './FlowStationBlockItem';

const FlowStationScreen = ({ navigation }) => {
  const loading = useSelector((state) => !!state.loading.effects.blocks.loadFlowStationBlocksForCurrentSite);
  const sortLoading = useSelector((state) => !!state.loading.effects.blocks.sortFlowStationBlocks);
  const flowStationBlocks = useSelector((state) => state.blocks.sortedFlowStationBlocks);
  const irrigationAnimatedOpacity = useSharedValue(1);
  const listRef = useRef(undefined);
  const initialSortOrderState = {
    [SORT_COLUMNS.BLOCK]: SORT_ORDER.NONE,
    [SORT_COLUMNS.FLOW]: SORT_ORDER.NONE,
    [SORT_COLUMNS.VOLUME]: SORT_ORDER.NONE,
  };
  const dispatch = useDispatch();

  const [refreshing, setRefreshing] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  const sortFlowStationBlocksFromSortedColumn = () => {
    if (sortedColumn[SORT_COLUMNS.BLOCK] !== SORT_ORDER.NONE) {
      dispatch.blocks.sortFlowStationBlocks({ column: SORT_COLUMNS.BLOCK, order: sortedColumn[SORT_COLUMNS.BLOCK] });
    } else if (sortedColumn[SORT_COLUMNS.FLOW] !== SORT_ORDER.NONE) {
      dispatch.blocks.sortFlowStationBlocks({ column: SORT_COLUMNS.FLOW, order: sortedColumn[SORT_COLUMNS.FLOW] });
    } else if (sortedColumn[SORT_COLUMNS.VOLUME] !== SORT_ORDER.NONE) {
      dispatch.blocks.sortFlowStationBlocks({ column: SORT_COLUMNS.VOLUME, order: sortedColumn[SORT_COLUMNS.VOLUME] });
    }
  };

  const loadAndSortBlocks = async () => {
    await dispatch.blocks.loadFlowStationBlocksForCurrentSite();
    await sortFlowStationBlocksFromSortedColumn();
  };

  const refreshBlocks = async () => {
    setRefreshing(true);
    await loadAndSortBlocks();
    setRefreshing(false);
  };

  const renderEmptyView = () => {
    return <SkeletonMultiRowView repetition={4} rows={1} />;
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setSortedColumn(initialSortOrderState);
      dispatch.blocks.loadFlowStationBlocksForCurrentSite();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  useEffect(() => {
    sortFlowStationBlocksFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const renderFlowStationBlockItem = (element) => {
    return (
      <FlowStationBlockItem
        keyIndex={element.index}
        block={element.item}
        navigation={navigation}
        animatedOpacity={irrigationAnimatedOpacity}
      />
    );
  };

  return (
    <View testID="flow-station__subScreen-container" style={globalStyles.bottomContainer}>
      <SortableFlowStationHeader sortedColumn={sortedColumn} toggle={toggle} loading={sortLoading} />
      <SortableList
        testID={'flow-station__subScreen_list'}
        ref={listRef}
        elements={flowStationBlocks}
        loading={loading}
        refreshing={refreshing}
        sort={dispatch.blocks.sortFlowStationBlocks}
        renderEmptyView={renderEmptyView}
        renderItem={renderFlowStationBlockItem}
        screenName={'flow-station'}
        onRefresh={refreshBlocks}
      />
    </View>
  );
};

FlowStationScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default FlowStationScreen;
