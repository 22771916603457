import { processColor } from 'react-native';

// services
import { isIos } from '../../services/PlatformService';

//utils
import { toMomentDate, isInLessThanAWeek, middleMillisecondBetweenMoments, momentToMilliseconds } from '../../utils/dateUtils';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import { DATA_STREAMS_TYPES } from '../../models/dataStreamTypes';
import { maxDateAsMilliseconds, maxDateWithFullForecast, minDateAsMilliseconds } from './DateChartConstants';

const getLineColor = (id, tensionReadings) => {
  let result = COLORS.whiteThree;
  if (tensionReadings) {
    const matchingReading = tensionReadings.find((reading) => reading.id === id);
    if (matchingReading) {
      if (matchingReading.color) {
        result = matchingReading.color;
      } else if (matchingReading.colorCode) {
        result = `#${matchingReading.colorCode}`;
      }
    }
  }

  return result;
};

const buildDataset = (dataStream, axisDependency, colors) => {
  return {
    values: dataStream.dataStreamData,
    label: 'Datastream data',
    config: {
      colors,
      drawValues: false,
      drawCircles: false,
      axisDependency,
      highlightEnabled: false,
      lineWidth: 2.5,
      mode: 'HORIZONTAL_BEZIER',
    },
  };
};

export const buildTensionForecaset = (tensionValues, axisDependency, colors) => {
  return {
    values: tensionValues,
    label: 'Forecast data',
    config: {
      colors,
      drawValues: false,
      drawCircles: false,
      axisDependency,
      highlightEnabled: false,
      lineWidth: 2.5,
      mode: 'HORIZONTAL_BEZIER',
      dashedLine: {
        lineLength: isIos() ? 4 : 8,
        spaceLength: isIos() ? 2 : 4,
      },
    },
  };
};

export const hasTensions = (streams) =>
  streams.some((dataStream) => dataStream.dataStreamType === DATA_STREAMS_TYPES.WATER_TENSION.code);

export const buildLineData = (
  dataStreamsHeader,
  selectedDataStreams,
  comfortZone,
  showComfortZone,
  tensionReadings,
  irrigationEvents,
  yAxisConfig,
  graphPresenter,
  eventProducers,
  tensionForecast,
  futureIrrigationEvents,
) => {
  let data = {
    lineData: {
      dataSets: [],
    },
  };

  const hasForecast = (streams) => {
    return streams.some((stream) => {
      return tensionForecast?.some((forecast) => forecast.tensionSensorID === stream.id);
    });
  };
  const hasComfortZone = (streams) => comfortZone && showComfortZone && hasTensions(streams);

  const putLineData = (streams, dependency) => {
    streams.forEach((dataStream) => {
      const lineColors = [];
      let forecastFound;

      if (dataStream.dataStreamType === DATA_STREAMS_TYPES.WATER_TENSION.code) {
        lineColors.push(processColor(getLineColor(dataStream.id, tensionReadings)));

        forecastFound = tensionForecast?.find((forecast) => forecast.tensionSensorID === dataStream.id);
      } else {
        lineColors.push(processColor(getLineColor(dataStream.id, dataStreamsHeader)));
      }

      data.lineData.dataSets.push(buildDataset(dataStream, dependency, lineColors));

      if (forecastFound) {
        data.lineData.dataSets.push(buildTensionForecaset(forecastFound.tensionValues, dependency, lineColors));
      }
    });
  };

  const hasForecastOnLeft = hasForecast(selectedDataStreams.left);
  const hasForecastOnRight = hasForecast(selectedDataStreams.right);

  if (hasComfortZone(selectedDataStreams.left)) {
    const comfortZoneDataLeft = buildComfortZoneData(comfortZone, 'LEFT', hasForecastOnLeft);
    data.lineData.dataSets.push(comfortZoneDataLeft);
  } else if (hasComfortZone(selectedDataStreams.right)) {
    const comfortZoneDataRight = buildComfortZoneData(comfortZone, 'RIGHT', hasForecastOnRight);
    data.lineData.dataSets.push(comfortZoneDataRight);
  }

  const buildAndAddData = (side, isFutureEvents) => {
    const axisConfig = yAxisConfig[side.toLowerCase()];
    const forecastEvents = isFutureEvents ? futureIrrigationEvents : irrigationEvents;
    const producers = isFutureEvents ? null : eventProducers;

    buildIrrigationEventsData(
      forecastEvents,
      axisConfig.axisMinimum,
      axisConfig.axisMaximum,
      side.toUpperCase(),
      graphPresenter,
      producers,
      isFutureEvents,
    ).forEach((dataset) => {
      data.lineData.dataSets.push(dataset);
    });
  };

  if (hasTensions(selectedDataStreams.left)) {
    buildAndAddData('left', false);
    if (hasForecastOnLeft) {
      buildAndAddData('left', true);
    }
  } else if (hasTensions(selectedDataStreams.right)) {
    buildAndAddData('right', false);
    if (hasForecastOnRight) {
      buildAndAddData('right', true);
    }
  }

  putLineData(selectedDataStreams.left, 'LEFT');
  putLineData(selectedDataStreams.right, 'RIGHT');

  return data;
};

const buildComfortZoneData = (optimalTension, axisDependency, hasForecast) => {
  const MIN_DATE_AS_MILLISECONDS = minDateAsMilliseconds();
  return {
    values: [
      { x: MIN_DATE_AS_MILLISECONDS, y: optimalTension.maximum },
      {
        x: hasForecast ? maxDateWithFullForecast() : maxDateAsMilliseconds(),
        y: optimalTension.maximum,
      },
    ],
    label: 'Comfort Zone',
    config: {
      color: 0,
      drawFilled: true,
      drawValues: false,
      drawCircles: false,
      highlightEnabled: false,
      axisDependency,
      fillColor: processColor(COLORS.cerulean),
      fillFormatter: {
        min: optimalTension.minimum,
      },
    },
  };
};

const IRRIGATION_EVENT_DATASET_LABEL = 'Event data';
const FUTURE_IRRIGATION_EVENT_DATASET_LABEL = 'Future event data';
const buildIrrigationEventsData = (
  irrigationEvents = [],
  minValue,
  maxValue,
  axisDependency,
  graphPresenter,
  eventProducers = {},
  isFutureEvents,
) => {
  return irrigationEvents.map((event) => {
    let color = isFutureEvents ? COLORS.pictonBlue : (eventProducers[event.subSensorId]?.color ?? COLORS.pictonBlue);
    let fillAlpha = isFutureEvents ? 45 : 140;
    let eventLabel = IRRIGATION_EVENT_DATASET_LABEL;
    let eventEndDate = event.endDate;

    if (isFutureEvents) {
      eventLabel = FUTURE_IRRIGATION_EVENT_DATASET_LABEL;

      const maxDateFullForecast = toMomentDate(maxDateWithFullForecast());
      eventEndDate = isInLessThanAWeek(event.endDate) ? event.endDate : maxDateFullForecast;
    }

    return {
      values: [
        {
          x: momentToMilliseconds(event.startDate),
          y: maxValue,
        },
        {
          x: middleMillisecondBetweenMoments(event.startDate, eventEndDate),
          y: maxValue,
        },
        {
          x: momentToMilliseconds(event.endDate),
          y: maxValue,
        },
      ],
      label: eventLabel,
      config: {
        color: 0,
        drawFilled: true,
        drawValues: graphPresenter.shouldDisplayIrrigationEventDuration(irrigationEvents),
        drawCircles: false,
        highlightEnabled: false,
        axisDependency,
        valueTextSize: 11,
        valueTextColor: processColor(color),
        fontFamily: FONTS.firaSansBold,
        valueFormatter: 'labelByXValue',
        valueFormatterLabels: [
          {
            x: middleMillisecondBetweenMoments(event.startDate, eventEndDate),
            label: graphPresenter.formatIrrigationEventDuration(event),
          },
        ],
        fillColor: processColor(color),
        fillAlpha,
        fillFormatter: {
          min: minValue,
        },
      },
    };
  });
};

export const changeIrrigationEventsLabelsInChartData = (irrigationEvents, graphIrrigationEventPresenter, chartData) => {
  return {
    lineData: {
      dataSets: chartData.lineData.dataSets.map((dataSet) => {
        if (dataSet.label === IRRIGATION_EVENT_DATASET_LABEL) {
          const newDataSet = JSON.parse(JSON.stringify(dataSet));
          newDataSet.config.drawValues = graphIrrigationEventPresenter.shouldDisplayIrrigationEventDuration(irrigationEvents);
          return newDataSet;
        }
        return dataSet;
      }),
    },
  };
};
