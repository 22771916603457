import { compareBlock, SORT_ORDER } from '../../utils/sorting';

const SORT_COLUMNS = {
  BLOCK: 'block',
  FLOW: 'flow',
  VOLUME: 'volume',
};

const sortFlowStationBlocks = (flowStationBlocks, column, order) => {
  const compareByBlock = (a, b) => compareBlock(a, b, order);

  const compareByFlow = (a, b) => {
    const compareByFlowmeterFlow = (flowmeterA, flowmeterB) => {
      const flowA = flowmeterA.outflow ? flowmeterA.outflow.value : Number.MIN_VALUE;
      const flowB = flowmeterB.outflow ? flowmeterB.outflow.value : Number.MIN_VALUE;

      if (flowA > flowB) {
        return order === SORT_ORDER.ASC ? 1 : -1;
      } else if (flowA < flowB) {
        return order === SORT_ORDER.ASC ? -1 : 1;
      }
      return 0;
    };

    const flowmetersA = a.flowStationCapability().flowmeters();
    const flowmetersB = b.flowStationCapability().flowmeters();

    flowmetersA.sort(compareByFlowmeterFlow);
    flowmetersB.sort(compareByFlowmeterFlow);

    const firstFlowA = flowmetersA.length > 0 && flowmetersA[0].outflow ? flowmetersA[0].outflow.value : Number.MIN_VALUE;
    const firstFlowB = flowmetersB.length > 0 && flowmetersB[0].outflow ? flowmetersB[0].outflow.value : Number.MIN_VALUE;

    if (firstFlowA > firstFlowB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (firstFlowA < firstFlowB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return order === SORT_ORDER.ASC ? flowmetersA.length - flowmetersB.length : flowmetersB.length - flowmetersA.length;
  };

  const compareByTotalVolume = (a, b) => {
    const compareByFlowmeterTotalVolume = (flowmeterA, flowmeterB) => {
      const volumeA = flowmeterA.totalVolume ? flowmeterA.totalVolume.value : Number.MIN_VALUE;
      const volumeB = flowmeterB.totalVolume ? flowmeterB.totalVolume.value : Number.MIN_VALUE;

      if (volumeA > volumeB) {
        return order === SORT_ORDER.ASC ? 1 : -1;
      } else if (volumeA < volumeB) {
        return order === SORT_ORDER.ASC ? -1 : 1;
      }
      return 0;
    };

    const flowmetersA = a.flowStationCapability().flowmeters();
    const flowmetersB = b.flowStationCapability().flowmeters();

    flowmetersA.sort(compareByFlowmeterTotalVolume);
    flowmetersB.sort(compareByFlowmeterTotalVolume);

    const firstVolumeA =
      flowmetersA.length > 0 && flowmetersA[0].totalVolume ? flowmetersA[0].totalVolume.value : Number.MIN_VALUE;
    const firstVolumeB =
      flowmetersB.length > 0 && flowmetersA[0].totalVolume ? flowmetersB[0].totalVolume.value : Number.MIN_VALUE;

    if (firstVolumeA > firstVolumeB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (firstVolumeA < firstVolumeB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return order === SORT_ORDER.ASC ? flowmetersA.length - flowmetersB.length : flowmetersB.length - flowmetersA.length;
  };

  switch (column) {
    case SORT_COLUMNS.BLOCK: {
      flowStationBlocks.sort(compareByBlock);
      break;
    }
    case SORT_COLUMNS.FLOW: {
      flowStationBlocks.sort(compareByFlow);
      break;
    }
    case SORT_COLUMNS.VOLUME: {
      flowStationBlocks.sort(compareByTotalVolume);
      break;
    }
  }
};

export { SORT_COLUMNS, sortFlowStationBlocks };
