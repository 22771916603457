import moment from 'moment-timezone';

// queries
import { fetchNotes } from '../queries/note';
import { executeApolloQuery } from './apolloHelper';

export const notes = {
  state: {
    blocks: [],
    notes: [],
  },

  reducers: {
    updateNotes(state, dataNotes) {
      let blocksWithNotes = [];
      if (dataNotes.blocks) {
        dataNotes.blocks.forEach((block) => {
          if (block.notes && block.notes.length > 0) {
            block.notes.sort(compareNoteCreatedAt);
            blocksWithNotes.push(block);
          }
        });
      }

      let siteNotes = [];
      if (dataNotes.notes) {
        dataNotes.notes.sort(compareNoteCreatedAt);
        siteNotes = [...dataNotes.notes];
      }

      return { ...state, blocks: blocksWithNotes, notes: siteNotes };
    },
  },

  effects: (dispatch) => ({
    async loadNotes(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchNotes(rootState.site.currentSite.id, payload.startDate, payload.endDate),
        (data) => {
          dispatch.notes.updateNotes(data.notes);
        },
      );
    },
  }),
};

const compareNoteCreatedAt = (noteA, noteB) => {
  const noteACreatedAt = moment(noteA.createdAt);
  const noteBCreatedAt = moment(noteB.createdAt);

  if (noteACreatedAt.isBefore(noteBCreatedAt)) {
    return 1;
  } else if (noteACreatedAt.isAfter(noteBCreatedAt)) {
    return -1;
  }
  return 0;
};
