/* istanbul ignore file */
import React from 'react';
import { ImageBackground, ScrollView, View } from 'react-native';
import PropTypes from 'prop-types';

import { backgroundImage } from '../../assets/index';
import styles from './AuthContainer.style';

const AuthContainer = ({ children }) => {
  return (
    <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>{children}</ScrollView>
      </View>
    </ImageBackground>
  );
};

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContainer;
