import { celsiusToFahrenheit, kpaToPsi } from './mathUtil';

const END_OF_LINE_CHAR = '\r';

export default class terminalParser {
  constructor() {
    this.unfinishedLine = '';
    this.filterData = false;
  }

  parse(data, filterData) {
    this.filterData = filterData;
    const [lines, unfinished] = this.extractLines(this.unfinishedLine + data);
    this.unfinishedLine = unfinished;
    return lines;
  }

  extractLines(data) {
    const lines = data.split(END_OF_LINE_CHAR).map(this.cleanPrompt);
    const lastChar = data.charAt(data.length - 1);
    const unfinished = lastChar === END_OF_LINE_CHAR || lines.length === 0 ? '' : lines.pop();
    let extractedLines = lines.filter((line) => this.filterEmptyLines(line));
    return [this.processLines(extractedLines), unfinished];
  }

  cleanPrompt(line) {
    return line.replace('CELL>', '');
  }

  filterEmptyLines(line) {
    return !(line.trim() === '');
  }

  processLines(lines) {
    let processedLines = lines;
    if (this.filterData) {
      processedLines = processedLines.filter((line) => !this.isData(line));
    } else {
      processedLines = processedLines.map(this.processData, this);
    }
    return processedLines.map(this.removeTimestamp);
  }

  isData(line) {
    return line.startsWith('[DATA]');
  }

  processData(line) {
    const regex = /^\[DATA\] - producers\/((?:[^:/]+\/)+)([^/:]+)\s*:\s*(-?[\d.]+)/;

    const match = line.match(regex);
    if (match && match.length === 4) {
      return '  =>  ' + this.formatData(match);
    }
    return line;
  }

  formatData(match) {
    switch (match[2].trim()) {
      case 'TEMP':
      case 'TEMPERATURE':
      case 'OILTEMP':
        return this.processTemperature(match);
      case 'PRESS':
      case 'OILPRESSURE':
      case 'PRESSURE':
        return this.processPressure(match);
      default:
        return match[1] + match[2] + ': ' + match[3];
    }
  }

  processTemperature(match) {
    const floatValue = parseFloat(match[3]);
    const celsius = Math.round(floatValue);
    return match[1] + match[2] + ': ' + celsiusToFahrenheit(celsius) + '\u00B0F' + ' / ' + celsius + '\u00B0C';
  }

  processPressure(match) {
    const floatValue = parseFloat(match[3]);
    const kpa = Math.round(floatValue);
    return match[1] + match[2] + ': ' + kpaToPsi(kpa) + ' PSI';
  }

  removeTimestamp(line) {
    const regexTrace = /^\[(\w+)-(\d+)] (.+)$/;
    const matchesTrace = line.match(regexTrace);
    if (matchesTrace && matchesTrace.length === 4) {
      return matchesTrace[matchesTrace.length - 1];
    }
    return line;
  }
}
