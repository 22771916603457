import React from 'react';
import { Image } from 'react-native';
import PropTypes from 'prop-types';
import { getLanguage } from '../i18n/localize';

const LocalizedImage = ({ image, ...otherProps }) => {
  const language = getLanguage();
  const localizedLanguage = language in image ? language : 'default';

  const localizedObject = image[localizedLanguage];
  const source = localizedObject.image;
  const style = localizedObject.style;

  return <Image source={source} style={style} {...otherProps} />;
};

LocalizedImage.propTypes = {
  image: PropTypes.object.isRequired,
};

export default LocalizedImage;
