import PropTypes from 'prop-types';

export const SchedulerBlockShape = PropTypes.shape({
  alias: PropTypes.string,
  groupId: PropTypes.string,
  id: PropTypes.string,
  notes: PropTypes.array,
  name: PropTypes.string,
  inferredEvents: PropTypes.array,
  plannedEvents: PropTypes.array,
});
