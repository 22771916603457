import COLORS from '../../colors';

import NORMAL_ICON from '../../assets/icons/icnNormal.png';
import COMMUNICATION_ERROR_ICON from '../../assets/icons/icnCommunicationError.png';
import OUT_OF_FIELD_ICON from '../../assets/icons/icnOutOfField.png';

export const NORMAL = 'NORMAL';
export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const OUT_OF_FIELD = 'OUT_OF_FIELD';

const deviceColorsByStatus = {
  NORMAL: COLORS.seafoamBlue,
  COMMUNICATION_ERROR: COLORS.greyishThree,
  OUT_OF_FIELD: COLORS.darkBlue,
  [undefined]: COLORS.darkBlue,
  default: COLORS.darkBlue,
};

const deviceIconByStatus = {
  NORMAL: NORMAL_ICON,
  COMMUNICATION_ERROR: COMMUNICATION_ERROR_ICON,
  OUT_OF_FIELD: OUT_OF_FIELD_ICON,
  default: COMMUNICATION_ERROR_ICON,
};

export const colorForStatus = (status) => deviceColorsByStatus[status] || deviceColorsByStatus.default;

export const iconForStatus = (status) => deviceIconByStatus[status] || deviceIconByStatus.default;
