import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Image, Linking } from 'react-native';

// icons
import icnPestProphet from '../../assets/icons/icnPestProphet.png';

// constants
import OPACITIES from '../../opacities';
import COLORS from '../../colors';

// shape
import { styleShape } from '../../shapes/style';

// services
import ANALYTICS from '../../services/AnalyticsEvents';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';

// components
import CustomButton from '../button/CustomButton';

const PEST_PROPHET_LINK = 'https://app.pestprophet.com';

const PestProphet = ({ contentStyle, testId }) => {
  const analyticsService = useAnalyticsContext();

  const openPestProphetLink = () => {
    Linking.openURL(PEST_PROPHET_LINK).then(() => {
      analyticsService.trackEvent(ANALYTICS.eventViewPestProphet);
    });
  };

  return (
    <View style={contentStyle}>
      <CustomButton
        onPress={openPestProphetLink}
        buttonStyle={styles.componentStyle}
        testId={testId}
        underlayColor={COLORS.whiteTwo}>
        <View style={styles.bigButtonContent}>
          <Image source={icnPestProphet} style={styles.icon} />
        </View>
      </CustomButton>
    </View>
  );
};

PestProphet.propTypes = {
  contentStyle: styleShape,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  bigButtonContent: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 98,
  },
  componentStyle: {
    borderRadius: 4,
    backgroundColor: COLORS.white,
    elevation: 2,
    shadowColor: COLORS.greyShadow,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 2,
  },
  icon: {
    width: 300,
    height: 64,
  },
});

export default PestProphet;
