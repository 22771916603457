import { gql } from 'graphql-tag';

export const MANAGE_EVENT = gql`
  mutation ManageEvent($eventId: ID, $event: eventInput) {
    manageEvent(eventId: $eventId, event: $event) {
      id
      type
      anytimeDayEvent
      startDate
      endDate
    }
  }
`;
