import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';

// const
import COLORS from '../../colors';
import ANALYTICS from '../../services/AnalyticsEvents';
import { DAILY_WEATHER_ROW_HEIGHT } from '../../styles';

// hooks
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';

// components
import Divider from '../Divider';
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';
import DailyWeather from './upcomingDaysWeather/DailyWeather';

const UpcomingDaysWeather = ({ forecasts = [], loading, scrollIndex = 0 }) => {
  const analyticsService = useAnalyticsContext();
  const showSkeleton = loading || forecasts.length === 0;
  const containerStyle = showSkeleton ? {} : styles.container;
  const daysList = useRef(null);
  const index = scrollIndex < 1 ? 0 : scrollIndex - 1;

  useEffect(() => {
    if (forecasts.length < 1 || loading) {
      return;
    }

    daysList.current?.scrollToIndex({ index, animated: false });
  }, [forecasts.length, index, loading, scrollIndex]);

  const renderEmptyView = () => (
    <>
      {[...Array(8)].map((_e, i) => (
        <View key={'row' + i}>
          <View style={styles.skeletonRow} key={'topRow' + i}>
            <SkeletonPlaceholder width={20} height={20} />
            <SkeletonPlaceholder width={70} height={14} />
            <View />
          </View>
          <View style={styles.skeletonRow} key={'bottomRow' + i}>
            <SkeletonPlaceholder width={60} height={14} />
            <SkeletonPlaceholder width={60} height={14} />
            <SkeletonPlaceholder width={60} height={14} />
            <SkeletonPlaceholder width={60} height={14} />
          </View>
          <Divider style={styles.itemDivider} />
        </View>
      ))}
    </>
  );

  const renderDailyWeatherCard = (element) => (
    <DailyWeather
      date={element.item.date}
      etoUnit={element.item.eto?.unit}
      etoValue={element.item.eto?.value}
      temperatureMax={element.item.temperature?.max}
      temperatureMin={element.item.temperature?.min}
      temperatureUnit={element.item.temperature?.unit}
      rainPrecipitation={element.item.rain?.precipitation}
      rainProbability={element.item.rain?.probability}
      rainUnit={element.item.rain?.unit}
      weatherCode={element.item.weatherCode}
      windDirection={element.item.wind?.direction}
      windSpeed={element.item.wind?.speed}
      windUnit={element.item.wind?.unit}
    />
  );

  const dividerItem = () => <Divider style={styles.itemDivider} />;

  return (
    <FlashList
      ref={daysList}
      data={forecasts}
      renderItem={renderDailyWeatherCard}
      initialScrollIndex={index}
      estimatedItemSize={DAILY_WEATHER_ROW_HEIGHT}
      disableHorizontalListHeightMeasurement={true}
      ListEmptyComponent={renderEmptyView}
      contentContainerStyle={containerStyle}
      ItemSeparatorComponent={dividerItem}
      onScrollEndDrag={() => analyticsService.trackEvent(ANALYTICS.weatherForecastsListScroll)}
      keyExtractor={(item) => item.date}
      testID={'upcoming_days_weather_list'}
    />
  );
};

UpcomingDaysWeather.propTypes = {
  forecasts: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  scrollIndex: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.white,
  },
  itemDivider: {
    borderBottomColor: COLORS.whiteThree,
  },
  skeletonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 6,
    marginBottom: 6,
  },
});

export default UpcomingDaysWeather;
