import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// components
import GenericSection from '../../layouts/GenericSection';
import IrrigationButton from '../../button/IrrigationButton';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

//model
import { EVENT_TYPES, isAutomatedIrrigation, isManualIrrigation } from '../../../models/eventType';

const noop = () => {};

const EventTypeSelector = ({ isModificationMode, eventTypeSelected, onChange }) => {
  const { t } = useTranslation();

  const isShowingManualIrrigation = isModificationMode ? true : isManualIrrigation(eventTypeSelected);
  const isShowingAutomatedIrrigation = isModificationMode ? true : isAutomatedIrrigation(eventTypeSelected);

  const onChangeEventType = isModificationMode ? onChange : noop;

  return (
    <GenericSection title={t('irrigation_type')} testID="event_type_selector">
      <View style={styles.container}>
        {isShowingManualIrrigation ? (
          <IrrigationButton
            type={EVENT_TYPES.manualIrrigation}
            isActive={isManualIrrigation(eventTypeSelected)}
            onPress={() => onChangeEventType(EVENT_TYPES.manualIrrigation)}
            testID="event_type_selector_manualButton"
          />
        ) : null}

        {isShowingAutomatedIrrigation ? (
          <IrrigationButton
            type={EVENT_TYPES.automatedIrrigation}
            isActive={isAutomatedIrrigation(eventTypeSelected)}
            onPress={() => onChangeEventType(EVENT_TYPES.automatedIrrigation)}
            testID="event_type_selector_automatedButton"
          />
        ) : null}
      </View>
    </GenericSection>
  );
};

EventTypeSelector.propTypes = {
  isModificationMode: PropTypes.bool,
  eventTypeSelected: PropTypes.oneOf([EVENT_TYPES.automatedIrrigation, EVENT_TYPES.manualIrrigation]).isRequired,
  onChange: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: 13,
  },
});

export default EventTypeSelector;
