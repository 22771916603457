import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, ScrollView, Text, View, RefreshControl, useWindowDimensions } from 'react-native';
import { DataProvider, LayoutProvider, RecyclerListView } from 'recyclerlistview';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// hook
import { useTranslation } from '../../hooks/useTranslation';

// constants for style
const CELL_HEIGHT = 46;
const CELL_WIDTH_NAME = 90;
const CELL_WIDTH_TOTAL = 80;
const FIRST_COL_PADDING = 10;
const CONTAINER_PADDING = 10;

const GREY = COLORS.whiteThree;

const ColTypes = {
  NORMAL: 0,
  TOTAL: 1,
};

// istanbul ignore next
const dataProviderStart = new DataProvider((r1, r2) => {
  return r1.start !== r2.start;
});

// istanbul ignore next
const dataProviderVal = new DataProvider((r1, r2) => {
  return r1.val !== r2.val;
});

// istanbul ignore next
const setTotalCol = (type) => {
  if (type === ColTypes.TOTAL) {
    return styles.totalCol;
  }
  return {};
};

// istanbul ignore next
const renderTopHeaders = (layout, data) => (
  <View style={[styles.center, layout.type === ColTypes.TOTAL ? { width: CELL_WIDTH_TOTAL } : {}]}>
    <Text style={[styles.headerText, layout.type === ColTypes.TOTAL ? styles.centerText : {}]}>{data.start}</Text>
    {data.end ? <Text style={styles.headerText}>{data.end}</Text> : null}
  </View>
);

// istanbul ignore next
const renderTotal = (layout, data) => (
  <View style={[styles.center, setTotalCol(layout.type)]}>
    <Text style={styles.totalText}>{data.val}</Text>
  </View>
);

const Table = ({
  rowHeaders,
  colHeaders,
  dataTable,
  totals,
  onRefresh,
  isRefreshing,
  displayRowTotal = true,
  fitToScreenSize = false,
  scrollToColIndex = 0,
  isHortau = false,
}) => {
  const { t } = useTranslation();
  const window = useWindowDimensions();

  const topRow = useRef(null);
  const cells = useRef(null);
  const totalRow = useRef(null);

  const totalColumns = isHortau ? 3 : 1;
  const nbCols = colHeaders.length;
  const nbRows = rowHeaders.length;
  const tableWidth = window.width - CELL_WIDTH_NAME - CONTAINER_PADDING - 32;
  const CELL_WIDTH = !fitToScreenSize ? 55 : tableWidth / nbCols;
  const cellsDefaultIndex = !fitToScreenSize ? scrollToColIndex : 0;
  const cellsDefaultIndexOffset = cellsDefaultIndex === 0 ? 0 : Math.max(0, CELL_WIDTH * (cellsDefaultIndex + 1) - tableWidth);

  const [dataHeaders, setDataHeaders] = useState(() => dataProviderStart);
  const [dataCells, setDataCells] = useState(() => dataProviderVal);
  const [dataTotals, setDataTotals] = useState(() => dataProviderVal);

  useEffect(() => {
    setDataHeaders((prevState) => prevState.cloneWithRows(colHeaders));
    setDataCells((prevState) => prevState.cloneWithRows(dataTable));
    setDataTotals((prevState) => prevState.cloneWithRows(totals));
  }, [colHeaders, dataTable, totals]);

  // istanbul ignore next
  const renderCell = (layout, data) => (
    <View
      style={[
        styles.center,
        (layout.index % nbRows) % 2 ? styles.backgroundWhite : styles.backgroundGrey,
        setTotalCol(layout.type),
      ]}>
      <Text style={styles.cellText}>{data.val}</Text>
    </View>
  );

  // istanbul ignore next
  const onScrollHorizontal = ({ nativeEvent }) => {
    const x = nativeEvent.contentOffset.x;
    topRow.current?.scrollToOffset(x, 0, false);
    totalRow.current?.scrollToOffset(x, 0, false);
  };

  // istanbul ignore next
  const getLayoutColTypeForIndex = (index) => {
    if (!displayRowTotal || index < nbRows * (nbCols - totalColumns)) {
      return { index, type: ColTypes.NORMAL };
    }
    return { index, type: ColTypes.TOTAL };
  };

  // istanbul ignore next
  const getLayoutRowTypeForIndex = (index) => {
    if (!displayRowTotal || index < nbCols - totalColumns) {
      return { index, type: ColTypes.NORMAL };
    }
    return { index, type: ColTypes.TOTAL };
  };

  const setLayoutForType = (layout, dim) => {
    switch (layout.type) {
      case ColTypes.NORMAL:
        dim.height = CELL_HEIGHT;
        dim.width = CELL_WIDTH;
        break;
      case ColTypes.TOTAL:
        dim.height = CELL_HEIGHT;
        dim.width = CELL_WIDTH_TOTAL;
        break;
    }
  };

  const layoutProviderCol = new LayoutProvider(getLayoutColTypeForIndex, setLayoutForType);
  const layoutProviderRow = new LayoutProvider(getLayoutRowTypeForIndex, setLayoutForType);

  return (
    <SafeAreaView style={styles.safeArea} edges={['right', 'left', 'bottom']}>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <View style={styles.headerRow}>
            <View style={[styles.headerCol, styles.firstCol]}>
              <Text style={styles.headerText}>{t('report_name')}</Text>
            </View>

            {dataHeaders.getSize() > 0 ? (
              <RecyclerListView
                style={styles.monthsRecyclerView}
                ref={topRow}
                initialRenderIndex={0}
                isHorizontal={true}
                layoutProvider={layoutProviderRow}
                dataProvider={dataHeaders}
                rowRenderer={renderTopHeaders}
                scrollViewProps={{
                  showsHorizontalScrollIndicator: false,
                  scrollEnabled: false,
                  bounces: false,
                  overScrollMode: 'never',
                }}
                showsVerticalScrollIndicator={false}
                disableRecycling={true}
                testID="table__topRow"
              />
            ) : null}
          </View>
          <ScrollView
            refreshControl={<RefreshControl testID="table__refresh-controller" onRefresh={onRefresh} refreshing={isRefreshing} />}
            contentContainerStyle={[styles.dataGrid, { height: CELL_HEIGHT * nbRows + 1 }]}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            scrollEventThrottle={1000}
            testID="table__scrollview">
            <View style={[styles.blockNames, styles.firstCol]}>
              {rowHeaders.map((data, index) => (
                <View
                  style={[styles.blockNameCell, index % 2 ? styles.backgroundWhite : styles.backgroundGrey, styles.firstCol]}
                  key={`table__rowHeader-${index}`}>
                  <Text style={styles.headerText}>{data.name}</Text>
                  {data.sub ? (
                    <Text style={styles.subHeaderText} ellipsizeMode="tail" numberOfLines={1}>
                      {data.sub}
                    </Text>
                  ) : null}
                </View>
              ))}
            </View>

            {dataCells.getSize() > 0 ? (
              <RecyclerListView
                ref={cells}
                initialOffset={cellsDefaultIndexOffset}
                isHorizontal={true}
                layoutProvider={layoutProviderCol}
                dataProvider={dataCells}
                rowRenderer={renderCell}
                onScroll={onScrollHorizontal}
                canChangeSize={true}
                scrollViewProps={{
                  pagingEnabled: false,
                  scrollEnabled: true,
                  showsHorizontalScrollIndicator: false,
                  bounces: false,
                  overScrollMode: 'never',
                }}
                showsVerticalScrollIndicator={false}
                testID="table__cells"
              />
            ) : null}
          </ScrollView>

          <View style={styles.totalRow}>
            <View style={[styles.total, styles.firstCol]}>
              <Text style={styles.totalText}>{t('report_total')}</Text>
            </View>

            {dataTotals.getSize() > 0 ? (
              <RecyclerListView
                ref={totalRow}
                initialRenderIndex={0}
                isHorizontal={true}
                layoutProvider={layoutProviderRow}
                dataProvider={dataTotals}
                rowRenderer={renderTotal}
                scrollViewProps={{
                  showsHorizontalScrollIndicator: false,
                  scrollEnabled: false,
                  bounces: false,
                  overScrollMode: 'never',
                }}
                showsVerticalScrollIndicator={false}
                disableRecycling={true}
                testID="table__totalRow"
              />
            ) : null}
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

Table.propTypes = {
  rowHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sub: PropTypes.string,
    }).isRequired,
  ).isRequired,
  colHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string,
    }).isRequired,
  ).isRequired,
  dataTable: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  ).isRequired,
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  ).isRequired,
  onRefresh: PropTypes.func.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  displayRowTotal: PropTypes.bool,
  fitToScreenSize: PropTypes.bool,
  scrollToColIndex: PropTypes.number,
  isHortau: PropTypes.bool,
};

const styles = StyleSheet.create({
  backgroundGrey: {
    backgroundColor: COLORS.greyish22,
  },
  backgroundWhite: {
    backgroundColor: COLORS.white,
  },
  cellText: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: CELL_HEIGHT,
  },
  centerText: {
    textAlign: 'center',
  },
  container: {
    flexGrow: 0,
    padding: CONTAINER_PADDING,
    paddingTop: 0,
    borderColor: COLORS.greyBorderColor,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginTop: 5,
    marginBottom: 10,
    backgroundColor: COLORS.white,
    flexShrink: 1,
  },
  firstCol: {
    borderRightColor: GREY,
    borderRightWidth: 1,
  },
  headerCol: {
    justifyContent: 'center',
    width: CELL_WIDTH_NAME,
    paddingLeft: FIRST_COL_PADDING,
    backgroundColor: COLORS.white,
    borderTopLeftRadius: 4,
  },
  headerRow: {
    flexDirection: 'row',
    height: CELL_HEIGHT,
    backgroundColor: COLORS.white,
    borderBottomColor: GREY,
    borderBottomWidth: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerText: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  monthsRecyclerView: {
    flex: 1,
    height: CELL_HEIGHT,
  },
  blockNames: {
    width: CELL_WIDTH_NAME,
    paddingRight: 0,
    marginRight: 0,
  },
  blockNameCell: {
    justifyContent: 'center',
    width: CELL_WIDTH_NAME,
    height: CELL_HEIGHT,
    paddingLeft: FIRST_COL_PADDING,
    backgroundColor: COLORS.white,
  },
  dataGrid: {
    flexDirection: 'row',
  },
  subHeaderText: {
    paddingLeft: 5,
    fontFamily: FONTS.notoSans,
    fontSize: 10,
    color: COLORS.greyishBrown,
  },
  safeArea: {
    flex: 1,
  },
  topContainer: {
    flexGrow: 0,
    flexShrink: 1,
  },
  total: {
    justifyContent: 'center',
    width: CELL_WIDTH_NAME,
    paddingLeft: FIRST_COL_PADDING,
    backgroundColor: COLORS.havelockBlue,
    borderBottomLeftRadius: 4,
  },
  totalCol: {
    width: CELL_WIDTH_TOTAL,
    borderLeftColor: COLORS.white,
    borderLeftWidth: 2,
  },
  totalRow: {
    flexDirection: 'row',
    height: CELL_HEIGHT,
    backgroundColor: COLORS.havelockBlue,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  totalText: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.white,
  },
});

export default Table;
