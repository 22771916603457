import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';

//entity
import AutomationEntity from '../../models/entities/automationEntity';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

//components
import LastUpdate from '../block/LastUpdate';
import ComponentAlias from './ComponentAlias';

const AutomationPanelHeader = ({ automationComponent }) => {
  const aliasColor = automationComponent.statusColor();
  const aliasBackgroundColor = automationComponent.aliasBackgroundColor();
  const lastUpdated = automationComponent.lastUpdated();

  return (
    <View style={styles.headerBar}>
      <View style={styles.leftSection}>
        <ComponentAlias alias={automationComponent.alias} color={aliasColor} backgroundColor={aliasBackgroundColor} />
        <Text style={styles.componentName} ellipsizeMode="tail" numberOfLines={1}>
          {automationComponent.name}
        </Text>
      </View>

      <LastUpdate date={lastUpdated} />
    </View>
  );
};

AutomationPanelHeader.propTypes = {
  automationComponent: PropTypes.instanceOf(AutomationEntity).isRequired,
};

const styles = StyleSheet.create({
  headerBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    paddingRight: 17,
    paddingLeft: 15,
    borderBottomColor: COLORS.lightGrey,
    borderBottomWidth: 1,
  },
  leftSection: {
    flexDirection: 'row',
  },
  componentName: {
    alignSelf: 'center',
    marginLeft: 22,
    textAlign: 'left',
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    lineHeight: 14,
    letterSpacing: -0.07,
    color: COLORS.greyishBrown,
  },
});

export default React.memo(AutomationPanelHeader);
