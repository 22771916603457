import React, { useState, useCallback, useRef } from 'react';
import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native';
import PropTypes from 'prop-types';
import { useFocusEffect } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Ionicons';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

const SiteSearchBar = ({ currentSite = '', onSearchFilterChanged, onCancelClick, testId }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      inputRef.current?.focus();
      return () => {
        handleClear();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const handleClear = () => {
    setValue('');
    onSearchFilterChanged('');
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.bar}>
          <View style={styles.input}>
            <Icon style={styles.iconSearch} name="search" size={20} color={COLORS.grey} />
            <TextInput
              ref={inputRef}
              style={styles.textInput}
              placeholder={currentSite}
              placeholderTextColor={COLORS.lightPeach}
              value={value}
              onChangeText={(text) => {
                setValue(text);
                onSearchFilterChanged(text);
              }}
              autoFocus={true}
              autoCorrect={false}
              testID={testId}
            />
            {value ? (
              <Pressable onPress={handleClear} testID="clear-text">
                <Icon style={styles.iconClear} name="close-circle-outline" size={20} color={COLORS.grey} />
              </Pressable>
            ) : null}
          </View>

          <Pressable style={styles.cancelButton} onPress={onCancelClick}>
            <Text style={styles.cancelText}>{t('generic_button_cancel')}</Text>
          </Pressable>
        </View>
      </View>
    </>
  );
};

SiteSearchBar.propTypes = {
  onSearchFilterChanged: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  currentSite: PropTypes.string,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    paddingTop: 8,
    paddingRight: 10,
    paddingBottom: 7,
    paddingLeft: 10,
    borderColor: COLORS.lightPeach,
    backgroundColor: COLORS.lightGrey,
  },
  iconSearch: {
    marginLeft: 6,
  },
  iconClear: {
    padding: 4,
    marginRight: 6,
  },
  input: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    borderRadius: 8,
    backgroundColor: COLORS.white,
  },
  textInput: {
    flex: 1,
    marginLeft: 6,
    fontFamily: FONTS.firaSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  cancelButton: {
    justifyContent: 'center',
    height: 50,
    marginLeft: 4,
  },
  cancelText: {
    paddingRight: 2,
    paddingLeft: 6,
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    letterSpacing: 1,
    color: COLORS.greyishBrown,
  },
});

export default SiteSearchBar;
