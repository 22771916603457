import React from 'react';
import { StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, interpolate, Extrapolation } from 'react-native-reanimated';
import PropTypes from 'prop-types';

// Constants
import COLORS from '../../colors';

const SynchronizationDots = ({ index, count, progress }) => {
  const animatedStyle = useAnimatedStyle(() => {
    const wholeCount = count + 1;
    const scale = interpolate(
      progress.value,
      [index / wholeCount, (index + 1) / wholeCount, (index + 2) / wholeCount],
      [1, 1.5, 1],
      Extrapolation.CLAMP,
    );
    return {
      transform: [{ scale }],
    };
  });
  return <Animated.View style={[styles.dots, animatedStyle]} />;
};

const styles = StyleSheet.create({
  dots: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: COLORS.greyishBrownTwo,
  },
});

SynchronizationDots.propTypes = {
  index: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  progress: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

export default SynchronizationDots;
