//queries
import { fetchMapGraph } from '../queries/mapGraph';
import { fetchDataStreams } from '../queries/datastreams';
import { fetchGraphEvents } from '../queries/graphEvents';
import { fetchSiteWeatherDataStreams } from '../queries/siteWeatherDataStreams';
import { fetchTensionForecast } from '../queries/tensionForecast';

//services
import { compareDataStreamByPriority } from '../utils/sorting';
import { executeApolloQuery } from './apolloHelper';

// entities
import GraphEvent from './entities/GraphEvent';
import FutureGraphEvent from './entities/futureGraphEvent';

// constants
import { WEATHER } from './modules';

export const graph = {
  state: {
    dataStreams: undefined,
    irrigationEvents: [],
    selectedDataStreams: {
      left: {
        unit: undefined,
        ids: [],
      },
      right: {
        unit: undefined,
        ids: [],
      },
    },
    weatherDataStreams: [],
    futureIrrigationEvents: [],
    tensionForecast: [],
    tensionForecastError: false,
  },

  reducers: {
    updateDataStreams(state, dataStreams) {
      return { ...state, dataStreams };
    },

    updateWeatherDataStreams(state, weatherDataStreams) {
      weatherDataStreams.sort(compareDataStreamByPriority);
      return { ...state, weatherDataStreams };
    },

    setDefaultSelectedDataStreams(state, selectedDataStreams) {
      if (!selectedDataStreams.right) {
        selectedDataStreams.right = { ids: [], unit: undefined };
      }

      return {
        ...state,
        selectedDataStreams,
      };
    },

    toggleSelectedDataStream(state, { id, unit }) {
      if (state.selectedDataStreams === undefined) {
        return { ...state, selectedDataStreams };
      }

      if (state.selectedDataStreams.left.ids.length === 0) {
        const selectedDataStreams = { ...state.selectedDataStreams, left: { unit, ids: [id] } };
        return { ...state, selectedDataStreams };
      }

      if (state.selectedDataStreams.left.unit === unit) {
        const leftIds = removeOrPush(state.selectedDataStreams.left.ids, id);
        if (leftIds.length === 0) {
          return {
            ...state,
            selectedDataStreams: {
              left: state.selectedDataStreams.right,
              right: { unit: undefined, ids: [] },
            },
          };
        }

        const selectedDataStreams = {
          ...state.selectedDataStreams,
          left: { unit, ids: leftIds },
        };
        return { ...state, selectedDataStreams };
      }

      if (state.selectedDataStreams.right.ids.length === 0) {
        const selectedDataStreams = { ...state.selectedDataStreams, right: { unit, ids: [id] } };
        return { ...state, selectedDataStreams };
      }

      if (state.selectedDataStreams.right.unit === unit) {
        const selectedDataStreams = {
          ...state.selectedDataStreams,
          right: { unit, ids: removeOrPush(state.selectedDataStreams.right.ids, id) },
        };
        return { ...state, selectedDataStreams };
      }

      const selectedDataStreams = {
        ...state.selectedDataStreams,
        right: { unit, ids: [id] },
      };
      return { ...state, selectedDataStreams };
    },

    updateGraphEvents(state, graphEvents) {
      const irrigationEvents = graphEvents.filter((event) => !!event.startDate).map((event) => new GraphEvent(event));
      return { ...state, irrigationEvents };
    },

    updateGraphFutureEvents(state, graphFutureEvents) {
      if (!graphFutureEvents) {
        return { ...state, futureIrrigationEvents: [] };
      }

      const futureIrrigationEvents = graphFutureEvents
        .filter((event) => !!event.startDate)
        .map((event) => new FutureGraphEvent(event));
      return { ...state, futureIrrigationEvents };
    },

    updateTensionForecast(state, tensionForecast) {
      return { ...state, tensionForecast };
    },
    updateTensionForecastError(state, tensionForecastError) {
      return { ...state, tensionForecastError };
    },
  },

  effects: (dispatch) => ({
    async loadMapGraph({ siteId, blockId, dataStreamIds, startDate, endDate }) {
      if (dataStreamIds && dataStreamIds.length > 0) {
        await executeApolloQuery(
          dispatch,
          async () => await fetchMapGraph(siteId, blockId, dataStreamIds, startDate, endDate),
          (data) => {
            dispatch.graph.updateGraphEvents(data.graphEvents);
            dispatch.graph.updateDataStreams(data.dataStreams);
          },
        );
      }
    },

    async loadDataStreams({ siteId, dataStreamIds, startDate, endDate }) {
      if (dataStreamIds && dataStreamIds.length > 0) {
        await executeApolloQuery(
          dispatch,
          async () => await fetchDataStreams(siteId, dataStreamIds, startDate, endDate),
          (data) => dispatch.graph.updateDataStreams(data.dataStreams),
        );
      }
    },

    async loadIrrigationEvents({ siteId, blockId, startDate, endDate }) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchGraphEvents(siteId, blockId, startDate, endDate),
        (data) => dispatch.graph.updateGraphEvents(data.graphEvents),
      );
    },

    async loadSiteWeatherDataStreams(payload, rootState) {
      if (rootState.site.currentSite.modules.includes(WEATHER)) {
        await executeApolloQuery(
          dispatch,
          async () => await fetchSiteWeatherDataStreams(rootState.site.currentSite.id),
          (data) => dispatch.graph.updateWeatherDataStreams([...new Set(data.siteWeatherDataStreams)]),
        );
      }
    },

    async loadTensionForecast({ siteId, blockId, startDate, endDate }) {
      try {
        dispatch.graph.updateTensionForecastError(false);
        const response = await fetchTensionForecast(siteId, blockId, startDate, endDate);
        dispatch.graph.updateTensionForecast(response.tensionForecast);
        dispatch.graph.updateGraphFutureEvents(response.futureIrrigation);
      } catch (error) {
        dispatch.graph.updateTensionForecastError(true);
      }
    },

    async reset() {
      dispatch.graph.updateDataStreams(undefined);
      dispatch.graph.setDefaultSelectedDataStreams({
        left: { ids: [], unit: undefined },
        right: { ids: [], unit: undefined },
      });
      dispatch.graph.updateGraphEvents([]);
      dispatch.graph.updateWeatherDataStreams([]);
      dispatch.graph.updateTensionForecast([]);
      dispatch.graph.updateGraphFutureEvents([]);
      dispatch.graph.updateTensionForecastError(false);
    },
  }),
};

const removeOrPush = (ids, id) => {
  const array = [...ids];
  const index = array.findIndex((value) => value === id);
  if (index === -1) {
    array.push(id);
  } else {
    array.splice(index, 1);
  }
  return array;
};
