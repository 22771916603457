import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
  Easing,
} from 'react-native-reanimated';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';

const ConsolePulse = ({ delay }) => {
  const animation = useSharedValue(0);

  useEffect(() => {
    animation.value = withDelay(
      delay,
      withRepeat(
        withTiming(1, {
          duration: 3000,
          easing: Easing.linear,
        }),
        -1,
        false,
      ),
    );
  });

  const animatedStyles = useAnimatedStyle(() => {
    const opacity = interpolate(animation.value, [0, 1], [0.6, 0], Extrapolation.CLAMP);
    return {
      opacity,
      transform: [{ scale: animation.value }],
    };
  }, [animation]);

  return <Animated.View style={[styles.circle, animatedStyles]} />;
};

ConsolePulse.propTypes = {
  delay: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  circle: {
    position: 'absolute',
    width: 300,
    height: 300,
    borderColor: COLORS.darkGrey,
    borderWidth: 4,
    borderRadius: 150,
    backgroundColor: COLORS.azureRadiance,
  },
});

export default ConsolePulse;
