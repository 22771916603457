import React from 'react';
import { Text, StyleSheet, View, Image } from 'react-native';
import PropTypes from 'prop-types';

// shape
import { styleShape } from '../../shapes/style';

// contants
import COLORS from '../../colors';
import FONTS from '../../fonts';

import { TemperatureSensorPresenter } from '../../presenters/TemperatureSensorPresenter';
import { useTranslation } from '../../hooks/useTranslation';
import { FlowmeterPresenter } from '../../presenters/FlowmeterPresenter';

const SensorName = ({ presenter, containerStyle }) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.container, containerStyle]}>
      {presenter.displayIcon() && (
        <View style={styles.sensorContainer}>
          <Image source={presenter.icon().icon} style={styles.sensorIcon} />
        </View>
      )}
      <Text numberOfLines={1} ellipsizeMode={'middle'} style={styles.text} adjustsFontSizeToFit>
        {presenter.name(t)}
      </Text>
    </View>
  );
};

SensorName.propTypes = {
  containerStyle: styleShape,
  presenter: PropTypes.oneOfType([PropTypes.instanceOf(TemperatureSensorPresenter), PropTypes.instanceOf(FlowmeterPresenter)]),
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: 3,
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  sensorContainer: {
    marginHorizontal: 17,
    marginVertical: 10,
  },
  sensorIcon: {
    width: 12,
    height: 25,
  },
});

export default React.memo(SensorName);
