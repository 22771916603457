import Config from 'react-native-config';
import installations from '@react-native-firebase/installations';

import { getApolloClient } from '../clients/apollo';
import CancellableManager from '../utils/CancellableManager';
import { consoleWarn } from '../utils/loggerUtil';

// mutations
import { registerAppInstallation } from '../mutations/registerAppInstallation';

// services
import { isAndroid, isIos } from '../services/PlatformService';
import { getTrackingService } from '../services/AnalyticsTrackingService';
import { postJson } from '../clients/fetch';
import { getOS } from './osName';
import { executeApolloQuery } from './apolloHelper';

const LEEWAY = 300000;

/* istanbul ignore next */
const noop = () => {};

export const authentication = {
  state: {
    user: 'N/A',
    token: undefined,
    error: undefined,
  },

  reducers: {
    updateUser(state, user) {
      return { ...state, user };
    },
    updateToken(state, token) {
      return { ...state, token };
    },
    updateError(state, error) {
      return { ...state, error };
    },
    clearError(state) {
      return { ...state, error: undefined };
    },
  },

  effects: (dispatch) => ({
    async login(payload) {
      dispatch.authentication.clearError();
      dispatch.error.clearRefreshTokenError();
      try {
        const response = await postJson(`${Config.GRAPHQL_SERVER_V2}/api/login`, payload);

        if (!response.ok || !response.data) {
          const [errorMessage] = response.data?.errors;
          dispatch.authentication.updateError(errorMessage);
          return;
        }

        dispatch.authentication.updateUser(payload.username);
        const extractedToken = updateTokenInfo(dispatch.authentication, response.data);
        await dispatch.preferences.writeAuthenticationToken({ authenticationToken: extractedToken });
      } catch (error) {
        dispatch.authentication.updateError(error);
      }
    },

    async registerMobileDevice(token, rootState) {
      if (!rootState.authentication.token) {
        return;
      }

      const uniqueIID = await installations().getId();
      const type = getOS();
      await executeApolloQuery(dispatch, async () => await registerAppInstallation(uniqueIID, token, type), noop);
    },

    async postLogin(haveToken) {
      if (!haveToken) {
        if (isAndroid() || isIos()) {
          try {
            const token = await installations().getToken();
            this.registerMobileDevice(token);
          } catch (err) {
            /* istanbul ignore next */
            consoleWarn(err);
          }
        }
      }

      await dispatch.user.loadViewer();
      await dispatch.site.setCurrentSite();
      await dispatch.user.loadPermissions();
    },

    async logOut() {
      if (isAndroid() || isIos()) {
        await this.registerMobileDevice(null);
      }

      CancellableManager.cancelAll();
      getApolloClient().stop();

      const logoutAnalyticsPromise = getTrackingService().logout();
      const clearPreferencesPromise = dispatch.preferences.clearMultiple();
      const clearRematchStorePromise = dispatch({ type: 'CLEAR_STORE' });
      const clearApolloCachePromise = getApolloClient().clearStore();

      await Promise.all([logoutAnalyticsPromise, clearPreferencesPromise, clearRematchStorePromise, clearApolloCachePromise]);
    },

    async refreshToken(_, rootState) {
      try {
        if (!rootState.authentication.token) {
          throw new Error('No refresh token to renew');
        }

        const response = await postJson(`${Config.GRAPHQL_SERVER_V2}/api/refresh-login`, {
          refresh_token: rootState.authentication.token.refreshToken,
        });

        if (!response.ok || !response.data) {
          const error = `Unable to refresh token, response code: ${response.status}, data: ${JSON.stringify(response.data)}`;
          consoleWarn(error);
          return { error };
        }

        updateTokenInfo(dispatch.authentication, response.data);
      } catch (e) {
        const error = `Unable to refresh token: ${e}`;
        consoleWarn(error);
        return { error };
      }
    },

    async isTokenExpired(_, rootState) {
      if (rootState.authentication.token) {
        return rootState.authentication.token.expiresAt <= Date.now();
      }
      return false;
    },

    async sendForgotUsernameRequest(payload) {
      const { email, lang } = payload;
      try {
        await postJson(`${Config.GRAPHQL_SERVER}/api/user/username/forgot?recovery-email=${email}&lang=${lang}`);
      } catch (error) {
        consoleWarn(`Unable to send forgot username request, response: ${error}`);
      }
    },

    async sendForgotPasswordRequest(payload) {
      const { username, lang } = payload;
      try {
        await postJson(`${Config.GRAPHQL_SERVER}/api/user/password/forgot?username=${username}&lang=${lang}`);
      } catch (error) {
        consoleWarn(`Unable to send forgot password request, response: ${error}`);
      }
    },
  }),
};

const updateTokenInfo = (dataAuthentication, data) => {
  const extractedToken = {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expiresAt: Date.now() + data.expires_in * 1000 - LEEWAY,
  };
  dataAuthentication.updateToken(extractedToken);

  return extractedToken;
};
