import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const ProgressBar = ({ labelStart, labelEnd, value }) => {
  return (
    <View>
      <View style={styles.container}>
        <View style={[styles.progressBar, { width: `${value * 100}%` }]} />
      </View>

      <View style={styles.textContainer}>
        <Text style={styles.text}>{labelStart}</Text>
        <Text style={styles.text}>{labelEnd}</Text>
      </View>
    </View>
  );
};

ProgressBar.propTypes = {
  labelStart: PropTypes.string,
  labelEnd: PropTypes.string,
  value: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    height: 7,
    borderRadius: 3,
    marginTop: 3,
    backgroundColor: COLORS.lightGrey2,
  },
  progressBar: {
    height: '100%',
    backgroundColor: COLORS.cerulean2,
  },
  text: {
    fontFamily: FONTS.firaSansBold,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.47,
    color: COLORS.greyishBrown,
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 7,
  },
});

export default ProgressBar;
