import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

const EventItemDescription = ({ title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t(title)}</Text>

      {subtitle ? <Text style={styles.subtitle}>{t(subtitle)}</Text> : null}
    </View>
  );
};

EventItemDescription.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    height: '100%',
  },
  title: {
    marginHorizontal: 10,
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  subtitle: {
    marginHorizontal: 9,
    fontFamily: FONTS.firaSans,
    fontSize: 10,
    color: COLORS.brownGreyOpaque,
  },
});

export default EventItemDescription;
