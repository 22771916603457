import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';

// propType shape
import { navigationShape } from '../../shapes/navigation';

// services
import { SORT_ORDER, toggleSort } from '../../utils/sorting';
import ANALYTICS from '../../services/AnalyticsEvents';

// hooks
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// styles
import { globalStyles } from '../../styles';

// constants
import ROUTES from '../../navigation/routes';
import { TENSION } from '../../models/capability';
import { BLOCK_ORDER } from '../../models/blockSorting';

// components
import HeaderBack from '../../components/header/HeaderBack';
import Divider from '../../components/Divider';
import SortableList from '../../components/SortableList';
import BlueBandIndexListSkeleton from '../../components/blueBandIndex/BlueBandIndexListSkeleton';
import SiteBlueBandIndexItem from './SiteBlueBandIndexItem';
import SortableSiteBlueBandIndexHeader from './SortableSiteBlueBandIndexHeader';

// constants
import { SORT_COLUMNS } from './sorting';

const SiteBlueBandIndexScreen = ({ navigation }) => {
  const currentSite = useSelector((state) => state.site.currentSite);
  const analyticsService = useAnalyticsContext();
  const loading = useSelector((state) => !!state.loading.effects.dashboard.loadTensionBlocksForCurrentSite);
  const sortLoading = useSelector((state) => !!state.loading.effects.dashboard.sortTensionBlocks);
  const [refreshing, setRefreshing] = useState(false);
  const tensionBlocks = useSelector((state) => state.dashboard.sortedTensionBlocks);
  const listRef = useRef(undefined);
  const initialSortOrderState = {
    block: SORT_ORDER.NONE,
    dry: SORT_ORDER.NONE,
    good: SORT_ORDER.NONE,
    wet: SORT_ORDER.NONE,
  };
  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);
  const dispatch = useDispatch();

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setSortedColumn(initialSortOrderState);
      dispatch.dashboard.loadTensionBlocksForCurrentSite({
        sortBlocksBy: BLOCK_ORDER,
        blockFilters: { capabilities: [TENSION] },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  useEffect(() => {
    sortTensionBlocksFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const goBack = useCallback(() => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewDashboard);
    navigation.navigate(ROUTES.DASHBOARD);
    return true;
  }, [analyticsService, navigation]);

  useBackHandler(goBack);

  const sortTensionBlocksFromSortedColumn = () => {
    if (sortedColumn.block !== SORT_ORDER.NONE) {
      dispatch.dashboard.sortTensionBlocks({ column: SORT_COLUMNS.BLOCK, order: sortedColumn.block });
    } else if (sortedColumn.dry !== SORT_ORDER.NONE) {
      dispatch.dashboard.sortTensionBlocks({ column: SORT_COLUMNS.DRY_BLUE_BAND_INDEX, order: sortedColumn.dry });
    } else if (sortedColumn.good !== SORT_ORDER.NONE) {
      dispatch.dashboard.sortTensionBlocks({ column: SORT_COLUMNS.GOOD_BLUE_BAND_INDEX, order: sortedColumn.good });
    } else if (sortedColumn.wet !== SORT_ORDER.NONE) {
      dispatch.dashboard.sortTensionBlocks({ column: SORT_COLUMNS.WET_BLUE_BAND_INDEX, order: sortedColumn.wet });
    }
  };

  const loadAndSortTensionBlocks = async () => {
    await dispatch.dashboard.loadTensionBlocksForCurrentSite({
      sortBlocksBy: BLOCK_ORDER,
      blockFilters: { capabilities: [TENSION] },
    });
    sortTensionBlocksFromSortedColumn();
  };

  const refreshTensionBlocks = async () => {
    setRefreshing(true);
    await loadAndSortTensionBlocks();
    setRefreshing(false);
  };

  const renderSiteBlueBandIndexItem = (element) => {
    return (
      <SiteBlueBandIndexItem
        navigation={navigation}
        block={element.item}
        keyIndex={element.index}
        testId={`site-blue-band-index__block-item-${element.index}`}
      />
    );
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderBack
          screenName="site-blue-band-index"
          navigation={navigation}
          goBack={goBack}
          title={currentSite?.name}
          testID="block-details__screen-title"
        />
      </View>

      <Divider />

      <View testID="site-blue-band-index__subscreen-container" style={globalStyles.bottomContainer}>
        <SortableSiteBlueBandIndexHeader sortedColumn={sortedColumn} toggle={toggle} loading={sortLoading} />

        <SortableList
          testID={'site-blue-band-index__subscreen_list'}
          ref={listRef}
          elements={tensionBlocks}
          loading={loading}
          refreshing={refreshing}
          sort={dispatch.dashboard.sortTensionBlocks}
          renderEmptyView={<BlueBandIndexListSkeleton />}
          renderItem={renderSiteBlueBandIndexItem}
          screenName={'SiteBlueBandIndex'}
          onRefresh={refreshTensionBlocks}
        />
      </View>
    </SafeAreaView>
  );
};

SiteBlueBandIndexScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default SiteBlueBandIndexScreen;
