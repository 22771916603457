import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { REPORT } from './report.graphql';
import { REPORT_ALPHA } from './reportAlpha.graphql';

const fetchReport = async (siteId, startDate, endDate, groupBy, isHortau) => {
  const query = isHortau ? REPORT_ALPHA : REPORT;

  const { loading, error, data } = await getApolloClient().query({
    query,
    variables: { siteId, filter: { startDate, endDate, groupBy } },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  let site;
  if (data) {
    site = data.viewer.site;
  }

  return {
    loading,
    error,
    site,
  };
};

export { fetchReport };
