import { Dimensions, PixelRatio } from 'react-native';

export const getAttributionHorizontalOffset = () => {
  let offset = {
    bottom: 6,
    left: 103,
  };

  const pixelRatio = PixelRatio.get();
  var { width } = Dimensions.get('window');

  if (pixelRatio >= 2.75) {
    offset.left = width / 3 - width / 40;
    offset.bottom = 4;
  } else if (pixelRatio >= 2) {
    offset.left = width / pixelRatio - pixelRatio * (width / 8);
    offset.bottom = 7;
  } else if (pixelRatio >= 1) {
    offset.left = width / 3;
  }

  return offset;
};

export const width = Dimensions.get('window').width;
