import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

//components
import RoundedUnderline from '../RoundedUnderline';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

const TensionValueElement = ({ value, colorCode }) => {
  return (
    <View style={styles.item}>
      <Text style={styles.value}>{value}</Text>
      <RoundedUnderline color={colorCode} />
    </View>
  );
};

TensionValueElement.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colorCode: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 42,
    paddingTop: 2,
    paddingBottom: 5,
  },
  value: {
    width: '100%',
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 15,
    lineHeight: 20,
    color: COLORS.greyishBrown,
  },
});

export default TensionValueElement;
