import { StyleSheet, Text, View, Image, ScrollView, Pressable } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';

//hook
import { useTranslation } from '../../hooks/useTranslation';

//icons
import icnCancel from '../../assets/icons/icnCancel.png';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

//components
import DataStreams from '../graph/DataStreams';

// styles
import { SIZES } from '../../styles';
import BlockEntity from '../../models/entities/blockEntity';

const DataStreamModal = ({ block, isVisible, localStreams, onCloseModal, onToggle, selectedDataStreams, weatherStreams }) => {
  const { t } = useTranslation();

  return (
    <Modal
      testID="block-detail__modal"
      animationIn={'slideInUp'}
      animationOut={'slideOutDown'}
      animationInTiming={400}
      animationOutTiming={600}
      backdropTransitionInTiming={400}
      backdropTransitionOutTiming={600}
      useNativeDriver
      useNativeDriverForBackdrop
      hideModalContentWhileAnimating
      style={styles.modal}
      onBackdropPress={onCloseModal}
      backdropColor={COLORS.black}
      onBackButtonPress={onCloseModal}
      backdropOpacity={OPACITIES.modalBackDrop}
      isVisible={isVisible}
      transparent
      supportedOrientations={['portrait', 'landscape']}>
      <View style={styles.main}>
        <View style={styles.topRow}>
          <Text style={styles.headerText}>{t('data_stream_modal_title')}</Text>
          <View style={styles.closeSection}>
            <Pressable style={styles.closeButton} onPress={onCloseModal} testID="block-detail__modal-close-icon">
              <Image style={styles.closeIcon} source={icnCancel} />
            </Pressable>
          </View>
        </View>
        <View style={styles.spacer}>
          <ScrollView>
            <DataStreams
              block={block}
              isPortrait={false}
              localStreams={localStreams}
              onToggle={onToggle}
              selectedDataStreams={selectedDataStreams}
              weatherStreams={weatherStreams}
            />
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

DataStreamModal.propTypes = {
  block: PropTypes.instanceOf(BlockEntity),
  isVisible: PropTypes.bool.isRequired,
  localStreams: PropTypes.arrayOf(PropTypes.object),
  onCloseModal: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  selectedDataStreams: PropTypes.object.isRequired,
  weatherStreams: PropTypes.arrayOf(PropTypes.object),
};

const styles = StyleSheet.create({
  main: {
    flex: 1,
    alignSelf: 'flex-end',
    width: 470,
    backgroundColor: COLORS.white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  modal: {
    marginBottom: 0,
  },
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomColor: COLORS.lightGrey,
    borderBottomWidth: 1,
  },
  headerText: {
    flex: 1,
    paddingLeft: SIZES.ICON + 10,
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  closeSection: {
    alignSelf: 'center',
  },
  closeButton: {
    padding: 10,
  },
  closeIcon: {
    alignSelf: 'flex-end',
    width: 18,
    height: 18,
  },
  spacer: {
    paddingHorizontal: 5,
    marginBottom: 70,
  },
});

export default DataStreamModal;
