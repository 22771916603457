/* istanbul ignore file */

let trackingService;

const initAnalytics = (apiToken) => {
  trackingService = new AnalyticsTrackingService(apiToken);
  return trackingService;
};

class AnalyticsTrackingService {
  constructor(apiToken) {
    this.apiToken = apiToken;
  }

  async connect(isHeadless = false) {}

  trackNavigationEvent(event, properties) {}

  trackEvent(event, properties) {}

  changeSite(siteName) {}

  async logout() {
    this.changeSite();
  }

  setUser(user, site) {}
}

const getTrackingService = () => trackingService;

export { initAnalytics, getTrackingService };
