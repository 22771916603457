import { gql } from 'graphql-tag';

export const DATASTREAMS = gql`
  query datastreamsData($siteId: ID!, $dataStreamIds: [ID], $startDate: String, $endDate: String) {
    viewer {
      site(id: $siteId) {
        id
        dataStreams(ids: $dataStreamIds) {
          id
          minimumValue
          maximumValue
          unit
          dataStreamType
          specifiedRange {
            maximum
          }
          dataStreamData(startDate: $startDate, endDate: $endDate) {
            x
            y
          }
        }
      }
    }
  }
`;
