export class FlowStationSensorPresenter {
  constructor(sensorReading, translation) {
    this.sensorReading = sensorReading;
    this.translation = translation;
  }

  value() {
    const value = parseFloat(this.sensorReading.value);
    if (isNaN(value)) {
      return '- -';
    }

    if (this.maxValueReached()) {
      return `+${this.sensorReading.dataStream.specifiedRange.maximum}`;
    }
    return `${value.toFixed(1)}`;
  }

  unit() {
    return this.translation(`unit_${this.sensorReading.unit.toLowerCase()}`);
  }

  color() {
    return `#${this.sensorReading.color}`;
  }

  maxValueReached() {
    const sensorValue = this.sensorReading.value;
    const maxValue = this.sensorReading.dataStream?.specifiedRange?.maximum;
    if (sensorValue && maxValue) {
      return sensorValue >= maxValue;
    }
    return false;
  }
}
