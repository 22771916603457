// constants
import * as BlockType from '../blockType';
import * as TensionStatus from '../status/tensionStatus';
import * as FlowStationStatus from '../status/flowStationStatus';
import * as TemperatureStatus from '../status/temperatureStatus';
import * as WeatherStationStatus from '../status/weatherStationStatus';
import { PLANNED } from '../calendarType';
import { isAutomatedIrrigation } from '../eventType';

// services
import BlockCapabilities from '../../presenters/BlockCapabilities';
import { compareDataStreamByPriority } from '../../utils/sorting';

// entities
import { DATA_STREAMS_TYPES } from '../dataStreamTypes';
import { isNullOrUndefined } from '../../utils/util';
import TensionCapability from './tensionCapability';
import IrrigationCapability from './irrigationCapability';
import TemperatureCapability from './temperatureCapability';
import FlowStationCapability from './flowStationCapability';
import WeatherStationCapability from './weatherStationCapability';

export default class BlockEntity {
  constructor(block) {
    if (block) {
      this.id = block.id;
      this.order = block.order;
      this.coordinates = block.coordinates;
      this.capabilities = new BlockCapabilities(block.capabilities);
      this.name = block.name;
      this.alias = block.alias;
      this.order = block.order;
      this.lastUpdated = block.lastUpdated;
      this.sensors = block.sensors;
      this.calendars = block.calendars;
      this.notes = block.notes || [];
      this.groupId = block.groupId;
      this.events = block.events || [];
      this.automationId = block.automationId || null;
      this.isVirtual = block.isVirtual;
      this.schedule = block.schedule;
      this.eventProducers = block.eventProducers;
    }

    this.blockCapability = {};
  }

  blockType() {
    if (this.isTensionCapable()) {
      return BlockType.TENSION;
    } else if (this.isFlowStationCapable()) {
      return BlockType.FLOW_STATION;
    } else if (this.isWeatherStationCapable()) {
      return BlockType.WEATHER;
    } else if (this.isTemperatureCapable()) {
      return BlockType.TEMPERATURE;
    } else if (this.isWaterLevelCapable()) {
      return BlockType.WATER_LEVEL;
    }
    return BlockType.TENSION;
  }

  isTensionCapable() {
    return !!this.capabilities.tension();
  }

  tensionCapability() {
    if (!this.blockCapability.tension) {
      this.blockCapability.tension = new TensionCapability(this.capabilities.tension());
    }
    return this.blockCapability.tension;
  }

  isFlowStationCapable() {
    return !!this.capabilities.flowStation();
  }

  isTemperatureCapable() {
    return !!this.capabilities.temperature();
  }

  temperatureCapability() {
    if (!this.blockCapability.temperature) {
      if (this.capabilities && this.capabilities.temperature()) {
        this.blockCapability.temperature = new TemperatureCapability(this.capabilities.temperature(), this.sensors);
      } else {
        this.blockCapability.temperature = new TemperatureCapability(undefined, this.sensors);
      }
    }
    return this.blockCapability.temperature;
  }

  isWaterLevelCapable() {
    return this.waterCapability().length > 0;
  }

  waterCapability() {
    return this.dataStreams().filter(
      (dataStream) =>
        dataStream.dataStreamType === DATA_STREAMS_TYPES.WATER_DEPTH.code ||
        dataStream.dataStreamType === DATA_STREAMS_TYPES.WELL_DEPTH.code,
    );
  }

  isWeatherStationCapable() {
    return !!this.capabilities.weatherStation();
  }

  hasWeatherStationData() {
    const weather = this.capabilities.weatherStation();
    const hasWeatherInfo = !isNullOrUndefined(weather);
    const hasAirTemperature = !isNullOrUndefined(weather?.airTemperature);
    const hasWindInfo = !isNullOrUndefined(weather?.windSpeed) || !isNullOrUndefined(weather?.windDirection);

    return hasWeatherInfo && (hasAirTemperature || hasWindInfo);
  }

  isIrrigationCapable() {
    return !!this.capabilities.irrigation() || this.schedule;
  }

  irrigationCapability() {
    if (!this.blockCapability.irrigation) {
      this.blockCapability.irrigation = new IrrigationCapability(this.capabilities.irrigation(), this.schedule);
    }
    return this.blockCapability.irrigation;
  }

  isIrrigating() {
    if (this.isInCommunicationError()) {
      return false;
    }
    const irrigation = this.capabilities.irrigation();
    const flowStation = this.capabilities.flowStation();

    const isIrrigating = irrigation != null && irrigation.isIrrigating;
    const isFlowing = flowStation != null && flowStation.isFlowing;
    return isIrrigating || isFlowing;
  }

  isInCommunicationError() {
    if (this.blockType() === BlockType.TENSION && this.isTensionCapable()) {
      const tension = this.capabilities.tension();
      return tension !== null && tension.status !== null && tension.status === TensionStatus.COMMUNICATION_ERROR;
    }

    if (this.isFlowStationCapable() && this.blockType() === BlockType.FLOW_STATION) {
      const flowStation = this.capabilities.flowStation();
      return flowStation !== null && flowStation.status !== null && flowStation.status === FlowStationStatus.COMMUNICATION_ERROR;
    }
    return false;
  }

  isOutOfField() {
    if (this.isTensionCapable()) {
      return this.capabilities.tension().status === TensionStatus.OUT_OF_FIELD;
    } else if (this.isTemperatureCapable()) {
      return this.capabilities.temperature().status === TemperatureStatus.OUT_OF_FIELD;
    } else if (this.isWeatherStationCapable()) {
      return this.capabilities.weatherStation().status === WeatherStationStatus.OUT_OF_FIELD;
    } else if (this.isFlowStationCapable()) {
      return this.capabilities.flowStation().status === FlowStationStatus.OUT_OF_FIELD;
    }
    return false;
  }

  dataStreams() {
    if (!this.cachedDataStreams) {
      if (this.sensors) {
        this.cachedDataStreams = this.sensors.reduce((dataStreams, sensor) => {
          dataStreams.push(...sensor.dataStreams);
          return dataStreams;
        }, []);
        this.cachedDataStreams.sort(compareDataStreamByPriority);
      } else {
        this.cachedDataStreams = [];
      }
    }
    return this.cachedDataStreams;
  }

  flowStationCapability() {
    if (!this.blockCapability.flowStation) {
      this.blockCapability.flowStation = new FlowStationCapability(this.capabilities.flowStation(), this.sensors);
    }
    return this.blockCapability.flowStation;
  }

  getPlannedEvents() {
    let events = [];
    if (this.calendars?.length) {
      this.calendars.forEach((calendar) => {
        if (calendar.type === PLANNED && calendar.events != null) {
          events.push(...calendar.events);
        }
      });
    }

    if (this.schedule?.scheduleEvents?.length) {
      this.schedule.scheduleEvents.forEach((event) => {
        if (isAutomatedIrrigation(event.type)) {
          events.push(event);
        }
      });
    }
    return events;
  }

  getInferredEvents() {
    return this.events;
  }

  getAllEvents() {
    return [...this.getInferredEvents(), ...this.getPlannedEvents()];
  }

  weatherCapability() {
    if (!this.blockCapability.weatherStation) {
      this.blockCapability.weatherStation = new WeatherStationCapability(this.capabilities.weatherStation());
    }
    return this.blockCapability.weatherStation;
  }

  hasAutomations() {
    return this.automationId !== null;
  }

  getMainSwitches() {
    if (!this.eventProducers) {
      return [];
    }
    return this.eventProducers.filter((element) => !(element.color == null));
  }

  hasMultiSwitches() {
    return this.getMainSwitches().length > 1;
  }
}
