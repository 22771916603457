import icnManualOn from '../assets/icons/automation/icnManualOn.png';
import icnManualOff from '../assets/icons/automation/icnManualOff.png';
import icnAutomOn from '../assets/icons/automation/icnAutomOn.png';
import icnAutomOff from '../assets/icons/automation/icnAutomOff.png';

export const IRRIGATION = 'IRRIGATION';

export const EVENT_TYPES = {
  manualIrrigation: 'ManualIrrigation',
  automatedIrrigation: 'AutomatedIrrigation',
};

export const isManualIrrigation = (type) => type === EVENT_TYPES.manualIrrigation;

export const isAutomatedIrrigation = (type) => type === EVENT_TYPES.automatedIrrigation;

export const findIrrigationIcon = (type, isActive) => {
  if (isManualIrrigation(type)) {
    return isActive ? icnManualOn : icnManualOff;
  }
  return isActive ? icnAutomOn : icnAutomOff;
};
