import { Pressable } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

// services
import { isDebug } from '../../services/EnvironmentService';

// components
import LocalizedImage from '../LocalizedImage';
import * as LocalizedImages from '../../i18n/LocalizedImages';

const Logo = ({ onClick }) => {
  return (
    <>
      {isDebug() ? (
        <Pressable testID={'logo__touchable'} onPress={onClick}>
          <LocalizedImage image={LocalizedImages.LOGO} />
        </Pressable>
      ) : (
        <LocalizedImage image={LocalizedImages.LOGO} />
      )}
    </>
  );
};

Logo.propTypes = {
  onClick: PropTypes.func,
};

export default Logo;
