import PropTypes from 'prop-types';

export const SensorShape = PropTypes.shape({
  dataStreamType: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  isVirtual: PropTypes.boolean,
  lastUpdated: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number,
});
