import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

// constant
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

// components
import Divider from '../../components/Divider';
import SortableHeaderItem from '../../components/header/SortableHeaderItem';
import { SORT_COLUMNS } from './sorting';

const SortableAutomationRuleHeader = ({ loading, sortedColumn, toggle }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.bar}>
        <SortableHeaderItem
          style={styles.columnType}
          loading={loading}
          sortKey={SORT_COLUMNS.TYPE}
          sortOrder={sortedColumn.type}
          onPress={toggle}
          testID={'automation-rules__header-item-type'}
          title={t('automation_rule_header_type')}
          titleStyle={styles.flexStart}
        />

        <View style={styles.containerEnd}>
          <SortableHeaderItem
            style={styles.columnName}
            loading={loading}
            sortKey={SORT_COLUMNS.NAME}
            sortOrder={sortedColumn.name}
            onPress={toggle}
            testID={'automation-rules__header-item-name'}
            title={t('automation_rule_header_name')}
            titleStyle={styles.flexStart}
          />

          <SortableHeaderItem
            style={styles.columnThreshold}
            loading={loading}
            sortKey={SORT_COLUMNS.THRESHOLD}
            sortOrder={sortedColumn.threshold}
            onPress={toggle}
            testID={'automation-rules__header-item-threshold'}
            title={t('automation_rule_threshold')}
            titleStyle={styles.flexEnd}
          />
        </View>
      </View>

      <Divider style={styles.divider} />
    </View>
  );
};

SortableAutomationRuleHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortedColumn: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 22,
  },
  container: {
    zIndex: 2,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  containerEnd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnName: {
    width: '50%',
  },
  columnThreshold: {
    width: '50%',
  },
  columnType: {
    width: '25%',
    marginRight: 23,
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.lightGrey,
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
});

export default SortableAutomationRuleHeader;
