import * as CAPABILITY from './capability';

const GraphDataType = {
  DEVICE: 'DEVICE',
  FLOW: 'FLOW',
  TENSION: 'TENSION',
  TEMPERATURE: 'TEMPERATURE',
  WEATHER: 'WEATHER',
  WATER_LEVEL: 'WATER_LEVEL',
};

const getGraphDataType = (capability) => {
  switch (capability) {
    case CAPABILITY.FLOW_STATION:
      return GraphDataType.FLOW;
    case CAPABILITY.TEMPERATURE:
      return GraphDataType.TEMPERATURE;
    case CAPABILITY.WEATHER_STATION:
      return GraphDataType.WEATHER;
    default:
      return GraphDataType.TENSION;
  }
};

export { GraphDataType, getGraphDataType };
