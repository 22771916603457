import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 25,
  },
});
