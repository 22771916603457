import { useTranslation as i18nHook } from 'react-i18next';

const adaptParameters = (...parameters) => {
  const namedPositionalParameters = {};

  let position = 1;
  let parameter;
  for (parameter of parameters) {
    if (typeof parameter === 'object' && parameter !== null) {
      Object.assign(namedPositionalParameters, parameter);
    } else {
      namedPositionalParameters[position.toString()] = parameter;
      position = position + 1;
    }
  }

  return namedPositionalParameters;
};

export function useTranslation() {
  const { t, i18n } = i18nHook();

  return {
    t: (translationKey, ...parameters) => {
      const args = adaptParameters(...parameters);
      return t(translationKey, args);
    },
    i18n,
  };
}
