/* istanbul ignore file */
const ANALYTICS = {
  automationPanelOpen: 'automation_panel_open',
  automationPanelClose: 'automation_panel_close',
  automationModifyButton: 'automation_modify_button',
  automationStartButton: 'automation_start_button',
  automationStopButton: 'automation_stop_button',
  createEventButton: 'create_event_button',
  eventConsoleAction: 'console_action_',
  eventExpandScheduleBlockPanel: 'expand_schedule_block_panel',
  eventSaveAutomationRule: 'save_automation_rule',
  eventSegmentedDetails: 'segmented_details',
  eventSegmentedGraph: 'segmented_graph',
  eventSegmentedWeeklyReport: 'segmented_weekly_report',
  eventSegmentedMonthlyReport: 'segmented_monthly_report',
  eventViewAutomation: 'view_automation',
  eventViewAutomationPreview: 'view_automation_preview',
  eventViewAutomationRuleDetail: 'view_automation_rule_detail',
  eventViewAutomationRules: 'view_automation_rules',
  eventViewBlockDetail: 'view_block_detail',
  eventViewBlockSchedule: 'view_block_schedule',
  eventViewConsole: 'view_console',
  eventViewConsoleDetails: 'view_console_details',
  eventViewDevices: 'view_devices',
  eventViewDeviceDetails: 'view_device_details',
  eventViewGraph: 'view_graph',
  eventRotateGraphLandscape: 'rotate_graph_to_landscape',
  eventViewScheduleEventList: 'view_schedule_event_list',
  eventViewSchedule: 'view_schedule',
  eventViewMap: 'view_map',
  eventViewMapStyle: 'view_map_style',
  eventViewPestProphet: 'view_pest_prophet',
  eventViewReport: 'view_report',
  eventViewReportPreview: 'view_report_preview',
  eventViewWeather: 'view_weather',
  eventViewWeatherForecast: 'view_weather_forecast',
  eventViewWeatherPreview: 'view_weather_preview',
  eventViewIrrigation: 'view_irrigation',
  eventViewMapAnnotationDetails: 'view_map_annotation_detail',
  eventViewDashboard: 'view_dashboard',
  eventViewFlowStation: 'view_flows',
  eventViewFlowStationPreview: 'view_flows_preview',
  eventViewNotes: 'view_notes',
  eventViewBlockBlueBand: 'view_block_blue_band',
  eventViewSiteBlueBand: 'view_site_blue_band',
  eventViewTemperature: 'view_temperatures',
  eventViewTemperaturePreview: 'view_temperature_preview',
  NDVIFaq: 'ndvi_faq',
  NDVILegend: 'ndvi_legend',
  scheduleNextWeekButton: 'schedule_nextweek_button',
  schedulePreviousWeekButton: 'schedule_previousweek_button',
  weatherForecastsListScroll: 'weather_forecasts_list_scroll',

  // not implemented
  eventViewAlerts: 'view_notifications',
  eventViewSnooze: 'view_snooze',
  eventViewAlertSnoozed: 'notification_snoozed',
  eventAlertAcknowledged: 'notification_acknowledged',
};

export default ANALYTICS;
