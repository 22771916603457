//constants
import * as BlockType from '../blockType';
import { COMMUNICATION_ERROR } from '../status/temperatureStatus';
import { DATA_STREAMS_TYPES } from '../dataStreamTypes';
import { compareDataStreamByPriority } from '../../utils/sorting';

export default class TemperatureCapability {
  constructor(temperature, sensors) {
    this.temperature = temperature;
    this.sensors = sensors;
  }

  isInCommunicationError() {
    const temperature = this.temperature;

    return temperature !== null && temperature.status !== null && temperature.status === COMMUNICATION_ERROR;
  }

  blockType() {
    return BlockType.TEMPERATURE;
  }

  temperatures() {
    if (!this.cachedTemperatures) {
      this.cachedTemperatures = [];
      if (this.sensors) {
        this.sensors.forEach((sensor) => {
          sensor.dataStreams.forEach((dataStream) => {
            if (
              dataStream.dataStreamType === DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code ||
              dataStream.dataStreamType === DATA_STREAMS_TYPES.UNSHIELDED_TEMPERATURE.code ||
              dataStream.dataStreamType === DATA_STREAMS_TYPES.WATER_TEMPERATURE.code
            ) {
              this.cachedTemperatures.push(dataStream);
            }
          });
        });
        this.cachedTemperatures.sort(compareDataStreamByPriority);
      }
    }
    return this.cachedTemperatures;
  }

  readings() {
    return this.temperature ? this.temperature.readings : [];
  }

  status() {
    return this.temperature?.status;
  }

  unit() {
    if (this.temperatures().length > 0) {
      return this.cachedTemperatures[0].unit;
    }
    return undefined;
  }
}
