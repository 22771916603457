import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { RefreshControl, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useDebouncedCallback } from 'use-debounce';

// services
import { navigationShape } from '../../shapes/navigation';
import { useBackHandlerRouteTo } from '../../hooks/useBackHandler';

// styles
import { globalStyles } from '../../styles';

// components
import WeatherHeader from '../../components/weatherStation/WeatherHeader';
import WeatherStationDetail from '../../components/weatherStation/WeatherStationDetail';
import { computeInitialDateRange } from '../../components/graph/ChartXAxisHelper';
import Divider from '../../components/Divider';

const WeatherScreen = ({ navigation, route }) => {
  useBackHandlerRouteTo(navigation, route);
  const { initialWeatherStationId } = route.params || {};
  const isFocused = useIsFocused();
  const scrollViewRef = useRef(undefined);
  const loadedSelectedDataStreamsCache = useRef([]);
  const chartRef = useRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => !!state.loading.effects.blocks.loadWeatherStationBlocksForCurrentSite);
  const currentSite = useSelector((state) => state.site.currentSite);
  const weatherStations = useSelector((state) => state.blocks.weatherStation);
  const selectedWeatherStation = useSelector((state) => state.blocks.selectedWeatherStation);
  const selectedDataStreams = useSelector((state) => state.graph.selectedDataStreams);
  const initialDataRange = () => computeInitialDateRange(currentSite);

  const [refreshing, setRefreshing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [dataRange, setDataRange] = useState(initialDataRange());

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      onRefresh();

      return () => {
        dispatch.graph.reset();
        loadedSelectedDataStreamsCache.current = [];
        dispatch.blocks.updateSelectedWeatherStation(undefined);
        setSelectedIndex(0);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  useEffect(() => {
    if (!loading && weatherStations.length !== 0) {
      selectDefaultWeatherStation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, weatherStations]);

  useEffect(() => {
    scrollViewRef?.current?.scrollTo({ x: 0, y: 0, animated: false });
    if (!loading && weatherStations.length !== 0) {
      const weatherStation = weatherStations[selectedIndex];
      if (weatherStation) {
        dispatch.blocks.updateSelectedWeatherStation(weatherStation);
        updateAndSelectDataStream(weatherStation.dataStreams());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, weatherStations, selectedIndex]);

  /* istanbul ignore next */
  useEffect(() => {
    const idsNeeded = [...selectedDataStreams.left.ids, ...selectedDataStreams.right.ids];
    if (needToReloadData(idsNeeded)) {
      const idsToReload = [...new Set([...loadedSelectedDataStreamsCache.current, ...idsNeeded])];
      loadedSelectedDataStreamsCache.current = idsToReload;
      loadSelectedDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataStreams.left.ids, selectedDataStreams.right.ids]);

  useEffect(() => {
    loadSelectedDataStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRange]);

  const loadSelectedDataStreams = () => {
    if (isFocused && dataRange.length > 0) {
      const [startDate, endDate] = dataRange;
      debouncedLoadDataStreams({
        siteId: currentSite.id,
        dataStreamIds: loadedSelectedDataStreamsCache.current,
        startDate,
        endDate,
      });
    }
  };

  const needToReloadData = (ids) => ids.some((id) => loadedSelectedDataStreamsCache.current.indexOf(id) === -1);

  const [debouncedLoadDataStreams] = useDebouncedCallback(dispatch.graph.loadDataStreams, 200);

  const updateAndSelectDataStream = (dataStreams) => {
    dispatch.graph.updateWeatherDataStreams(dataStreams);
    const firstDataStream = dataStreams[0];
    const secondDataStream = dataStreams[1];
    if (firstDataStream.unit === secondDataStream.unit) {
      dispatch.graph.setDefaultSelectedDataStreams({
        left: { ids: [firstDataStream.id, secondDataStream.id], unit: firstDataStream.unit },
      });
    } else {
      dispatch.graph.setDefaultSelectedDataStreams({
        left: { ids: [firstDataStream.id], unit: firstDataStream.unit },
        right: { ids: [secondDataStream.id], unit: secondDataStream.unit },
      });
    }
  };

  const selectDefaultWeatherStation = () => {
    if (initialWeatherStationId) {
      const initialIndex = weatherStations.findIndex((weatherStation) => {
        return weatherStation.id === initialWeatherStationId;
      });
      setSelectedIndex(initialIndex);
    }
  };

  const changeActiveWeatherStation = (index) => {
    chartRef.current.resetZoom();
    setDataRange(initialDataRange());
    setSelectedIndex(index);
    loadedSelectedDataStreamsCache.current = [];
    dispatch.graph.updateDataStreams();
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await dispatch.blocks.loadWeatherStationBlocksForCurrentSite();
    chartRef.current?.refresh();
    setRefreshing(false);
  };

  const onGraphInteraction = (newDateRange) => setDataRange(newDateRange);

  if (!isFocused) {
    return null;
  }

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.bottomContainer} testID="weather__subscreen-container">
        <View style={globalStyles.header}>
          <WeatherHeader
            changeActiveWeatherStation={changeActiveWeatherStation}
            navigation={navigation}
            selectedWeatherStation={selectedWeatherStation}
            siteName={currentSite.name}
            weatherStations={weatherStations}
          />
        </View>

        <Divider />

        <ScrollView
          ref={scrollViewRef}
          refreshControl={<RefreshControl testID="weather__refresh-controller" onRefresh={onRefresh} refreshing={refreshing} />}
          contentContainerStyle={globalStyles.scrollContainer}
          showsVerticalScrollIndicator={false}>
          <WeatherStationDetail
            ref={chartRef}
            selectedDataStreams={selectedDataStreams}
            onGraphInteraction={onGraphInteraction}
            weatherStation={selectedWeatherStation}
          />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

WeatherScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      initialWeatherStationId: PropTypes.string,
    }),
  }),
};

export default WeatherScreen;
