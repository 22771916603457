import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

// shape
import { navigationShape } from '../../shapes/navigation';
import { styleShape } from '../../shapes/style';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import ANALYTICS from '../../services/AnalyticsEvents';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import ROUTES from '../../navigation/routes';

// components
import Divider from '../Divider';
import BlueBandIndexStack from '../blueBandIndex/BlueBandIndexStack';
import BlockDetailComponentHeader from './BlockDetailComponentHeader';

const BlockDetailBlueBandIndex = ({ block, containerStyle, navigation }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const blueBandIndex = block.tensionCapability().blueBandIndex();

  const onPressHeaderLink = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockBlueBand);
    navigation.navigate(ROUTES.BLOCK_BLUE_BAND_INDEX, { block });
  };

  return (
    <View style={[containerStyle, styles.container]} testID="block-details__blueBandIndex-container">
      <BlockDetailComponentHeader
        handleOnPress={onPressHeaderLink}
        linkName={t('block_detail_performance_details')}
        buttonTestId={'block-details__blueBandIndex-details-button'}
        title={t('block_detail_performance_title')}
      />
      <Divider style={styles.headerDivider} />
      <View style={styles.graphSection}>
        <BlueBandIndexStack blueBandIndexData={blueBandIndex} />
        <View style={styles.descriptionSection}>
          <View style={styles.graphDescription}>
            <Text style={styles.descriptionText}>{t('block_detail_performance_description')}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

BlockDetailBlueBandIndex.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  containerStyle: styleShape,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  descriptionSection: {
    alignSelf: 'center',
    paddingLeft: 15,
  },
  descriptionText: {
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    letterSpacing: 0.8,
    color: COLORS.greyishBrown,
  },
  graphDescription: {
    justifyContent: 'center',
    width: 175,
    height: 70,
    paddingHorizontal: 3,
    backgroundColor: COLORS.white,
    lineHeight: 5,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 4,
  },
  graphSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 190,
    backgroundColor: COLORS.whiteTwo,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  headerDivider: {
    height: 1,
    backgroundColor: COLORS.brownGrey,
  },
});

export default BlockDetailBlueBandIndex;
