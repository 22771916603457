export const error = {
  state: {
    apolloError: undefined,
    refreshTokenError: undefined,
  },

  reducers: {
    updateApolloError(state, apolloError) {
      return { ...state, apolloError };
    },
    updateRefreshTokenError(state, refreshTokenError) {
      return { ...state, refreshTokenError };
    },
  },

  effects: (dispatch) => ({
    async clearApolloError() {
      dispatch.error.updateApolloError(undefined);
    },

    async setApolloError(payload, rootState) {
      if (!rootState.error.apolloError) {
        dispatch.error.updateApolloError({ dispatch, ...payload });
      }
    },

    async clearRefreshTokenError() {
      dispatch.error.updateRefreshTokenError(undefined);
    },

    async setRefreshTokenError(payload, rootState) {
      if (!rootState.error.refreshTokenError) {
        dispatch.error.updateRefreshTokenError({ dispatch, ...payload });
      }
    },
  }),
};
