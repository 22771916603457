import { SORT_ORDER } from '../../utils/sorting';
import { RULE_SORT_ORDER } from '../../models/automationRuleType';

const SORT_COLUMNS = {
  TYPE: 'type',
  NAME: 'name',
  THRESHOLD: 'threshold',
};

const sortAutomationRules = (rules, column, order) => {
  const compareByType = (a, b) => {
    let typeA = a.ruleType ? RULE_SORT_ORDER.indexOf(a.ruleType) : RULE_SORT_ORDER.length;
    let typeB = b.ruleType ? RULE_SORT_ORDER.indexOf(b.ruleType) : RULE_SORT_ORDER.length;

    if (typeA === -1) {
      typeA = RULE_SORT_ORDER.length;
    }
    if (typeB === -1) {
      typeB = RULE_SORT_ORDER.length;
    }

    if (typeA > typeB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (typeA < typeB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else {
      return compareByName(a, b);
    }
  };

  const compareByName = (a, b) => {
    const nameA = a.name ? a.name.toLowerCase() : '';
    const nameB = b.name ? b.name.toLowerCase() : '';

    const compareValue = nameA.localeCompare(nameB);
    return order === SORT_ORDER.ASC ? compareValue : -compareValue;
  };

  const compareByThreshold = (a, b) => {
    const thresholdA = order === SORT_ORDER.ASC ? a.thresholdDown : a.thresholdUp;
    const thresholdB = order === SORT_ORDER.ASC ? b.thresholdDown : b.thresholdUp;

    if (thresholdA > thresholdB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (thresholdA < thresholdB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else {
      return compareByName(a, b);
    }
  };

  switch (column) {
    case SORT_COLUMNS.TYPE: {
      rules.sort(compareByType);
      break;
    }
    case SORT_COLUMNS.NAME: {
      rules.sort(compareByName);
      break;
    }
    case SORT_COLUMNS.THRESHOLD: {
      rules.sort(compareByThreshold);
      break;
    }
  }
};

export { sortAutomationRules, SORT_COLUMNS };
