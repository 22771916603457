import { getApolloClient } from '../clients/apollo';
import { SITE } from './site.graphql';

const fetchSite = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: SITE,
    variables: { siteId },
  });

  let site;
  if (data) {
    site = data.viewer.site;
  }

  return {
    loading,
    error,
    site,
  };
};

export { fetchSite };
