import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// services
import { navigationShape } from '../../shapes/navigation';
import * as CAPABILITIES from '../../models/capability';
import ROUTES from '../../navigation/routes';
import ANALYTICS from '../../services/AnalyticsEvents';
import IrrigationBlockAliasPresenter from '../../presenters/blockAlias/IrrigationBlockAliasPresenter';

// components
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import * as TABS from '../../screens/blockDetails/BlockDetailsTabs';
import BlockAliasButton from '../block/BlockAliasButton';

const CriticalBlockItem = ({ block, navigation }) => {
  const analyticsService = useAnalyticsContext();
  const irrigationBlockAliasPresenter = new IrrigationBlockAliasPresenter(block);
  const dispatch = useDispatch();

  const blockDetailsNavigationProps = {
    capability: CAPABILITIES.TENSION,
    block,
    defaultSelectedStreamIds: block
      .tensionCapability()
      .tensionReadings()
      .map((reading) => reading.id),
    unit: irrigationBlockAliasPresenter.unit(),
    displayTabs: true,
  };

  const goToDetails = async () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      activeTab: TABS.DETAILS,
      ...blockDetailsNavigationProps,
      routeToGoBackTo: ROUTES.DASHBOARD,
    });
  };

  return (
    <BlockAliasButton
      key={block.id}
      testId={`irrigation__blockAlias-button-${block.id}`}
      presenter={irrigationBlockAliasPresenter}
      buttonStyle={styles.buttonStyle}
      onClick={goToDetails}
    />
  );
};

CriticalBlockItem.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: 60,
    marginTop: 13,
    marginRight: 20,
  },
});

export default CriticalBlockItem;
