/* istanbul ignore file */
import ORIENTATIONS from './DeviceOrientations';

export const isLandscape = (orientation) =>
  orientation === ORIENTATIONS.LANDSCAPE_RIGHT || orientation === ORIENTATIONS.LANDSCAPE_LEFT;

export const isPortrait = (orientation) => !isLandscape(orientation);

export const lockToPortrait = () => {};

export const unlockAllOrientations = () => {};

export const addOrientationListener = () => {};

export const removeOrientationListener = () => {};
