import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, TextInput } from 'react-native';

import { styleShape } from '../shapes/style';
import ErrorMessageBanner from './ErrorMessageBanner';

const InputWithValidation = ({
  value,
  onChangeText,
  placeholder,
  isSecureText = false,
  errorMessage,
  isRequired = false,
  testId,
  returnKeyType,
  onSubmitEditing,
  refToFocus,
  blurOnSubmit = true,
  style,
  placeholderTextColor,
}) => {
  return (
    <View>
      <TextInput
        autoCapitalize="none"
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        secureTextEntry={isSecureText}
        testID={testId}
        returnKeyType={returnKeyType}
        onSubmitEditing={onSubmitEditing}
        ref={refToFocus}
        style={[styles.inputStyle, style]}
        blurOnSubmit={blurOnSubmit}
        placeholderTextColor={placeholderTextColor}
      />
      {isRequired && value === '' && <ErrorMessageBanner errorMessage={errorMessage} bannerStyle={styles.errorBanner} />}
    </View>
  );
};

InputWithValidation.propTypes = {
  value: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isSecureText: PropTypes.bool,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  testId: PropTypes.string,
  returnKeyType: PropTypes.string,
  onSubmitEditing: PropTypes.func,
  refToFocus: PropTypes.func,
  blurOnSubmit: PropTypes.bool,
  style: styleShape,
  placeholderTextColor: PropTypes.string,
};

const styles = StyleSheet.create({
  inputStyle: {
    paddingVertical: 11,
  },
  errorBanner: {
    paddingHorizontal: 0,
    paddingVertical: 4,
  },
});

export default InputWithValidation;
