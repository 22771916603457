import React from 'react';
import { StyleSheet, Text, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const NDVILegendButton = ({ onPress, isVisible = false }) => {
  const { t } = useTranslation();

  if (isVisible) {
    return (
      <Pressable style={styles.legendButton} onPress={onPress} testID={'mapLegend-button'}>
        <View style={styles.legendButtonTitle}>
          <Text style={styles.textButton}>{t('map_ndvi_legend')}</Text>
        </View>
      </Pressable>
    );
  } else {
    return null;
  }
};

NDVILegendButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

const styles = StyleSheet.create({
  legendButton: {
    position: 'absolute',
    zIndex: 1,
    top: '45%',
    left: -44,
    transform: [{ rotate: '270deg' }],
    width: 112,
  },
  legendButtonTitle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    backgroundColor: COLORS.white,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
  },
  textButton: {
    paddingHorizontal: 25,
    paddingVertical: 3,
    fontFamily: FONTS.firaSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
});

export default NDVILegendButton;
