// services
import { isAndroid } from '../services/PlatformService';

export const ANDROID = 'ANDROID';
export const IOS = 'IOS';

export const getOS = () => {
  if (isAndroid()) {
    return ANDROID;
  }
  return IOS;
};
