import {
  PermissionsEntity,
  ACTION_MODIFY_AUTOMATION_CALENDAR_EVENTS,
  ACTION_MODIFY_AUTOMATION_RULES,
  ACTION_START_STOP_AUTOMATION,
} from '@hortau/knit';

import { fetchPermissions, fetchUser } from '../queries/user';

// services
import * as CrashlyticsService from '../services/CrashlyticsService';

// models helper
import { executeApolloQuery } from './apolloHelper';
import { setUserIdentity } from './preferences';

export const user = {
  state: {
    currentUser: undefined,
    permissions: undefined,
  },

  selectors: (slice, createSelector) => ({
    isAlphaUser() {
      // eslint-disable-next-line no-shadow
      return slice((user) => checkIsAlpha(user.currentUser.roles));
    },
    isBetaUser() {
      // eslint-disable-next-line no-shadow
      return slice((user) => checkIsBeta(user.currentUser.roles));
    },
    isHortauStaff() {
      return slice((usr) => usr.currentUser.userAccount?.isHortauStaff === true);
    },
    hasModifyAutomationEventsPermission() {
      return createSelector(
        slice,
        (rootState) => rootState.site.currentSite.id,
        (state, siteId) => state.permissions?.hasSitePermission(siteId, ACTION_MODIFY_AUTOMATION_CALENDAR_EVENTS),
      );
    },
    hasModifyAutomationRulesPermission() {
      return createSelector(
        slice,
        (rootState) => rootState.site.currentSite.id,
        (state, siteId) => state.permissions?.hasSitePermission(siteId, ACTION_MODIFY_AUTOMATION_RULES),
      );
    },
    hasStartStopAutomationPermission() {
      return createSelector(
        slice,
        (rootState) => rootState.site.currentSite.id,
        (state, siteId) => state.permissions?.hasSitePermission(siteId, ACTION_START_STOP_AUTOMATION),
      );
    },
  }),

  reducers: {
    updateCurrentUser(state, currentUser) {
      return { ...state, currentUser };
    },
    updatePermissions(state, permissions) {
      return { ...state, permissions };
    },
  },

  effects: (dispatch) => ({
    async loadViewer() {
      await executeApolloQuery(
        dispatch,
        async () => await fetchUser(),
        async (data) => {
          const userId = data.user.id;
          await setUserIdentity(data.user);
          dispatch.user.updateCurrentUser(data.user);
          await CrashlyticsService.setUserId(userId);
        },
      );
    },

    async loadPermissions(payload, rootState) {
      executeApolloQuery(
        dispatch,
        async () => await fetchPermissions(rootState.site.currentSite.id),
        async (data) => {
          dispatch.user.updatePermissions(new PermissionsEntity(data.permissions));
        },
      );
    },
  }),
};

export const checkIsAlpha = (roles) => roles?.some((role) => role.name === 'Toggler Alpha');
export const checkIsBeta = (roles) => roles?.some((role) => role.name === 'Toggler Beta');
