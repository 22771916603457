import { gql } from 'graphql-tag';

export const SCHEDULE = gql`
  query schedule($siteId: ID!, $startDate: String!, $endDate: String!) {
    viewer {
      site(id: $siteId) {
        id
        notes(startDate: $startDate, endDate: $endDate) {
          id
          startDate
          endDate
        }
        blockGroups {
          groupId
          name
          alias
          elementIds
          automationIds
          schedule {
            ...scheduleFields
          }
        }
        blocks(sortBy: BLOCK_ORDER, filters: { capabilities: [TENSION] }) {
          id
          schedule {
            ...scheduleFields
          }
          alias
          name
          groupId
          automationId
          notes(startDate: $startDate, endDate: $endDate) {
            id
            startDate
            endDate
          }
          order
          capabilities {
            tension {
              status
            }
          }
          calendars(startDate: $startDate, endDate: $endDate, calendarTypes: [PLANNED], eventTypes: [IRRIGATION]) {
            type
            events {
              id
              type
              startDate
              endDate
              anytimeDayEvent
            }
          }
          events(startDate: $startDate, endDate: $endDate) {
            id
            startDate
            endDate
            subSensorId
            statistics {
              id
              maxValue
              minValue
              subSensorId
              subSensorType
              unit
            }
          }
        }
        todayForecast {
          date
          temperature {
            current
            unit
          }
          weatherCode
        }
        weatherForecast {
          city
          dailyForecasts {
            date
            eto {
              unit
              value
            }
            weatherCode
            temperature {
              min
              max
              unit
            }
            rain {
              precipitation
              probability
              unit
            }
            wind {
              direction
              speed
              unit
            }
          }
        }
      }
    }
  }

  fragment scheduleFields on schedule {
    scheduleEvents(startDate: $startDate, endDate: $endDate) {
      id
      type
      resource {
        id
        type
      }
      startDate
      endDate
      duration
    }
  }
`;
