export const ERROR_START_BELOW = 5431;
export const ERROR_STOP_ABOVE = 5432;
export const ERROR_RESTART_TRIGGER = 5433;
export const ERROR_START_ABOVE = 5451;
export const ERROR_STOP_BELOW = 5452;

export const getAutomationRuleErrorTranslationKey = (errorCode) => {
  switch (errorCode) {
    case 1003:
      return 'error_access_denied';
    case 5418:
      return 'automation_rule_error_not_found'; //AutomationRuleNotFound
    case 5419:
      return 'automation_rule_error_name_empty'; //ErrRuleNameEmpty
    case 5420:
      return 'automation_rule_error_name_too_long'; //ErrRuleNameTooLong
    case 5421:
      return 'automation_rule_error_unsupported_type'; //ErrUnsupportedType
    case 5422:
      return 'automation_rule_error_generating_conditions'; //ErrGeneratingConditions
    case 5423:
      return 'automation_rule_error_invalid_trigger_type'; //ErrInvalidTriggerType
    case 5424:
      return 'automation_rule_error_irrigation_duration_values'; //ErrIrrigationDurationValues
    case 5425:
      return 'automation_rule_error_invalid_type'; //ErrInvalidAutomationRuleType
    case 5426:
      return 'automation_rule_error_type_already_exists'; //ErrSameTypeRuleAlreadyCreated
    case 5427:
      return 'automation_rule_error_rule_not_exist'; //ErrProvidedRuleNotExist
    case 5428:
      return 'automation_rule_error_name_already_used'; //ErrSameRuleNameAlreadyExist
    case 5429:
      return 'automation_rule_error_min_irrigation_value'; //ErrMinIrrigationValue
    case 5430:
      return 'automation_rule_error_max_irrigation_value'; //ErrMaxIrrigationValue
    case ERROR_START_BELOW:
      return 'automation_rule_error_start_below_value'; //ErrStartBelowValue
    case ERROR_STOP_ABOVE:
      return 'automation_rule_error_stop_above_value'; //ErrStopAboveValue
    case ERROR_RESTART_TRIGGER:
      return 'automation_rule_error_cycling_restart_trigger_value'; //ErrCyclingRestartTriggerValue
    case 5434:
      return 'automation_rule_error_cycling_duration_value'; //ErrCyclingDurationValue
    case 5435:
      return 'automation_rule_error_invalid_component'; //ErrInvalidSummaryId
    case 5436:
      return 'automation_rule_error_sensor_not_belong_to_component'; //ErrSensorsNotBelongToSite
    case 5437:
      return 'automation_rule_error_empty_type_system_unit'; //ErrEmptyRuleTypeSystemUnit
    case 5438:
      return 'automation_rule_error_converting_to_system_unit'; //ErrConvertingDataToSystemUnit
    case 5439:
      return 'automation_rule_error_unsupported_sensor_type'; //ErrUnsupportedSensorType
    case 5440:
      return 'automation_rule_error_mapping_system_unit'; //ErrMappingSystemUnit
    case 5441:
      return 'automation_rule_error_start_below_higher_than_stop_above'; //ErrStartBelowHigherThanStopAbove
    case 5442:
      return 'automation_rule_error_site_not_found'; //ErrSiteNotFound
    case 5443:
      return 'automation_rule_error_empty_min_irrigation'; //ErrEmptyMinIrrigationValue
    case 5444:
      return 'automation_rule_error_unknown_automation_version'; //ErrUnKnownAutomationVersion
    case 5445:
      return 'automation_rule_error_fetching_components'; //ErrFetchingSummaries
    case 5446:
      return 'automation_rule_error_invalid_rule_type_filter'; //ErrInvalidRuleTypeFilter
    case 5447:
      return 'automation_rule_error_empty_subsensor_type'; //ErrEmptySupportedSubSensorTypeList
    case 5448:
      return 'automation_rule_error_empty_component'; //ErrEmptySummaryDeviceList
    case 5449:
      return 'automation_rule_error_fetching_sensor'; //ErrFetchingSensorSummaries
    case 5450:
      return 'automation_rule_error_empty_max_irrigation'; //ErrEmptyMaxIrrigationValue
    case ERROR_START_ABOVE:
      return 'automation_rule_error_start_above_value'; //ErrStartAboveValue
    case ERROR_STOP_BELOW:
      return 'automation_rule_error_stop_below_value'; //ErrStopBelowValue
    case 5453:
      return 'automation_rule_error_stop_below_higher_than_start_above'; //ErrStopBelowHigherThanStartAbove
    case 5454:
      return 'automation_rule_error_sensor_type_not_available'; //ErrSensorTypeNotAvailableOnSite
    case 5455:
      return 'automation_rule_error_cannot_upsert_low_rule'; //ErrCannotUpsertLowRuleOnHighEndpoint
    case 5456:
      return 'automation_rule_error_cannot_upsert_high_rule'; //ErrCannotUpsertHighRuleOnLowEndpoint
    case 5461:
      return 'automation_rule_error_invalid_sensors'; //ErrSensorsNotBelongToSite
    default:
      return 'generic_error_server_error_title';
  }
};
