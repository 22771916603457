import moment from 'moment-timezone';
import { SORT_ORDER, compareBlock } from '../../utils/sorting';

const SORT_COLUMNS = {
  BLOCK: 'block',
  IRRIGATION: 'irrigation',
  BLUEBAND_INDEX: 'blueBandIndex',
  TENSION: 'tension',
};

const sortIrrigationBlocks = (irrigationBlocks, column, order) => {
  const compareByBlock = (a, b) => compareBlock(a, b, order);

  const compareByIrrigationStartTime = (a, b) => {
    const event1 = a.irrigationCapability().currentOrNextEvent();
    const event2 = b.irrigationCapability().currentOrNextEvent();
    const value1 = event1 ? moment(event1.startDate) : null;
    const value2 = event2 ? moment(event2.startDate) : null;

    if (value1 === null && value2 === null) {
      return 0;
    }

    if (value1 === null) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    }

    if (value2 === null) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }

    if (value1.isAfter(value2)) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (value1.isBefore(value2)) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return 0;
  };

  const compareByBluebandIndex = (a, b) => {
    const value1 = a.tensionCapability().blueBandIndex().good !== null ? a.tensionCapability().blueBandIndex().good : -1;
    const value2 = b.tensionCapability().blueBandIndex().good !== null ? b.tensionCapability().blueBandIndex().good : -1;
    if (value1 > value2) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (value1 < value2) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return 0;
  };

  const compareByTension = (a, b) => {
    let index = 0;
    const readingsA = sortReadings(a.tensionCapability().tensionReadings());
    const readingsB = sortReadings(b.tensionCapability().tensionReadings());
    const maximumIteration = Math.min(readingsA.length, readingsB.length);

    if (readingsA.length === 0 || readingsB.length === 0) {
      const difference = readingsA.length - readingsB.length;
      return order === SORT_ORDER.ASC ? difference : -difference;
    }

    do {
      const firstReadingSorted = readingsA[index].value ?? -1;
      const secondReadingSorted = readingsB[index].value ?? -1;

      if (firstReadingSorted < secondReadingSorted) {
        return order === SORT_ORDER.ASC ? -1 : 1;
      }

      if (firstReadingSorted > secondReadingSorted) {
        return order === SORT_ORDER.ASC ? 1 : -1;
      }

      index = index + 1;
    } while (index < maximumIteration);

    if (readingsA.length < readingsB.length) {
      // istanbul ignore next
      return order === SORT_ORDER.ASC ? -1 : 1;
    }

    return order === SORT_ORDER.ASC ? 1 : -1;
  };

  const sortReadings = (readings) => {
    return readings.sort((reading1, reading2) => {
      const value1 = reading1.value;
      const value2 = reading2.value;
      if (value1 > value2) {
        return order === SORT_ORDER.ASC ? 1 : -1;
      } else if (value1 < value2) {
        return order === SORT_ORDER.ASC ? -1 : 1;
      }
      return 0;
    });
  };

  switch (column) {
    case SORT_COLUMNS.BLOCK: {
      irrigationBlocks.sort(compareByBlock);
      break;
    }
    case SORT_COLUMNS.IRRIGATION: {
      irrigationBlocks.sort(compareByIrrigationStartTime);
      break;
    }
    case SORT_COLUMNS.BLUEBAND_INDEX: {
      irrigationBlocks.sort(compareByBluebandIndex);
      break;
    }
    case SORT_COLUMNS.TENSION: {
      irrigationBlocks.sort(compareByTension);
      break;
    }
  }
};

export { sortIrrigationBlocks, SORT_COLUMNS };
