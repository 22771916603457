const LOGO = {
  default: {
    image: require('../assets/images/logoHortauEn.png'),
    style: {
      width: 152,
      height: 117,
    },
  },
  en: {
    image: require('../assets/images/logoHortauEn.png'),
    style: {
      width: 152,
      height: 117,
    },
  },
  es: {
    image: require('../assets/images/logoHortauEs.png'),
    style: {
      width: 149,
      height: 116,
    },
  },
  fr: {
    image: require('../assets/images/logoHortauFr.png'),
    style: {
      width: 152,
      height: 117,
    },
  },
};

const previewImageStyle = {
  flex: 1,
  width: '100%',
  height: '100%',
  resizeMode: 'contain',
};

const AUTOMATION_PREVIEW = {
  default: {
    image: require('../assets/images/previews/PLG_automation_en.png'),
    style: previewImageStyle,
  },
  en: {
    image: require('../assets/images/previews/PLG_automation_en.png'),
    style: previewImageStyle,
  },
  es: {
    image: require('../assets/images/previews/PLG_automation_es.png'),
    style: previewImageStyle,
  },
  fr: {
    image: require('../assets/images/previews/PLG_automation_fr.png'),
    style: previewImageStyle,
  },
};

const FLOWMETERS_PREVIEW = {
  default: {
    image: require('../assets/images/previews/PLG_flowmeters_en.png'),
    style: previewImageStyle,
  },
  en: {
    image: require('../assets/images/previews/PLG_flowmeters_en.png'),
    style: previewImageStyle,
  },
  es: {
    image: require('../assets/images/previews/PLG_flowmeters_es.png'),
    style: previewImageStyle,
  },
  fr: {
    image: require('../assets/images/previews/PLG_flowmeters_fr.png'),
    style: previewImageStyle,
  },
};

const TEMPERATURE_PREVIEW = {
  default: {
    image: require('../assets/images/previews/PLG_temperature_en.png'),
    style: previewImageStyle,
  },
  en: {
    image: require('../assets/images/previews/PLG_temperature_en.png'),
    style: previewImageStyle,
  },
  es: {
    image: require('../assets/images/previews/PLG_temperature_es.png'),
    style: previewImageStyle,
  },
  fr: {
    image: require('../assets/images/previews/PLG_temperature_fr.png'),
    style: previewImageStyle,
  },
};

const WEATHER_PREVIEW = {
  default: {
    image: require('../assets/images/previews/PLG_Weather_en.png'),
    style: previewImageStyle,
  },
  en: {
    image: require('../assets/images/previews/PLG_Weather_en.png'),
    style: previewImageStyle,
  },
  es: {
    image: require('../assets/images/previews/PLG_Weather_es.png'),
    style: previewImageStyle,
  },
  fr: {
    image: require('../assets/images/previews/PLG_Weather_fr.png'),
    style: previewImageStyle,
  },
};

const WATER_REPORT_PREVIEW = {
  default: {
    image: require('../assets/images/previews/PLG_waterreport_en.png'),
    style: previewImageStyle,
  },
  en: {
    image: require('../assets/images/previews/PLG_waterreport_en.png'),
    style: previewImageStyle,
  },
  es: {
    image: require('../assets/images/previews/PLG_waterreport_es.png'),
    style: previewImageStyle,
  },
  fr: {
    image: require('../assets/images/previews/PLG_waterreport_fr.png'),
    style: previewImageStyle,
  },
};

export { LOGO, AUTOMATION_PREVIEW, FLOWMETERS_PREVIEW, TEMPERATURE_PREVIEW, WEATHER_PREVIEW, WATER_REPORT_PREVIEW };
