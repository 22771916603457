import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import ShimmerPlaceHolder from 'react-native-shimmer-placeholder';
import PropTypes from 'prop-types';

// shapes
import { styleShape } from '../../shapes/style';

const SkeletonPlaceholder = (props) => (
  <ShimmerPlaceHolder
    LinearGradient={LinearGradient}
    shimmerColors={['#E1E9EE', '#F2F8FC', '#E1E9EE']}
    duration={800}
    {...props}
  />
);

SkeletonPlaceholder.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: styleShape,
};

export default SkeletonPlaceholder;
