// constants
import * as BlockType from '../blockType';
import * as TensionStatus from '../status/tensionStatus';
import * as FlowStationStatus from '../status/flowStationStatus';
import * as WeatherStationStatus from '../status/weatherStationStatus';
import * as TemperatureStatus from '../status/temperatureStatus';
import COLORS from '../../colors';
import { MapDisplayMode, MapStyleMode } from '../../presenters/MapDisplayMode';

// utils
import { readingsToDataStream } from '../../utils/dataStreamsUtil';
import { isNullOrUndefined } from '../../utils/util';
import PIN_ICONS from '../../assets/icons/mapPins/pinIcons';
import * as TemperatureUnits from '../units/temperatureUnits';

export default class MapItemEntity {
  constructor(block, automation) {
    this.block = block;
    this.automation = automation;
    this.graphPresenter = null;
  }

  alias() {
    if (this.block) {
      return this.block.alias;
    }

    if (this.automation) {
      return this.automation.alias;
    }

    return null;
  }

  color(mapMode, mapStyle) {
    if (mapStyle === MapStyleMode.NDVI) {
      return null;
    }

    if (this.block) {
      switch (mapMode) {
        case MapDisplayMode.TENSION:
          if (this.block.isTensionCapable()) {
            return TensionStatus.colorForStatus(this.block.capabilities.tension().status);
          } else if (this.block.isFlowStationCapable()) {
            return FlowStationStatus.colorForStatus(this.block.capabilities.flowStation().status);
          } else {
            return COLORS.darkBlue;
          }
        case MapDisplayMode.TEMPERATURE:
          if (this.block.isWeatherStationCapable()) {
            return WeatherStationStatus.colorForStatus(this.block.capabilities.weatherStation().status);
          } else if (this.block.isTemperatureCapable()) {
            return TemperatureStatus.colorForStatus(this.block.capabilities.temperature().status);
          } else {
            return COLORS.darkBlue;
          }
      }
    }

    if (this.automation) {
      return this.automation.statusColor();
    }

    return COLORS.darkBlue;
  }

  coordinates() {
    if (this.block) {
      return this.block.coordinates;
    }

    if (this.automation) {
      return this.automation.coordinates;
    }

    return null;
  }

  id() {
    if (this.block) {
      return this.block.id;
    }

    if (this.automation) {
      return this.automation.id;
    }

    return null;
  }

  lastUpdated() {
    if (this.block) {
      return this.block.lastUpdated;
    }

    if (this.automation) {
      return this.automation.lastUpdated();
    }

    return null;
  }

  name() {
    if (this.block) {
      return this.block.name;
    }

    if (this.automation) {
      return this.automation.name;
    }

    return null;
  }

  readings() {
    if (this.block) {
      switch (this.type()) {
        case BlockType.TENSION:
          return this.block.tensionCapability().tensionReadings();
        case BlockType.FLOW_STATION:
          return this.block.flowStationCapability().readings();
        case BlockType.TEMPERATURE:
        case BlockType.WEATHER:
          return readingsToDataStream(this.block.temperatureCapability().readings());
        case BlockType.WATER_LEVEL:
          return this.block.dataStreams();
      }
    }

    if (this.automation) {
      return this.automation.subSensorsCurrentData;
    }

    return [];
  }

  type() {
    if (this.block) {
      return this.block.blockType();
    }

    if (this.automation) {
      return 'automation'; // TODO: Fix in future PR
    }

    return null;
  }

  getGraphPresenter() {
    return this.graphPresenter;
  }

  setGraphPresenter(graphPresenter) {
    this.graphPresenter = graphPresenter;
  }

  // TODO: Backward compatibilities below (to refactor when the whole map uses the new pins)
  blockId() {
    return this.id();
  }

  isForMapV1() {
    if (isNullOrUndefined(this.block)) {
      return false;
    }

    return (
      this.block.isTensionCapable() ||
      this.block.isFlowStationCapable() ||
      this.block.isTemperatureCapable() ||
      this.block.isWeatherStationCapable()
    );
  }

  tensionIcon() {
    if (this.block.isIrrigating()) {
      return PIN_ICONS.irrigating;
    }
    return TensionStatus.iconForMapStatus(this.block.capabilities.tension().status);
  }

  flowStationIcon() {
    if (this.block.isIrrigating()) {
      return PIN_ICONS.flowIrrigating;
    }
    return FlowStationStatus.iconForMapStatus(this.block.capabilities.flowStation().status);
  }

  temperatureIcon() {
    return TemperatureStatus.iconForMapStatus(this.block.capabilities.temperature().status);
  }

  weatherStationIcon() {
    return WeatherStationStatus.iconForMapStatus(this.block.capabilities.weatherStation().status);
  }

  icon(mapMode, mapStyle) {
    if (mapStyle === MapStyleMode.NDVI) {
      return PIN_ICONS.ndvi;
    }

    if (mapMode === MapDisplayMode.TENSION) {
      if (this.block.isTensionCapable()) {
        return this.tensionIcon();
      } else if (this.block.isFlowStationCapable()) {
        return this.flowStationIcon(mapMode);
      } else {
        return PIN_ICONS.problems;
      }
    }

    if (mapMode === MapDisplayMode.TEMPERATURE) {
      if (this.block.isWeatherStationCapable()) {
        return this.weatherStationIcon();
      } else if (this.block.isTemperatureCapable()) {
        return this.temperatureIcon();
      } else {
        return PIN_ICONS.problems;
      }
    }
    return PIN_ICONS.unknown;
  }

  valueForDisplay(mapMode, mapStyle) {
    if (mapStyle === MapStyleMode.NDVI) {
      return this.block.alias;
    }

    if (mapMode === MapDisplayMode.TEMPERATURE) {
      if (this.block.isWeatherStationCapable()) {
        return this.block.alias;
      }

      if (this.block.isTemperatureCapable()) {
        const temperature = this.block.capabilities.temperature();

        if (temperature.status !== TemperatureStatus.COMMUNICATION_ERROR) {
          if (temperature.value === undefined || temperature.value === null) {
            return `${this.block.alias}\n- -${TemperatureUnits[temperature.unit].displayValue}`;
          }
          return `${this.block.alias}\n${temperature.value}${TemperatureUnits[temperature.unit].displayValue}`;
        }
        return this.block.alias;
      }
      return this.block.alias;
    }
    return this.block.alias;
  }
}
