// queries
import { fetchBlockDataStream } from '../queries/blockDataStream';
import { fetchIrrigationBlocks } from '../queries/irrigationBlocks';
import { fetchIrrigationBlock } from '../queries/irrigationBlock';
import { fetchTemperatureBlocks } from '../queries/temperatureBlocks';
import { fetchFlowStationBlocks } from '../queries/flowStationBlocks';
import { fetchWeatherStationBlocks } from '../queries/weatherStation';

// services
import { sortIrrigationBlocks } from '../screens/irrigation/sorting';
import { sortTemperatureBlocks } from '../screens/temperature/sorting';
import { sortFlowStationBlocks } from '../screens/flowStation/sorting';

import { getEventDateRange } from '../utils/dateUtils';

import { executeApolloQuery } from './apolloHelper';

// entity
import BlockEntity from './entities/blockEntity';

// constants
import { LOCAL_DATA } from './dataStreamTypes';

const NUMBER_OF_WEEK_IN_PAST = 2;
const NUMBER_OF_WEEK_IN_FUTURE = 2;

export const blocks = {
  state: {
    irrigation: [],
    temperature: [],
    flowStation: [],
    weatherStation: [],
    sortedIrrigationBlocks: [],
    sortedTemperatureBlocks: [],
    sortedFlowStationBlocks: [],

    // related to one block
    irrigationBlock: undefined,
    selectedBlock: undefined,
    selectedWeatherStation: undefined,
  },

  reducers: {
    updateFlowStationBlocks(state, dataBlocks) {
      const blockEntities = dataBlocks.map((block) => {
        return new BlockEntity(block);
      });
      return { ...state, flowStation: blockEntities, sortedFlowStationBlocks: blockEntities };
    },
    updateSortedFlowStationBlockEntities(state, sortedFlowStationBlocks) {
      return { ...state, sortedFlowStationBlocks };
    },
    updateTemperatureBlocks(state, dataBlocks) {
      const blockEntities = dataBlocks.map((block, index) => {
        return new BlockEntity(block, index, block.sensors);
      });
      return { ...state, temperature: blockEntities, sortedTemperatureBlocks: blockEntities };
    },
    updateSortedTemperatureBlockEntities(state, sortedTemperatureBlocks) {
      return { ...state, sortedTemperatureBlocks };
    },
    updateIrrigationBlocks(state, dataBlocks) {
      const blockEntities = dataBlocks.map((block) => {
        return new BlockEntity(block);
      });
      return { ...state, irrigation: blockEntities, sortedIrrigationBlocks: blockEntities };
    },
    updateIrrigationBlock(state, block) {
      return { ...state, irrigationBlock: new BlockEntity(block) };
    },
    updateIrrigationBlockEntity(state, block) {
      return { ...state, irrigationBlock: block };
    },
    updateSortedIrrigationBlockEntities(state, sortedIrrigationBlocks) {
      return { ...state, sortedIrrigationBlocks };
    },
    updateWeatherStationBlocks(state, dataBlocks) {
      const blockEntities = dataBlocks.map((block) => {
        return new BlockEntity(block);
      });
      return { ...state, weatherStation: blockEntities };
    },
    updateSelectedBlock(state, selectedBlock) {
      return { ...state, selectedBlock: new BlockEntity(selectedBlock) };
    },
    updateSelectedEntityBlock(state, selectedBlock) {
      return { ...state, selectedBlock };
    },
    updateSelectedWeatherStation(state, selectedWeatherStation) {
      return { ...state, selectedBlock: selectedWeatherStation, selectedWeatherStation };
    },
  },

  effects: (dispatch) => ({
    async loadFlowStationBlocksForCurrentSite(_payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchFlowStationBlocks(rootState.site.currentSite.id),
        (data) => dispatch.blocks.updateFlowStationBlocks(data.flowStationBlocks),
      );
    },

    async sortFlowStationBlocks({ column, order }, rootState) {
      const sortedBlocks = [...rootState.blocks.flowStation];
      sortFlowStationBlocks(sortedBlocks, column, order);
      dispatch.blocks.updateSortedFlowStationBlockEntities(sortedBlocks);
    },

    async loadTemperatureBlocksForCurrentSite(_payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchTemperatureBlocks(rootState.site.currentSite.id),
        (data) => dispatch.blocks.updateTemperatureBlocks(data.temperatureBlocks),
      );
    },

    async sortTemperatureBlocks({ column, order }, rootState) {
      const sortedBlocks = [...rootState.blocks.temperature];
      sortTemperatureBlocks(sortedBlocks, column, order);
      dispatch.blocks.updateSortedTemperatureBlockEntities(sortedBlocks);
    },

    async loadIrrigationBlocksForCurrentSite({ sortBlocksBy, blockFilters }, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchIrrigationBlocks(rootState.site.currentSite.id, sortBlocksBy, blockFilters),
        (data) => dispatch.blocks.updateIrrigationBlocks(data.irrigationBlocks),
      );
    },

    async loadIrrigationBlockById({ blockId }, rootState) {
      const { startDate, endDate } = getEventDateRange(NUMBER_OF_WEEK_IN_PAST, NUMBER_OF_WEEK_IN_FUTURE);
      await executeApolloQuery(
        dispatch,
        async () => await fetchIrrigationBlock(rootState.site.currentSite.id, blockId, startDate, endDate),
        (data) => dispatch.blocks.updateIrrigationBlock(data.irrigationBlock),
      );
    },

    async sortIrrigationBlocks({ column, order }, rootState) {
      const irrigationBlockEntities = [...rootState.blocks.irrigation];
      sortIrrigationBlocks(irrigationBlockEntities, column, order);

      dispatch.blocks.updateSortedIrrigationBlockEntities(irrigationBlockEntities);
    },

    async loadBlockDataStreams({ blockId }, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchBlockDataStream(rootState.site.currentSite.id, blockId, LOCAL_DATA),
        (data) => dispatch.blocks.updateSelectedBlock(data.block),
      );
    },

    async loadWeatherStationBlocksForCurrentSite(_payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchWeatherStationBlocks(rootState.site.currentSite.id),
        (data) => dispatch.blocks.updateWeatherStationBlocks(data.weatherStationBlocks),
      );
    },

    async reset() {
      dispatch.blocks.updateFlowStationBlocks([]);
      dispatch.blocks.updateTemperatureBlocks([]);
      dispatch.blocks.updateIrrigationBlocks([]);
      dispatch.blocks.updateSelectedEntityBlock();
    },
  }),
};
