// consts
import COLORS from '../colors';
import { MIN_VALUE_BEFORE_COMPACT } from '../consts';

export class FlowmeterPresenter {
  constructor(flowmeter) {
    this.flowmeter = flowmeter;
  }

  containerStyle(index) {
    const container = {
      alignItems: 'center',
      flexDirection: 'row',
      height: 46,
      justifyContent: 'space-between',
      paddingHorizontal: 10,
    };
    const greyContainer = { ...container, backgroundColor: COLORS.greyish22 };

    return index % 2 === 0 ? greyContainer : container;
  }

  displayIcon() {
    return false;
  }

  flowValue(t) {
    if (
      this.isFlowStationOutOfField() ||
      this.isFlowStationInCommunicationError() ||
      !this.flowmeter.outflow ||
      !this.flowmeter.outflow.dataStream
    ) {
      return '- -';
    }

    const value = this.flowmeter.outflow.dataStream.value;
    const unit = t(`unit_${this.flowmeter.outflow.dataStream.unit.toLowerCase()}`);

    return `${value} ${unit}`;
  }

  isFlowStationInCommunicationError() {
    return this.flowmeter.flowStationStatus === 'COMMUNICATION_ERROR';
  }

  isFlowStationOutOfField() {
    return this.flowmeter.flowStationStatus === 'OUT_OF_FIELD';
  }

  isIrrigating() {
    return this.flowmeter.isFlowing;
  }

  name(t) {
    if (this.flowmeter.description) {
      return this.flowmeter.description;
    }
    return t('data_stream_button_name_flow');
  }

  totalVolumeValue(t, i18n) {
    if (this.isFlowStationOutOfField() || this.isFlowStationInCommunicationError() || !this.flowmeter.totalVolume) {
      return '- -';
    }

    const value = this.flowmeter.totalVolume.dataStream.value;
    const formattedValue =
      value > MIN_VALUE_BEFORE_COMPACT ? value.toLocaleString(i18n.language, { notation: 'compact' }) : value;

    const unit = t(`unit_${this.flowmeter.totalVolume.dataStream.unit.toLowerCase()}`);

    return `${formattedValue} ${unit}`;
  }
}
