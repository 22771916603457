import outOfFieldIcon from '../../assets/icons/icnPelletOutField.png';
import { TENSION_TREND } from '../../models/tensionTrend';
import trendDownIcon from '../../assets/icons/icnDescent.png';
import trendUpIcon from '../../assets/icons/icnAscent.png';
import BlockAliasPresenter from './BlockAliasPresenter';

export default class ListBlockAliasPresenter extends BlockAliasPresenter {
  constructor(block, capability) {
    super(block, capability);
  }

  getOverlayIcon() {
    if (this.block.isOutOfField()) {
      return {
        icon: outOfFieldIcon,
        testID: 'out-of-field-icon',
        style: {
          position: 'absolute',
          left: 35,
          bottom: 13,
        },
      };
    }
    if (this.block.isTensionCapable() && this.block.tensionCapability().tensionTrend() === TENSION_TREND.DECREASING) {
      return {
        icon: trendDownIcon,
        testID: 'trend-decreasing-icon',
        style: {
          position: 'absolute',
          left: 35,
          bottom: 13,
        },
      };
    }
    if (this.block.isTensionCapable() && this.block.tensionCapability().tensionTrend() === TENSION_TREND.RISING) {
      return {
        icon: trendUpIcon,
        testID: 'trend-rising-icon',
        style: {
          position: 'absolute',
          left: 35,
          bottom: 13,
        },
      };
    }
  }
}
