import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Pressable } from 'react-native';
import Config from 'react-native-config';
import { useDispatch, useSelector } from 'react-redux';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import ROUTES from '../../navigation/routes';
import { getLoginErrorMessageKey } from '../../loginErrorCodes';
import { navigationShape } from '../../shapes/navigation';

// components
import ErrorMessageBanner from '../../components/ErrorMessageBanner';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthHeader from '../../components/auth/AuthHeader';

// hooks
import { useBackExitApp } from '../../hooks/useBackHandler';
import { useTranslation } from '../../hooks/useTranslation';

// screen components
import LoginForm from './LoginForm';

const LoginScreen = ({ navigation }) => {
  const authentication = useSelector((state) => state.authentication);
  const loading = useSelector((state) => !!state.loading.effects.authentication.login);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(undefined);

  useBackExitApp();

  useEffect(() => {
    async function doPostLoginActions() {
      await dispatch.authentication.postLogin();
    }

    if (authentication.token) {
      doPostLoginActions();
      navigation.navigate(ROUTES.LOADING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, authentication.token]);

  useEffect(() => {
    if (authentication.error) {
      const errorMessageKey = getLoginErrorMessageKey(authentication.error);
      setErrorMessage(t(errorMessageKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication.error]);

  /* istanbul ignore next */
  const onLogoClick = () => dispatch.authentication.login({ username: Config.USERNAME, password: Config.PASSWORD });
  const onSubmit = dispatch.authentication.login;
  const onForgotUsername = () => navigation.navigate(ROUTES.FORGOT_USERNAME);
  const onForgotPassword = () => navigation.navigate(ROUTES.FORGOT_PASSWORD);

  return (
    <AuthContainer>
      <View style={styles.headerContainer}>
        <View style={styles.headerSection}>
          <AuthHeader onLogoClick={onLogoClick} headerText={t('login_view_sign_into')} />
        </View>
        {errorMessage && (
          <ErrorMessageBanner errorMessage={errorMessage} bannerStyle={styles.bannerStyle} testId={'login__error-banner'} />
        )}
      </View>
      <View style={styles.interactionsContainer}>
        <View style={styles.formContainer}>
          <LoginForm onSubmit={onSubmit} loading={loading} />
        </View>

        <View style={styles.forgotSection}>
          <Pressable onPress={onForgotUsername} testID="login__forgot-username">
            <Text style={styles.forgotButtons}>{t('login_view_forgot_username_button')}</Text>
          </Pressable>

          <Pressable onPress={onForgotPassword} testID="login__forgot-password">
            <Text style={styles.forgotButtons}>{t('login_view_forgot_password_button')}</Text>
          </Pressable>
        </View>
      </View>
    </AuthContainer>
  );
};

const styles = StyleSheet.create({
  interactionsContainer: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    minHeight: 250,
  },
  headerContainer: {
    flex: 1,
    minHeight: 240,
    marginTop: 75,
  },
  headerSection: {
    flex: 1,
    alignItems: 'center',
  },
  forgotButtons: {
    opacity: 0.8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSansItalic,
    fontSize: 14,
    color: COLORS.white,
  },
  forgotSection: {
    marginVertical: 10,
  },
  bannerStyle: {
    alignSelf: 'center',
    marginBottom: 15,
  },
});

LoginScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default LoginScreen;
