import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// components
import SortableHeaderItem from '../../components/header/SortableHeaderItem';
import Divider from '../../components/Divider';

// services
import { useTranslation } from '../../hooks/useTranslation';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import { SORT_COLUMNS } from './sorting';

const SortableTemperatureHeader = ({ sortedColumn, toggle, loading }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.bar}>
        <SortableHeaderItem
          title={t('temperature_sort_button_name')}
          onPress={toggle}
          sortOrder={sortedColumn.block}
          sortKey={SORT_COLUMNS.BLOCK}
          testID="list__temperature-header-item-block"
          style={styles.blockHeader}
          loading={loading}
        />
        <SortableHeaderItem
          title={t('temperature_sort_button_temperature')}
          onPress={toggle}
          sortOrder={sortedColumn.temperature}
          sortKey={SORT_COLUMNS.TEMPERATURE}
          testID="list__temperature-header-item-temperature"
          style={styles.temperatureHeader}
          loading={loading}
        />
        <SortableHeaderItem
          title={t('temperature_sort_button_last_update')}
          onPress={toggle}
          sortOrder={sortedColumn.lastUpdated}
          sortKey={SORT_COLUMNS.LAST_UPDATED}
          testID="list__temperature-header-item-lastUpdated"
          style={styles.lastUpdatedHeader}
          loading={loading}
        />
      </View>
      <Divider style={styles.divider} />
    </View>
  );
};

SortableTemperatureHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortedColumn: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    zIndex: 2,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
  },
  divider: {
    borderBottomColor: COLORS.lightGrey,
    borderBottomWidth: 1,
  },
  blockHeader: {
    width: 55,
    marginRight: 55,
    marginLeft: 20,
  },
  temperatureHeader: {
    flex: 1,
    alignItems: 'flex-end',
  },
  lastUpdatedHeader: {
    alignItems: 'flex-end',
    width: 80,
    marginRight: 25,
    marginLeft: 15,
  },
});

export default SortableTemperatureHeader;
