import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { USER } from './user.graphql';
import { PERMISSIONS } from './userPermissions.graphql';

const fetchUser = async () => {
  const { loading, error, data } = await getApolloClient().query({ query: USER });

  let user;
  if (data) {
    user = data.viewer.user;
  }

  return {
    loading,
    error,
    user,
  };
};

const fetchPermissions = async (siteId) => {
  let permissions;

  const { loading, error, data } = await getApolloClient().query({
    query: PERMISSIONS,
    variables: { siteId },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  if (data) {
    permissions = data.viewer.user.permissions;
  }

  return {
    loading,
    error,
    permissions,
  };
};

export { fetchUser, fetchPermissions };
