import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

// constants
import FONTS from '../../fonts';

// styles
import { MINIMUM_FONT_SCALE } from '../../styles.js';

const ComponentAlias = ({ alias, backgroundColor, color }) => {
  return (
    <View style={[styles.aliasStyle, { borderColor: color, backgroundColor }]}>
      <Text
        ellipsizeMode={'tail'}
        numberOfLines={1}
        adjustsFontSizeToFit
        maxFontSizeMultiplier={1}
        minimumFontScale={MINIMUM_FONT_SCALE}
        style={{
          color,
          ...styles.aliasDefaultFont,
        }}>
        {alias}
      </Text>
    </View>
  );
};

ComponentAlias.propTypes = {
  alias: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  aliasDefaultFont: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 13,
    letterSpacing: 0.66,
  },
  aliasStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 57,
    height: 43,
    padding: 2,
    borderWidth: 2,
    borderRadius: 2,
  },
});

export default React.memo(ComponentAlias);
