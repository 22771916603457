import React from 'react';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';

// icons
import icnCollapse from '../../assets/icons/icnCollapse.png';
import icnExpand from '../../assets/icons/icnExpand.png';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import OPACITIES from '../../opacities';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import ANALYTICS from '../../services/AnalyticsEvents';

// components
import { ScheduleConstants } from '../../screens/schedule/ScheduleConstants';

const ExpandButton = ({ expanded, blockPanelWidth, onClick, testId }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const onClickExpand = () => {
    if (!expanded) {
      analyticsService.trackEvent(ANALYTICS.eventExpandScheduleBlockPanel);
    }
    onClick(!expanded);
  };

  return (
    <Pressable style={[styles.expandButton, { width: blockPanelWidth }]} testID={testId} onPress={onClickExpand}>
      {expanded ? (
        <>
          <View style={styles.textBlockOpened}>
            <Text style={styles.blocks}>{t('schedule_button_blocks')}</Text>
          </View>
          <View style={styles.iconContainer}>
            <Image source={icnCollapse} style={styles.iconStyle} />
          </View>
        </>
      ) : (
        <Image source={icnExpand} style={styles.iconStyle} />
      )}
    </Pressable>
  );
};

ExpandButton.propTypes = {
  blockPanelWidth: PropTypes.number,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  expandButton: {
    zIndex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: ScheduleConstants.TOP_LABEL_HEIGHT + 1,
    borderColor: ScheduleConstants.BORDER_COLOR,
    backgroundColor: COLORS.lightGrey,
    borderBottomWidth: 1,
    elevation: 6,
    shadowColor: COLORS.greyishBrown,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
  },
  iconStyle: {
    tintColor: COLORS.greyishBrown,
  },
  blocks: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  textBlockOpened: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 15,
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },
});

export default ExpandButton;
