import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';

import { authentication } from './authentication';
import { automations } from './automations';
import { blocks } from './blocks';
import { blueBandIndex } from './blueBandIndex';
import { console } from './console';
import { dashboard } from './dashboard';
import { devices } from './devices';
import { error } from './error';
import { graph } from './graph';
import { map } from './map';
import { preferences } from './preferences';
import { report } from './report';
import { schedule } from './schedule';
import { site } from './site';
import { user } from './user';
import { notes } from './notes';

const loadingPlugin = createLoadingPlugin({});

export const initStore = () => {
  return init({
    plugins: [loadingPlugin, selectPlugin()],
    redux: {
      rootReducers: {
        CLEAR_STORE: /* istanbul ignore next */ () => undefined,
      },
    },
    models: {
      authentication,
      automations,
      blocks,
      blueBandIndex,
      console,
      dashboard,
      devices,
      error,
      graph,
      map,
      preferences,
      report,
      schedule,
      site,
      user,
      notes,
    },
  });
};
