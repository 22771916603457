import moment from 'moment-timezone';
import { compareBlock, SORT_ORDER } from '../../utils/sorting';

const SORT_COLUMNS = {
  BLOCK: 'block',
  TEMPERATURE: 'temperature',
  LAST_UPDATED: 'lastUpdated',
};

const sortBlocksOrSensors = (blocks, compareBlockFunction, compareSensorFunction) => {
  if (blocks.length === 1) {
    const sensors = blocks[0].temperatureCapability().temperatures();
    sensors.sort(compareSensorFunction);
    return;
  }
  blocks.sort(compareBlockFunction);
};

const sortTemperatureBlocks = (temperatureBlocks, column, order) => {
  const compareByBlock = (a, b) => compareBlock(a, b, order);

  const compareBySensorLastUpdatedTime = (sensorA, sensorB) => {
    const time1 = sensorA.lastUpdated ? moment(sensorA.lastUpdated) : moment('2100-01-01');
    const time2 = sensorB.lastUpdated ? moment(sensorB.lastUpdated) : moment('2100-01-01');

    if (time1.isAfter(time2)) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else if (time1.isBefore(time2)) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    }
    return 0;
  };

  const compareByLastUpdated = (a, b) => {
    const sensorsA = a.temperatureCapability().temperatures();
    const sensorsB = b.temperatureCapability().temperatures();

    sensorsA.sort(compareBySensorLastUpdatedTime);
    sensorsB.sort(compareBySensorLastUpdatedTime);

    const value1 = sensorsA.length > 0 && sensorsA[0].lastUpdated ? moment(sensorsA[0].lastUpdated) : moment('2100-01-01');
    const value2 = sensorsB.length > 0 && sensorsA[0].lastUpdated ? moment(sensorsB[0].lastUpdated) : moment('2100-01-01');

    if (value1.isAfter(value2)) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else if (value1.isBefore(value2)) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    }
    return order === SORT_ORDER.ASC ? sensorsA.length - sensorsB.length : sensorsB.length - sensorsA.length;
  };

  const compareBySensorTemperature = (sensorA, sensorB) => {
    const temperatureA = sensorA.value ? sensorA.value : Number.MIN_VALUE;
    const temperatureB = sensorB.value ? sensorB.value : Number.MIN_VALUE;

    if (temperatureA > temperatureB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (temperatureA < temperatureB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return 0;
  };

  const compareByTemperature = (a, b) => {
    const sensorsA = a.temperatureCapability().temperatures();
    const sensorsB = b.temperatureCapability().temperatures();

    sensorsA.sort(compareBySensorTemperature);
    sensorsB.sort(compareBySensorTemperature);

    const firstTemperatureA = sensorsA.length > 0 ? sensorsA[0].value : Number.MIN_VALUE;
    const firstTemperatureB = sensorsB.length > 0 ? sensorsB[0].value : Number.MIN_VALUE;

    if (firstTemperatureA > firstTemperatureB) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (firstTemperatureA < firstTemperatureB) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return order === SORT_ORDER.ASC ? sensorsA.length - sensorsB.length : sensorsB.length - sensorsA.length;
  };

  switch (column) {
    case SORT_COLUMNS.BLOCK: {
      temperatureBlocks.sort(compareByBlock);
      break;
    }
    case SORT_COLUMNS.TEMPERATURE: {
      sortBlocksOrSensors(temperatureBlocks, compareByTemperature, compareBySensorTemperature);
      break;
    }
    case SORT_COLUMNS.LAST_UPDATED: {
      sortBlocksOrSensors(temperatureBlocks, compareByLastUpdated, compareBySensorLastUpdatedTime);
      break;
    }
  }
};

export { sortTemperatureBlocks, SORT_COLUMNS };
