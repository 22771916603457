import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';

//constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// shape
import { styleShape } from '../../shapes/style';

const BlockName = ({ blockName, style = {}, testId }) => {
  return (
    <Text style={[styles.text, style]} ellipsizeMode="tail" numberOfLines={1} testID={testId}>
      {blockName}
    </Text>
  );
};

BlockName.propTypes = {
  blockName: PropTypes.string.isRequired,
  style: styleShape,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  text: {
    marginLeft: 10,
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
});

export default React.memo(BlockName);
