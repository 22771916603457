import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

// components
import Divider from '../../components/Divider';
import SortableHeaderItem from '../../components/header/SortableHeaderItem';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import { SORT_COLUMNS } from './sorting';

const SortableFlowStationHeader = ({ sortedColumn, toggle, loading }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.bar}>
        <SortableHeaderItem
          title={t('flow_station_sort_button_name')}
          onPress={toggle}
          sortOrder={sortedColumn.block}
          sortKey={SORT_COLUMNS.BLOCK}
          testID="list__flow-station-header-item-block"
          loading={loading}
          style={styles.blockHeader}
        />
        <SortableHeaderItem
          title={t('flow_station_sort_button_flow')}
          onPress={toggle}
          sortOrder={sortedColumn.flow}
          sortKey={SORT_COLUMNS.FLOW}
          testID="list__flow-station-header-item-flow"
          style={styles.flowHeader}
          loading={loading}
        />
        <SortableHeaderItem
          title={t('flow_station_sort_button_volume')}
          onPress={toggle}
          sortOrder={sortedColumn.volume}
          sortKey={SORT_COLUMNS.VOLUME}
          testID="list__flow-station-header-item-volume"
          style={styles.volumeHeader}
          loading={loading}
        />
      </View>
      <Divider style={styles.divider} />
    </View>
  );
};

SortableFlowStationHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortedColumn: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  blockHeader: {
    width: 150,
  },
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 25,
  },
  container: {
    zIndex: 2,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.lightGrey,
  },
  flowHeader: {
    flex: 1,
  },
  volumeHeader: {
    alignItems: 'flex-end',
    width: 97,
    marginRight: 25,
  },
});

export default SortableFlowStationHeader;
