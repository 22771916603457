import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

// constants
import COLORS from '../../colors';
import icnBluetooth from '../../assets/icons/console/icnBluetoothOn.png';

// components
import ConsolePulse from './ConsolePulse';

const ConsoleDiscoveryView = () => {
  return (
    <View style={styles.view} testID="console-discovery__view">
      <ConsolePulse delay={0} />
      <ConsolePulse delay={1000} />
      <ConsolePulse delay={2000} />
      <ConsolePulse delay={3000} />
      <View style={styles.innerCircle}>
        <Image source={icnBluetooth} style={styles.iconSizeStyle} testID="console-discovery__icon" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  iconSizeStyle: {
    width: 48,
    height: 50,
  },
  innerCircle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    borderRadius: 80,
    backgroundColor: COLORS.white,
  },
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default React.memo(ConsoleDiscoveryView);
