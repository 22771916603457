import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import { SvgXml } from 'react-native-svg';

// Components
import CustomButton from '../button/CustomButton';

// Styles and fonts
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import FONTS from '../../fonts';

const ConsoleActionButton = ({ onPress, icon, text, testId }) => {
  return (
    <CustomButton
      testId={testId}
      onPress={onPress}
      buttonStyle={styles.buttonStyle}
      pressedStyle={[styles.buttonPressed]}
      underlayColor={COLORS.whiteTwo}>
      <View style={styles.buttonContent}>
        <SvgXml xml={icon} fill={COLORS.blueGrey} width={45.5} height={30} />
        <Text style={styles.buttonText}>{text}</Text>
      </View>
    </CustomButton>
  );
};

ConsoleActionButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: '30%',
    height: 64,
    borderRadius: 4,
    backgroundColor: COLORS.greyish22,
    elevation: 10,
    shadowColor: COLORS.greyShadow,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 2,
  },
  buttonPressed: {
    borderColor: COLORS.greyish22,
    borderWidth: 2,
    backgroundColor: COLORS.white,
    shadowColor: COLORS.white,
  },
  buttonContent: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: 2,
    elevation: 5,
  },
  buttonText: {
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    fontWeight: 'bold',
    color: COLORS.greyishBrown,
  },
});

export default ConsoleActionButton;
