import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import COLORS from '../colors';
import FONTS from '../fonts';

const MAX_BOX_WIDTH = 150;

const SummaryBox = ({ title, image, topText, podContent }) => {
  return (
    <View style={styles.container}>
      {topText && <Text style={styles.topText}>{topText}</Text>}
      <Image source={image} />
      <Text style={styles.title}>{title}</Text>
      <View style={styles.pod}>{podContent}</View>
    </View>
  );
};

SummaryBox.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  podContent: PropTypes.any.isRequired,
  topText: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: MAX_BOX_WIDTH,
    minHeight: 135,
    borderRadius: 4,
    backgroundColor: COLORS.iceBlue,
  },
  pod: {
    top: 18.5,
    justifyContent: 'center',
    width: 90,
    height: 37,
    borderRadius: 18.5,
    marginHorizontal: 21,
    backgroundColor: COLORS.brownGreyOpaque,
  },
  title: {
    maxWidth: MAX_BOX_WIDTH - 10,
    marginTop: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  topText: {
    marginTop: 8,
    marginBottom: 12,
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    letterSpacing: 0.59,
    color: COLORS.greyishBrown,
  },
});

export default SummaryBox;
