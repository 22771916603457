export const getAutomationErrorTranslationKey = (errorCode) => {
  switch (errorCode) {
    case 2011:
      return 'automation_error_missing_mandatory';
    case 3012:
      return 'automation_error_resource_not_exist';
    case 4000:
      return 'automation_error_malformed_request';
    case 4001:
      return 'automation_error_invalid_state';
    default:
      return 'generic_error_server_error_message';
  }
};
