import { getApolloClient } from '../clients/apollo';
import { NOTE } from './note.graphql';

const fetchNotes = async (siteId, startDate, endDate) => {
  const { loading, error, data } = await getApolloClient().query({
    query: NOTE,
    variables: { siteId, startDate, endDate },
  });

  let notes;
  if (data) {
    notes = data.viewer.site;
  }

  return {
    loading,
    error,
    notes,
  };
};

export { fetchNotes };
