import * as TensionStatus from '../../models/status/tensionStatus';
import * as FlowStationStatus from '../../models/status/flowStationStatus';
import * as WeatherStationStatus from '../../models/status/weatherStationStatus';
import * as TemperatureStatus from '../../models/status/temperatureStatus';
import COLORS from '../../colors';
import { BACKGROUND_COLOR_ALPHA_VALUE } from '../../styles';
import { BlockPresenter } from '../BlockPresenter';
import * as CAPABILITIES from '../../models/capability';

export default class BlockAliasPresenter extends BlockPresenter {
  constructor(block, capability) {
    super(block, capability);
  }

  getOverlayIcon() {}

  getBlockHeaderColor() {
    if (!this.block) {
      return;
    }

    switch (this.capability) {
      case CAPABILITIES.TENSION:
        return TensionStatus.colorForStatus(this.block.tensionCapability().status());
      case CAPABILITIES.FLOW_STATION:
        return FlowStationStatus.colorForStatus(this.block.flowStationCapability().status());
      case CAPABILITIES.WEATHER_STATION:
        return WeatherStationStatus.colorForStatus(this.block.weatherCapability().status());
      case CAPABILITIES.TEMPERATURE:
        return TemperatureStatus.colorForStatus(this.block.temperatureCapability().status());
    }

    if (this.block.isTensionCapable()) {
      return TensionStatus.colorForStatus(this.block.tensionCapability().status());
    } else if (this.block.isFlowStationCapable()) {
      return FlowStationStatus.colorForStatus(this.block.flowStationCapability().status());
    } else if (this.block.isWeatherStationCapable()) {
      return WeatherStationStatus.colorForStatus(this.block.weatherCapability().status());
    } else if (this.block.isTemperatureCapable()) {
      return TemperatureStatus.colorForStatus(this.block.temperatureCapability().status());
    }
    return COLORS.darkBlue;
  }

  getBackgroundColor() {
    const statusColor = this.getBlockHeaderColor();
    return statusColor + BACKGROUND_COLOR_ALPHA_VALUE;
  }

  alias() {
    return this.block.alias;
  }

  lastUpdated() {
    return this.block.lastUpdated;
  }
}
