import React from 'react';
import { Pressable, StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { globalStyles } from '../../styles';
import { ScheduleConstants } from '../../screens/schedule/ScheduleConstants';

// helpers
import WeatherIcon from '../weatherForecasts/WeatherIcon';

const Day = ({ day, onPress, weatherCode }) => {
  const isPast = moment().isAfter(day, 'day');
  const isCurrent = moment().isSame(day, 'day');

  const renderDay = () => (
    <>
      <Text style={[styles.dayOfWeek, isPast && styles.pastText, isCurrent && styles.currentItemText]}>
        {day.format('dd').slice(0, 1)}
      </Text>
      <Text style={[styles.dayOfMonth, isPast && styles.pastText, isCurrent && styles.currentItemText]}>{day.date()}</Text>
    </>
  );

  return (
    <View style={[styles.dayCell, isCurrent ? { backgroundColor: COLORS.lightGreyBlue12 } : {}]}>
      {weatherCode ? (
        <Pressable
          style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.button]}
          onPress={onPress}>
          {renderDay()}
          <WeatherIcon weatherCode={weatherCode} />
        </Pressable>
      ) : (
        renderDay()
      )}
    </View>
  );
};

Day.propTypes = {
  day: PropTypes.instanceOf(moment).isRequired,
  onPress: PropTypes.func.isRequired,
  weatherCode: PropTypes.string,
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  currentItemText: {
    color: COLORS.bluishGrey,
  },
  dayCell: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: ScheduleConstants.CELL_WIDTH,
    height: ScheduleConstants.TOP_LABEL_HEIGHT + 2,
    borderColor: ScheduleConstants.BORDER_COLOR,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  dayOfWeek: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  dayOfMonth: {
    marginTop: -2,
    marginBottom: 5,
    fontFamily: FONTS.firaSansLight,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
  pastText: {
    opacity: 0.5,
  },
});

export default React.memo(Day);
