import { gql } from 'graphql-tag';

export const MAP_GRAPH = gql`
  query datastreamsData(
    $siteId: ID!
    $blockId: ID!
    $dataStreamIds: [ID]
    $startDate: String!
    $endDate: String!
    $maxNumberOfPoints: Int!
  ) {
    viewer {
      site(id: $siteId) {
        id
        block(id: $blockId) {
          events(startDate: $startDate, endDate: $endDate) {
            id
            startDate
            endDate
            subSensorId
          }
        }
        dataStreams(ids: $dataStreamIds) {
          id
          minimumValue
          maximumValue
          unit
          dataStreamType
          specifiedRange {
            maximum
          }
          dataStreamData(startDate: $startDate, endDate: $endDate, maxNumberOfPoints: $maxNumberOfPoints) {
            x
            y
          }
        }
      }
    }
  }
`;
