import React from 'react';
import PropTypes, { string } from 'prop-types';
import { View } from 'react-native';

//proptype
import { navigationShape } from '../../shapes/navigation';

//constants
import COLORS from '../../colors';

// styles
import { globalStyles } from '../../styles';

// components
import BackButton from '../button/BackButton';
import Title from './Title';
import BurgerMenuButton from './BurgerMenuButton';

const HeaderBack = ({ screenName, navigation, goBack, title, subtitle, titleColor, actions }) => {
  return (
    <View style={globalStyles.headerContainer}>
      <View style={globalStyles.navSpacerContainer}>
        <BackButton color={COLORS.lightPeach} onPress={goBack} />

        <BurgerMenuButton androidOnly navigation={navigation} screenName={screenName} />
      </View>

      <View style={globalStyles.headerTitle}>
        <Title value={title} color={titleColor} type="title" />

        {subtitle ? <Title value={subtitle} type="subtitle" /> : null}
      </View>

      <View style={globalStyles.actionsSpacerContainer}>{actions}</View>
    </View>
  );
};

HeaderBack.propTypes = {
  screenName: string.isRequired,
  navigation: navigationShape.isRequired,
  goBack: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleColor: PropTypes.string,
  actions: PropTypes.node,
};

export default HeaderBack;
