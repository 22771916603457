import React from 'react';
import { Pressable, StyleSheet, Image } from 'react-native';
import PropTypes from 'prop-types';

// constant
import COLORS from '../../colors';

// shape
import { styleShape } from '../../shapes/style';

const RoundButton = ({ icon, onPress, onPressIn, onPressOut, iconStyle, isSmall = false }) => {
  return (
    <Pressable
      style={
        /* istanbul ignore next */ ({ pressed }) => [
          pressed ? styles.touchOpacity : {},
          styles.layerCircle,
          isSmall ? styles.small : {},
        ]
      }
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}>
      <Image source={icon} style={[styles.layerImage, iconStyle && iconStyle]} />
    </Pressable>
  );
};

RoundButton.propTypes = {
  icon: PropTypes.any.isRequired,
  iconStyle: styleShape,
  isSmall: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  onPressIn: PropTypes.func,
  onPressOut: PropTypes.func,
};

const styles = StyleSheet.create({
  layerCircle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 55,
    height: 55,
    padding: 10,
    borderRadius: 55,
    margin: 10,
    backgroundColor: COLORS.white,
  },
  layerImage: {
    padding: 10,
  },
  small: {
    width: 44,
    height: 44,
    padding: 5,
    margin: 5,
  },
  touchOpacity: {
    opacity: 0.8,
  },
});

export default RoundButton;
