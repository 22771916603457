import { gql } from 'graphql-tag';

export const DEVICE = gql`
  query device($siteId: ID!, $deviceId: ID!, $dataStreamTypes: [dataStreamType]) {
    viewer {
      site(id: $siteId) {
        id
        device(id: $deviceId) {
          id
          status
          serialNumber
          lastUpdated
          block {
            id
            alias
            name
          }
          dataStreams(dataStreamTypes: $dataStreamTypes) {
            id
            unit
            value
            dataStreamType
            lastUpdated
            isVirtual
          }
        }
      }
    }
  }
`;
