import { getApolloClient } from '../clients/apollo';
import { SITES } from './sites.graphql.js';

const fetchSites = async () => {
  const { loading, error, data } = await getApolloClient().query({ query: SITES });

  let sites;
  if (data) {
    sites = data.viewer.sites;
  }

  return {
    loading,
    error,
    sites,
  };
};

export { fetchSites };
