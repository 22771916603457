import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

//model
import NoteEntity from '../../models/entities/noteEntity';

// components
import NoteHeader from './NoteHeader';
import NoteItem from './NoteItem';

const renderNotes = (block, onNotePress) => {
  if (!block.notes || !block.notes.length) {
    return null;
  }

  return (
    <>
      {block.notes.map((note) => (
        <NoteItem note={new NoteEntity(note, block.id)} onNotePress={onNotePress} key={note.id} testId={`block-note${note.id}`} />
      ))}
    </>
  );
};

const BlocksNotes = ({ blocks, onNotePress, testId }) => {
  if (!blocks || !blocks.length) {
    return null;
  }
  return (
    <>
      <View testID={testId}>
        {blocks.map((block) => (
          <View style={styles.container} key={block.id}>
            <NoteHeader title={block.alias} testId={'block-alias-' + block.id} />
            {renderNotes(block, onNotePress)}
          </View>
        ))}
      </View>
    </>
  );
};

BlocksNotes.propTypes = {
  blocks: PropTypes.array.isRequired,
  onNotePress: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginTop: 10,
    marginBottom: 2,
  },
});

export default BlocksNotes;
