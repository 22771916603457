// client
import { getApolloClient } from '../clients/apollo';
//graphQL
import { REGISTER_APP_INSTALLATION } from './registerAppInstallation.graphql';

async function registerAppInstallation(iid, token, type) {
  const query = {
    mutation: REGISTER_APP_INSTALLATION,
    variables: {
      iid,
      token,
      type,
    },
  };
  return execute(query);
}

async function execute(mutation) {
  try {
    const response = await getApolloClient().mutate(mutation);
    return { event: response };
  } catch (error) {
    // TODO: Remove this once there is a fix available for catching errors
    // https://github.com/apollographql/apollo-client/issues/5708
    if (error != null && typeof error.networkError.result && error.networkError.result != null) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}
export { registerAppInstallation };
