import { useReducer } from 'react';
import moment from 'moment-timezone';

// models
import { EVENT_TYPES, isAutomatedIrrigation, isManualIrrigation } from '../../models/eventType';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// helpers
import { filterBlocksWithAutomation, filterNonVirtualBlocks } from './eventFilter';
import { EVENT_ACTIONS } from './eventActions';

const useManageEvents = ({
  isEditMode,
  block,
  event,
  activeBlocks,
  activeBlocksWithAutomations,
  canModifyAutomationEvents,
  automationEventConstraints,
  manualEventConstraints,
}) => {
  const { t } = useTranslation();

  const defaultEventTypeInCreateMode = canModifyAutomationEvents ? EVENT_TYPES.automatedIrrigation : EVENT_TYPES.manualIrrigation;

  const initialState = {
    screenTitle: isEditMode ? t('edit_event_modify_title') : t('edit_event_add_title'),
    eventTypeSelected: event?.isManualIrrigation() ? EVENT_TYPES.manualIrrigation : defaultEventTypeInCreateMode,
    eventBlocks: isEditMode ? [block] : [],
    eventDate: event ? event.startDate : moment().add(1, 'day').startOf('day'),
    isAnytimeEvent: isEditMode ? event?.anytimeDayEvent : !canModifyAutomationEvents,
    isAnytimeEventVisible: isEditMode ? event.isManualIrrigation() : !canModifyAutomationEvents,
    eventDuration: event ? moment.duration(event.duration) : moment.duration({ hours: 12, minutes: 0 }),
    eventConstraints: canModifyAutomationEvents ? automationEventConstraints : manualEventConstraints,
    availableBlocks: isEditMode ? [] : canModifyAutomationEvents ? activeBlocksWithAutomations : activeBlocks,
  };

  const initializeState = () => {
    return {
      ...initialState,
    };
  };

  const manageEventsReducer = (state, action) => {
    switch (action.type) {
      case EVENT_ACTIONS.SET_EVENT_TYPE: {
        return {
          ...state,
          eventTypeSelected: action.payload,
          eventBlocks: isAutomatedIrrigation(action.payload)
            ? filterBlocksWithAutomation(state.eventBlocks)
            : filterNonVirtualBlocks(state.eventBlocks),
          isAnytimeEvent: false,
          isAnytimeEventVisible: isManualIrrigation(action.payload),
          eventConstraints: isManualIrrigation(action.payload) ? manualEventConstraints : automationEventConstraints,
          availableBlocks: isManualIrrigation(action.payload) ? activeBlocks : activeBlocksWithAutomations,
        };
      }
      case EVENT_ACTIONS.SET_EVENT_BLOCKS: {
        return {
          ...state,
          eventBlocks: action.payload,
        };
      }
      case EVENT_ACTIONS.SET_EVENT_DATE: {
        return {
          ...state,
          eventDate: action.payload,
        };
      }
      case EVENT_ACTIONS.SET_ANYTIME_EVENT: {
        return {
          ...state,
          isAnytimeEvent: action.payload,
        };
      }
      case EVENT_ACTIONS.SET_EVENT_DURATION: {
        return {
          ...state,
          eventDuration: action.payload,
        };
      }
      case EVENT_ACTIONS.RESET: {
        return initializeState();
      }
      default: {
        return state;
      }
    }
  };

  return useReducer(manageEventsReducer, initialState, initializeState);
};

export default useManageEvents;
