import React, { useRef, useState } from 'react';
import { StyleSheet, Text, TextInput } from 'react-native';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// services
import { isIos } from '../../../services/PlatformService';

// components
import Divider from '../../Divider';
import GenericSection from '../../layouts/GenericSection';
import ToggleSwitch from '../../button/ToggleSwitch';
import RowSection from '../../layouts/RowSection';
import COLORS from '../../../colors';
import FONTS from '../../../fonts';
import SelectDuration from '../../event/addEvent/time/SelectDuration';

const AutomationRuleEditRestart = ({
  durationConstraints,
  enabled,
  onEnabledChanged,
  onRunForValueChanged,
  onValueChanged,
  runForValue,
  value,
  valueUnit,
}) => {
  const { t } = useTranslation();
  const [isDurationPickerVisible, setDurationPickerVisible] = useState(false);
  const refRestartBelowInput = useRef();
  const stringifiedValue = isNaN(value) ? '' : value.toString();

  const setRestartEnabled = () => {
    onEnabledChanged(!enabled);
  };

  /* istanbul ignore next */
  const setFocusToRestartBelowInput = () => {
    if (refRestartBelowInput.current) {
      refRestartBelowInput.current.focus();
    }
  };

  const setDurationPickerVisibility = () => {
    setDurationPickerVisible(!isDurationPickerVisible);
  };

  const keyboardType = isIos() ? 'numbers-and-punctuation' : 'numeric';

  return (
    <GenericSection title={t('automation_rule_restart')}>
      <RowSection onPress={setRestartEnabled} title={t('automation_rule_restart_enabled')}>
        <ToggleSwitch onValueChange={onEnabledChanged} value={enabled} testID={'automation-rule-edit-restart__toggle-enable'} />
      </RowSection>

      {enabled ? (
        <>
          <Divider style={styles.divider} />

          <RowSection onPress={setFocusToRestartBelowInput} title={t('automation_rule_restart_below')}>
            <TextInput
              ref={refRestartBelowInput}
              style={[styles.text, styles.textInput]}
              keyboardType={keyboardType}
              textAlign={'right'}
              multiline={false}
              defaultValue={stringifiedValue}
              onChangeText={onValueChanged}
              selectTextOnFocus
              maxLength={10}
              testID={'automation-rule-edit-restart__text-value'}
            />
            <Text style={styles.text}>{valueUnit}</Text>
          </RowSection>

          <Divider style={styles.divider} />

          <SelectDuration
            durationConstraints={durationConstraints}
            eventDuration={runForValue}
            isDurationPickerVisible={isDurationPickerVisible}
            label={t('automation_rule_restart_for')}
            setDurationPickerVisibility={setDurationPickerVisibility}
            setEventDuration={onRunForValueChanged}
          />

          <Divider style={styles.divider} />
        </>
      ) : null}
    </GenericSection>
  );
};

AutomationRuleEditRestart.propTypes = {
  durationConstraints: PropTypes.object,
  enabled: PropTypes.bool.isRequired,
  onEnabledChanged: PropTypes.func.isRequired,
  onRunForValueChanged: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  runForValue: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  valueUnit: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  divider: {
    marginLeft: 16,
  },
  text: {
    fontFamily: FONTS.firaSans,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  textInput: {
    width: 75,
    height: 30,
    padding: 0,
  },
});

export default AutomationRuleEditRestart;
