import { useEffect, useReducer } from 'react';

import GraphPresenter from '../presenters/GraphPresenter';

const actions = {
  LOAD_GRAPH: 'LOAD_GRAPH',
};

const useGraph = ({
  dataStreams,
  legendStreams,
  selectedDataStreams,
  block,
  defaultTimeScaleInHours,
  irrigationEvents,
  dataType,
  isLoading,
  resetEnabled,
  tensionForecast,
  futureIrrigationEvents,
}) => {
  const initialState = {
    graphPresenter: new GraphPresenter({
      dataStreams: [],
      legendStreams: [],
      selectedDataStreams: { left: { ids: [] }, right: { ids: [] } },
      assignedDataStreamsColors: null,
      block: null,
      defaultTimeScaleInHours,
      irrigationEvents: [],
      dataType: null,
      tensionForecast: [],
      futureIrrigationEvents: [],
    }),
  };

  const initializeState = () => {
    return {
      ...initialState,
    };
  };

  // istanbul ignore next
  const getAssignedDataStreamsColors = (previousAssignedColors) => {
    if (!previousAssignedColors) {
      return [];
    }

    const assignedColor = [];
    selectedDataStreams.left.ids.concat(selectedDataStreams.right.ids).forEach((id) => {
      const found = previousAssignedColors.find((color) => color.id === id);

      if (found) {
        assignedColor.push(found);
      }
    });

    return assignedColor;
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case actions.LOAD_GRAPH: {
        // istanbul ignore next
        if (isLoading) {
          return state;
        }

        const graphPresenter = new GraphPresenter({
          dataStreams,
          legendStreams,
          selectedDataStreams,
          assignedDataStreamsColors: getAssignedDataStreamsColors(state.graphPresenter.assignedDataStreamsColors),
          block,
          defaultTimeScaleInHours,
          irrigationEvents,
          dataType,
          resetEnabled,
          tensionForecast,
          futureIrrigationEvents,
        });

        return { ...state, graphPresenter };
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState, initializeState);

  // istanbul ignore next
  const onToggleDataStreams = () => {
    dispatch({ type: actions.LOAD_GRAPH });
  };

  useEffect(() => {
    if (isLoading === false) {
      dispatch({ type: actions.LOAD_GRAPH });
    }
  }, [isLoading]);

  return [state, onToggleDataStreams];
};

export default useGraph;
