import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

// shape
import { styleShape } from '../../shapes/style';

//entity
import BlockEntity from '../../models/entities/blockEntity';

//components
import LastUpdate from '../block/LastUpdate';
import BlockTensionStatus from '../block/BlockTensionStatus';

const BlockDetailStatus = ({ block, containerStyle }) => {
  return (
    <View style={[containerStyle, styles.container]} testID="block-details__status-container">
      <BlockTensionStatus block={block} containerStyle={styles.blockStatusContainer} />
      <LastUpdate date={block.lastUpdated} containerStyle={styles.lastUpdateContainer} />
    </View>
  );
};

BlockDetailStatus.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  containerStyle: styleShape,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blockStatusContainer: {
    marginVertical: 7,
    marginLeft: 8,
  },
  lastUpdateContainer: {
    marginVertical: 14,
    marginRight: 16,
  },
});

export default React.memo(BlockDetailStatus);
