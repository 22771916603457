import { getApolloClient } from '../clients/apollo';
import { DASHBOARD } from './dashboard.graphql';

async function fetchDashboard(site, startDate, endDate) {
  const { loading, error, data } = await getApolloClient().query({
    query: DASHBOARD,
    variables: {
      site,
      startDate,
      endDate,
    },
  });

  let dashboardData;
  if (data) {
    dashboardData = data.viewer.site;
  }

  return {
    loading,
    error,
    dashboardData,
  };
}

export { fetchDashboard };
