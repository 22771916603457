import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';
// icons
import icnNavLeft from '../../assets/icons/icnNavLeft.png';
import icnNavRight from '../../assets/icons/icnNavRight.png';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// styles
import { globalStyles } from '../../styles';

// components
import TouchIconButton from '../button/TouchIconButton';

const PeriodPicker = ({ disableLeftArrow, onLeftArrowClick, disableRightArrow, onRightArrowClick, screenName, date }) => {
  return (
    <View style={styles.main}>
      <View style={styles.container}>
        <TouchIconButton
          disabled={disableLeftArrow}
          testId={`${screenName}__left-arrow`}
          onPress={onLeftArrowClick}
          icon={icnNavLeft}
          iconStyle={globalStyles.icnNav}
        />

        <View>
          <Text testID={`${screenName}__title-date`} style={styles.dateText}>
            {date}
          </Text>
        </View>

        <TouchIconButton
          disabled={disableRightArrow}
          testId={`${screenName}__right-arrow`}
          onPress={onRightArrowClick}
          icon={icnNavRight}
          iconStyle={globalStyles.icnNav}
        />
      </View>
    </View>
  );
};

PeriodPicker.propTypes = {
  date: PropTypes.string.isRequired,
  disableLeftArrow: PropTypes.bool.isRequired,
  disableRightArrow: PropTypes.bool.isRequired,
  onLeftArrowClick: PropTypes.func.isRequired,
  onRightArrowClick: PropTypes.func.isRequired,
  screenName: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateText: {
    minWidth: 140,
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    letterSpacing: 0.83,
    color: COLORS.warmGrey,
  },
  main: {
    height: 40,
  },
});

export default PeriodPicker;
