import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import {
  DISABLED,
  OFF,
  ONGOING,
  SYNCHRONIZING,
  COMMUNICATION_ERROR,
  DEVICE_CONFIGURATION_ERROR,
  ERROR_READING_COMPONENT,
  ERROR_READING_FEEDBACK,
  ERROR_COMPONENT_WRONG_STATE,
  ERROR_FEEDBACK,
  ERROR_COMPONENT_NOT_READY,
  ERROR_READING_SENSOR,
  ERROR_COMMUNICATION_LOST,
  ERROR_CONTROL_RETRY,
  ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF,
  ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON,
  ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON,
  ERROR_MOTOR_CONTROLLER,
  IN_ERROR,
} from '../../models/status/automationStatus';

// icons
import icnDisabled from '../../assets/icons/icnDisabled.png';
import icnIrrigationOff from '../../assets/icons/icnIrrigationOff.png';

//entity
import AutomationEntity from '../../models/entities/automationEntity';

// services
import { useTranslation } from '../../hooks/useTranslation';

// components
import ComponentAlias from '../../components/automation/ComponentAlias';
import Irrigation from '../../components/block/Irrigation';
import ListBox from '../../components/list/ListBox';
import Synchronization from '../../components/animation/Synchronization';
import ProgressBarDuration from '../../components/progressBar/ProgressBarDuration';
import AutomationRuleItemMinified from '../../components/automation/rule/AutomationRuleItemMinified';

const AutomationItem = ({ entity, keyIndex, onSelect, showRules, animatedOpacity }) => {
  const { t } = useTranslation();

  const itemBackgroundColor = entity.itemBackgroundColor();
  const aliasColor = entity.statusColor();
  const aliasBackgroundColor = entity.aliasBackgroundColor();

  const selectComponent = () => {
    onSelect(entity);
  };
  const inError = (status) => {
    return (
      status === COMMUNICATION_ERROR ||
      status === DEVICE_CONFIGURATION_ERROR ||
      status === ERROR_READING_COMPONENT ||
      status === ERROR_READING_FEEDBACK ||
      status === ERROR_COMPONENT_WRONG_STATE ||
      status === ERROR_FEEDBACK ||
      status === ERROR_COMPONENT_NOT_READY ||
      status === ERROR_READING_SENSOR ||
      status === ERROR_COMMUNICATION_LOST ||
      status === ERROR_CONTROL_RETRY ||
      status === ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF ||
      status === ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON ||
      status === ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON ||
      status === ERROR_MOTOR_CONTROLLER ||
      status === IN_ERROR
    );
  };

  const renderStatusIcon = () => {
    const status = entity.statusType();
    switch (true) {
      case status === SYNCHRONIZING:
        return <Synchronization testID={'automation__status-icon-synchronizing'} />;
      case status === DISABLED:
        return <Image source={icnDisabled} testID={'automation__status-icon-disabled'} />;
      case status === OFF:
        return <Image source={icnIrrigationOff} testID={'automation__status-icon-off'} />;
      case status === ONGOING:
      case inError(status) && entity.ongoing:
        return (
          <Irrigation irrigating={entity.ongoing} testID={'automation__status-icon-ongoing'} animatedOpacity={animatedOpacity} />
        );
      default:
        return null;
    }
  };

  const renderRow = (i, rule, subSensors, withRules) => {
    return (
      <View key={i} style={styles.container}>
        <View style={styles.componentSection}>
          {i === 0 ? <ComponentAlias alias={entity.alias} color={aliasColor} backgroundColor={aliasBackgroundColor} /> : null}
        </View>

        <View style={styles.statusSection}>
          {i === 0 ? (
            <>
              <View style={styles.statusIcon}>{renderStatusIcon()}</View>

              <View style={styles.statusText}>
                {ongoingOK ? (
                  <ProgressBarDuration startDate={startDate} endDate={endDate} />
                ) : (
                  <Text style={styles.statusFont}>{entity.statusText(t)}</Text>
                )}
              </View>
            </>
          ) : null}
        </View>

        {i === 0 && subSensors?.length > 0 ? (
          <View style={styles.rpmContainer} testID={'automation__rpm-list'}>
            {subSensors.map((subSensor, index) => (
              <View key={index} style={styles.rpmSection}>
                <Text style={styles.rpmFont}>{subSensor.getRPMValues()}</Text>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.rpmContainer} />
        )}

        {rule ? (
          <View style={styles.rulesContainer}>
            <AutomationRuleItemMinified
              ruleType={rule.ruleType}
              thresholdDown={rule.thresholdDown}
              thresholdUp={rule.thresholdUp}
              unit={rule.unit}
            />
          </View>
        ) : withRules ? (
          <View style={styles.rulesContainer} testID={'automation__rule-empty'} />
        ) : null}
      </View>
    );
  };

  const startDate = entity.currentExecution?.startDate;
  const endDate = entity.currentExecution?.endDate;
  const ongoingOK = startDate && endDate && (entity.statusType() === ONGOING || entity.ongoing);
  const subSensors = entity.getRPMSubSensors();

  return (
    <ListBox
      testID={`automation__componentItem-${keyIndex}`}
      customStyle={{ ...styles.listBox, backgroundColor: itemBackgroundColor }}
      onPress={selectComponent}
      renderContent={() => (
        <View style={styles.row}>
          {entity.getEnabledRules()?.length > 0
            ? entity.getEnabledRules()?.map((rule, i) => renderRow(i, rule, subSensors, showRules))
            : renderRow(0, null, subSensors, showRules)}
        </View>
      )}
    />
  );
};

AutomationItem.propTypes = {
  entity: PropTypes.instanceOf(AutomationEntity).isRequired,
  keyIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  showRules: PropTypes.bool,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  componentSection: {
    width: '20%',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listBox: {
    paddingHorizontal: 11,
  },
  rpmContainer: {
    justifyContent: 'space-between',
    width: '10%',
    marginVertical: 3,
  },
  rpmSection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  rpmFont: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
  },
  rulesContainer: {
    justifyContent: 'space-between',
    width: '20%',
    marginVertical: 3,
  },
  row: {
    flex: 1,
  },
  statusFont: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
  },
  statusIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  statusSection: {
    flexDirection: 'row',
    width: '40%',
  },
  statusText: {
    justifyContent: 'center',
    width: '70%',
  },
});

export default AutomationItem;
