import { isNullOrUndefined } from '../../utils/util';

export default class SubSensorCurrentDataEntity {
  constructor(subSensorCurrentData) {
    this.subSensorID = subSensorCurrentData.subSensorId;
    this.subSensorType = subSensorCurrentData.subSensorType;
    this.value = isNullOrUndefined(subSensorCurrentData.value) ? null : subSensorCurrentData.value;
    this.unit = subSensorCurrentData.unit;
    this.isRPM = this.unit === 'RPM';
  }

  getRPMValues() {
    return this.isRPM ? (this.value < 0 ? 0 : this.value) : null;
  }
}
