import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector } from 'react-redux';
import { StyleSheet, View, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// styles
import { globalStyles } from '../../styles';

//hook
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// constants
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';
import ANALYTICS from '../../services/AnalyticsEvents';

// services
import { isIos } from '../../services/PlatformService';
import { navigationShape } from '../../shapes/navigation';

// components
import TodayWeather from '../../components/weatherForecasts/TodayWeather';
import UpcomingDaysWeather from '../../components/weatherForecasts/UpcomingDaysWeather';
import HeaderBack from '../../components/header/HeaderBack';
import Divider from '../../components/Divider';

const WeatherForecastScreen = ({ navigation, route }) => {
  const store = useStore();

  const currentSite = useSelector((state) => state.site.currentSite);
  const todayWeatherForecasts = useSelector(store.select.schedule.todayForecast);
  const upcomingWeatherForecasts = useSelector(store.select.schedule.upcomingForecasts);
  const weatherForecastsCity = useSelector((state) => state.schedule.city);
  const currentTemperature = todayWeatherForecasts?.temperature;
  const currentWeatherCode = todayWeatherForecasts?.weatherCode;
  const loading = useSelector((state) => !!state.loading.effects.schedule.loadSchedule);
  const analyticsService = useAnalyticsContext();
  const forecastIndex = route?.params?.forecastIndex;

  const goBack = useCallback(() => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewSchedule);
    navigation.navigate(ROUTES.SCHEDULE);

    return true;
  }, [analyticsService, navigation]);

  useBackHandler(goBack);

  return (
    <>
      <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
        <View style={styles.page}>
          <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

          <View style={globalStyles.header}>
            <HeaderBack
              screenName="weather-forecast"
              navigation={navigation}
              goBack={goBack}
              title={currentSite.name}
              testID="weather-forecast__screen-title"
            />
          </View>

          <Divider />

          <View style={globalStyles.bottomContainer}>
            <TodayWeather
              cityName={weatherForecastsCity}
              etoUnit={todayWeatherForecasts?.eto?.unit}
              etoValue={todayWeatherForecasts?.eto?.value}
              rainPrecipitation={todayWeatherForecasts?.rain?.precipitation}
              rainProbability={todayWeatherForecasts?.rain?.probability}
              rainUnit={todayWeatherForecasts?.rain?.unit}
              temperatureMax={todayWeatherForecasts?.temperature?.max}
              temperatureMin={todayWeatherForecasts?.temperature?.min}
              temperatureToday={currentTemperature?.current}
              temperatureUnit={currentTemperature?.unit}
              weatherCode={currentWeatherCode}
              windDirection={todayWeatherForecasts?.wind?.direction}
              windSpeed={todayWeatherForecasts?.wind?.speed}
              windUnit={todayWeatherForecasts?.wind?.unit}
              loading={loading}
            />

            <SafeAreaView style={styles.screenBottom} edges={['right', 'left', 'bottom']}>
              <View style={styles.upcomingDaysWeather}>
                <UpcomingDaysWeather forecasts={upcomingWeatherForecasts} loading={loading} scrollIndex={forecastIndex} />
              </View>
            </SafeAreaView>
          </View>
        </View>
      </SafeAreaView>
    </>
  );
};

WeatherForecastScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      forecastIndex: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  upcomingDaysWeather: {
    flex: 1,
    marginHorizontal: 10,
    marginTop: 5,
  },
  screenBottom: {
    flex: 1,
  },
});

export default WeatherForecastScreen;
