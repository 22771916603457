import { getUpdatedScheduleData } from '@hortau/knit';

// queries
import { fetchSchedule } from '../queries/schedule';

//services
import { executeApolloQuery } from './apolloHelper';

//models
import BlockEntity from './entities/blockEntity';
import IrrigationEvent from './entities/irrigationEvent';
import * as TensionStatus from './status/tensionStatus';

export const schedule = {
  state: {
    blocks: [],
    blockGroups: [],
    notes: [],
    selectedBlockId: null,
    selectedEvent: null,
    city: undefined,
    dailyForecasts: [],
    todayForecast: undefined,
  },

  selectors: (slice) => ({
    getActiveBlocks() {
      // eslint-disable-next-line no-shadow
      return slice((schedule) =>
        schedule.blocks
          ?.filter((block) => block.capabilities.tension?.status !== TensionStatus.OUT_OF_FIELD && block.isVirtual !== true)
          .map((block) => new BlockEntity(block)),
      );
    },

    getActiveBlocksWithAutomations() {
      // eslint-disable-next-line no-shadow
      return slice((schedule) =>
        schedule.blocks
          ?.filter((block) => block.capabilities.tension?.status !== TensionStatus.OUT_OF_FIELD || block.isVirtual === true)
          .map((block) => new BlockEntity(block))
          ?.filter((block) => block.hasAutomations()),
      );
    },

    getSelectedBlock() {
      // eslint-disable-next-line no-shadow
      return slice((schedule) => {
        // eslint-disable-next-line no-shadow
        const block = schedule.blocks.find((block) => block.id === schedule.selectedBlockId);
        return schedule.selectedBlockId ? new BlockEntity(block) : null;
      });
    },

    getSelectedEvent() {
      // eslint-disable-next-line no-shadow
      return slice((schedule) => (schedule.selectedEvent ? new IrrigationEvent(schedule.selectedEvent) : null));
    },

    getIsEditMode() {
      // eslint-disable-next-line no-shadow
      return slice((schedule) => !!schedule.selectedEvent);
    },

    todayForecast() {
      return slice((state) => {
        const todayForecast = state.todayForecast;

        if (!state.dailyForecasts?.length) {
          return todayForecast;
        }

        todayForecast.temperature = { ...state.dailyForecasts[0]?.temperature, ...state.todayForecast?.temperature };
        return { ...state.dailyForecasts[0], ...todayForecast };
      });
    },

    upcomingForecasts() {
      return slice((state) => {
        if (state.dailyForecasts.length === 0) {
          return [];
        }
        return state.dailyForecasts.slice(1);
      });
    },
  }),

  reducers: {
    updateSchedule(state, dataSchedule) {
      const updatedSchedule = getUpdatedScheduleData(dataSchedule);

      return {
        ...state,
        blocks: updatedSchedule.blocks,
        blockGroups: updatedSchedule.blockGroups,
        notes: dataSchedule.notes,
        dailyForecasts: dataSchedule.weatherForecast?.dailyForecasts,
        city: dataSchedule.weatherForecast?.city,
        todayForecast: dataSchedule.todayForecast,
      };
    },

    updateSelectedBlockId(state, id) {
      return {
        ...state,
        selectedBlockId: id,
      };
    },

    updateSelectedEvent(state, event) {
      return {
        ...state,
        selectedEvent: event,
      };
    },
  },

  effects: (dispatch) => ({
    async loadSchedule(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchSchedule(rootState.site.currentSite.id, payload.startDate, payload.endDate),
        (data) => {
          dispatch.schedule.updateSchedule(data.schedule);
        },
      );
    },
  }),
};
