import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// constants
import COLORS from '../../../colors';

// components
import TodayForecastDetailsData from '../ForecastDetailsData';
import TodayForecastDetailsTitle from './ForecastDetailsTitle';

const TodayForecastDetailsCard = ({ eto, loading, temperatureMax, temperatureMin, rainPrecipitation, rainProbability, wind }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        {!loading ? (
          <View style={styles.minMax}>
            <TodayForecastDetailsData style={styles.columnContent} value={temperatureMin} />
            <TodayForecastDetailsData style={styles.columnContent} value={temperatureMax} />
          </View>
        ) : null}
        <TodayForecastDetailsTitle value={t('min_max')} />
      </View>

      <View style={styles.verticalLine} />

      <View style={styles.column}>
        {!loading ? (
          <View style={styles.minMax}>
            <TodayForecastDetailsData style={styles.columnContent} value={rainProbability} />
            <TodayForecastDetailsData style={styles.columnContent} value={rainPrecipitation} />
          </View>
        ) : null}
        <TodayForecastDetailsTitle value={t('precipitation')} />
      </View>

      <View style={styles.verticalLine} />

      <View style={styles.column}>
        {!loading ? <TodayForecastDetailsData style={styles.columnContent} value={eto} /> : null}
        <TodayForecastDetailsTitle value={t('eto_daily')} />
      </View>

      <View style={styles.verticalLine} />

      <View style={styles.column}>
        {!loading ? <TodayForecastDetailsData style={styles.columnContent} value={wind} /> : null}
        <TodayForecastDetailsTitle value={t('weather_wind')} />
      </View>
    </View>
  );
};

TodayForecastDetailsCard.propTypes = {
  eto: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  temperatureMin: PropTypes.string,
  temperatureMax: PropTypes.string,
  rainPrecipitation: PropTypes.string,
  rainProbability: PropTypes.string,
  wind: PropTypes.string,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 55,
    paddingHorizontal: 10,
  },
  minMax: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  column: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnContent: {
    paddingHorizontal: '5%',
    fontWeight: 'bold',
  },
  verticalLine: {
    width: 1,
    height: '100%',
    backgroundColor: COLORS.greyBorderColor,
  },
});

export default TodayForecastDetailsCard;
