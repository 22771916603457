import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import { commandAnalyticsForType, commandTypeForID } from '../../models/automationCommandType';

//models
import AutomationEntity from '../../models/entities/automationEntity';

//hooks
import { useTranslation } from '../../hooks/useTranslation';

//component
import AutomationButton from './AutomationButton';

const AutomationControl = ({ automationComponent, onButtonPress }) => {
  const { t } = useTranslation();
  const commands = automationComponent.getCommandsInfo(t);
  const label = automationComponent.hasModifyType() ? t('automation_modify_stop_text') : t('automation_start_stop_text');

  const onPress = (commandID) => {
    const type = commandTypeForID(commandID);
    const analyticEvent = commandAnalyticsForType(type);
    onButtonPress(type, analyticEvent);
  };

  return (
    <View>
      <View style={styles.startStopTextContainer}>
        <Text style={styles.startStopTextFont}>{label}</Text>
      </View>
      <View style={styles.buttonContainer}>
        {commands.map((command) => (
          <AutomationButton
            key={command.id}
            id={command.id}
            icon={command.icon}
            text={command.text}
            onPress={onPress}
            testId={command.testID}
          />
        ))}
      </View>
    </View>
  );
};

AutomationControl.propTypes = {
  automationComponent: PropTypes.instanceOf(AutomationEntity).isRequired,
  onButtonPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  startStopTextContainer: {
    marginVertical: 16,
    marginLeft: 21,
  },
  startStopTextFont: {
    fontFamily: FONTS.notoSansItalic,
    fontSize: 13,
    letterSpacing: 0.61,
    color: COLORS.greyishBrown,
  },
});

export default React.memo(AutomationControl);
