const isNullOrUndefined = (obj) => {
  return obj === null || obj === undefined;
};

const parseFloatV2 = (value) => {
  if (value === '') {
    return undefined;
  }

  return Number.parseFloat(value.replace(',', '.'));
};

export { isNullOrUndefined, parseFloatV2 };
