import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

//proptypes
import { navigationShape } from '../../shapes/navigation';

// icons
import icnNavLeft from '../../assets/icons/icnNavLeft.png';
import icnNavRight from '../../assets/icons/icnNavRight.png';

//entity
import BlockEntity from '../../models/entities/blockEntity';

// components
import BurgerMenuButton from '../header/BurgerMenuButton';
import Title from '../header/Title';
import TouchIconButton from '../button/TouchIconButton';

// styles
import { globalStyles } from '../../styles';

const WeatherHeader = ({ changeActiveWeatherStation, navigation, selectedWeatherStation, siteName, weatherStations }) => {
  const SCREEN_NAME = 'weather';

  const hasMultipleWeatherStations = weatherStations.length > 1;

  let selectedIndex;

  if (selectedWeatherStation && hasMultipleWeatherStations) {
    selectedIndex = weatherStations.findIndex((weatherStation) => {
      return weatherStation.id === selectedWeatherStation.id;
    });
  }

  const onLeftArrowClick = () => {
    const previousIndex = (selectedIndex + weatherStations.length - 1) % weatherStations.length;
    changeActiveWeatherStation(previousIndex);
  };

  const onRightArrowClick = () => {
    const nextIndex = (selectedIndex + 1) % weatherStations.length;
    changeActiveWeatherStation(nextIndex);
  };

  return (
    <View style={globalStyles.headerContainer}>
      <BurgerMenuButton screenName={SCREEN_NAME} navigation={navigation} />

      <View style={globalStyles.containerFullSizeRow}>
        {hasMultipleWeatherStations ? (
          <TouchIconButton
            icon={icnNavLeft}
            onPress={onLeftArrowClick}
            iconStyle={globalStyles.icnNav}
            testId={'weather__left-arrow'}
          />
        ) : null}

        <View style={globalStyles.headerTitle}>
          {hasMultipleWeatherStations ? (
            <>
              <Title key={'multi-station__title'} value={siteName} type="title" />
              <Title key={'multi-station__subtitle'} value={selectedWeatherStation?.name || ''} type="subtitle" />
            </>
          ) : (
            <Title key={'one-station__title'} value={siteName} type="title" />
          )}
        </View>

        {hasMultipleWeatherStations ? (
          <TouchIconButton
            icon={icnNavRight}
            onPress={onRightArrowClick}
            iconStyle={globalStyles.icnNav}
            testId={'weather__right-arrow'}
          />
        ) : null}
      </View>

      <View style={globalStyles.headerActions} />
    </View>
  );
};

WeatherHeader.propTypes = {
  changeActiveWeatherStation: PropTypes.func.isRequired,
  navigation: navigationShape.isRequired,
  selectedWeatherStation: PropTypes.instanceOf(BlockEntity),
  siteName: PropTypes.string,
  weatherStations: PropTypes.arrayOf(PropTypes.instanceOf(BlockEntity)).isRequired,
};

export default WeatherHeader;
