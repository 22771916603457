import moment from 'moment-timezone';

const DATE_FOR_API_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
const MAXIMUM_HOURS_BEFORE_REMOVING_MINUTES = 99;

const timeDifferenceWithMinutesAbbreviation = (duration, minutesAbbreviation, minutesAbbreviationWithoutHours) => {
  if (duration.seconds() > 0) {
    duration.add(1, 'm');
  }

  const elapsedHours = Math.floor(duration.asHours());
  const elapsedMinutes = duration.minutes();

  if (elapsedHours > 0) {
    if (elapsedHours > MAXIMUM_HOURS_BEFORE_REMOVING_MINUTES) {
      return `${elapsedHours}h`;
    }
    if (elapsedMinutes > 0) {
      return elapsedMinutes < 10
        ? `${elapsedHours}h0${elapsedMinutes}${minutesAbbreviation}`
        : `${elapsedHours}h${elapsedMinutes}${minutesAbbreviation}`;
    }
    return `${elapsedHours}h`;
  }

  return `${elapsedMinutes}${minutesAbbreviationWithoutHours}`;
};

const formattedTimeDifference = (startDate, endDate) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const duration = moment.duration(Math.abs(startDateMoment.diff(endDateMoment)));
  return timeDifferenceWithMinutesAbbreviation(duration, 'min', 'min');
};

const formattedTimeDifferenceForGraph = (startDate, endDate) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const duration = moment.duration(Math.abs(startDateMoment.diff(endDateMoment)));
  return timeDifferenceWithMinutesAbbreviation(duration, '', 'min');
};

const formattedTimeDifferenceForSchedule = (duration) => {
  const durationMoment = moment.duration(duration);
  return timeDifferenceWithMinutesAbbreviation(durationMoment, '', 'm');
};

const formattedDurationFromNow = (date, t) => {
  let formattedDate = '--';

  if (date) {
    const momentDate = moment(date);
    const duration = moment.duration(Math.abs(momentDate.diff(moment())));

    if (duration.years() > 0) {
      const years = duration.years();
      formattedDate = `${years} ${t('date_format_year_device', { count: years })}`;
    } else if (duration.months() > 0) {
      const months = duration.months();
      formattedDate = `${months} ${t('date_format_month_device', { count: months })}`;
    } else if (duration.days() > 0) {
      const days = duration.days();
      formattedDate = `${days} ${t('date_format_day_ago_device', { count: days })}`;
    } else if (duration.hours() > 0) {
      const hours = duration.hours();
      formattedDate = `${hours} ${t('date_format_hour_device', { count: hours })}`;
    } else if (duration.minutes() > 0) {
      const minutes = duration.minutes();
      formattedDate = `${minutes} ${t('date_format_minute_device')}`;
    } else {
      formattedDate = t('date_format_just_now_device');
    }
  }

  return formattedDate;
};

const middleMillisecondBetweenMoments = (startDate, endDate) => {
  return (momentToMilliseconds(startDate) + momentToMilliseconds(endDate)) / 2;
};

const isToday = (date, timezone) => {
  const dateToUse = moment();
  if (timezone) {
    dateToUse.tz(timezone);
  }
  return dateToUse.isSame(date, 'day');
};

const isTomorrow = (date, timezone) => {
  const dateToUse = moment();
  if (timezone) {
    dateToUse.tz(timezone);
  }
  return date.startOf('day').diff(dateToUse.startOf('day'), 'day') === 1;
};

const isInLessThanAWeek = (date) => {
  const diff = date.diff(moment(), 'week', true);
  return diff >= 0 && diff <= 1;
};

const momentToMilliseconds = (momentInTime) => {
  return momentInTime.toDate().getTime();
};

const formatDate = (dateString, format) => {
  return moment(dateString).format(format);
};

const toMomentDate = (dateString) => {
  return moment(dateString);
};

// when in utc mode, there is not dst
const formatDateForAPI = (dateString) => {
  return moment(dateString).utc().format(DATE_FOR_API_FORMAT);
};

const formatMoment = (momentInTime) => {
  return momentInTime.format('YYYY-MM-DDTHH:mm:ssZZ');
};

const secondsFromNowString = (t) => {
  return (number, withoutSuffix, key, isFuture) => t('date_format_just_now');
};

const pastDatesString = (t) => {
  return (value) => {
    if (value === t('date_format_just_now')) {
      return value;
    } else {
      return t('relative_date_format', value);
    }
  };
};

const daysToMilliseconds = (numberOfDays) => numberOfDays * 86400000;

const startOfDayHoursFromNow = (hours) => moment().clone().subtract({ hours }).startOf('day');

const formatWeekLabel = (first, last) => `${first?.date()} ${first?.format('MMM')} - ${last?.date()} ${last?.format('MMM')}`;

const getDayOfTheWeek = (date) => {
  if (date) {
    return moment(date).format('dddd');
  }

  return 'no_day_found';
};

const getEventDateRange = (nbWeekInPass, nbWeekInFuture) => {
  return {
    startDate: formatDateForAPI(moment().subtract(nbWeekInPass, 'weeks').startOf('day')),
    endDate: formatDateForAPI(moment().add(nbWeekInFuture, 'weeks').endOf('day')),
  };
};

export {
  formatDate,
  formatDateForAPI,
  formatMoment,
  formattedTimeDifference,
  formattedTimeDifferenceForGraph,
  formattedTimeDifferenceForSchedule,
  formattedDurationFromNow,
  isToday,
  isTomorrow,
  isInLessThanAWeek,
  middleMillisecondBetweenMoments,
  momentToMilliseconds,
  pastDatesString,
  secondsFromNowString,
  daysToMilliseconds,
  startOfDayHoursFromNow,
  formatWeekLabel,
  getDayOfTheWeek,
  getEventDateRange,
  toMomentDate,
};
