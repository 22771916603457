import moment from 'moment-timezone';

// Queries
import { fetchDashboard } from '../queries/dashboard';
import { fetchTensionBlocks } from '../queries/tensionBlocks';

//services
import { sortTensionBlocks } from '../screens/siteBlueBandIndex/sorting';
import { executeApolloQuery } from './apolloHelper';

//entity
import BlockEntity from './entities/blockEntity';

//constants
import { MEDIUM, HIGH, orderByStatus } from './status/tensionStatus';

export const dashboard = {
  state: {
    criticalBlocks: undefined,
    dashboard: undefined,
    schedule: undefined,
    tensionBlocks: [],
    sortedTensionBlocks: [],
  },

  reducers: {
    updateDashboard(state, dataDashboard) {
      const schedule = countActivities(dataDashboard);
      const criticalBlocks = sortByCriticalTension(dataDashboard);
      return { ...state, dashboard: dataDashboard, schedule, criticalBlocks };
    },

    updateTensionBlocks(state, blocks) {
      const blocksEntities = blocks.reduce((accumulator, block) => {
        const blockEntity = new BlockEntity(block);
        if (blockEntity.isTensionCapable()) {
          return accumulator.concat(blockEntity);
        }
        return accumulator;
      }, []);
      return { ...state, tensionBlocks: blocksEntities, sortedTensionBlocks: blocksEntities };
    },

    updateSortedTensionBlocks(state, sortedTensionBlocks) {
      return { ...state, sortedTensionBlocks };
    },
  },

  effects: (dispatch) => ({
    async loadDashboard(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchDashboard(rootState.site.currentSite.id, payload.startDate, payload.endDate),
        (data) => {
          dispatch.dashboard.updateDashboard(data.dashboardData);
          dispatch.blueBandIndex.updateBlueBandIndexSite(data.dashboardData.blueBandIndex);
        },
      );
    },

    async loadTensionBlocksForCurrentSite(payload, rootState) {
      const { sortBlocksBy, blockFilters } = payload;
      await executeApolloQuery(
        dispatch,
        async () => await fetchTensionBlocks(rootState.site.currentSite.id, sortBlocksBy, blockFilters),
        (data) => {
          dispatch.dashboard.updateTensionBlocks(data.tensionBlocks);
        },
      );
    },

    async sortTensionBlocks(payload, rootState) {
      const { column, order } = payload;
      const tensionBlockEntities = [...rootState.dashboard.tensionBlocks];
      sortTensionBlocks(tensionBlockEntities, column, order);

      dispatch.dashboard.updateSortedTensionBlocks(tensionBlockEntities);
    },

    async reset() {
      dispatch.dashboard.updateTensionBlocks([]);
    },
  }),
};

const countActivities = (dataDashboard) => {
  let ongoingCount = 0;
  let lateCount = 0;
  let plannedCount = 0;

  if (dataDashboard.blocks) {
    dataDashboard.blocks.forEach((block) => {
      const blockEntity = new BlockEntity(block);
      const plannedEvents = blockEntity.getPlannedEvents();

      let currentEventCount = 0;
      plannedEvents.forEach((event) => {
        if (eventIsCurrent(event)) {
          currentEventCount++;
        }
      });

      if (blockEntity.isIrrigating()) {
        ongoingCount++;
      } else {
        lateCount += currentEventCount;
      }
      plannedCount += plannedEvents.length;
    });
  }
  return { ongoingCount, lateCount, plannedCount };
};

const eventIsCurrent = (event) => {
  if (event.startDate) {
    return moment(event.startDate).isBefore() && moment(event.endDate).isAfter();
  }
  return false;
};

const sortByCriticalTension = (dataDashboard) => {
  let criticalBlocks = [];

  if (dataDashboard.blocks) {
    dataDashboard.blocks.forEach((block) => {
      const blockEntity = new BlockEntity(block);
      const status = blockEntity.tensionCapability().status();

      if (status === HIGH || status === MEDIUM) {
        criticalBlocks.push(blockEntity);
      }
    });
  }

  return criticalBlocks.sort(byCriticalTension);
};

const byCriticalTension = (a, b) => {
  const statusCompare = orderByStatus(a.tensionCapability().status()) - orderByStatus(b.tensionCapability().status());

  if (statusCompare === 0) {
    const aliasA = a.alias ? a.alias : '';
    const aliasB = b.alias ? b.alias : '';
    return aliasA.localeCompare(aliasB);
  } else {
    return statusCompare;
  }
};
