import { StyleSheet, Text, View } from 'react-native';
import { getVersion, getBuildNumber } from 'react-native-device-info';
import Config from 'react-native-config';
import React from 'react';

// consts
import COLORS from '../colors';
import FONTS from '../fonts';

// services
import * as EnvironmentService from '../services/EnvironmentService';

// components
import Divider from './Divider';

const AppVersion = () => {
  return (
    !EnvironmentService.isProductionEnvironment() && (
      <>
        <Divider style={styles.divider} />
        <View style={styles.versionContainer} testID="app-version__label">
          <Text style={styles.versionText}>
            {Config.ENV_NAME} {getVersion()} {getBuildNumber()}
          </Text>
        </View>
      </>
    )
  );
};

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: COLORS.brownGrey,
  },
  versionText: {
    paddingVertical: 6,
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    color: COLORS.warmGrey,
  },
  versionContainer: {
    justifyContent: 'flex-start',
    paddingVertical: 7,
    paddingLeft: 25,
    borderColor: COLORS.white,
    borderLeftWidth: 3,
  },
});

export default AppVersion;
