// constants
import { TENSION_TREND } from '../tensionTrend';

export default class TensionCapability {
  constructor(tensionCapability) {
    this.tensionCapability = tensionCapability;
  }

  blueBandIndex() {
    if (this.tensionCapability) {
      const nullBlueBandIndex = { wet: null, good: null, dry: null };
      return { ...nullBlueBandIndex, ...this.tensionCapability.blueBandIndex };
    }
    return { wet: null, good: null, dry: null };
  }

  tensionReadings() {
    const dataStreams = [];

    if (this.tensionCapability) {
      if (this.tensionCapability.readings != null) {
        const sensors = this.tensionCapability.readings;
        sensors.forEach((sensor) => {
          dataStreams.push(sensor);
        });
      }
    }

    return dataStreams;
  }

  unit() {
    if (this.tensionReadings() && this.tensionReadings().length > 0) {
      return this.tensionReadings()[0].unit;
    }
    return undefined;
  }

  tensionComfortZone() {
    if (this.tensionCapability) {
      const optimalTension = this.tensionCapability.optimalTension;
      if (optimalTension) {
        return {
          minimum: optimalTension.minimum,
          maximum: optimalTension.maximum,
        };
      }
    }

    return null;
  }

  tensionTrend() {
    if (this.tensionCapability) {
      return this.tensionCapability.trend;
    }

    return TENSION_TREND.UNAVAILABLE;
  }

  status() {
    return this.tensionCapability?.status;
  }
}
