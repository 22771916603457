import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// icons
import icnNote from '../../assets/icons/icnNote.png';

const NoteHeader = ({ title, testId }) => {
  return (
    <View style={styles.header} testID={testId}>
      <Image source={icnNote} style={styles.icnNote} />
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};

NoteHeader.propTypes = {
  title: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  title: {
    marginLeft: 10,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 14,
    letterSpacing: 1.1,
    color: COLORS.greyishBrown,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 9,
  },
  icnNote: {
    width: 16,
    height: 22,
  },
});

export default NoteHeader;
