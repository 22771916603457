import React from 'react';
import { StyleSheet, Image, View, Pressable } from 'react-native';
import PropTypes from 'prop-types';

// shape
import { styleShape } from '../../shapes/style';

// services
import { isAndroid } from '../../services/PlatformService';

// constants
import COLORS from '../../colors';
import { navigationShape } from '../../shapes/navigation';

// icons
import icnBack from '../../assets/icons/icnBack.png';

const BackButton = ({ color = COLORS.white, onPress, navigation, style }) => {
  if (isAndroid()) {
    return null;
  }

  const goBack = onPress || (() => navigation.goBack());

  return (
    <View style={[style, styles.imageContainer]}>
      <Pressable onPress={goBack} testID="back-button__touchable">
        <Image source={icnBack} style={[styles.icnBack, { tintColor: color }]} />
      </Pressable>
    </View>
  );
};

BackButton.propTypes = {
  color: PropTypes.string,
  style: styleShape,
  navigation: navigationShape,
  onPress: PropTypes.func,
};

const styles = StyleSheet.create({
  imageContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  icnBack: {
    width: 24,
    height: 24,
  },
});

export default BackButton;
