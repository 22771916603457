import React, { useEffect, useRef, forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// services
import { isPortrait } from '../../services/DeviceOrientationService';
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';
import { readingsToDataStream } from '../../utils/dataStreamsUtil';

// constants
import COLORS from '../../colors';
import * as CAPABILITIES from '../../models/capability';
import ORIENTATIONS from '../../services/DeviceOrientations';

// hooks
import useGraph from '../../hooks/useGraph';

// components
import Graph from '../graph/Graph';
import GraphHeader from '../graph/GraphHeader';
import DataStreams from '../graph/DataStreams';
import ShadowedDivider from '../ShadowedDivider';
import { getGraphDataType } from '../../models/graphDataType';
import DataStreamModal from './DataStreamModal';

const GraphDetails = forwardRef(
  (
    {
      baseBlock,
      capability,
      selectedDataStreams,
      goToDetails,
      currentOrientation = ORIENTATIONS.PORTRAIT,
      onGraphInteraction,
      isModalOpened = false,
      toggleModal,
      showTensionForecast,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const blockDetails = useSelector((state) => state.blocks.selectedBlock);
    const dataStreams = useSelector((state) => state.graph.dataStreams);
    const tensionForecast = useSelector((state) => state.graph.tensionForecast);
    const futureIrrigationEvents = useSelector((state) => state.graph.futureIrrigationEvents);
    const weatherDataStreams = useSelector((state) => state.graph.weatherDataStreams);
    const currentSite = useSelector((state) => state.site.currentSite);
    const irrigationEvents = useSelector((state) => state.graph.irrigationEvents);
    const loadingDataStreams = useSelector((state) => !!state.loading.effects.graph.loadDataStreams);
    const loadingTensionForecast = useSelector((state) => !!state.loading.effects.graph.loadTensionForecast);
    const loadingLocalData = useSelector((state) => !!state.loading.effects.blocks.loadBlockDataStreams);
    const loadingSiteWeatherData = useSelector((state) => !!state.loading.effects.graph.loadSiteWeatherDataStreams);
    const dataStreamsIsLoading = loadingDataStreams || loadingLocalData || loadingSiteWeatherData || loadingTensionForecast;

    const tensionDataStreams = readingsToDataStream(blockDetails?.tensionCapability().tensionReadings());
    const [state, onToggleDataStreams] = useGraph({
      dataStreams,
      tensionForecast,
      futureIrrigationEvents,
      legendStreams: blockDetails?.dataStreams().concat(weatherDataStreams).concat(tensionDataStreams),
      selectedDataStreams,
      block: blockDetails,
      defaultTimeScaleInHours: currentSite.defaultTimeScaleInHours,
      irrigationEvents,
      dataType: getGraphDataType(capability),
      isLoading: dataStreamsIsLoading,
    });

    const blockAliasPresenterRef = useRef(new BlockAliasPresenter(baseBlock, capability));

    const isPortraitMode = isPortrait(currentOrientation);
    const shouldDisplayModal = !isPortraitMode && isModalOpened;

    useEffect(() => {
      dispatch.blocks.loadBlockDataStreams({ blockId: baseBlock.id });
      dispatch.graph.loadSiteWeatherDataStreams();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBlock.id]);

    useEffect(() => {
      /* istanbul ignore next */
      if (blockDetails && blockDetails.id === baseBlock.id) {
        blockAliasPresenterRef.current = new BlockAliasPresenter(blockDetails, capability);
      } else {
        blockAliasPresenterRef.current = new BlockAliasPresenter(baseBlock, capability);
      }
    }, [baseBlock, blockDetails, capability]);

    const onGraphRefresh = (dataRange) => {
      onGraphInteraction(dataRange);
    };

    return (
      <View
        style={isPortraitMode ? styles.graphContainer : styles.graphContainerLandscape}
        testID={'block-details__graph-container'}>
        {isPortraitMode && (
          <>
            <GraphHeader blockAliasPresenter={blockAliasPresenterRef.current} showBorder={false} onAliasPress={goToDetails} />
            <ShadowedDivider />
          </>
        )}

        <Graph
          ref={ref}
          graphPresenter={state.graphPresenter}
          isPortrait={isPortraitMode}
          onRefresh={onGraphRefresh}
          isLoading={dataStreamsIsLoading || !state.graphPresenter.dataType}
          testId={'block-detail__graph'}
          showTensionForecast={showTensionForecast}
        />

        {isPortraitMode ? (
          <DataStreams
            block={blockDetails}
            localStreams={blockDetails?.dataStreams()}
            onToggle={onToggleDataStreams}
            selectedDataStreams={state.graphPresenter.selectedDataStreams}
            weatherStreams={weatherDataStreams}
          />
        ) : (
          <DataStreamModal
            block={blockDetails}
            localStreams={blockDetails?.dataStreams()}
            onToggle={onToggleDataStreams}
            selectedDataStreams={state.graphPresenter.selectedDataStreams}
            weatherStreams={weatherDataStreams}
            isVisible={shouldDisplayModal}
            onCloseModal={toggleModal}
          />
        )}
      </View>
    );
  },
);

GraphDetails.displayName = 'GraphDetails';

GraphDetails.propTypes = {
  baseBlock: PropTypes.instanceOf(BlockEntity).isRequired,
  capability: PropTypes.oneOf([CAPABILITIES.TENSION, CAPABILITIES.TEMPERATURE, CAPABILITIES.FLOW_STATION]).isRequired,
  selectedDataStreams: PropTypes.object,
  currentOrientation: PropTypes.string,
  goToDetails: PropTypes.func.isRequired,
  onGraphInteraction: PropTypes.func.isRequired,
  isModalOpened: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  showTensionForecast: PropTypes.bool,
};

const styles = StyleSheet.create({
  graphContainer: {
    flex: 1,
    borderColor: COLORS.whisper,
    borderWidth: 1,
    borderRadius: 5,
    marginHorizontal: 11,
    marginTop: 12.5,
    marginBottom: 13,
    backgroundColor: COLORS.white,
  },
  graphContainerLandscape: {
    flex: 1,
    borderColor: COLORS.whisper,
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: COLORS.white,
  },
});

export default GraphDetails;
