export const INCHES = {
  name: 'INCHES',
  displayValue: '"',
};

export const CENTIMETERS = {
  name: 'CM',
  displayValue: 'cm',
};

export const METERS = {
  name: 'M',
  displayValue: 'm',
};

export const FEET = {
  name: 'FEET',
  displayValue: 'feet',
};

export const FT = {
  name: 'FT',
  displayValue: 'ft',
};
