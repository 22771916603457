import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Pressable, View } from 'react-native';
import moment from 'moment-timezone';

//service
import SchedulePresenter from '../../presenters/SchedulePresenter';

// constants
import { globalStyles } from '../../styles';
import { ScheduleConstants } from '../../screens/schedule/ScheduleConstants';
import { AUTOMATED_PLANNED, INFERRED, PLANNED } from '../../models/calendarType';
import COLORS from '../../colors';

// components
import Event from './Event';

const BlockDay = ({ block, presenter, day, onDayPress }) => {
  const year = day.year();
  const dayOfYear = day.dayOfYear();
  const isCurrent = moment().isSame(day, 'day');

  const inferredEventDuration = presenter.getEventsDuration(INFERRED, block, dayOfYear, year);
  const anyInferredEvents = inferredEventDuration > 0;
  const isMultipleDailyInferredEvents = presenter.getDailyEvents(INFERRED, block, dayOfYear, year).length > 1;
  const inferredEventDurationFormat = presenter.getEventDurationFormat(inferredEventDuration);

  const manualPlannedEventsDuration = presenter.getEventsDuration(PLANNED, block, dayOfYear, year);
  const anyManualPlannedEvents = manualPlannedEventsDuration > 0;
  const isMultipleDailyManualPlannedEvents = presenter.getDailyEvents(PLANNED, block, dayOfYear, year).length > 1;
  const manualPlannedEventsDurationFormat = presenter.getEventDurationFormat(manualPlannedEventsDuration);

  const automatedPlannedEventsDuration = presenter.getEventsDuration(AUTOMATED_PLANNED, block, dayOfYear, year);
  const anyAutomatedPlannedEvents = automatedPlannedEventsDuration > 0;
  const isMultipleDailyAutomatedPlannedEvents = presenter.getDailyEvents(AUTOMATED_PLANNED, block, dayOfYear, year).length > 1;
  const automatedPlannedEventsDurationFormat = presenter.getEventDurationFormat(automatedPlannedEventsDuration);

  const anyEvents = anyInferredEvents || anyManualPlannedEvents || anyAutomatedPlannedEvents;

  const dailyEvents = presenter.getAllDailyEvents(block, dayOfYear, year);

  const modalButtonTestId = `schedule__modal-button-block-${block.id}-day-${dayOfYear}`;

  return (
    <View style={[styles.cell, isCurrent ? { backgroundColor: COLORS.lightGreyBlue12 } : {}]}>
      {anyEvents ? (
        <Pressable
          style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.button]}
          testID={modalButtonTestId}
          onPress={() => onDayPress(block, day, dailyEvents)}>
          {anyManualPlannedEvents ? (
            <Event duration={manualPlannedEventsDurationFormat} type={PLANNED} isMultiple={isMultipleDailyManualPlannedEvents} />
          ) : null}
          {anyAutomatedPlannedEvents ? (
            <Event
              duration={automatedPlannedEventsDurationFormat}
              type={AUTOMATED_PLANNED}
              isMultiple={isMultipleDailyAutomatedPlannedEvents}
            />
          ) : null}
          {anyInferredEvents ? (
            <Event duration={inferredEventDurationFormat} type={INFERRED} isMultiple={isMultipleDailyInferredEvents} />
          ) : null}
        </Pressable>
      ) : null}
    </View>
  );
};

BlockDay.propTypes = {
  block: PropTypes.object.isRequired,
  day: PropTypes.instanceOf(moment).isRequired,
  onDayPress: PropTypes.func.isRequired,
  presenter: PropTypes.instanceOf(SchedulePresenter).isRequired,
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  cell: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: ScheduleConstants.CELL_WIDTH,
    height: ScheduleConstants.CELL_HEIGHT,
    borderColor: ScheduleConstants.BORDER_COLOR,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
});

export default React.memo(BlockDay);
