import ANALYTICS from '../../services/AnalyticsEvents';

export const GRAPH = {
  index: 0,
  key: 'graph',
  analyticsEvent: ANALYTICS.eventSegmentedGraph,
};

export const DETAILS = {
  index: 1,
  key: 'details',
  analyticsEvent: ANALYTICS.eventSegmentedDetails,
};
