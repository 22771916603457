import { CriticalTemperature } from '../models/entities/criticalTemperature';

export class DashboardPresenter {
  constructor(dashboard, translation) {
    this.dashboard = dashboard;
    this.translation = translation;
  }

  criticalTemperature() {
    let criticalTemperature = null;

    if (this.dashboard != null && this.dashboard.criticalTemperature != null) {
      criticalTemperature = new CriticalTemperature(this.dashboard.criticalTemperature, this.translation);
    }
    return criticalTemperature;
  }
}
