import { getApolloClient } from '../clients/apollo';
import { BLOCK_DATASTREAMS } from './blockDatastreams.graphql';

const fetchBlockDataStream = async (siteId, blockId, dataStreamTypes) => {
  const { loading, error, data } = await getApolloClient().query({
    query: BLOCK_DATASTREAMS,
    variables: {
      siteId,
      block: blockId,
      dataStreamTypes,
    },
  });
  let block;
  if (data) {
    block = data.viewer.site.block;
  }

  return {
    loading,
    error,
    block,
  };
};

export { fetchBlockDataStream };
