import { getApolloClient } from '../clients/apollo';
import { TEMPERATURE_BLOCKS } from './temperatureblocks.graphql';

const fetchTemperatureBlocks = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: TEMPERATURE_BLOCKS,
    variables: {
      siteId,
    },
  });
  let temperatureBlocks;
  if (data) {
    temperatureBlocks = data.viewer.site.blocks;
  }

  return {
    loading,
    error,
    temperatureBlocks,
  };
};

export { fetchTemperatureBlocks };
