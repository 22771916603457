import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

//proptype
import { tensionReadingShape } from '../../shapes/dataStreams';

//hooks
import { useTranslation } from '../../hooks/useTranslation';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

//presenters
import { TensionSensorPresenter } from '../../presenters/TensionSensorPresenter';

//components
import RoundedUnderline from '../RoundedUnderline';

const TensionLegendElement = ({ readings }) => {
  const { t } = useTranslation();
  const tensionReadingPresenter = new TensionSensorPresenter(readings, t);
  const depthColor = tensionReadingPresenter.color();
  const value = tensionReadingPresenter.value();
  const unit = tensionReadingPresenter.unit();

  return (
    <View style={styles.container}>
      <View style={styles.tensionButtonContainer}>
        <RoundedUnderline color={depthColor} />

        <View style={styles.readingSection}>
          <Text style={styles.tensionValue}>{value}</Text>
          <Text style={styles.tensionUnit}>{unit}</Text>
        </View>
      </View>
    </View>
  );
};

TensionLegendElement.propTypes = {
  readings: tensionReadingShape.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '33.33%',
  },
  tensionButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 78,
    height: 15,
  },
  readingSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tensionValue: {
    paddingRight: 5,
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  tensionUnit: {
    alignSelf: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
});

export default TensionLegendElement;
