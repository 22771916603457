import { StyleSheet } from 'react-native';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

const styles = StyleSheet.create({
  durationHourText: {
    position: 'absolute',
    left: 110,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  durationMinutesText: {
    position: 'absolute',
    left: 221,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  spaceBetweenPicker: {
    marginRight: 4,
  },
  pickerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 40,
  },
  picker: {
    width: 110,
    height: 120,
  },
  pickerItem: {
    // only iOS
    height: 120,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 15,
    color: COLORS.greyishBrownDarker,
  },
});

export default styles;
