import React from 'react';
import { View, StyleSheet, Text, Image, Pressable } from 'react-native';
import PropTypes from 'prop-types';

//hooks
import { useTranslation } from '../../hooks/useTranslation';

//icons
import icnWindMap from '../../assets/icons/icnWindMap.png';
import icnDirectionWeatherWidget from '../../assets/icons/icnDirectionWeatherWidget.png';
import icnWeatherWidgetTemperature from '../../assets/icons/temperature/icnTemperatureMapWidget.png';

//entity
import BlockEntity from '../../models/entities/blockEntity';

// utils
import { isNullOrUndefined } from '../../utils/util';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

//components
import SummaryBox from '../SummaryBox';
import NoDataView from '../NoDataView';

const WeatherStationInformations = ({ block, onClick, paddingTop, testId }) => {
  const { t } = useTranslation();

  const capability = block.weatherCapability();

  const windSpeed = capability.formattedWindSpeed(t);
  const windDirection = capability.formattedWindDirection(t);
  const airTemperature = capability.formattedAirTemperature();
  const hasNoData = isNullOrUndefined(airTemperature) && isNullOrUndefined(windSpeed) && isNullOrUndefined(windDirection);

  const handleOnPress = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Pressable style={[styles.container, { paddingTop }]} onPress={handleOnPress} testID={testId}>
      {hasNoData ? <NoDataView message={t('weather_view_no_data')} /> : null}

      {airTemperature ? (
        <SummaryBox
          title={t('weather_temperature')}
          image={icnWeatherWidgetTemperature}
          podContent={
            <View style={styles.podTemperatureContent}>
              <Text style={[styles.podText, styles.podTemperatureText]}>{airTemperature}</Text>
            </View>
          }
        />
      ) : null}

      {windDirection || windSpeed ? (
        <SummaryBox
          title={t('weather_wind')}
          image={icnWindMap}
          topText={windSpeed}
          podContent={
            <View style={styles.podWeatherContent}>
              <Image source={icnDirectionWeatherWidget} style={styles.podWeatherWidgetImage} />
              <View style={styles.podWeatherTextContainer}>
                <Text style={[styles.podText, styles.podWeatherText]}>{windDirection}</Text>
              </View>
            </View>
          }
        />
      ) : null}
    </Pressable>
  );
};

WeatherStationInformations.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  onClick: PropTypes.func,
  paddingTop: PropTypes.number,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  podTemperatureContent: {
    alignItems: 'center',
  },
  podTemperatureText: {
    fontSize: 22,
    letterSpacing: 0.79,
  },
  podText: {
    fontFamily: FONTS.notoSansBold,
    color: COLORS.white,
  },
  podWeatherContent: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: 6,
  },
  podWeatherText: {
    fontSize: 18,
    letterSpacing: 0.67,
  },
  podWeatherTextContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  podWeatherWidgetImage: {
    tintColor: COLORS.white,
  },
});

export default WeatherStationInformations;
