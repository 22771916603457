// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/FiraSans-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/FiraSans-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/FiraSans-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/FiraSans-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("fonts/FiraSans-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("fonts/NotoSans-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("fonts/NotoSans-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("fonts/NotoSans-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Make the body full-height */
html, body {
  height: 100%;
}

/* Disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* Make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

@font-face {
  font-family: "FiraSans-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
}

@font-face {
  font-family: "FiraSans-Italic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
}

@font-face {
  font-family: "FiraSans-Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
}

@font-face {
  font-family: "FiraSans-Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
}

@font-face {
  font-family: "FiraSans-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2");
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff2");
}

input {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./web/app.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,YAAY;AACd;;AAEA,yDAAyD;AACzD;EACE,gBAAgB;AAClB;;AAEA,sCAAsC;AACtC;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,4BAA4B;EAC5B,4DAAqD;AACvD;;AAEA;EACE,8BAA8B;EAC9B,4DAAuD;AACzD;;AAEA;EACE,6BAA6B;EAC7B,4DAAsD;AACxD;;AAEA;EACE,8BAA8B;EAC9B,4DAAuD;AACzD;;AAEA;EACE,+BAA+B;EAC/B,4DAAwD;AAC1D;;AAEA;EACE,4BAA4B;EAC5B,4DAAqD;AACvD;;AAEA;EACE,8BAA8B;EAC9B,4DAAuD;AACzD;;AAEA;EACE,+BAA+B;EAC/B,4DAAwD;AAC1D;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* Make the body full-height */\nhtml, body {\n  height: 100%;\n}\n\n/* Disable body scrolling if you are using <ScrollView> */\nbody {\n  overflow: hidden;\n}\n\n/* Make the root element full-height */\n#root {\n  display: flex;\n  height: 100%;\n}\n\n@font-face {\n  font-family: \"FiraSans-Bold\";\n  src: url(\"fonts/FiraSans-Bold.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"FiraSans-Italic\";\n  src: url(\"fonts/FiraSans-Italic.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"FiraSans-Light\";\n  src: url(\"fonts/FiraSans-Light.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"FiraSans-Medium\";\n  src: url(\"fonts/FiraSans-Medium.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"FiraSans-Regular\";\n  src: url(\"fonts/FiraSans-Regular.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"NotoSans-Bold\";\n  src: url(\"fonts/NotoSans-Bold.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"NotoSans-Italic\";\n  src: url(\"fonts/NotoSans-Italic.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"NotoSans-Regular\";\n  src: url(\"fonts/NotoSans-Regular.woff2\") format(\"woff2\");\n}\n\ninput {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
