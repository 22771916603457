import COLORS from '../../colors';
import PIN_ICONS from '../../assets/icons/mapPins/pinIcons';

import HIGH_ICON from '../../assets/icons/icnTempSmallHigh.png';
import MEDIUM_ICON from '../../assets/icons/icnTempSmallMed.png';
import LOW_ICON from '../../assets/icons/icnTempSmallLow.png';
import ERROR_ICON from '../../assets/icons/icnTempError.png';

export const HIGH = 'HIGH';
export const MEDIUM = 'MEDIUM';
export const LOW = 'LOW';
export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const OUT_OF_FIELD = 'OUT_OF_FIELD';

const temperatureColorsByStatus = {
  HIGH: COLORS.dustyOrange,
  MEDIUM: COLORS.maize,
  LOW: COLORS.seafoamBlue,
  OUT_OF_FIELD: COLORS.greyishThree,
  COMMUNICATION_ERROR: COLORS.greyishThree,
  [undefined]: COLORS.darkBlue,
  default: COLORS.darkBlue,
  ERROR: COLORS.greyishThree,
};

const temperatureIconByStatus = {
  HIGH: HIGH_ICON,
  MEDIUM: MEDIUM_ICON,
  LOW: LOW_ICON,
  COMMUNICATION_ERROR: ERROR_ICON,
  default: ERROR_ICON,
};

const mapTemperatureIconByStatus = {
  HIGH: PIN_ICONS.tempHigh,
  MEDIUM: PIN_ICONS.tempMed,
  LOW: PIN_ICONS.tempLow,
  OUT_OF_FIELD: PIN_ICONS.outOfField,
  COMMUNICATION_ERROR: PIN_ICONS.tempCommunicationProblem,
  default: PIN_ICONS.tempUnknown,
};

export const colorForStatus = (status) => temperatureColorsByStatus[status] || temperatureColorsByStatus.default;

export const iconForStatus = (status) => temperatureIconByStatus[status] || temperatureIconByStatus.default;

export const iconForMapStatus = (status) => mapTemperatureIconByStatus[status] || mapTemperatureIconByStatus.default;
