import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import SegmentedControlTab from 'react-native-segmented-control-tab';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// styles
import { BLOCK_DETAILS_CONTENT_HORIZONTAL_MARGIN } from '../../styles';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import OPACITIES from '../../opacities';
import * as TABS from './BlockDetailsTabs';

const BlockDetailsSubHeader = ({ selectedTab = { index: 0 }, changeActiveTab }) => {
  const { t } = useTranslation();

  const tabValues = Object.values(TABS)
    .sort((a, b) => a.index - b.index)
    .map((tab) => t(tab.key));

  return (
    <View style={styles.segmentedViewContainer} testID="block-details__segmented-view-container">
      <SegmentedControlTab
        tabsContainerStyle={styles.tabsContainerStyle}
        tabStyle={styles.tabStyle}
        firstTabStyle={styles.firstTabStyle}
        lastTabStyle={styles.lastTabStyle}
        tabTextStyle={styles.tabTextStyle}
        activeTabStyle={selectedTab?.index === 0 ? styles.firstTabActiveStyle : styles.lastTabActiveStyle}
        activeTabTextStyle={styles.activeTabTextStyle}
        selectedIndex={selectedTab?.index}
        allowFontScaling={false}
        values={tabValues}
        onTabPress={changeActiveTab}
      />
    </View>
  );
};

BlockDetailsSubHeader.propTypes = {
  selectedTab: PropTypes.object.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
};

const activeTabStyle = {
  backgroundColor: COLORS.white,
  borderRadius: 8,
  borderWidth: 0,
  margin: 1,
  shadowColor: COLORS.warmGrey,
  shadowOpacity: OPACITIES.shadow,
  shadowRadius: 2,
  elevation: 3,
};

const styles = StyleSheet.create({
  segmentedViewContainer: {
    alignItems: 'center',
    paddingHorizontal: BLOCK_DETAILS_CONTENT_HORIZONTAL_MARGIN,
    paddingVertical: 10,
    backgroundColor: COLORS.white,
  },
  tabsContainerStyle: {
    height: 35,
    borderColor: COLORS.midGrey,
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: COLORS.midGrey,
  },
  tabStyle: {
    borderWidth: 0,
    borderRadius: 5,
    margin: 1,
    backgroundColor: COLORS.midGrey,
  },
  firstTabStyle: {
    borderRadius: 8,
    margin: 1,
    borderRightWidth: 0,
  },
  lastTabStyle: {
    borderWidth: 0,
  },
  tabTextStyle: {
    paddingHorizontal: 35,
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
  firstTabActiveStyle: {
    ...activeTabStyle,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  lastTabActiveStyle: {
    ...activeTabStyle,
    shadowOffset: {
      width: -2,
      height: 2,
    },
  },
  activeTabTextStyle: {
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
});

export default BlockDetailsSubHeader;
