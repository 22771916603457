import moment from 'moment-timezone';
import { SORT_ORDER } from '../../utils/sorting';

const SORT_COLUMNS = {
  DATE: 'date',
  DRY_BLUE_BAND_INDEX: 'dry',
  GOOD_BLUE_BAND_INDEX: 'good',
  WET_BLUE_BAND_INDEX: 'wet',
};

const sortBlockBlueBandIndexes = (blockBlueBandIndex, column, order) => {
  const compareByDate = (a, b) => {
    if (moment(a.date).isBefore(moment(b.date))) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    } else if (moment(a.date).isAfter(moment(b.date))) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    }
    return 0;
  };

  const compareByDryBluebandIndex = (a, b) => {
    return compareBluebandIndex(a.value.dry(), b.value.dry());
  };

  const compareByGoodBluebandIndex = (a, b) => {
    return compareBluebandIndex(a.value.good(), b.value.good());
  };

  const compareByWetBluebandIndex = (a, b) => {
    return compareBluebandIndex(a.value.wet(), b.value.wet());
  };

  const compareBluebandIndex = (val1, val2) => {
    const value1 = val1 !== null ? val1 : -1;
    const value2 = val2 !== null ? val2 : -1;
    if (value1 > value2) {
      return order === SORT_ORDER.ASC ? 1 : -1;
    } else if (value1 < value2) {
      return order === SORT_ORDER.ASC ? -1 : 1;
    }
    return 0;
  };

  switch (column) {
    case SORT_COLUMNS.DATE: {
      blockBlueBandIndex.sort(compareByDate);
      break;
    }
    case SORT_COLUMNS.DRY_BLUE_BAND_INDEX: {
      blockBlueBandIndex.sort(compareByDryBluebandIndex);
      break;
    }
    case SORT_COLUMNS.GOOD_BLUE_BAND_INDEX: {
      blockBlueBandIndex.sort(compareByGoodBluebandIndex);
      break;
    }
    case SORT_COLUMNS.WET_BLUE_BAND_INDEX: {
      blockBlueBandIndex.sort(compareByWetBluebandIndex);
      break;
    }
  }
};

export { sortBlockBlueBandIndexes, SORT_COLUMNS };
