import PropTypes from 'prop-types';
import { SensorShape } from './sensor';

const TotalVolumeShape = PropTypes.shape({
  unit: PropTypes.string,
  value: PropTypes.number,
  dataStream: SensorShape,
});

const OutflowShape = PropTypes.shape({
  unit: PropTypes.string,
  value: PropTypes.number,
  dataStream: SensorShape,
});

export const FlowmeterShape = PropTypes.shape({
  isFlowing: PropTypes.bool,
  totalVolume: TotalVolumeShape,
  outflow: OutflowShape,
});
