import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

// shape
import { styleShape } from '../../shapes/style';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// services
import { TensionSensorPresenter } from '../../presenters/TensionSensorPresenter';
import { DataStreamButtonPresenter } from '../../presenters/DataStreamButtonPresenter';
import { useTranslation } from '../../hooks/useTranslation';
import { categoryForDataStreamType, DATA_STREAM_CATEGORY } from '../../models/dataStreamTypes';

//components
import ShadowedDivider from '../ShadowedDivider';
import RoundedUnderline from '../RoundedUnderline';

const LocalDataList = ({ containerStyle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const block = useSelector((state) => state.blocks.selectedBlock);

  const [localDataList, setLocalDataList] = useState([]);

  useEffect(() => {
    if (!block) {
      return;
    }
    dispatch.blocks.loadBlockDataStreams({ blockId: block.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!block || !block.sensors) {
      return;
    }

    const tensionReadings = block.tensionCapability().tensionReadings();
    setLocalDataList([...tensionReadings, ...block.dataStreams()]);
  }, [block]);

  const renderHeader = () => {
    return (
      <View style={[styles.header, styles.centerRow]}>
        <Text style={styles.titleText}>{t('local_data')}</Text>
        <Text style={styles.titleSubText}>{t('last_recorded_value')}</Text>
      </View>
    );
  };

  const renderIcon = (presenter, color) => {
    if (presenter.icon()) {
      return <Image source={presenter.icon()} style={{ tintColor: color }} />;
    } else {
      return <Text style={[styles.unknownIcon, { color }]}>{presenter.getFirstLetterOfDataStreamType()}</Text>;
    }
  };

  const renderItem = (item, index) => {
    const datastream = item.dataStream ? item.dataStream : item;
    const backgroundColor = index % 2 === 0 ? COLORS.white : COLORS.whiteTwo;
    const dataStreamCategory = categoryForDataStreamType(datastream.dataStreamType);
    let color, icon, formattedValue, name, presenter, value;

    if (dataStreamCategory !== DATA_STREAM_CATEGORY.TENSION) {
      presenter = new DataStreamButtonPresenter(item, t);
      name = presenter.getDescription();
      value = presenter.value;
      color = value !== null ? dataStreamCategory.preferredColor : COLORS.lightGrey2;
      icon = renderIcon(presenter, color);
      formattedValue = presenter.getFormattedValue();
    } else {
      presenter = new TensionSensorPresenter(item, t);
      name = presenter.name();
      const rawValue = presenter.rawValue(true);
      value = presenter.value(true);
      color = isNaN(rawValue) ? COLORS.lightGrey2 : `#${item.colorCode}`;
      icon = <RoundedUnderline color={color} />;
      formattedValue = `${value} ${presenter.unit()}`;
    }

    return (
      <View key={datastream.id} style={[styles.itemContainer, { backgroundColor }]}>
        <View style={styles.itemIdentifier}>
          <View style={styles.itemIcon}>{icon}</View>
          <Text ellipsizeMode="tail" style={styles.itemName}>
            {name}
          </Text>
        </View>
        <Text style={styles.itemValue}>{formattedValue}</Text>
      </View>
    );
  };

  return (
    <View style={[containerStyle, styles.container]}>
      {renderHeader()}
      {localDataList.length > 0 && (
        <View style={styles.dataStreamSection}>
          <ShadowedDivider />
          {localDataList.map((item, index) => renderItem(item, index))}
        </View>
      )}
    </View>
  );
};

LocalDataList.propTypes = {
  containerStyle: styleShape,
};

const styles = StyleSheet.create({
  dataStreamSection: {
    justifyContent: 'center',
    backgroundColor: COLORS.white,
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flex: 1,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 50,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    paddingHorizontal: 18,
  },
  itemIcon: {
    alignItems: 'center',
    width: 20,
  },
  itemIdentifier: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  itemName: {
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
    flexShrink: 1,
    marginEnd: 10,
    marginStart: 20,
  },
  itemValue: {
    minWidth: 60,
    textAlign: 'right',
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  titleSubText: {
    maxWidth: 142,
    marginHorizontal: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSansLight,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  titleText: {
    marginVertical: 10,
    marginLeft: 17,
    textAlign: 'center',
    fontFamily: FONTS.firaSansMedium,
    fontSize: 18,
    color: COLORS.greyishBrown,
  },
  unknownIcon: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 20,
  },
});

export default LocalDataList;
