import COLORS from '../../colors';

export const TEMPERATURE_STREAM_COLORS = [
  COLORS.dustyOrange,
  COLORS.fadedOrange,
  COLORS.waterBlue,
  COLORS.steelBlue,
  COLORS.paleTeal,
  COLORS.lightGreyBlue,
  COLORS.greyishBrown,
  COLORS.fadedBlue,
  COLORS.darkishPink,
  COLORS.babyPurple,
  COLORS.maize,
  COLORS.pinkishBrown,
  COLORS.watermelon,
  COLORS.deepRed,
];

export const DEFAULT_STREAM_COLORS = [
  COLORS.babyPurple,
  COLORS.darkishPink,
  COLORS.dustyOrange,
  COLORS.fadedBlue,
  COLORS.fadedOrange,
  COLORS.greyishBrown,
  COLORS.lightGreyBlue,
  COLORS.paleTeal,
  COLORS.steelBlue,
  COLORS.waterBlue,
];

const minute = 60 * 1000;
const hour = 60 * minute;
const day = 24 * hour;

export const TIME_CONSTANTS = {
  ONE_MINUTE_IN_MS: minute,
  FIVE_MINUTES_IN_MS: 5 * minute,
  ONE_HOUR_IN_MS: hour,
  ONE_DAY_IN_MS: day,
  FOUR_DAYS_IN_MS: 4 * day,
};
