import { dataStreamName } from '../utils/dataStreamsUtil';
import { INCHES } from '../models/units/depthUnit';

const MAX_VALUE_WITH_DECIMAL = 100;

export class TensionSensorPresenter {
  constructor(tensionReading, translation) {
    this.tensionReading = tensionReading;
    this.translation = translation;
  }

  depth() {
    if (!this.tensionReading.depth || this.tensionReading.depth === 0) {
      return;
    }

    const roundedDepth = Math.round(this.tensionReading.depth);
    return `${roundedDepth}`;
  }

  formattedDepth() {
    const roundedDepth = this.depth();

    if (!roundedDepth) {
      return;
    }

    let translatedDepthUnit;
    if (!this.tensionReading.depthUnit || this.tensionReading.depthUnit === INCHES.name) {
      translatedDepthUnit = INCHES.displayValue;
    } else {
      translatedDepthUnit = this.translation(`unit_${this.tensionReading.depthUnit.toLowerCase()}`);
    }

    return `${roundedDepth}${translatedDepthUnit}`;
  }

  color() {
    if (this.tensionReading.color) {
      return this.tensionReading.color;
    }

    if (!this.tensionReading.colorCode) {
      return;
    }

    return `#${this.tensionReading.colorCode}`;
  }

  name() {
    const name = dataStreamName(this.tensionReading.dataStream.dataStreamType, null, this.translation);
    let depth = this.formattedDepth() || '';

    return `${name} ${depth}`;
  }

  value(returnLastRecordedValue = false) {
    let value = this.rawValue(returnLastRecordedValue);

    if (isNaN(value)) {
      return '- -';
    }

    if (this.maxValueReached(returnLastRecordedValue)) {
      return `+${this.tensionReading.dataStream.specifiedRange.maximum}`;
    }

    if (value >= MAX_VALUE_WITH_DECIMAL) {
      return `${Math.round(value)}`;
    }

    return value;
  }

  rawValue(returnLastRecordedValue = false) {
    const value = returnLastRecordedValue ? this.tensionReading.dataStream?.value : this.tensionReading.value;
    return parseFloat(value);
  }

  unit() {
    return this.translation(`unit_${this.tensionReading.unit.toLowerCase()}`);
  }

  unitKey() {
    return this.tensionReading.unit.toUpperCase();
  }

  maxValueReached(returnLastRecordedValue = false) {
    const tensionValue = !returnLastRecordedValue ? this.tensionReading.value : this.tensionReading.dataStream?.value;
    const maxValue = this.tensionReading.dataStream?.specifiedRange?.maximum;
    if (tensionValue && maxValue) {
      return tensionValue >= maxValue;
    }
    return false;
  }
}
