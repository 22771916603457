//entity
import BlueBandIndexStatuses from './blueBandIndexStatuses';

export default class BlueBandIndexDay {
  constructor(date, value) {
    this.id = date;
    this.date = date;
    this.value = new BlueBandIndexStatuses(value);
  }
}
