import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { AUTOMATION_RULES } from './automationRules.graphql';

const fetchAutomationRules = async (siteId) => {
  let automationRules;

  const { loading, error, data } = await getApolloClient().query({
    query: AUTOMATION_RULES,
    variables: { siteId },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  if (data) {
    automationRules = data.viewer.site.automationRules;
  }

  return {
    loading,
    error,
    automationRules,
  };
};

export { fetchAutomationRules };
