// models
import { categoryForDataStreamType, DATA_STREAMS_TYPES } from '../models/dataStreamTypes';
import { getWindDirectionName } from '../models/windDirections';

// utils
import { dataStreamName, formatValue } from '../utils/dataStreamsUtil';

// consts
import { MIN_VALUE_BEFORE_COMPACT } from '../consts';

export class DataStreamButtonPresenter {
  constructor(datastream, translation) {
    this.datastream = datastream;
    this.dataStreamType = datastream.dataStreamType;
    this.value = datastream.value;
    this.unit = datastream.unit;
    this.translation = translation;
  }

  icon() {
    return categoryForDataStreamType(this.dataStreamType).icon;
  }

  getDescription() {
    return dataStreamName(this.dataStreamType, this.datastream.description, this.translation);
  }

  getFirstLetterOfDataStreamType() {
    if (!this.dataStreamType) {
      return '';
    }

    return this.dataStreamType.substring(0, 1).toUpperCase();
  }

  getValue(i18n) {
    if (this.dataStreamType === DATA_STREAMS_TYPES.WIND_DIRECTION.code) {
      return this.translation(getWindDirectionName(this.value));
    } else if (this.value > MIN_VALUE_BEFORE_COMPACT) {
      return this.value.toLocaleString(i18n.language, { notation: 'compact' });
    }
    return this.value;
  }

  getUnit() {
    if (this.dataStreamType === DATA_STREAMS_TYPES.WIND_DIRECTION.code) {
      return '';
    } else {
      return this.unit ? this.translation(`unit_${this.unit.toLowerCase()}`) : '';
    }
  }

  getFormattedValue() {
    return `${formatValue(this.value)} ${this.getUnit()}`;
  }
}
