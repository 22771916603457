export const WindDirections = [
  'wind_direction_n',
  'wind_direction_ne',
  'wind_direction_e',
  'wind_direction_se',
  'wind_direction_s',
  'wind_direction_sw',
  'wind_direction_w',
  'wind_direction_nw',
];

export const getWindDirectionName = (value) => {
  const compassSection = Math.round((value % 360) / 45);
  const index = compassSection % WindDirections.length;

  return WindDirections[index];
};
