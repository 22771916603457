import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// presenter
import { WeatherForecastPresenter } from '../../presenters/WeatherForecastPresenter';

// components
import City from './todayWeather/City';
import TodayDate from './todayWeather/TodayDate';
import TodayForecastDetailsCard from './todayWeather/TodayForecastDetailsCard';
import TodayForecast from './todayWeather/TodayForecast';

const TodayWeather = ({
  cityName,
  etoUnit,
  etoValue,
  rainPrecipitation,
  rainProbability,
  rainUnit,
  temperatureMax,
  temperatureMin,
  temperatureUnit,
  temperatureToday,
  weatherCode,
  windDirection,
  windSpeed,
  windUnit,
  loading,
}) => {
  const { t } = useTranslation();

  const weatherForecastPresenter = new WeatherForecastPresenter(
    t,
    etoUnit,
    etoValue,
    rainPrecipitation,
    rainProbability,
    rainUnit,
    temperatureMax,
    temperatureMin,
    temperatureUnit,
    weatherCode,
    windDirection,
    windSpeed,
    windUnit,
    temperatureToday,
  );

  const formattedTemperatureToday = weatherForecastPresenter.formattedTemperatureToday();
  const formattedTemperatureMin = weatherForecastPresenter.formattedTemperatureMin();
  const formattedTemperatureMax = weatherForecastPresenter.formattedTemperatureMax();
  const formattedEto = weatherForecastPresenter.formattedEto();
  const formattedRainPrecipitation = weatherForecastPresenter.formattedRainPrecipitation();
  const formattedRainProbability = weatherForecastPresenter.formattedRainProbability();
  const formattedWindSpeed = weatherForecastPresenter.formattedWindSpeed();
  const todayWeatherText = weatherForecastPresenter.translatedDescription();
  const todayWeatherImage = weatherForecastPresenter.image();

  return (
    <View>
      <View style={styles.topPart}>
        <City name={cityName} loading={loading} />
        <TodayDate />
      </View>

      <View style={styles.middlePart}>
        <TodayForecast
          formattedTemperature={formattedTemperatureToday}
          weatherText={todayWeatherText}
          weatherImage={todayWeatherImage}
          loading={loading}
        />
      </View>

      <TodayForecastDetailsCard
        eto={formattedEto}
        loading={loading}
        temperatureMax={formattedTemperatureMax}
        temperatureMin={formattedTemperatureMin}
        rainPrecipitation={formattedRainPrecipitation}
        rainProbability={formattedRainProbability}
        wind={formattedWindSpeed}
      />
    </View>
  );
};

TodayWeather.propTypes = {
  cityName: PropTypes.string,
  etoUnit: PropTypes.string,
  etoValue: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  rainPrecipitation: PropTypes.number,
  rainProbability: PropTypes.number,
  rainUnit: PropTypes.string,
  temperatureMax: PropTypes.number,
  temperatureMin: PropTypes.number,
  temperatureToday: PropTypes.number,
  temperatureUnit: PropTypes.string,
  weatherCode: PropTypes.string,
  windDirection: PropTypes.number,
  windSpeed: PropTypes.number,
  windUnit: PropTypes.string,
};

const styles = StyleSheet.create({
  topPart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '3%',
  },
  middlePart: {
    marginBottom: '5%',
  },
});

export default TodayWeather;
