import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import Proptypes from 'prop-types';
import moment from 'moment-timezone';

// services
import { useTranslation } from '../../../hooks/useTranslation';

//components
import GenericSection from '../../layouts/GenericSection';
import AnytimeEventForm from '../../schedule/AnytimeEventForm';
import ShadowedDivider from '../../ShadowedDivider';
import SelectStartDate from './time/SelectStartDate';
import SelectDuration from './time/SelectDuration';

const TimeSelector = ({
  eventDate,
  eventDuration,
  setEventDuration,
  isAnytimeEvent,
  isAnytimeEventVisible = true,
  onSelectAnytime,
  onSelectDate,
  eventConstraints,
}) => {
  const { t } = useTranslation();

  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [isDurationPickerVisible, setDurationPickerVisible] = useState(false);

  /* istanbul ignore next */
  const setDatePickerVisibility = () => {
    setDatePickerVisible(!isDatePickerVisible);
    setDurationPickerVisible(false);
  };

  const setDurationPickerVisibility = () => {
    setDatePickerVisible(false);
    setDurationPickerVisible(!isDurationPickerVisible);
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setDatePickerVisible(false);
      setDurationPickerVisible(false);
    }, []),
  );

  return (
    <GenericSection title={t('edit_event_select_time_section_title')}>
      {isAnytimeEventVisible ? <AnytimeEventForm isAnytimeEvent={isAnytimeEvent} onSelectAnytime={onSelectAnytime} /> : null}

      <SelectStartDate
        eventDate={eventDate}
        isAnytimeEvent={isAnytimeEvent}
        isDatePickerVisible={isDatePickerVisible}
        onSelectDate={onSelectDate}
        setDatePickerVisibility={setDatePickerVisibility}
        startDateConstraint={eventConstraints}
      />

      <SelectDuration
        eventDuration={eventDuration}
        setEventDuration={setEventDuration}
        isDurationPickerVisible={isDurationPickerVisible}
        setDurationPickerVisibility={setDurationPickerVisibility}
        durationConstraints={eventConstraints}
      />

      <ShadowedDivider style={styles.divider} />
    </GenericSection>
  );
};

TimeSelector.propTypes = {
  eventDate: Proptypes.instanceOf(moment).isRequired,
  eventDuration: Proptypes.object.isRequired,
  isAnytimeEvent: Proptypes.bool,
  isAnytimeEventVisible: Proptypes.bool,
  onSelectAnytime: Proptypes.func.isRequired,
  onSelectDate: Proptypes.func.isRequired,
  setEventDuration: Proptypes.func.isRequired,
  eventConstraints: Proptypes.object,
};

const styles = StyleSheet.create({
  divider: {
    marginLeft: 16,
  },
});

export default TimeSelector;
