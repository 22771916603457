import { gql } from 'graphql-tag';

export const SITES = gql`
  {
    viewer {
      sites {
        id
        name
      }
    }
  }
`;
