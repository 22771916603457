import React, { useEffect, useState } from 'react';
import { StyleSheet, ImageBackground, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

// constants
import ROUTES from '../navigation/routes';
import { navigationShape } from '../shapes/navigation';

import { useAnalyticsContext } from '../components/initialization/AnalyticsProvider';
import LoadingOverlay from '../components/LoadingOverlay';
import { backgroundImage } from '../assets';
import COLOR from '../colors';

const LoadingScreen = ({ navigation }) => {
  const authentication = useSelector((state) => state.authentication);
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentSite = useSelector((state) => state.site.currentSite);
  const preferences = useSelector((state) => state.preferences);
  const refreshTokenError = useSelector((state) => state.error.refreshTokenError);
  const [loading, setLoading] = useState(true);
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  useEffect(() => {
    async function doPostLoginActions() {
      await dispatch.authentication.postLogin(true);
    }

    if (preferences.authenticationTokenLoaded) {
      if (authentication.token) {
        doPostLoginActions();
      } else {
        setLoading(false);
        navigation.navigate(ROUTES.AUTH);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences.authenticationTokenLoaded]);

  useEffect(() => {
    if (authentication.token && currentUser?.id && currentSite?.id) {
      analyticsService.setUser(currentUser, currentSite);
      setLoading(false);
      navigation.navigate(ROUTES.APP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication, currentUser, currentSite, navigation]);

  useEffect(() => {
    async function clearErrorAndLogout() {
      refreshTokenError.dispatch.error.clearRefreshTokenError();
      await dispatch.authentication.logOut();
      setLoading(false);
      navigation.navigate(ROUTES.AUTH);
    }

    if (refreshTokenError) {
      clearErrorAndLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenError]);

  return (
    <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
      <View style={styles.container}>
        <LoadingOverlay isLoading={loading} />
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
    backgroundColor: COLOR.splashBackground,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: 16,
  },
});

LoadingScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default LoadingScreen;
