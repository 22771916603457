const OPACITIES = {
  lightShadow: 0.1,
  dataStreamButtonShadow: 0.5,
  shadow: 0.2,
  pressedButton: 0.8,
  drawerBorder: 0.19,
  modalBackDrop: 0.3,
};

export default OPACITIES;
