import { StyleSheet, View } from 'react-native';
import React from 'react';

// shape
import { styleShape } from '../shapes/style';

// services
import COLORS from '../colors';
import OPACITIES from '../opacities';

// components
import Divider from './Divider';

const ShadowedDivider = ({ style }) => {
  return (
    <View style={styles.separator}>
      <Divider style={[styles.divider, style]} />
    </View>
  );
};

ShadowedDivider.propTypes = {
  style: styleShape,
};

const styles = StyleSheet.create({
  separator: {
    zIndex: 2,
    height: 2,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.whisper,
  },
});

export default ShadowedDivider;
