import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { SvgXml } from 'react-native-svg';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// icons
import iconDrop from '../../assets/icons/icnDrop.svg';

const MainSwitchesLegend = ({ switches, isPortrait }) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.container, isPortrait ? {} : styles.topMargin]}>
      {switches.map((element) => (
        <View style={styles.element} key={element.id}>
          <SvgXml xml={iconDrop} fill={element.color} fillOpacity={0.55} width={18} height={18} />
          <Text style={styles.text}>
            {element.description || t(`data_stream_button_name_${element.subSensorType}`.toLowerCase())}
          </Text>
        </View>
      ))}
    </View>
  );
};

MainSwitchesLegend.propTypes = {
  switches: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
  ),
  isPortrait: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginRight: 22,
    marginBottom: 10,
  },
  element: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: 22,
  },
  text: {
    marginLeft: 5,
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 10,
    color: COLORS.greyishBrown,
  },
  topMargin: {
    marginTop: 10,
  },
});

export default MainSwitchesLegend;
