import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from 'react-native';
import COLORS from '../../colors';
import FONTS from '../../fonts';

const AuthSubHeader = ({ description }) => {
  return <Text style={styles.description}>{description}</Text>;
};

AuthSubHeader.propTypes = {
  description: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  description: {
    opacity: 0.8,
    width: '90%',
    marginHorizontal: 10,
    marginTop: 10,
    marginBottom: 42,
    textAlign: 'center',
    fontFamily: FONTS.notoSansItalic,
    fontSize: 14,
    letterSpacing: 0.6,
    color: COLORS.white,
  },
});

export default AuthSubHeader;
