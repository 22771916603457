import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { MAP_ITEMS } from './mapItems.graphql';

async function fetchMapItems(siteId) {
  const { loading, error, data } = await getApolloClient().query({
    query: MAP_ITEMS,
    variables: { siteId },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  let mapItems;
  if (data) {
    mapItems = data.viewer.site.mapItems;
  }

  return {
    loading,
    error,
    mapItems,
  };
}

export { fetchMapItems };
