const LOGIN_ERROR_CODES = {
  ERROR_CODE_INVALID_USERNAME_PASSWORD: 1000,
  ERROR_CODE_INACTIVE_USER: 1001,
  ERROR_CODE_NO_SITES: 1002,
  ERROR_CODE_NO_ACCESS: 1003,
  ERROR_CODE_LOCKED_TEMPORARILY: 1004,
  ERROR_CODE_LOCKED: 1005,
};

export const LOGIN_ERRORS = {
  [LOGIN_ERROR_CODES.ERROR_CODE_INVALID_USERNAME_PASSWORD]: 'login_view_error_invalid_username_password',
  [LOGIN_ERROR_CODES.ERROR_CODE_NO_SITES]: 'login_view_error_no_sites',
  [LOGIN_ERROR_CODES.ERROR_CODE_INACTIVE_USER]: 'login_view_error_inactive_user',
  [LOGIN_ERROR_CODES.ERROR_CODE_LOCKED_TEMPORARILY]: 'login_view_error_locked_temporarily',
  [LOGIN_ERROR_CODES.ERROR_CODE_NO_ACCESS]: 'login_view_error_no_access',
  [LOGIN_ERROR_CODES.ERROR_CODE_LOCKED]: 'login_view_error_locked',
};

export function getLoginErrorMessageKey({ code, message }) {
  return (
    LOGIN_ERRORS[code] ||
    (message === 'Network request failed' || message === 'Failed to fetch'
      ? 'generic_error_network_error_message'
      : 'generic_error_server_error_message')
  );
}
