import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import { findEventIcon, INFERRED, PLANNED, AUTOMATED_PLANNED } from '../../models/calendarType';
import { ScheduleConstants } from '../../screens/schedule/ScheduleConstants';
import FONTS from '../../fonts';
import COLORS from '../../colors';

const Event = ({ duration, isMultiple, type }) => {
  const formattedType = type.toLowerCase();
  const formattedMultiplicity = isMultiple ? 'multiple' : 'single';

  return (
    <View style={styles.eventContainer}>
      <Image
        source={findEventIcon(type, isMultiple)}
        style={styles.cellImage}
        testID={`schedule__${formattedType}-${formattedMultiplicity}-icon`}
      />
      <Text style={styles.cellTextStyle} numberOfLines={1} adjustsFontSizeToFit>
        {duration}
      </Text>
    </View>
  );
};

Event.propTypes = {
  duration: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([INFERRED, PLANNED, AUTOMATED_PLANNED]).isRequired,
};

const styles = StyleSheet.create({
  cellImage: {
    width: 18,
    height: 16,
    marginVertical: 2,
    marginRight: 1,
    resizeMode: 'center',
  },
  cellTextStyle: {
    width: ScheduleConstants.CELL_WIDTH - 20,
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  eventContainer: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 1,
  },
});

export default Event;
