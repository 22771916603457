import { gql } from 'graphql-tag';

export const NOTE = gql`
  query note($siteId: ID!, $startDate: String!, $endDate: String!) {
    viewer {
      site(id: $siteId) {
        id
        notes(startDate: $startDate, endDate: $endDate) {
          id
          content
          createdBy
          createdAt
        }
        blocks(filters: { capabilities: [TENSION] }) {
          id
          alias
          notes(startDate: $startDate, endDate: $endDate) {
            id
            content
            createdBy
            createdAt
          }
        }
      }
    }
  }
`;
