import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

const EventItemTime = ({ startTime, endTime }) => {
  return (
    <View style={styles.leftContainer}>
      <Text style={styles.left}>{startTime}</Text>

      {endTime !== '' ? <Text style={styles.left}>{endTime}</Text> : null}
    </View>
  );
};

EventItemTime.propTypes = {
  startTime: PropTypes.any,
  endTime: PropTypes.any,
};

const styles = StyleSheet.create({
  left: {
    margin: 3,
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  leftContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: 75,
    height: '80%',
    paddingRight: 5,
    borderRightWidth: 0,
  },
});

export default EventItemTime;
