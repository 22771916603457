import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { useSharedValue } from 'react-native-reanimated';
import PropTypes from 'prop-types';

//propTypes
import { navigationShape } from '../../shapes/navigation';

// constants
import COLORS from '../../colors';
import { FORCE_START, FORCE_STOP, ADD_TIME, REMOVE_TIME } from '../../models/automationCommandType';

// services
import { SORT_ORDER, toggleSort } from '../../utils/sorting';
import { useTranslation } from '../../hooks/useTranslation';
import { isIos } from '../../services/PlatformService';

// styles
import { globalStyles } from '../../styles';

// components
import SortableList from '../../components/SortableList';
import AutomationPanel from '../../components/automation/AutomationPanel';
import ToastView from '../../components/ToastView';
import SkeletonPlaceholder from '../../components/skeletons/SkeletonPlaceholder';
import SortableAutomationHeader from './SortableAutomationHeader';
import AutomationItem from './AutomationItem';
import { SORT_COLUMNS } from './sorting';

const AutomationScreen = ({ navigation, route }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const listRef = useRef(undefined);
  const [initLoading, setInitLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [showAutomationPanel, setShowAutomationPanel] = useState(false);
  const currentSite = useSelector((state) => state.site.currentSite);
  const loading = useSelector((state) => !!state.loading.effects.automations.loadAutomationComponents);
  const sortLoading = useSelector((state) => !!state.loading.effects.automations.sortAutomationComponents);
  const components = useSelector((state) => state.automations.sortedAutomationComponents);
  const selectedComponent = useSelector((state) => state.automations.selectedAutomationComponent);
  const hasAutomationRules = useSelector(store.select.automations.hasAutomationRules);
  const hasEnabledAutomationRules = useSelector(store.select.automations.hasEnabledAutomationRules);
  const hasRPM = useSelector(store.select.automations.subSensorsHaveRPM);
  const offsetToast = isIos() ? 90 : 70;
  const canStartStopAutomation = useSelector(store.select.user.hasStartStopAutomationPermission);
  const irrigationAnimatedOpacity = useSharedValue(1);

  const initialSortOrderState = {
    component: SORT_ORDER.NONE,
    status: SORT_ORDER.NONE,
    rpm: SORT_ORDER.NONE,
  };

  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  const REFRESH_TIMER = 60000;

  /* istanbul ignore next */
  useFocusEffect(() => {
    const interval = setInterval(async () => {
      await dispatch.automations.loadAutomationComponents();
      sortAutomationComponentsFromSortedColumn();
    }, REFRESH_TIMER);

    return () => clearInterval(interval);
  });

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      if (route.params?.reloadData) {
        initialLoading();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  /* istanbul ignore next */
  const initialLoading = async () => {
    setInitLoading(true);
    await dispatch.automations.loadAutomationComponents();
    setSortedColumn({ ...initialSortOrderState, [SORT_COLUMNS.COMPONENT]: SORT_ORDER.ASC });
    setInitLoading(false);
  };

  useEffect(() => {
    return function cleanup() {
      setSortedColumn(initialSortOrderState);
      dispatch.automations.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite]);

  useEffect(() => {
    sortAutomationComponentsFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const sortAutomationComponentsFromSortedColumn = () => {
    const sortedColumnKey = Object.keys(sortedColumn).find((key) => sortedColumn[key] !== SORT_ORDER.NONE);

    if (sortedColumnKey) {
      dispatch.automations.sortAutomationComponents({
        column: SORT_COLUMNS[sortedColumnKey.toUpperCase()],
        order: sortedColumn[sortedColumnKey],
      });
    }
  };

  const refreshAutomationComponents = async () => {
    setRefreshing(true);
    await dispatch.automations.loadAutomationComponents();
    sortAutomationComponentsFromSortedColumn();
    setRefreshing(false);
  };

  /* istanbul ignore next */
  const toggleSortAndScrollToTop = (payload) => {
    if (listRef.current && !loading) {
      listRef.current.scrollToOffset({ x: 0, y: 0, animated: false });
      toggle(payload);
    }
  };

  const selectAutomationComponent = (component) => {
    if (canStartStopAutomation) {
      setShowAutomationPanel(true);
      dispatch.automations.updateSelectedAutomationComponent(component);
    }
  };

  const closeAutomationControl = () => {
    setShowAutomationPanel(false);
  };

  const onCommandSent = (type) => {
    let translatedType, text2;

    switch (type) {
      case ADD_TIME:
      case REMOVE_TIME:
        text2 = t('automation_command_send_modify_subtitle');
        break;
      case FORCE_START:
        translatedType = t('automation_start_button');
        text2 = t('automation_command_send_subtitle', translatedType.toLowerCase());
        break;
      case FORCE_STOP:
        translatedType = t('automation_stop_button');
        text2 = t('automation_command_send_subtitle', translatedType.toLowerCase());
        break;
    }

    Toast.show({
      type: 'successToast',
      position: 'top',
      text1: t('automation_command_send'),
      text2,
      autoHide: true,
      visibilityTime: 3500,
      topOffset: offsetToast,
    });
  };

  const renderAutomationItem = (element) => {
    return (
      <AutomationItem
        entity={element.item}
        keyIndex={element.index}
        onSelect={selectAutomationComponent}
        showRules={hasEnabledAutomationRules}
        animatedOpacity={irrigationAnimatedOpacity}
      />
    );
  };

  const renderEmptyView = () => {
    return (
      <View testID="loadingActivity">
        {[...Array(5)].map((_e, i) => (
          <View style={styles.skeletonRow} key={'row' + i}>
            <SkeletonPlaceholder width={60} height={45} />
            <SkeletonPlaceholder width={95} height={15} style={styles.skeletonText} />
            {hasRPM ? <SkeletonPlaceholder width={60} height={45} /> : <View />}
            {hasEnabledAutomationRules ? <SkeletonPlaceholder width={60} height={45} /> : <View />}
          </View>
        ))}
      </View>
    );
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View testID={'automation__subscreen-container'} style={globalStyles.bottomContainer}>
        <SortableAutomationHeader
          enableAutomationRules={hasEnabledAutomationRules}
          hasAutomationRules={hasAutomationRules}
          hasRPMSubSensors={hasRPM}
          loading={sortLoading}
          navigation={navigation}
          siteName={currentSite.name}
          sortedColumn={sortedColumn}
          toggle={toggleSortAndScrollToTop}
        />
        <SortableList
          testID={'automation__subscreen_list'}
          ref={listRef}
          elements={components}
          loading={loading || initLoading}
          refreshing={refreshing}
          renderEmptyView={renderEmptyView}
          renderItem={renderAutomationItem}
          screenName={'automation'}
          onRefresh={refreshAutomationComponents}
        />

        {selectedComponent ? (
          <AutomationPanel
            automationComponent={selectedComponent}
            onClose={closeAutomationControl}
            onCommandSent={onCommandSent}
            show={showAutomationPanel}
          />
        ) : null}
      </View>

      <Toast config={ToastView} />
    </SafeAreaView>
  );
};

AutomationScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

const styles = StyleSheet.create({
  skeletonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderColor: COLORS.greyish21,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: COLORS.white,
  },
});

export default AutomationScreen;
